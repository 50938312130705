import React from "react";
import {
  Dropdown,
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment-timezone";
import { MultiSearchSelectUser } from "../components/MultiSearchSelectUser";
import { schConversions } from "../commonFunctions/conversions";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import {
  ILaborScheduleDeptPositionRequestDto,
  ILaborScheduleEmployeeRequestDto,
} from "../../../../../Common/Contracts/ILaborSchedule";
import ForecastLock from "../../../../../Common/Assets/Images/icons/forecast-lock.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { LaborPerformance } from "../../../../../Common/Services/LaborPerfomance";
import { LaborTimeSheet } from "../../../../../Common/Services/LaborTimeSheet";
import { MoonIcon } from "../../../../../Common/Components/MoonIcon";
import { InfoTooltip } from "../../../../../Common/Components/InfoTooltip";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../../../Common/Utilis";
import { AnyAaaaRecord } from "dns";

export class AddEntryModal extends React.Component<any, any> {
  private multiSelectUser: any;
  private btnSaveNewEntry: any;

  constructor(props: any) {
    super(props);
    this.multiSelectUser = React.createRef();
    this.btnSaveNewEntry = React.createRef();
    this.state = {
      // loaderTrue: true,
      isValidRequest: false,
      departmentList: [],
      departmentId: "",
      departmentSelected: "Select",
      departmentDangerWell: false,
      positionListMaster: [],
      positionList: [],
      positionSelected: "Select",
      positionId: "",
      positionDangerWell: false,
      employeeList: [],
      selectedEmployes: [],
      dateList: [],
      datewithApprovalStatus: [],
      dateSelected: "Select",
      // isDateApproved: "",
      // exportedStartDate: "",
      // exportedEndDate: "",
      dateDangerWell: false,
      inTime: "",
      inTimeDangerWell: false,
      outTime: "",
      outTimeDangerWell: false,
      punchInDateDangerWell: false,
      punchInDateList: [],
      punchInDate: "Select",
      associateDate: "Select",
      punchOutDateDangerWell: false,
      punchOutDateList: [],
      punchOutDate: "Select",
      showAssociateOption: false,
      isAssociateToggle: false,
      notes: "",
      isRemoveDNS: "No",
      isPayrollExported :this.props.parentState.isPayrollExported
    };
  }

  componentDidMount() {
    this.getDepartmentList();
  }

  getDateList() {
    let startDate: any = new Date(this.props.parentState.calenderDates[0]);
    let formatedDates: any = [];
    if (
      this.state.datewithApprovalStatus &&
      this.state.datewithApprovalStatus.length > 0
    ) {
      this.state.datewithApprovalStatus.find((item: any) => {
        let currentdate: any = new Date(item.date).getDate();
        let currentMonth: any = new Date(item.date).getMonth() + 1;
        let currentYear: any = new Date(item.date)
          .getFullYear()
          .toString()
          .substring(2, 4);
        if (currentdate.toString().length === 1) {
          currentdate = "0" + currentdate;
        }
        if (currentMonth.toString().length === 1) {
          currentMonth = "0" + currentMonth;
        }
        let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
        let obj = { date: tempDate, status: item.approvalStatus };
        formatedDates.push(obj);
        return false;
      });
    }

    let daterange: any = [];
    LaborTimeSheet.getCurrentDateByTenantAndHotelId(
      this.props.parentState.hidValue
    )
      .then((currentServeDateData) => {
        for (let i = 0; i < 7; i++) {
          let currentDate: any = new Date(startDate);
          currentDate.setDate(currentDate.getDate() + i);

          let mnths: any = new Date(currentDate).getMonth() + 1; // date form today only
          let days: any = new Date(currentDate).getDate();
          let year: any = new Date(currentDate)
            .getFullYear()
            .toString()
            .substring(2, 4);

          if (mnths.toString().length === 1) {
            mnths = "0" + mnths;
          }
          if (days.toString().length === 1) {
            days = "0" + days;
          }
          if (
            new Date(currentDate) <= new Date(currentServeDateData.currentDate)
          ) {
            const tempdate1 = `${mnths}/${days}/${year}`;
            let dateApporvalStatus = formatedDates.find(
              (i) => i.date === tempdate1
            );
            let obj = {
              date: tempdate1,
              approvalStatus: dateApporvalStatus
                ? dateApporvalStatus.status
                : "",
            };
            daterange.push(obj);
          }
          this.setState({
            dateList: currentServeDateData?.currentDate ? daterange : [],
          });
        }
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  getDepartmentList() {
    let requestPosDept = {} as ILaborScheduleDeptPositionRequestDto;
    requestPosDept.hotelID = this.props.parentState.hidValue;
    this.getPostionDepartment(requestPosDept);
  }

  getPostionDepartment = (request: ILaborScheduleDeptPositionRequestDto) => {
    LaborSchedule.GetLaborPositionDept(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let positonDetails: any = [];
          let departMentDetails: any = [];
          if (result.result.length > 0) {
            result.result[0].laborSchedulePosition.forEach((res: any) => {
              let positonObject: any = {};
              positonObject.id = res.positionID;
              positonObject.name = res.positionName;
              positonObject.deptid = res.deptID;
              positonDetails.push(positonObject);
            });
            result.result[0].laborScheduleDepartment.forEach((res: any) => {
              let departMentObject: any = {};
              departMentObject.id = res.deptID;
              departMentObject.name = res.departmentName;
              departMentDetails.push(departMentObject);
            });
          }
          this.setState({
            positionListMaster: positonDetails,
            departmentList: departMentDetails,
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  getPuncInOutDates = () => {
    debugger;
    let newDateList: Array<string> = [];
    let previousDate = moment(this.state.dateSelected)
      .subtract(1, "days")
      .format("MM/DD/YY");
    let currentDate = moment(this.state.dateSelected).format("MM/DD/YY");
    let todayActualDate = moment().format("MM/DD/YY");
    if(todayActualDate === currentDate){
       newDateList.push(previousDate);
      newDateList.push(currentDate);
    }else{
      let NextDate = moment(this.state.dateSelected)
      .add(1, "days")
      .format("MM/DD/YY");
      newDateList.push(previousDate);
      newDateList.push(currentDate);
      newDateList.push(NextDate);
    }

    this.setState({ punchInDateList: newDateList });
  };

  hideAddEntryModal = () => {
    this.props.hideAddEntryModal();
  };

  employeeSelection = (childState: any) => {
    this.setState({ selectedEmployes: childState.selectedItems }, () => {
      this.Isvalidate();
    });
  };

  multiSelectErrorValidation = () => {
    this.Isvalidate();
  };

  bindPositionList = (item: any) => {
    this.setState({ departmentDangerWell: false });
    let positionListdata: any = [];
    let positionFilterDetails = this.state.positionListMaster.filter(
      (Positem: any) => Positem.deptid === item.id
    );
    positionFilterDetails.forEach((res: any) => {
      let posobject: any = {};
      posobject.id = res.id;
      posobject.name = res.name;
      positionListdata.push(posobject);
    });

    this.setState(
      {
        positionList: positionListdata,
        departmentSelected: item.name,
        departmentId: item.id,
        // positionSelected: "Select",
        // positionId: "",
      },
      () => {
        this.getDateApprovalStatus();
      }
    );

    if (this.props.positionId > 0) {
      let selectedPoslist = positionListdata.filter(
        (positem: any) => positem.id === this.props.positionId
      );
      let posItem = selectedPoslist?.length > 0 ? selectedPoslist[0] : {};
      this.positionChange({ id: posItem.id, name: posItem.name });
    }
  };

  getDateApprovalStatus() {
    let startDate: any = new Date(this.props.parentState.calenderDates[0]);
    let lastDate: any = new Date(startDate);
    lastDate.setDate(lastDate.getDate() + 6);

    let mnths: any = new Date(lastDate).getMonth() + 1; // date form today only
    let days: any = new Date(lastDate).getDate();
    let year: any = new Date(lastDate).getFullYear().toString().substring(2, 4);

    if (mnths.toString().length === 1) {
      mnths = "0" + mnths;
    }
    if (days.toString().length === 1) {
      days = "0" + days;
    }

    let todate = `${mnths}/${days}/${year}`;
    LaborPerformance.GetLaborApprovalStatusWithDates(
      this.props.parentState.hidValue,
      this.state.departmentId,
      startDate,
      todate
    )
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState(
            {
              datewithApprovalStatus: result,
            },
            () => {
              this.getDateList();
            }
          );
        }
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  positionChange = (item: any) => {
    this.setState({
      positionSelected: item.name,
      positionId: item.id,
      positionDangerWell: false,
    });
  };

  importFromListSelect = (item: any, selectType: string) => {
    
    if (selectType === "date") {
      this.setState(
        {
          dateSelected: item.date,
          punchInDate: item.date,
          associateDate: item.date,
          isDateApproved: item.approvalStatus,
          dateDangerWell: false,
        },
        () => {
          this.getEmployeeData();
          if (
            this.props.callPage === "Performace" ||
            this.props.callPage === "Timesheet"
            //    &&
            // this.state.isAssociateToggle
          ) {
            this.getPuncInOutDates();
            this.getPunchOutTime();
          }
        }
      );
    } else if (selectType === "associateDate") {
      this.setState(
        {
          associateDate: item,
          punchInDateDangerWell: false,
          punchOutDateDangerWell: false,
        },
        () => {
          this.getPunchOutTime();
        }
      );
    } else {
      return false;
    }
  };

  updatePunchInDateList = () => {};

  getPunchOutTime = () => {
    if (
      this.state.inTime === "" ||
      this.state.outTime === "" ||
      this.state.punchInDate === "Select"
    ) {
      return false;
    } else {
      
      let inMomentTime = moment(
        `${this.state.punchInDate} ${this.state.inTime}`
      );
      let outMomentTime = moment(
        `${this.state.punchInDate} ${this.state.outTime}`
      );

      let differenceInTime = outMomentTime.diff(inMomentTime);

      let punchOutDate = moment(this.state.punchInDate).format("MM/DD/YYYY");
      if (differenceInTime <= 0) {
        punchOutDate = moment(this.state.punchInDate)
          .add(1, "days")
          .format("MM/DD/YYYY");
      }
      this.setState({ punchOutDate });
    }
  };

  getEmployeeData = () => {
    if (
      this.state.dateSelected === "Select" ||
      this.state.dateSelected === "" ||
      this.state.inTime === "" ||
      this.state.outTime === ""
    ) {
      return false;
    } else {
      this.setState({ loaderTrue: true });

      let request = {} as ILaborScheduleEmployeeRequestDto;
      request.inTime = schConversions.twentyFourToTwelveConvert(
        this.state.inTime
      );
      request.outTime = schConversions.twentyFourToTwelveConvert(
        this.state.outTime
      );

      request.hotelID = this.props.parentState.hidValue;
      request.date = this.state.dateSelected;
      request.type = "multiadd";
      request.position = this.state.positionId;
      request.shiftDate = this.state.dateSelected;
      request.uniqueno = 0;
      if (this.state.positionId === "Select" || this.state.positionId === "") {
        request.position = -1;
      }

      LaborSchedule.GetLaborScheduleEmployee(request)
        .then(async (result: any) => {
          this.setState({ employeeList: result.result, loaderTrue: false });
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error: ${err}`, {
            // position: toast.POSITION.BOTTOM_RIGHT,
          });
          reject();
        });
    }
  };

  inTimeChange = (e: any) => {
    this.setState(
      {
        inTime: e.target.value,
        inTimeDangerWell: false,
      },
      () => {
        this.getEmployeeData();
      }
    );
  };

  inTimeBlur = (e: any) => {
    if (
      (this.props.callPage === "Performace" ||
        this.props.callPage === "Timesheet") &&
      this.state.outTime === e.target.value
    ) {
      this.setState({
        inTime: "",
        inTimeDangerWell: true,
        punchOutDate: "-",
      });
      Utils.toastError("In Time and Out Time must be different.", {
        // position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.setState(
        {
          inTime: e.target.value,
        },
        () => {
          if (
            this.props.callPage === "Performace" ||
            this.props.callPage === "Timesheet"
            //   &&
            // this.state.isAssociateToggle
          ) {
            this.getPunchOutTime();
          }
        }
      );
    }
  };

  outTimeChange = (e: any) => {
    this.setState(
      {
        outTime: e.target.value,
        outTimeDangerWell: false,
      },
      () => {
        this.getEmployeeData();
      }
    );
  };

  onOutTimeKeyUp = (e: any) => {
    this.setState(
      {
        outTime: e.target.value,
        outTimeDangerWell: false,
      },
      () => {
        this.getEmployeeData();
      }
    );
  };

  outTimeBlur = (e: any) => {
    if (
      (this.props.callPage === "Performace" ||
        this.props.callPage === "Timesheet") &&
      this.state.inTime === e.target.value
    ) {
      this.setState({
        outTime: "",
        outTimeDangerWell: true,
        punchOutDate: "-",
      });
      Utils.toastError("In Time and Out Time must be different.", {
        // position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.setState(
        {
          outTime: e.target.value,
        },
        () => {
          if (
            this.props.callPage === "Performace" ||
            this.props.callPage === "Timesheet"
            //   &&
            // this.state.isAssociateToggle
          ) {
            this.getPunchOutTime();
          }
        }
      );
    }
  };

  Isvalidate = () => {
    let isValid = true;
    if (
      this.state.departmentId === "Select" ||
      this.state.departmentId === ""
    ) {
      this.setState({ departmentDangerWell: true, isValidRequest: false });
      isValid = false;
    }
    if (this.state.positionId === "Select" || this.state.positionId === "") {
      this.setState({ positionDangerWell: true, isValidRequest: false });
      isValid = false;
    }
    if (
      this.state.dateSelected === "Select" ||
      this.state.dateSelected === ""
    ) {
      this.setState({ dateDangerWell: true, isValidRequest: false });
      isValid = false;
    }
    if (this.state.inTime === "") {
      this.setState({ inTimeDangerWell: true, isValidRequest: false });
      isValid = false;
    }
    if (this.state.outTime === "") {
      this.setState({ outTimeDangerWell: true, isValidRequest: false });
      isValid = false;
    }
    if (this.state.selectedEmployes.length > 0) {
      if (isValid) {
        this.setState({ isValidRequest: true });
      }
    }

    if (
      this.props.callPage === "Performace" ||
      this.props.callPage === "Timesheet"
    ) {
      if (this.state.inTime === this.state.outTime) {
        this.setState({ inTimeDangerWell: true, outTimeDangerWell: true });
      }
      if (this.state.punchInDate === "Select") {
        this.setState({
          punchInDateDangerWell: true,
          punchOutDateDangerWell: true,
        });
        isValid = false;
      }
    }
    return isValid;
  };

  formatDate(time: string) {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  }

  addEntrySave = (e: any,type:any) => {
    // this.props.parentState.isPayrollExported = true;
   
   debugger;
    // else{
    if (this.state.departmentId === 0) {
      Utils.toastError("Please select department.", {
        // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (this.state.positionId === 0) {
      Utils.toastError("Please select position.", {
        // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (this.state.selectedEmployes.length === 0) {
      Utils.toastError("Please select user.");
      return false;
    }

    if (this.state.notes.trim().length === 0) {
      Utils.toastError("Please enter explanation");
      return false;
    }

    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    this.state.selectedEmployes.forEach((employee) => {
      let newItem: any = {};
      newItem.tenantID = tenantID;
      newItem.hotelID = this.props.parentState.hidValue;
      newItem.positionID = this.state.positionId;
      newItem.selectDate = this.state.associateDate;
      newItem.employeeName = employee.employee;
      newItem.departmentID = this.state.departmentId;
      newItem.inTime = this.formatDate(this.state.inTime);
      newItem.outTime = this.formatDate(this.state.outTime);
      newItem.actualId = 0;
      newItem.notes = this.state.notes;
      newItem.flag = "";
      newItem.scheduleId = 0;
      newItem.scheduleBreaktime = "";
      newItem.entryType = "multiadd";
      newItem.ddlSelectedDate =
        this.props.callPage === "Performace" ||
        this.props.callPage === "Timesheet"
          ? this.state.dateSelected
          : this.state.dateSelected;
      newItem.inDate =
        this.props.callPage === "Performace" ||
        this.props.callPage === "Timesheet"
          ? this.state.dateSelected
          : this.state.dateSelected;
      newItem.outDate =
        this.props.callPage === "Performace" ||
        this.props.callPage === "Timesheet"
          ? this.state.punchOutDate
          : this.state.dateSelected;
      newItem.inUniqueNo = 0;
      newItem.outUniqueNo = 0;
      newItem.actualChildId = 0;
      newItem.punchtype = "";
      newItem.moduleName = "TimeSheet";
      newItem.actionType = "AddEntry";
      newItem.pageSource = this.props.pageType;
      newItem.IsRemoveDNS = this.state.isRemoveDNS;
      saveActualDetail.push(newItem);
    });

    let laborActualmain: any = {};
    laborActualmain.laborActualDetails = saveActualDetail;
    
    if(this.props.callPage === "Performace"){
      if(this.props.payrollExportPerformance !== "" && type === "Yes"){
        if(this.state.associateDate > this.props.parentState.payPeriodDates[1] || (this.state.associateDate < this.props.parentState.payPeriodDates[0])){
          this.saveActualDetails(saveActualDetail);
        }
        else{
          this.ConfirmPayrollExport(e,type);
        }
       
      }
      else{
        this.saveActualDetails(saveActualDetail);
      }
    }
    else if(this.props.callPage === "Timesheet"){
      debugger;
      if(this.props.parentState.exportedDate !== "" && type === "Yes"){
        debugger;
          // if(this.state.associateDate > this.props.parentState.payPeriodDates[1])
          // {
          //   debugger;
          //   if(this.props.parentState.associateExportedNext === "")
          //   {
          //     this.saveActualDetails(saveActualDetail);
          //   }
          //   else
          //   {
          //     this.ConfirmPayrollExport(e,type)
          //   }
          // }
          // else if(this.state.associateDate < this.props.parentState.payPeriodDates[0])
          // {
          //     debugger;
          //     if(this.props.parentState.associateExportedPre === "")
          //     {
          //       this.saveActualDetails(saveActualDetail);
          //     }
          //     else
          //     {
          //       this.ConfirmPayrollExport(e,type)
          //     }
          // }
          // else{
          //   this.ConfirmPayrollExport(e,type);
          // }
          this.ConditionCheckIfExported(e,type,saveActualDetail)
        }
      else{
        // this.saveActualDetails(saveActualDetail);
        this.ConditionCheckIfUnExported(e,type,saveActualDetail)
      }
    }
   
   
  
  };

  saveActualDetails(saveActualDetail: any[]) {
    this.setState({ loaderTrue: true });
    this.btnSaveNewEntry.current.disabled = true;
    LaborPerformance.SaveLaborActual(saveActualDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.btnSaveNewEntry.current.disabled = false;
            this.props.addEntryRefresh();
            toast.success("Manual entry successfully added.", {
              // position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            this.btnSaveNewEntry.current.disabled = false;
            // Utils.toastError(result.result.message, {
            //   // position: toast.POSITION.BOTTOM_RIGHT,
            // });
            if(result.result.messageCode === "Scheduleoverlapped" && result.result.entryType === "8"){
              this.DNSConfirmation(saveActualDetail[0].employeeName)
            }
            else{
              Utils.toastError(result.result.message, {
                // position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }
        } else {
          this.btnSaveNewEntry.current.disabled = false;
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err) => {
        this.btnSaveNewEntry.current.disabled = false;
        Utils.toastError(`Server Error: ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  ConditionCheckIfUnExported = (e,type,saveActualDetail) => {
    if(this.state.associateDate > this.props.parentState.payPeriodDates[1])
      {
        debugger;
        if(this.props.parentState.associateExportedNext === "")
        {
          this.saveActualDetails(saveActualDetail);
        }
        else
        {
          this.ConfirmPayrollExport(e,type)
        }
      }
      else if(this.state.associateDate < this.props.parentState.payPeriodDates[0])
      {
          debugger;
          if(this.props.parentState.associateExportedPre === "")
          {
            this.saveActualDetails(saveActualDetail);
          }
          else
          {
            this.ConfirmPayrollExport(e,type)
          }
      }
      else{
        this.saveActualDetails(saveActualDetail);
      }
  }

  ConditionCheckIfExported = (e,type,saveActualDetail) => {
    if(this.state.associateDate > this.props.parentState.payPeriodDates[1])
      {
        debugger;
        if(this.props.parentState.associateExportedNext === "")
        {
          this.saveActualDetails(saveActualDetail);
        }
        else
        {
          this.ConfirmPayrollExport(e,type)
        }
      }
      else if(this.state.associateDate < this.props.parentState.payPeriodDates[0])
      {
          debugger;
          if(this.props.parentState.associateExportedPre === "")
          {
            this.saveActualDetails(saveActualDetail);
          }
          else
          {
            this.ConfirmPayrollExport(e,type)
          }
      }
      else{
          this.ConfirmPayrollExport(e,type);
        }
  }

  onChangeAssociateToggle = (e: any) => {
    
    if (e.target.checked === false) {
      this.setState({
        associateDate: this.state.dateSelected,
      });
    }

    this.setState(
      {
        isAssociateToggle: e.target.checked,
        punchInDate: this.state.dateSelected,
      },
      () => {
        if (
          this.props.callPage === "Performace" ||
          this.props.callPage === "Timesheet"
        ) {
          this.getPunchOutTime();
        }
      }
    );
  };

  ShowHideAditionalOption = () => {
    this.setState((prevState: any) => ({
      showAssociateOption: !prevState.showAssociateOption,
      isAssociateToggle: false,
      associateDate: this.state.dateSelected,
    }));
  };
  

  NotesChanged = (e: any) => {
    let value = e.target.value;
    this.setState({ notes: value});
  };
  onTextBlur = (e: any) => {
    let value = e.target.value.trim();
    this.setState({ notes: value });
  };

  AddManualShift = () => {
    let e: any
    this.setState({ isRemoveDNS: "Yes" }, () => {
      this.addEntrySave(e,"Yes")
    });
  }

  DNSConfirmation(e) {
    confirmAlert({
      message:
        // `Adding a manual shift will remove the DNS shift for ${e}. Are you sure you want to continue?`,
        `Adding a manual shift will remove the DNS shift for this employee. Are you sure you want to continue?`,
      buttons: [
        {
          label: "No",
          onClick: () => reject(),
        },
         {
          label: "Yes",
          onClick: () => this.AddManualShift(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  ConfirmPayrollExport(e:any,type:any) {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "Payroll for this period has already been exported. Continuing will require payroll to be re-exported to prevent any disruptions. Would you like to continue?",
      buttons: [
        {
          label: "Yes",
          // onClick: () => reject(),
          onClick:() => this.addEntrySave(e,"No"),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }


  render() {
    return (
      <div>
        <Modal
          className="add-edit-shift-modal bob"
          show={this.props.parentState.showAddEntryModal}
          onHide={this.hideAddEntryModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="dept-pos d-flex">
              <Form.Group controlId="select-department">
                <Form.Label>
                  Department <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    this.state.departmentSelected === "Select"
                      ? "import-from-dropdown select"
                      : "import-from-dropdown"
                  }
                >
                  <Dropdown.Toggle
                    id="dropdown-importFrom"
                    className={
                      this.state.departmentDangerWell ? "alert-danger" : ""
                    }
                  >
                    {this.state.departmentSelected === "Select" ? (
                      <span className="placeholder">Select Department</span>
                    ) : (
                      <span className="selected">
                        {this.state.departmentSelected}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      {this.state.departmentList.map((item: any, idx: any) => (
                        <Dropdown.Item
                          key={idx}
                          eventKey={item.id}
                          onClick={() => this.bindPositionList(item)}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>

              <Form.Group controlId="select-position">
                <Form.Label>
                  Position <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    this.state.departmentSelected === "Select"
                      ? "import-from-dropdown disabled"
                      : this.state.positionSelected === "Select"
                      ? "import-from-dropdown select"
                      : "import-from-dropdown"
                  }
                >
                  <Dropdown.Toggle
                    id="dropdown-importFrom"
                    className={
                      this.state.positionDangerWell ? "alert-danger" : ""
                    }
                  >
                    {this.state.positionSelected === "Select" ? (
                      <span className="placeholder">Select a Position</span>
                    ) : (
                      <span className="selected">
                        {this.state.positionSelected}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      {this.state.positionList.map((item: any, idx: any) => (
                        <EllipsisWithTooltip placement="bottom">
                          <Dropdown.Item
                            key={idx}
                            eventKey={item.id}
                            onClick={() => this.positionChange(item)}
                          >
                            {item.name}
                          </Dropdown.Item>
                        </EllipsisWithTooltip>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>

            <div className="day-date-time d-flex">
              <div className="date">
                <Form.Group controlId="date-selection">
                  <Form.Label>
                    Date <b>*</b>
                  </Form.Label>
                  <Dropdown
                    className={
                      this.state.positionSelected === "Select"
                        ? "import-from-dropdown disabled"
                        : "import-from-dropdown"
                    }
                  >
                    <Dropdown.Toggle
                      id="dropdown-importFrom"
                      className={
                        this.state.dateDangerWell ? "alert-danger" : ""
                      }
                    >
                      <span
                        className={
                          this.state.dateSelected === "Select"
                            ? "placeholder"
                            : "selected"
                        }
                      >
                        {this.state.dateSelected}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div>
                        {this.state.dateList.map((item: any, idx: any) => (
                          <Dropdown.Item
                            key={idx}
                            disabled={
                              item.approvalStatus === "Approve" &&
                              this.props.approvePermission === "No"
                                ? true
                                : false
                            }
                            eventKey={item.date}
                            onClick={() =>
                              this.importFromListSelect(item, "date")
                            }
                          >
                            {item.date}{" "}
                            {item.approvalStatus === "Approve" &&
                            this.props.approvePermission === "No" ? (
                              <img src={ForecastLock} alt="ForecastLock" />
                            ) : (
                              ""
                            )}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
              <div className="start-end-time d-flex">
                <Form.Group controlId="start-time">
                  <Form.Label>
                    Punch In <b>*</b>
                  </Form.Label>
                  <input
                    type="time"
                    id="inTime"
                    name="inTime"
                    className={
                      (this.state.inTimeDangerWell
                        ? "html-time-picker alert-danger"
                        : "html-time-picker") +
                      (this.state.dateSelected === "Select" ? " disabled" : "")
                    }
                    value={this.state.inTime}
                    onChange={(e: any) => this.inTimeChange(e)}
                    onBlur={(e: any) => this.inTimeBlur(e)}
                  />
                </Form.Group>

                {moment(this.state.punchInDate).format("MM/DD/YY") !==
                  moment(this.state.punchOutDate).format("MM/DD/YY") &&
                  this.state.punchInDate !== "Select" &&
                  this.state.punchOutDate !== "Select" && (
                    <div className="moon-icon">
                      <MoonIcon parentComponent={"Timehsheet"} />
                    </div>
                  )}

                <Form.Group controlId="end-time">
                  <Form.Label>
                    Punch Out <b>*</b>
                  </Form.Label>
                  <input
                    type="time"
                    id="outTime"
                    name="outTime"
                    className={
                      (this.state.outTimeDangerWell
                        ? "html-time-picker alert-danger"
                        : "html-time-picker") +
                      (this.state.inTime === "" ? " disabled" : "")
                    }
                    value={this.state.outTime}
                    onChange={(e: any) => this.outTimeChange(e)}
                    onKeyUp={(e: any) => this.onOutTimeKeyUp(e)}
                    onBlur={(e: any) => this.outTimeBlur(e)}
                  />
                </Form.Group>
              </div>
            </div>

            {(this.props.callPage === "Performace" ||
              this.props.callPage === "Timesheet") && (
              <div className="punch-dates d-flex">
                <div className="additional-options-section">
                  <Form.Group>
                    <Form.Label
                      className="label-btn"
                      onClick={() => {
                        this.ShowHideAditionalOption();
                      }}
                    >
                      {this.state.showAssociateOption
                        ? "Hide Additional Options"
                        : "Show Additional Options"}
                    </Form.Label>

                    {/* <Form.Label>
                    Punch In Date <b>*</b>
                  </Form.Label> */}

                    {this.state.showAssociateOption && (
                      <>
                        <div className="additional-options-container">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              // className={
                              //   this.state.inTime === "" || this.state.outTime === ""
                              //     ? "p-none disable"
                              //     : ""
                              // }
                              type="switch"
                              id="sw_issalaried"
                              label=""
                              checked={this.state.isAssociateToggle}
                              disabled={
                                this.state.inTime === "" ||
                                this.state.outTime === ""
                              }
                              onChange={(e: any) => {
                                this.onChangeAssociateToggle(e);
                              }}
                            />

                            <Form.Label className="d-flex align-items-center">
                              Associate to Different Day
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id={"tooltip-displayName"}>
                                    Association this entry to another date
                                    affects reporting and budgets.
                                  </Tooltip>
                                }
                              >
                                <span className="switch-tooltip">
                                  <InfoTooltip />{" "}
                                </span>
                              </OverlayTrigger>
                            </Form.Label>
                          </div>

                          {this.state.isAssociateToggle === true && (
                            <Dropdown
                              className={
                                this.state.inTime === "" ||
                                this.state.outTime === ""
                                  ? "import-from-dropdown disabled"
                                  : "import-from-dropdown"
                              }
                            >
                              <Dropdown.Toggle
                                id="dropdown-importFrom"
                                className={
                                  this.state.punchInDateDangerWell
                                    ? "alert-danger"
                                    : ""
                                }
                              >
                                <span
                                  className={
                                    this.state.punchInDate === "Select"
                                      ? "placeholder"
                                      : "selected"
                                  }
                                >
                                  {this.state.associateDate}
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  {this.state.punchInDateList.map(
                                    (item: any, idx: any) => (
                                      <Dropdown.Item
                                        key={idx}
                                        eventKey={item}
                                        onClick={() =>
                                          this.importFromListSelect(
                                            item,
                                            "associateDate"
                                          )
                                        }
                                      >
                                        {item}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </div>
                      </>
                    )}
                  </Form.Group>
                  {/* 
                <Form.Group>
                  <Form.Label>
                    Punch Out Date <b>*</b>
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    value={this.state.punchOutDate}
                  ></Form.Control>
                </Form.Group> */}
                </div>
              </div>
            )}

            <div className="bottomSepetatorLine"></div>
            <div className="select-users">
              <Form.Group controlId="set-Forecast">
                <Form.Label>User</Form.Label>
                <MultiSearchSelectUser
                  ref={this.multiSelectUser}
                  conflictCheck="Actual"
                  parentState={this.state}
                  employeeSelection={this.employeeSelection}
                  multiSelectErrorValidation={this.multiSelectErrorValidation}
                />
              </Form.Group>
            </div>
            <div className="notes-section">
            <Form.Group controlId="end-time">
              <label className="form-label">Notes</label>
              <div className="control">
                <Form.Control
                  className="input-notes"
                  as="textarea"
                  // value={row.lastInNotesdup}
                  onChange={(e:any) => this.NotesChanged(e)}
                  // onBlur={(e: any) => this.onTextBlur(e)}
                  placeholder="Explanation required..."
                />
              </div>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              ref={this.btnSaveNewEntry}
              disabled={!this.state.isValidRequest}
              className="import-forecast-button"
              onClick={(e: any) => this.addEntrySave(e,"Yes")}
            >
              Add Entry
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
