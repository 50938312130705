import * as React from "react";
import { MultiSelectSearchGroupReport } from "../../Common/Components/MultiSelectSearchGroupReport";
import { IReportGroupSubGroupWithHotelCountDto } from "../../Common/Contracts/IReportParameter";
import { ReportGroup } from "../../Common/Services/ReportGroup";

export class ReportGroupBrandSelector extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      groupList: [],
      selectedLetterCode: [],
      defaultValue: "",
    };
  }
  componentDidMount() {
    debugger;
    ReportGroup.GetReportGroupSubGroupWithHotelCount(
      "Labor Management",
      this.props?.isAll,
      this.props?.notIncludeEnterpriseAccounts,
      this.props?.showOnlyHotelAccounts,
      this.props?.payrollSubscriptionRequired
    )
      .then(async (result: IReportGroupSubGroupWithHotelCountDto[] | null) => {
        if (result != null && result.length > 0) {
          let groupList: any[] = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.groupID = element.groupID;
            newItem.subGroupID = element.subGroupID;
            newItem.subGroupName = element.subGroupName;
            newItem.noOfHotels = element.noOfHotels;
            newItem.isChecked = false;
            newItem.groupName = element.groupName;
            groupList.push(newItem);
          });
          this.setState({ groupList: groupList });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateGroupList = (newGroupList, isSelectAll: boolean = false) => {
    let groupList = [...this.state.groupList];
    if (isSelectAll && newGroupList.length === 0) {
      groupList = groupList.map((group) => {
        group.isChecked = true;
        return group;
      });
    } else {
      const newGroupIds = newGroupList.map((r) => r.subGroupName);
      groupList = groupList.map((hotel) => {
        if (newGroupIds.includes(hotel.subGroupName)) {
          hotel.isChecked = true;
        } else {
          hotel.isChecked = false;
        }
        return hotel;
      });
    }

    this.setState({ groupList }, () => {
      this.props.handleMultiReportGroupChange(
        groupList?.filter((r) => r?.isChecked)
      );
    });
  };

  removalChips = (removalChips) => {
    let groupList = [...this.state.groupList];
    if (removalChips) {
      let parentId = removalChips.subGroupID;
      for (let i = this.state.groupList.length - 1; i >= 0; i--) {
        if (Number(this.state.groupList[i].rGroupID) === Number(parentId)) {
          groupList.splice(i, 1);
        }
      }
      this.setState({ groupList });
    } else {
      this.setState({ groupList: [] });
    }
  };

  componentDidUpdate = (prevprops, prevState) => {
    debugger;
    let groupList = [...this.state.groupList];
    const { defaultValue, selectedItems } = this.props;
    if (prevState.defaultValue !== defaultValue && groupList.length > 0) {
      // let groupNames = defaultValue.split(",")?.map(r => r);
      // const selectedGroups = groupList?.filter(r => groupNames?.includes(r.subGroupName))
      // groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.subGroupName)?.includes(r));
      // groupList = groupList?.map(item => {
      //   if (groupNames?.includes(item?.subGroupName)) {
      //     item.isChecked = true;
      //   } else {
      //     item.isChecked = false;
      //   }
      //   return item;
      // })
      if (selectedItems?.length > 0) {
        groupList = groupList?.map((item) => {
          let groupNames = selectedItems.filter(
            (r) =>
              r.groupID === item.groupID && r.subGroupID === item.subGroupID
          );
          if (groupNames.length > 0) {
            item.isChecked = true;
          } else {
            item.isChecked = false;
          }
          return item;
        });
      }

      this.setState({ groupList, defaultValue });
    }
  };

  updateMultiSelectItem = (selectedItem: any) => {
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleMultiReportGroupChange(selectedItem, true);
    });
  };

  updateSingleSearchItem = (selectedItem: any) => {
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleMultiReportGroupChange(selectedItem, true);
    });
  };

  dataChange = () => {
    this.setState({ isUnsavedChanges: true });
  };

  render() {
    return (
      <div
        id="reportGroupBrandSelectior"
        className={
          this.props.isDisabled
            ? "account-selector noPointer"
            : "account-selector"
        }
      >
        <MultiSelectSearchGroupReport
          itemList={this.state.groupList}
          updateMultiSelectItem={this.updateMultiSelectItem.bind(this)}
          dataChange={this.dataChange.bind(this)}
          isSelectAll={true}
          filtertitle={"Filter Groups"}
        />
      </div>
    );
  }
}
