import { IApiConfig } from "../Contracts/IApiConfig";

const hostname = window && window.location && window.location.hostname;
const app_Root = {} as IApiConfig;

if (hostname.indexOf("localhost") > -1 || hostname.includes("10.52.75.149")) {
  // app_Root.environment = "Test";
  /** ******** For Reports Staging ******** **/
  app_Root.reportingHost = "https://porepapi.inn-flow.net/api";
  /** ******** React merge site (new - staging) ******** **/
  app_Root.backendHost = "https://poapi.inn-flow.net/api";
  //app_Root.backendHost = "https://localhost:44387/api";

  app_Root.notificationHubURL = "https://poapi.inn-flow.net/hubs";
} else {
  app_Root.environment = "PO";
  /** ******** For Reports Staging ******** **/
  app_Root.reportingHost = "https://porepapi.inn-flow.net/api";
  /** ******** React merge site (new - staging) ******** **/
  app_Root.backendHost = "https://poapi.inn-flow.net/api";

  app_Root.notificationHubURL = "https://poapi.inn-flow.net/hubs";

  //app_Root.reportingHost = "https://inn-flow2-reporting-test.azurewebsites.net/api";
  /** ******** React merge site (new - staging) ******** **/
  //app_Root.backendHost = "https://tsapiv2.inn-flow.net/api";
}

export const API_ROOT = app_Root;
