import Instense from "./Axios-config";
import { ITransactionChangeLogRequest, IVendorLogResponse } from "../Contracts/ITransactionChangeLog";
import axios from "axios";
import _ from "lodash";
import { Utils } from "../../Common/Utilis";
export class Register {
  private static ajaxRequestFindData: any = null;
  public static GetTransactionChangeLog = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantId = tenantID;
    let _isCondition = request.accType === "vendorlog" ? `TransactionLog/GetTransactionLogvendor` : `/TransactionLog/GetTransactionLog`
    const url = (!request.accType.includes("All")) ? _isCondition : `/TransactionLog/GetAllTransactionLog`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result?.result;
      return result;
    });
  };

  public static GetSplitTransactionLog = async (request: any): Promise<any[] | null> => {
    const url = `/TransactionLog/GetSplitTransactionLog`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DownloadChangeLog = async (
    request: ITransactionChangeLogRequest, fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantId = tenantID;
    let isCondition: any = request.accType === "vendorlog" ? `/TransactionLog/ExportTransactionLogvendor` : `/TransactionLog/DownloadChangeLog`
    const url = (!request.accType.includes("All")) ? isCondition : `/TransactionLog/DownloadAllChangeLog`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static ExportTransactionLogvendor = async (
    request: IVendorLogResponse, fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantId = tenantID;
    const url = `/TransactionLog/ExportTransactionLogvendor`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static Registerlist = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/Registerlist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RegisterAccountBal = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/RegisterAccountBal`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAccountBal = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/AccountBalance`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAccountNoBalanceList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    let url: any;
    if (request.OPRIDs === "") {
      request.OPRIDs = "0";
      url = ``;
    } else {
      url = `/Register/GetAccountNoBalanceList`;
    }
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RegisterAccount = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/RegisterAccount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAccountNoList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Register/GetAccountNoList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetDefaultHotelByAccount = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.Tenantid = tenantID;
    const url = `/Register/GetDefaultHotelByAccount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RegisterRepeatlist = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/RegisterRepeatlist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetHotelCOAlist = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetHotelCOAlist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetCOADatalist = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetCOADatalist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetImportList = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetImportList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetVendorTranlist = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetVendorTranlist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetVendorlist = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetVendorlist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static VendorSearchlistWithKey = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/VendorSearchlistWithKey`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetVendorlistUEM = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetVendorlistUEM`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetVendorContract = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetVendorContracts`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetVendorAddress = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetVendorAddress`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveVendorData = async (
    request: any
  ): Promise<any[] | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const username: any = (storage === null ? 0 : storage.userName);
    const tenantId: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantId;
    request.UserName = username;
    const url = `/Vendor/SaveVendorData`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveInvoiceRule = async (
    request: any
  ): Promise<any[] | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const username: any = (storage === null ? 0 : storage.userName);
    const tenantId: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantId;
    request.UserName = username;
    const url = `/Vendor/SaveInvoiceRule`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetAccountingPeriod = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetAccountingPeriod`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetPreviousTransactionList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetPreviousTransactionList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetTranToUpdate = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetTranToUpdate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetInvoiceList = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetInvoiceList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetUploadFileList = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetUploadFileList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetUploadFileWithFileCode = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetUploadFileWithFileCode`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveTransaction = async (
    request: any, files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    if (files.length === 0) {
      const url = `/Register/SaveTransaction`;
      return Instense.post(url, request).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    } else {
      const url = `/Register/SaveTransactionWithFile`;
      const formData: FormData = new FormData();
      files.forEach(element => {
        formData.append('file', element);
      });
      formData.append('formField', JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    }
  };

  public static SavePaymentUploadFile = async (
    request: any, files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    if (files.length === 0) {
      const url = `/Register/PaymentUploadFileSaveWithoutFile`;
      return Instense.post(url, request).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    } else {
      const url = `/Register/PaymentUploadFileSave`;
      const formData: FormData = new FormData();
      files.forEach(element => {
        formData.append('file', element);
      });
      formData.append('formField', JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    }
  };

  public static DownloadTranactionFile1 = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/DownloadTranactionFile`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DownloadTranactionFile = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantId = tenantID;
    const url = `/Register/DownloadTranactionFile`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", request.name);
        tempLink.click();
      }
    );
  };

  public static DeleteTransferFiles = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Transfer/DeleteTransferFiles`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveTransactionColumn = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantid = tenantID;
    const url = `/Register/SaveTransactionColumn`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetTransactionColoumn = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantid = tenantID;
    const url = `/Register/GetTransactionEntryColoumn`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAccountList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetInvoiceAccounts`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveInvoiceTransaction = async (
    request: any, files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    if (files.length === 0) {
      const url = `/Register/SaveInvoiceTransaction`;
      return Instense.post(url, request).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    } else {
      const url = `/Register/SaveInvoiceTransactionWithFile`;
      const formData: FormData = new FormData();
      files.forEach(element => {
        formData.append('file', element);
      });
      formData.append('formField', JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    }
  };

  public static SaveInvoiceFile = async (
    request: any, files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/SaveInvoiceFile`;
    const formData: FormData = new FormData();
    files.forEach(element => {
      formData.append('file', element);
    });
    formData.append('formField', JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveInboxInvoice = async (
    request: any, files: any[], onUploadProgress
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/SaveInboxInvoiceFile`;
    const formData: FormData = new FormData();
    files.forEach(element => {
      formData.append('file', element);
    });
    formData.append('formField', JSON.stringify(request));
    return Instense.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static IsAmountExist = async (
    request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/IsAmountExist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static CheckDuplicateInvoice = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetDuplicateInvoice`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetIncompleteTransactionList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetIncompleteTransactionList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static getbase64blob = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/getbase64blob`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetInvoiceToPost = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetInvoiceToPost`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetPostInvoiceByID = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetPostInvoiceByID`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  }

  public static DeleteUploadedFile = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/DeleteUploadedFile`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetTransactionChartList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetTransactionChartList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveTransfer = async (
    request: any,
    files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    if (files.length === 0) {
      const url = `/Transfer/SaveTransfer`;
      return Instense.post(url, request).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    } else {
      const url = `/Transfer/SaveTransferWithFile`;
      const formData: FormData = new FormData();
      files.forEach((element) => {
        formData.append("file", element);
      });
      formData.append("formField", JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    }
  };

  public static IsTransactionRolePermission = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/IsTransactionRolePermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetFileSize = async (): Promise<any | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetFileSize`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  }

  public static GetTransferEntry = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/GetTransferEntry`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetRepeatTransferEntry = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Repeating/GetRepeatTransferEntry`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static saveSplitFile = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/SaveSpiltInvoice`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static MoveToNewInvoice = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.forEach((element) => {
      element.tenantID = tenantID;
    });
    const url = `/Register/MoveToNewInvoice`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DownloadBulkInvoiceAttachment = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.forEach((element) => {
      element.tenantID = tenantID;
    });
    const url = `/Register/DownloadBulkInvoice`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAssociatedPayment = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetAssociatedPayment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DeleteTransactionFiles = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/DeleteTransactionFiles`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });

  };

  public static DeleteTransaction = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/DeleteTransaction`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DeleteTransferEntry = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = "/transfer/DeleteTransferEntry";
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RotateInvoicePDf = async (request: any[]): Promise<any[] | null> => {
    const url = `/Register/RotateInvoicePDf`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RotatePDf = async (request: any[]): Promise<any[] | null> => {
    const url = `/Register/RotatePDf`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RemoveInvoicePDf = async (request: any[]): Promise<any[] | null> => {
    const url = `/Register/RemoveInvoicePage`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RemovePDFPage = async (request: any[]): Promise<any[] | null> => {
    const url = `/Register/RemovePDFPage`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveInvoiceVoid = async (
    request: any[]
  ): Promise<any[] | null> => {
    const url = `/Register/TransactionInvoiceVoid`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static CreditCardExcelImport = async (
    files: any[]
  ): Promise<any | null> => {
    const url = `/Transfer/CreditCardExcelImport`;
    const formData: FormData = new FormData();
    files.forEach((element) => {
      formData.append("file", element);
    });
    formData.append("formField", '');
    return Instense.post(url, formData).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetMappingRule = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/GetMappingRule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetMappingRuleField = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/GetMappingRuleField`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetFieldMappedWithRule = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/GetFieldMappedWithRule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveImportStatementDataMapping = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/SaveImportStatementDataMapping`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static InsertCashImportStatementTableData = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/InsertCashImportStatementTableData`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetCashCCImportRuleMapping = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/GetCashCCImportRuleMapping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static SaveCashImportInvoice = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    let files: any = [];
    request.forEach((items) => { // 
      if (items.attachmentMain.length > 0) {
        items.attachmentMain.forEach((item, index) => {
          files.push(item);
        })
      }
    });
    if (files.length === 0) {
      request.filter((items) => delete items["attachmentMain"]);
      const url = `/Transfer/SaveCCImportData`;
      return Instense.post(url, request).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    } else {
      const url = `/Transfer/SaveCCImportDataWithFile`;
      const formData: FormData = new FormData();
      request.forEach((items) => { // 
        if (items.attachmentMain.length > 0) {
          items.attachmentMain.forEach((item, index) => {
            formData.append(items.Uniqueno, item);
          })
        }
      });
      formData.append("formField", JSON.stringify(request));
      request.filter((items) => delete items["attachmentMain"]);
      return Instense.post(url, formData).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    }
  };

  public static SaveCreditCardRuleName = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/SaveCreditCardRuleName`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static getRegisterPermission = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetRegisterPermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static ExportRegister = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = "/Register/ExportRegister";
    let _fnm: any = request.AccountType === "C" ? "CreditCard" : "MiscellaneousAccount"
    let _fName: any = request.AccountType === "O" ? "OperatingAccount" : _fnm;
    let fName = request.AccountType === "P" ? "PayrollAccount" : _fName;
    const fileName = `${fName}-${JSON.parse(localStorage.storage).currentUtcTime.replace(' PM', '').replace(' AM', '').replace(' ', '-').replace(':', '-')}`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static usernameList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/UsernameList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveTransactionComment = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/Register/SaveTransactionComment`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetTransactionComment = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetTransactionComment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static InvoiceWithUnapproveVendor = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/InvoiceWithUnapproveVendor`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DwollaServiceIsEnable = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Accounting/DwollaServiceIsEnable`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static IsInvoiceComplete = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/IsInvoiceComplete`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static PrintActualCheck = async (
    request: any, eventKey: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Accounting/PrintActualCheck`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        if (eventKey === "reprint") {
          let file = new Blob([response.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          let csvURL = window.URL.createObjectURL(response.data);
          let tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", request.CheckNo + '.pdf');
          tempLink.click();
        }
      }
    );
  };

  public static InvoicePdfExtraction = async (request: any): Promise<any[] | null> => {
    // request.fileCode="aa";
    let newrequest: any = {};
    newrequest.fileCode = "";
    newrequest.fileName = request.fileName;
    newrequest.fileUniqueNo = request.fileUniqueNo;
    newrequest.hotelId = request.hotelId;
    newrequest.invoicetype = request.invoicetype;
    newrequest.newpageno = request.newpageno;
    newrequest.pageno = request.pageno;
    newrequest.tenantID = request.tenantID;

    newrequest.uniqueNo = request.uniqueNo;
    newrequest.uploadFile = request.uploadFile;
    newrequest.vendorID = request.vendorID;


    const url = `/Register/InvoicePdfExtraction`;
    return Instense.get(url, { params: newrequest }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  // Azure extraction API
  public static InvoicePdfExtraction_New = async (request: any): Promise<any[] | null> => {
    // request.fileCode="aa";
    let newrequest: any = {};
    newrequest.fileCode = "";
    newrequest.fileName = request.fileName;
    newrequest.fileUniqueNo = request.fileUniqueNo;
    newrequest.hotelId = request.hotelId;
    newrequest.invoicetype = request.invoicetype;
    newrequest.newpageno = request.newpageno;
    newrequest.pageno = request.pageno;
    newrequest.tenantID = request.tenantID;

    newrequest.uniqueNo = request.uniqueNo;
    newrequest.uploadFile = request.uploadFile;
    newrequest.vendorID = request.vendorID;


    const url = `/Register/InvoicePdfExtraction_New`;
    return Instense.get(url, { params: newrequest }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static InvoiceMappingMasterDetails = async (request: any): Promise<any[] | null> => {
    const url = `/Register/InvoiceMappingMasterDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static SaveInvoicePdfExtraction = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/Register/SaveInvoicePdfExtraction`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveinvoiceMailBox = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/SaveinvoiceMailBox`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result.result;
      return result;
    });
  };

  public static bindPdfTextRactData(PdfExtractList: any[]) {
    let pdfList: any = {};
    let headerItemList: any = [];
    PdfExtractList[0].invoiceJsonDetails.forEach((element, index) => {
      let item: any = {};
      item.id = index;
      item.name = element.coloumnName;
      item.eventKey = element.coloumnName;
      item.value = element.coloumnValues;
      item.status = "";
      item.type = "Header";
      item.mappedTo = "";
      item.dbName = element?.dbName;
      item.tableno = element?.tableno;
      item.pageno = element?.pageno;
      headerItemList.push(item);
    });
    let tableItemList: any = [];
    PdfExtractList[0].invoiceSpiltheader.forEach((element, index) => {
      let item: any = {};
      let colValueList = PdfExtractList[0].invoiceSpiltDetails.filter(x => x.coloumnName.toLowerCase() === element.coloumnName.toLowerCase() && x.coloumnValues !== '')
      let colValue = "";
      if (colValueList.length > 0) {
        colValue = colValueList[0].coloumnValues;
      }
      item.id = index;
      item.name = element.coloumnName;
      item.eventKey = element.coloumnName;
      item.value = colValue;
      item.status = "";
      item.type = "LineItem";
      item.mappedTo = "";
      item.dbName = element?.dbName;
      item.tableno = element?.tableno;
      item.pageno = element?.pageno;
      // if (element.pageno === 1) {   // removed to read show split data of second page
        tableItemList.push(item);
      // }
    });
    pdfList.headerItemList = headerItemList;
    pdfList.tableItemList = tableItemList;
    return pdfList;
  }

  public static mapLineItem(LinemapData: any[], PdfExtractList: any[], tableItemList: any[]) {
    // debugger;

    let checkedArray: any = tableItemList.filter((item) => item.mappedTo !== "");
    let distinctTables: any = [];
    
    let splitItem: any = [];
    for (let i = 0; i < checkedArray.length; i++) {
      let distinctTables1 = PdfExtractList[0].invoiceSpiltheader.filter((item) => item.coloumnName === checkedArray[i].name);
      distinctTables = [...distinctTables, ...distinctTables1];
    }

    distinctTables = Utils.getUniqueCombinations(distinctTables, ["pageno", "tableno"]);

    let startIndex = 0;
    distinctTables.forEach((element, index) => {
      let a = checkedArray.filter((item) => item.tableno == element.tableno  && item.pageno == element.pageno);
      let maxlen = 0;
      LinemapData.forEach((element) => {
        let itemlen = 0;
        let b: any = a.filter((item) => item.mappedTo === element.name);
        for (let i = 0; i < b.length; i++) {
          itemlen = itemlen + PdfExtractList[0].invoiceSpiltDetails.filter(x => x.coloumnName.toLowerCase() === b[i].name.toLowerCase()).length;
        }
        maxlen = itemlen > maxlen ? itemlen : maxlen;
      });
      for (let i = 0; i < maxlen; i++) {
        let item: any = {};
        LinemapData.forEach((element) => {
          item[element.name] = '';
        });
        splitItem.push(item);
      }
      LinemapData.forEach((element) => {
        let b: any = a.filter((item) => item.mappedTo === element.name);
        let itemDet: any = [];
        for (let i = 0; i < b.length; i++) {
          let itemDet1 = PdfExtractList[0].invoiceSpiltDetails.filter(x => x.coloumnName.toLowerCase() === b[i].name.toLowerCase());
          itemDet = [...itemDet, ...itemDet1];
        }
        for (let i = startIndex, j = 0; i < splitItem.length; i++, j++) {
          let isCondition = itemDet[j]?.coloumnValues === undefined ? "" : itemDet[j]?.coloumnValues;
          splitItem[i][element.name] = itemDet.length < j ? "" : isCondition;
        }
      });
      startIndex = startIndex + maxlen;
    });

    console.log(JSON.stringify(splitItem));
    return splitItem;
  }

  public static saveInvoiceMapping = (selectedExtractFile: any[], mapData: any[], LinemapData: any[], tableItemList: any[]) => {
    let mappedDescCols = tableItemList.filter((item) => item.mappedTo !== "" && item.mappedTo === "Description");
    let mappedAmtCols = tableItemList.filter((item) => item.mappedTo !== "" && item.mappedTo === "Amount");
    let mappedDesc = "";
    let mappedAmt = "";
    mappedDescCols.forEach((element, index) => {
      if (index === 0) {
        mappedDesc = element?.dbName;
      } else {
        mappedDesc = mappedDesc + "," + element?.dbName;
      }
    });
    mappedAmtCols.forEach((element, index) => {
      if (index === 0) {
        mappedAmt = element?.dbName;
      } else {
        mappedAmt = mappedAmt + "," + element?.dbName;
      }
    });
    let saveRequest: any = [];
    mapData.forEach((element, index) => {
      let item: any = {};
      item.vendorID = selectedExtractFile[0].vendorID;
      item.hotelId = selectedExtractFile[0].hotelId;
      item.tenantID = selectedExtractFile[0].tenantID;
      item.masterID = element.id;
      item.mastername = element.name;
      item.mappedField = element?.dbName;
      item.type = "Header";
      saveRequest.push(item);
    });
    LinemapData.forEach((element, index) => {
      let item: any = {};
      item.vendorID = selectedExtractFile[0].vendorID;
      item.hotelId = selectedExtractFile[0].hotelId;
      item.tenantID = selectedExtractFile[0].tenantID;
      item.masterID = element.id;
      item.mastername = element.name;
      item.mappedField = element.name === "Description" ? mappedDesc : element.name === "Amount" ? mappedAmt : "";
      item.type = "LineItem";
      saveRequest.push(item);
    });
    return saveRequest;
  }

  public static getEtype(mtype, rowType) {
    let etype = '';
    if (mtype.toLowerCase() === 'deposit') {
      etype = 'Deposit'
    } else if (mtype.toLowerCase() === 'vendordeposit') {
      etype = 'Deposit'
    } else if (mtype.toLowerCase() === 'withdrawal') {
      etype = 'Withdrawal'
    } else if (mtype.toLowerCase() === 'misc') {
      etype = 'Withdrawal'
    } else if (mtype.toLowerCase() === 'miscdeposit') {
      etype = 'Deposit'
    } else if (mtype.toLowerCase() === 'payrollwithdrawal') {
      etype = 'Withdrawal'
    } else if (mtype.toLowerCase() === 'payrolldeposit') {
      etype = 'Deposit'
    } else if ((mtype.toLowerCase() === 'invoice' || mtype.toLowerCase() === 'invoices') && rowType.toLowerCase() === 'child') {
      etype = 'Invoice'
    } else if (mtype.toLowerCase() === 'payment' && rowType.toLowerCase() === 'child') {
      etype = 'Invoice'
    } else if (mtype.toLowerCase() === 'invoice' && rowType.toLowerCase() === 'main') {
      etype = 'payment'
    } else if (mtype.toLowerCase() === 'payment' && rowType.toLowerCase() === 'main') {
      etype = 'payment'
    } else if (mtype.toLowerCase() === 'incompleteinvoice') {
      etype = 'Incompleteinvoice'
    } else if (mtype.toString().toLowerCase().includes("transfer")) {
      etype = 'Transfer'
    } else if (mtype.toLowerCase() === 'miscdepositrepeat' || mtype.toLowerCase() === 'payrolldepositrepeat') {
      etype = 'Deposit'
    } else if (mtype.toLowerCase() === 'miscrepeat' || mtype.toLowerCase() === 'payrollwithdrawalrepeat') {
      etype = 'Withdrawal'
    }
    return etype;
  }

  public static getAccttype(mtype) {
    let accttype = '';
    if (mtype.toLowerCase() === 'deposit'.toLowerCase()) {
      accttype = 'O'
    } else if (mtype.toLowerCase() === 'VendorDeposit'.toLowerCase()) {
      accttype = 'O'
    } else if (mtype.toLowerCase() === 'Withdrawal'.toLowerCase()) {
      accttype = 'O'
    } else if (mtype.toLowerCase() === 'Misc'.toLowerCase()) {
      accttype = 'M'
    } else if (mtype.toLowerCase() === 'MiscDeposit'.toLowerCase()) {
      accttype = 'M'
    } else if (mtype.toLowerCase() === 'PayrollWithdrawal'.toLowerCase()) {
      accttype = 'P'
    } else if (mtype.toLowerCase() === 'PayrollDeposit'.toLowerCase()) {
      accttype = 'P'
    } else if (mtype.toLowerCase() === 'MiscTransfer'.toLowerCase()) {
      accttype = 'M'
    } else if (mtype.toLowerCase() === 'PayrollTransfer'.toLowerCase()) {
      accttype = 'P'
    } else if (mtype.toLowerCase() === 'Transfer'.toLowerCase()) {
      accttype = 'O'
    } else if (mtype.toLowerCase() === 'Payment'.toLowerCase()) {
      accttype = 'O'
    }
    return accttype;
  }

  public static CheckDuplicateCCImportData = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Transfer/CheckDuplicateCCImportData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static BulkImportExcelFile = async (
    type: any,
    request: any,
    files: any[]
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const username: any = (storage === null ? 0 : storage.userName);
    const tenantId: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantId;
    request.LoginUserName = username;
    let url = "";
    if (type === "importDW") {
      url = `/Transfer/BulkImportDepositsWithdrawalsExcelFile`;
    } else if (type === "importInvoice") {
      url = `/Transfer/BulkImportInvoiceExcelFile`;
    }
    const formData: FormData = new FormData();
    files.forEach((element) => {
      formData.append("file", element);
    });
    formData.append("formField", JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static SaveBulkTemplateData = async (
    type: any,
    request: any,
    tableData: any
  ): Promise<any | null> => {
    let url = "";
    let files: any = [];
    tableData.forEach((items) => { // 
      if (items.rowNo === 1) {
        if (items.attachmentMain.length > 0) {
          items.attachmentMain.forEach((item, index) => {
            files.push(item);
          })
        }
      }
    });
    if (files.length === 0) {
      if (type === "importDW") {
        url = `/Transfer/SaveDWImportTemplateData`;
      } else if ("importInvoice") {
        url = `/Transfer/SaveImportInvoiceTemplateData`;
      }
      return Instense.post(url, request).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    } else {
      if (type === "importDW") {
        url = `/Transfer/SaveDWImportTemplateDataWithFile`;
      } else if ("importInvoice") {
        url = `/Transfer/SaveImportInvoiceTemplateDataFile`;
      }
      const formData: FormData = new FormData();
      tableData.forEach((items) => {
        if (items.rowNo === 1) {
          if (items.attachmentMain.length > 0) {
            items.attachmentMain.forEach((item, index) => {
              formData.append(items.uniqueno, item);
            })
          }
        }
      });
      formData.append("formField", JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result: any = response.data.result;
        return result;
      });
    }
  };

  public static GetAccountingPeriodAllEHID = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Register/GetAccountingPeriodAllEHID`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static ApproveInvoiceStatus = async (request: any): Promise<any[] | null> => {
    const url = `/Register/ApproveInvoiceStatus?uniqueNo=` + request;
    return Instense.get(url).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static invoiceToApprove = async (request: any[]): Promise<any | null> => {
    const url = `/InvoiceApproval/ApproveInvoiceWaitingOnMe`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static VoidTranactionEntry = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    console.log(request);
    const url = "/transfer/VoidTranactionEntry";
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetTempUploadTransNo = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/transfer/GetTempUploadTransNo`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveTempWithFile = async (
    request: any, files: any[]
  ): Promise<any | null> => {
    const url = `/transfer/SaveTempWithFile`;
    const formData: FormData = new FormData();
    files.forEach(element => {
      formData.append('file', element);
    });
    formData.append('formField', JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static markAsPaidUnpaid = async (request: any[]): Promise<any | null> => {
    const url = `/Register/InvoicePayment`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static markAsUnpaidMultiple = async (request: any[]): Promise<any | null> => {
    const url = `/Register/InvoicePaymentMultiple`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DeleteUploadedFileMultiple = async (request: any[]): Promise<any | null> => {
    const url = `/Register/DeleteUploadedFileMultiple`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetInvoicePaymentType = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetInvoicePaymentType`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static CheckSharedAccountExist = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/CheckSharedAccountExist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static CheckACCOpeningDateStatusByDate = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = "/transfer/CheckACCOpeningDateStatusByDate";
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAccountingReleaseDate = async (request: any): Promise<any[] | null> => {
    const url = `/Vendor/GetAccountingReleaseDate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static RegisterAccountPayment = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/RegisterAccountPayment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static MovePaidInvoice = async (request: any[]): Promise<any | null> => {
    const url = `/Register/MovePaidInvoice`;
    return Instense.post(url, request).then((response) => {
      debugger;
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetVendorActiveContract = async (request: any): Promise<any[] | null> => {
    const url = `/Register/GetVendorActiveContract`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static MovePaidInvoiceHotels = async (request: any): Promise<any[] | null> => {
    const url = `/Register/MovePaidInvoiceHotels`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetInvoiceCOAMapping = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/InvoiceCOAMapping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static VendorCheckEpayAddress = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Vendor/VendorCheckEpayAddress`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  }

  public static UpdateVendorDefaultPaymentMethod = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/Vendor/UpdateVendorDefaultPaymentMethod`;
    return Instense.post(url, null, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static BulkUpdateVendorDefaultPaymentMethod = async (request: any): Promise<any | null> => {
    const url = `/Vendor/BulkUpdateVendorDefaultPaymentMethod`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static PDFExtractionSpike = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/PDFExtractionSpike`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAllExtractionData = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetAllExtractionData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetAdvanceFeatureFlagPermission = async (flag: string, hotelID: string): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    const url = `/AdvancedAccounting/GetAdvanceFeatureFlagPermission?Flag=` + flag + "&HotelId=" + Number(hotelID) + "&TenantId=" + Number(tenantID);
    return Instense.get(url).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetCheckEditInvoiceExtractionMapping = async (HotelID: string, VID: string, TenantID: string): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    const url = `/Register/V2_CheckEditInvoiceExtractionMapping?HotelID=` + Number(HotelID) + "&VID=" + Number(VID) + "&TenantId=" + Number(tenantID);
    return Instense.get(url).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static CheckInvoiceApprovalStatus = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/InvoiceApproval/CheckInvoiceApprovalStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static CreditCardReceiptToPost = async (HotelID: string): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    const url = `/Register/CreditCardReceiptToPost?HotelID=` + Number(HotelID) + "&TenantId=" + Number(tenantID);
    return Instense.get(url).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static DeleteUploadedFileCCReceipts = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/DeleteUploadedFileCCReceipts`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetReceiptAccountList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetCreditCardAccounts`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveCreditCardReceiptWithFile = async (
    request: any, files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/SaveCreditCardReceiptWithFile`;
    const formData: FormData = new FormData();
    files.forEach(element => {
      formData.append('file', element);
    });
    formData.append('formField', JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static ConvertCCReceiptToInvoice = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/ConvertCCReceiptToInvoice`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetGroupSubGroupDetails = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantId = tenantID;
    const url = `/RegisterController_CCReceipt/GetGroupSubGroupDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetHotelIdsByGroupId = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantId = tenantID;
    const url = `/RegisterController_CCReceipt/GetHotelIdsByGroupId`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetFlaggedTransactions = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/RegisterController_CCReceipt/GetFlaggedTransactions`;
    if (Register.ajaxRequestFindData) {
      Register.ajaxRequestFindData.cancel("Cancel");
    }
    Register.ajaxRequestFindData = axios.CancelToken.source();
    return Instense.post(url, request, { cancelToken: Register.ajaxRequestFindData.token }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };
  public static GetCommInboxPermission = async (): Promise<any | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/RegisterController_CCReceipt/GetCommInboxPermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  }

  public static GetReviewPermissions = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/GetAccountingServiceSubscriptionDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static GetTransactionComments = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/RegisterController_CCReceipt/GetTransactionComments`;
    if (Register.ajaxRequestFindData) { Register.ajaxRequestFindData.cancel("Cancel") }
    Register.ajaxRequestFindData = axios.CancelToken.source();
    return Instense.post(url, request, { cancelToken: Register.ajaxRequestFindData.token }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static ExtractFileByAzure = async (
    files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const url = `/Register/PDFExtractionusingAzureByFile`;
    const formData: FormData = new FormData();
    files.forEach(element => {
      formData.append('file', element);
    });
    return Instense.post(url, formData).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static DraftTransactionlist = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Register/DraftTransactionlist`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };
}