import * as React from "react";
import { Dropdown, Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
export class MapDataSearchDropdownList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            defaultSelectedName: props.defaultName,
            filterData: props.itemList,
            itemList: props.itemList,
            inputValue: "",
            searchText: props.defaultText,
            controlID: props.controlID,
            isMultiSelect: props?.isMultiSelect === undefined || props?.isMultiSelect === null ? false : props?.isMultiSelect,
            field: props?.field === undefined || props?.field === null ? "" : props?.field,
            selectedCheckBox: props.itemList.filter((row) => row.mappedTo !== "" && row.mappedTo == props?.field),
            tableSelectedCheckbox: props?.tableSelectedCheckbox === undefined || props?.tableSelectedCheckbox === null ? {} : props?.tableSelectedCheckbox,
            isCheckBoxChanged: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: any) {
        const updatedList = this.state.itemList.filter((item: any) => {
            return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
        });
        this.setState({ filterData: updatedList, inputValue: e.target.value });
    }
    handleDropdownChange(e: any) {
        this.setState({ inputValue: "", filterData: this.state.itemList });
        this.props.handleSelectedItem(e, this.state.controlID);
    }

    onChangeCheckbox = (e, item) => {
        let selectedCheckBox = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedCheckBox)));
        let itemList = Object.assign([], JSON.parse(JSON.stringify(this.state.itemList)));
        let filterData = Object.assign([], JSON.parse(JSON.stringify(this.state.filterData)));
        let row = Object.assign({}, JSON.parse(JSON.stringify(item)));
        // row.isChecked = e.target.checked;
        row.mappedTo = this.state.field;
        //let itemList: any = this.state.itemList.filter((item) => item.mappedTo !== "" && item.mappedTo === row.mappedTo && item.name !== row.name);
        if (e.target.checked) {
            selectedCheckBox.push(row);
            if (itemList.filter((x) => x.id === item.id).length > 0) {
                const index = itemList.findIndex((x) => x.id === item.id);
                itemList[index].mappedTo = this.state.field;
            }
            if (filterData.filter((x) => x.id === item.id).length > 0) {
                const index = filterData.findIndex((x) => x.id === item.id);
                filterData[index].mappedTo = this.state.field;
            }
        } else {
            if (selectedCheckBox.filter((x) => x.id === item.id).length > 0) {
                const index = selectedCheckBox.findIndex((x) => x.id === item.id);
                selectedCheckBox.splice(index, 1);
            }
            if (itemList.filter((x) => x.id === item.id).length > 0) {
                const index = itemList.findIndex((x) => x.id === item.id);
                itemList[index].mappedTo = "";
            }
            if (filterData.filter((x) => x.id === item.id).length > 0) {
                const index = filterData.findIndex((x) => x.id === item.id);
                filterData[index].mappedTo = "";
            }
        }

        this.setState({ selectedCheckBox, filterData, itemList, isCheckBoxChanged: true });
        // this.props.tableSelectedCheckbox(row, this.state.controlID);
    }

    handleMouseOver(name) {
        this.props.handleMouseOver(name, this.state.controlID);
    }
    handleMouseLeave(name) {
        this.props.handleMouseLeave(name, this.state.controlID);
    }

    handleToggle = (isOpen, event) => {
        if(!isOpen && this.state.isMultiSelect && this.state.isCheckBoxChanged) {
            this.props.tableSelectedCheckbox(this.state.selectedCheckBox, this.state.controlID);
        }
        this.setState({ isCheckBoxChanged: false })
    }

    render() {
        const handleSelect = (eventKey: any) => {
            this.setState({ inputValue: "", defaultSelectedName: eventKey });
        };


        return (
            <Dropdown className="single-select-image" onSelect={handleSelect} onToggle={this.handleToggle}>
                <Dropdown.Toggle id="map-dropdown">
                    <div className="d-flex align-items-center">
                        {!this.state.isMultiSelect && this.state.controlID === "1" &&
                            <div className="mr-auto select-name">
                                <EllipsisWithTooltip placement="bottom">{this.state.defaultSelectedName}</EllipsisWithTooltip>
                            </div>
                        }
                        {!this.state.isMultiSelect && this.state.controlID === "2" && this.state.selectedCheckBox.length === 0 &&
                            <div className="mr-auto select-name">
                                <EllipsisWithTooltip placement="bottom">{""}</EllipsisWithTooltip>
                            </div>
                        }
                        {!this.state.isMultiSelect && this.state.controlID === "2" && this.state.selectedCheckBox.length === 1 &&
                            <div className="mr-auto select-name">
                                <EllipsisWithTooltip placement="bottom">{this.state.selectedCheckBox[0].name}</EllipsisWithTooltip>
                            </div>
                        }
                        {this.state.isMultiSelect && this.state.selectedCheckBox.length === 0 &&
                            <div className="mr-auto select-name">
                                <EllipsisWithTooltip placement="bottom">{""}</EllipsisWithTooltip>
                            </div>
                        }
                        {this.state.isMultiSelect && this.state.selectedCheckBox.length === 1 &&
                            <div className="mr-auto select-name">
                                <EllipsisWithTooltip placement="bottom">{this.state.selectedCheckBox[0].name}</EllipsisWithTooltip>
                            </div>
                        }
                        {this.state.isMultiSelect && this.state.selectedCheckBox.length > 1 &&
                            <div className="d-flex mr-auto select-name multi-select">
                                <EllipsisWithTooltip placement="bottom">{this.state.field}</EllipsisWithTooltip>
                                <div className="selected-count">{this.state.selectedCheckBox.length}</div>
                            </div>
                        }
                        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.29248 0.292725L4.99981 3.58606L1.70715 0.292725L0.29248 1.70739L4.99981 6.41406L9.70715 1.70739L8.29248 0.292725Z" fill="#84888C" />
                        </svg>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="search-sec">
                        <input type="text" className="form-control"
                            onChange={this.handleChange}
                            placeholder={this.state.searchText}
                            value={this.state.inputValue}
                        />
                    </div>
                    <div className="list-area">
                        {!this.state.isMultiSelect &&
                            <Dropdown.Item eventKey={"Select"} key={"Select"} onClick={() => this.handleDropdownChange({ name: "", value: "", id: -1 })}>
                                <div className="d-flex align-items-center">
                                    <div className={"display-item mr-auto"}>
                                        <div className="name">{"Select"}</div>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        }
                        {!this.state.isMultiSelect &&
                            this.state.filterData.map((item: any, idx: any) => (
                                <Dropdown.Item eventKey={item.name} key={idx} onClick={() => this.handleDropdownChange(item)}
                                    //onMouseOver={() => this.handleMouseOver(item.name)}
                                    onMouseEnter={() => this.handleMouseOver(item.name)}
                                    onMouseLeave={() => this.handleMouseLeave(item.name)}
                                    disabled={item.mappedTo !== "" && item.mappedTo !== this.state.field && this.state.controlID === "2"}
                                    className={item.mappedTo !== "" && item.mappedTo !== this.state.field && this.state.controlID === "2" ?"disabled-item": ""}
                                //onMouseEnter={handleMouseEnter}
                                // onMouseLeave={handleMouseLeave}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className={item.status !== "" ? "is-status display-item mr-auto" : "display-item mr-auto"}>
                                            <div className="name"><EllipsisWithTooltip placement="bottom"  >{item.name}</EllipsisWithTooltip></div>
                                            <div className="val">
                                                <EllipsisWithTooltip placement="bottom">{item.value !== "" ? item.value : "No Value"}</EllipsisWithTooltip>
                                            </div>
                                        </div>
                                        {item.status !== "" && (
                                            <div className="status d-flex justify-content-center align-items-center">Mapped</div>
                                        )}
                                    </div>
                                </Dropdown.Item>
                            ))
                        }
                        {this.state.isMultiSelect &&
                            this.state.filterData.map((item: any, idx: any) => (
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    key={"custom-" + idx}
                                    id={"custom-" + idx}
                                    className={(item.mappedTo !== "" && item.mappedTo !== this.state.field) ? "disabled-item":""}
                                    label={
                                        <div className="d-flex align-items-center"
                                            onMouseEnter={() => this.handleMouseOver(item.name)}
                                            onMouseLeave={() => this.handleMouseLeave(item.name)}
                                        >
                                            <div className={item.status !== "" ? "is-status display-item mr-auto" : "display-item mr-auto"}>
                                                <div className="name"><EllipsisWithTooltip placement="bottom"  >{item.name}</EllipsisWithTooltip></div>
                                                <div className="val">
                                                    <EllipsisWithTooltip placement="bottom">{item.value !== "" ? item.value : "No Value"}</EllipsisWithTooltip>
                                                </div>
                                            </div>
                                            {item.status !== "" && (
                                                <div className="status d-flex justify-content-center align-items-center">Mapped</div>
                                            )}
                                        </div>
                                    }
                                    checked={item.mappedTo !== ""}
                                    disabled={item.mappedTo !== "" && item.mappedTo !== this.state.field}
                                    onChange={(e) => this.onChangeCheckbox(e, item)}
                                    onMouseEnter={() => this.handleMouseOver(item.name)}
                                    onMouseLeave={() => this.handleMouseLeave(item.name)}
                                />
                            ))
                        }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
