import React from "react";
import { toast, ToastContainer } from "react-toastify";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import payrollIcon64 from "../../../Common/Assets/Images/payroll-icon80.png";
import laborIcon64 from "../../../Common/Assets/Images/labor-management-icon80.png";
import facilities80 from "../../../Common/Assets/Images/facilities-80-icon.png";
import accounting80 from "../../../Common/Assets/Images/accounting80.png";
import accountingservices80 from "../../../Common/Assets/Images/accounting-services80.png";
import procurement80 from "../../../Common/Assets/Images/procurement-80-icon.png";
import sales80 from "../../../Common/Assets/Images/sales-80-icon.png";
import BootstrapTable from "react-bootstrap-table-next";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import UserManagement from "../../LaborManagement/UserManagement";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import _ from "lodash";
import { Utils } from "../../../Common/Utilis";
import Joi from "joi-browser";
import { FaCopy } from "react-icons/fa";
let selectedRolesItem: any = [];
export class Subscriptions extends React.Component<any, any> {
  payrollintegerationSchema = Joi.object({
    
    ClientID: Joi.string()
      .required()   
      .error((errors) => {
        return  errors.map((error) => {
          return { message: "Client Id is required" };
        });
      }),
      APIKey: Joi.string()
      .required()   
      .error((errors) => {
        return  errors.map((error) => {
          return { message: "API Key is required" };
        });
       
      }),
      APISecret: Joi.string()
      .required()   
      .error((errors) => {
        return errors.map((error) => {
          return { message: "API Secret is required" };
        });
       
      })
    })
  constructor(props: any) {
    super(props);
    this.state = {
      hotelDetails: {},
      allModules: [],
      subscriptionRow: {},
      tableExpended: [],
      AllowPerformanceReview: 0,
      AllowTimeOffReview: 0,
      AllowDDReview: 0,
      showLocalTaxFields: 0,
      AllowBreakAttestation:0,
      isExpand: false,
      PayrollAccountType: [],
      CheckWise: [],
      isFormValid: true,
      isPayrollSettingNotChanged: true,
      isDataSubmitting: false,
      isCopied: false,
      IsValidForm: false,
      inputPosition:0,
      error: {},
      PayrollSetting: {
        AutoTransfer: false,
        OperatingID: -2,
        PayOprId: -2,
      },
      PrevPayrollSetting: {
        AutoTransfer: false,
        OperatingID: -2,
        PayOprId: -2,
      },
    };
  }


  componentDidMount() {
    this.getPayrollImportAccount();
    this.getHotelModules();
  }

  handleDiscard = () => {
    this.setState({
      PayrollSetting: _.cloneDeep(this.state.PrevPayrollSetting),
      isPayrollSettingNotChanged: true,
      isFormValid: true,
    });
  };

  checkFormModified(isLoadedFromApi = false) {
    const { PayrollSetting, PrevPayrollSetting } = this.state;
    let _isPayrollSettingNotChanged = _.isEqual(
      PayrollSetting,
      PrevPayrollSetting
    );
    this.setState({ isPayrollSettingNotChanged: _isPayrollSettingNotChanged });
  }

  getHotelModules = () => {
    EntityManagementService.GetHotelModules(
      this.props?.entityRowData?.hotelId
    ).then((hotelDetails: any) => {

      const hotelModules = [...hotelDetails?.hotelModules?.map((x: any)=> {
        if(x?.moduleName === "Accounting Service") {
          x.moduleName = 'Accounting Services'
        }
        x.startDate = x.startDate
        ? moment(x.startDate).format("MM/YYYY")
        : x.startDate;
      x.terminationDate = x.terminationDate
        ? moment(x.terminationDate).format("MM/YYYY")
        : x.terminationDate;

        return x;
      })];
      const inactiveHotelModules = [...hotelDetails?.inactiveHotelModules?.map((x: any)=> {
        if(x?.moduleName === "Accounting Service") {
          x.moduleName = 'Accounting Services'
        }
        x.startDate = x.startDate
        ? moment(x.startDate).format("MM/YYYY")
        : x.startDate;
      x.terminationDate = x.terminationDate
        ? moment(x.terminationDate).format("MM/YYYY")
        : x.terminationDate;
        return x;
      })];
      const payrollImportSetting = hotelDetails.payrollImportSetting;
      let PayrollSetting = {
        AutoTransfer: payrollImportSetting?.autoTransfer,
        OperatingID: payrollImportSetting?.oprId,
        PayOprId: payrollImportSetting?.payOprId,
      };
      this.setState({
        PayrollSetting,
        PrevPayrollSetting: _.cloneDeep(PayrollSetting),
      });

      if (hotelModules?.length > 0) {
        hotelDetails.hotelModules = hotelModules.map((item) => {
          // item.startDate = item.startDate
          //   ? moment(item.startDate).format("MM/YYYY")
          //   : item.startDate;
          // item.terminationDate = item.terminationDate
          //   ? moment(item.terminationDate).format("MM/YYYY")
          //   : item.terminationDate;
          return item;
        });

        hotelDetails.inactiveHotelModules = inactiveHotelModules.map((item) => {
          // item.startDate = item.startDate
          //   ? moment(item.startDate).format("MM/YYYY")
          //   : item.startDate;
          // item.terminationDate = item.terminationDate
          //   ? moment(item.terminationDate).format("MM/YYYY")
          //   : item.terminationDate;
          return item;
        });
      }

      if (hotelDetails.addHotelDetail) {
        this.setState({
          AllowPerformanceReview:
            hotelDetails.addHotelDetail.allowPerformanceReview,
          AllowTimeOffReview: hotelDetails.addHotelDetail.allowTimeOffReview,
          AllowDDReview: hotelDetails.addHotelDetail.allowDDReview,
          showLocalTaxFields:hotelDetails.addHotelDetail.showLocalTaxFields,
          AllowBreakAttestation:hotelDetails.addHotelDetail.allowBreakAttestation,
          ClientID:hotelDetails.addHotelDetail.clientId,
          APIKey:hotelDetails.addHotelDetail.apiKey,
          APISecret:hotelDetails.addHotelDetail.apiSecret,
        });
      }

      hotelDetails.payrollIntegration=hotelDetails.payrollIntegration
      this.setState({ hotelDetails }, () => this.getAllModules());
    });
  };

  getPayrollImportAccount = () => {
    EntityManagementService.GetPayrollImportAccount(
      this.props?.entityRowData?.hotelId
    ).then((result: any) => {
      // debugger;
      if (result.message === "Success") {
        this.setState({
          PayrollAccountType: result?.result?.filter((r) => +r.oprId !== -1),
          CheckWise: result?.result,
        });
      }
    });
  };

  savePayrollImport = () => {
    this.setState({ isDataSubmitting: true });
    EntityManagementService.SavePayrollImport(
      this.state?.PayrollSetting,
      this.props?.entityRowData?.hotelId
    )
      .then((result: any) => {
        this.setState({ isDataSubmitting: false });

        if (result?.message === "Success") {
          this.setState({ isPayrollSettingNotChanged: true , PrevPayrollSetting: _.cloneDeep(this.state?.PayrollSetting)});

          toast.success("Payroll Import Saved Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSubmitting: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };

  getAllModules = () => {
    EntityManagementService.GetAllModules().then((allModules: any) => {

      allModules = allModules.map((x:any)=> {
        if(x?.moduleName === 'Accounting Service') {
          x.moduleName = 'Accounting Services';
        }
        return x;
      });
      this.setState({ allModules });
    });
  };

  closeSlideOut = () => {};

  getStatusBadgeclass = (status) => {
    let className = "";
    switch (status) {
      case "Active":
        className = "color-green";
        break;
      case "Inactive":
        className = "color-grey";
        break;
      default:
        className = "color-orange";
        break;
    }
    return className;
  };

  setActiveTab = (selectedTab) => {
    this.setState({ activeTab: selectedTab });
  };

  handleOnSubscriptionRowExpand = (
    row: any,
    isExpand: any,
    rowIndex: any,
    e: any
  ) => {
    if (this.state.tableExpended.length > 0) {
      this.setState({
        subscriptionRow: {},
        isExpand: false,
        tableExpended: [],
      }, ()=> {
        if(isExpand) {
          this.setState(() => ({
            isExpand: true,
            subscriptionRow: row,
            tableExpended: [row.moduleName],
          }));
        }
      });
    } else {
      this.setState(() => ({
        isExpand: true,
        subscriptionRow: row,
        tableExpended: [row.moduleName],
      }));
      //  this.getPTOAdjustedDetails(row.ScheduleId);
    }
  };

  onIsAllowPTOChange = (event) => {
    this.setState({ AllowTimeOffReview: event.target.checked ? 1 : 0 }, () => {
      this.updateDefaultToggle();
    });
  };

  onIsAllowPerformanceReviewChange = (event) => {
    this.setState(
      { AllowPerformanceReview: event.target.checked ? 1 : 0 },
      () => {
        this.updateDefaultToggle();
      }
    );
  };
  ClientID = (event,inputPosition) => {
    this.setState(
      { ClientID: event.target.value , inputPosition:inputPosition},
      () => {
         this.validateForm();
      }
    );
  };
  APIKey = (event,inputPosition) => {
    this.setState(
      { APIKey: event.target.value , inputPosition: inputPosition},
      () => {
        this.validateForm();
      }
    );
  };
  APISecret = (event, inputPosition) => {
    this.setState(
      { APISecret: event.target.value, inputPosition:inputPosition},
      () => {
        this.validateForm();
      }
    );
  };

  onIsAllowDDReview = (event) => {
    this.setState({ AllowDDReview: event.target.checked ? 1 : 0 }, () => {
      this.updateDefaultToggle();
    });
  };

  onShowLocalTaxFields = (event) => {
    this.setState({ showLocalTaxFields: event.target.checked ? 1 : 0 }, () => {
      this.updateDefaultToggle();
    });
  };

  onIsAllowBreakAttestation = (event) => {
    this.setState({ AllowBreakAttestation: event.target.checked ? 1 : 0 }, () => {
      this.updateDefaultToggle();
    });
  };

  saveOnBlur=(event,inputPosition)=>{
    const {ClientID,APIKey,APISecret, hotelDetails}=this.state
    const previousHotelDetails=_.cloneDeep(hotelDetails)
    let isSameData=false
    let currentApiSecret=""
    if(inputPosition == 1){
       isSameData=previousHotelDetails.addHotelDetail.clientId?.trim() === ClientID?.trim()
       if(!isSameData){
        previousHotelDetails.addHotelDetail.clientId =  ClientID?.trim()
      }
    }
    else if(inputPosition == 2)
    {
      isSameData=previousHotelDetails.addHotelDetail.apiKey?.trim() === APIKey?.trim()
      if(!isSameData){
        previousHotelDetails.addHotelDetail.apiKey =  APIKey?.trim()
      }
    }
    else if(inputPosition == 3){
      currentApiSecret=APISecret?.trim()
      isSameData=previousHotelDetails.addHotelDetail.apiSecret?.trim() === currentApiSecret
      if(!isSameData){
        if (currentApiSecret==""){
          currentApiSecret=previousHotelDetails.addHotelDetail.apiSecret?.trim()
          isSameData=true;
        }
        else {
          previousHotelDetails.addHotelDetail.apiSecret =  currentApiSecret

        }
      }
    }

    
    
    this.setState({
      ClientID:ClientID?.trim(),
      APIKey:APIKey?.trim(),
      APISecret:currentApiSecret,
      inputPosition:inputPosition,
      hotelDetails:previousHotelDetails
    },()=>{
      this.validateForm()
      if(event.target.value?.trim() !="" && !isSameData)
      {
        this.updateDefaultToggle()
      }
     
    })
  
    // if(event.target.value?.length > 0)
    // {
    //   this.updateDefaultToggle()
    // }
  }
  validateForm = () => {

const payrollintegeration={
  ClientID:this.state?.ClientID || "",
  APIKey:this.state?.APIKey || "",
  APISecret:this.state?.APISecret || ""}
    const valid = Joi.validate(payrollintegeration, this.payrollintegerationSchema, {
      abortEarly: false,
    });

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

   let newIsValidForm=false;
      if (Object.keys(newErrorObject).length === 0) {
        newIsValidForm = true
      }

    this.setState({ error: newErrorObject, IsValidForm:newIsValidForm});
    return newErrorObject;
  };
  copyToClipboard = (text, type) => {
    this.setState({ isCopied: true }, () => {
      setTimeout(() => {
        this.setState({ isCopied: false });
      }, 1000);
    });
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();   
      let message = "API Secret Copied to Your Clipboard";   
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "addGarnishment",
    });
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { error, inputPosition } = this.state;
    return (
      <>
        {Object.keys(error).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{error[fieldName]}</span>
        )}
      </>
    );
  };
  updateDefaultToggle = () => {
    const {
      AllowTimeOffReview,
      AllowPerformanceReview,
      AllowDDReview,
      showLocalTaxFields,
      AllowBreakAttestation,
      hotelDetails,
      ClientID,
      APIKey,
      APISecret
    } = this.state;

    EntityManagementService.UpdateHotelDefaultSetting(
      AllowPerformanceReview,
      AllowTimeOffReview,
      AllowDDReview,
      AllowBreakAttestation,
      showLocalTaxFields,
      hotelDetails.hotelDetail?.hotelID,
      ClientID,
      APIKey,
      APISecret
    ).then((result) => {
      if (result.message == "Success") {
        toast.success("Hotel default setting update successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      } else {
        Utils.toastError(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      }
    });
  };

  getModuleIcon = (moduleName) => {
    if (moduleName == "Payroll" || moduleName == "Payroll Service" || moduleName === "ShowPayroll") {
      return <img src={payrollIcon64} alt="Payroll Service" width="64"></img>;
    }
    if (moduleName == "Labor Management") {
      return <img src={laborIcon64} alt="Labor Management" width="64"></img>;
    }
    if (moduleName == "Sales") {
      return <img src={sales80} alt="Sales" width="64"></img>;
    }
    if (moduleName == "Accounting") {
      return <img src={accounting80} alt="Accounting" width="64"></img>;
    }
    if (moduleName == "Accounting Services") {
      return <img src={accountingservices80} alt="Accounting Services" width="64"></img>;
    }
    if (moduleName == "Procurement") {
      return <img src={procurement80} alt="Procurement" width="64"></img>;
    }
  };

  onPayrollAccChange = (value, keyName) => {
    let payrollSetting = this.state.PayrollSetting;
    if (keyName === "AutoTransfer") {
      payrollSetting.AutoTransfer = value.target.checked ? "True" : "False";
    } else {
      payrollSetting[keyName] = value;
    }
    if ((payrollSetting.OperatingID === payrollSetting.PayOprId) || payrollSetting.PayOprId == -1) {
      payrollSetting.AutoTransfer = "False";
    }
    this.setState({ PayrollSetting: payrollSetting }, () => {
      this.checkFormModified();
    });
  };

  render() {
    const {
      hotelDetails,
      allModules,
      PayrollAccountType,
      CheckWise,
      PayrollSetting,
      isDataSubmitting,
      isFormValid,
      isPayrollSettingNotChanged,
      error,
      inputPosition
    } = this.state;

    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const expandRowSubscriptions = {
      renderer: (row: any) => (
        <>
          {row.moduleName === "Labor Management" && (
            <div className="rowExpended" id="laborDayDetails">
              <div className="body-section">
                <div className="switch-heading d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    id="AllowPerformanceReview"
                    label=""
                    disabled={ !this.props?.hasEditPermission}
                    name="chkAllowPerformanceReview"
                    checked={
                      this.state.AllowPerformanceReview == 1 ? true : false
                    }
                    onChange={(event: any) => {
                      this.onIsAllowPerformanceReviewChange(event);
                    }}
                  />
                  <div className="heading">Performance Reviews</div>
                  <span className="label">
                  Defaults the Performance Review section for each user to Enabled
                  </span>
                </div>
              </div>
              <div className="body-section">
                <div className="switch-heading d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    id="AllowTimeOffReview"
                    label=""
                    disabled={!this.props?.hasEditPermission}
                    name="chkAllowTimeOffReview"
                    checked={this.state.AllowTimeOffReview == 1 ? true : false}
                    onChange={(event: any) => {
                      this.onIsAllowPTOChange(event);
                    }}
                  />

                  <div className="heading">Paid Time Off</div>
                  <span className="label">
                  Mark new users as requiring attention when they do not have any PTO policies
                  </span>
                </div>
              </div>
              <div className="body-section">
                <div className="switch-heading d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    id="AllowBreakAttestation"
                    label=""
                    disabled={ !this.props?.hasEditPermission}
                    name="chkAllowBreakAttestation"
                    checked={this.state.AllowBreakAttestation == 1 ? true : false}
                    onChange={(event: any) => {
                      this.onIsAllowBreakAttestation(event);
                    }}
                  />

                  <div className="heading">Break Attestation</div>
                  <span className="label">
                  Enable/Disable Break Attestation setting
                  </span>
                </div>
              </div>
            {hotelDetails.payrollIntegration != "" && 
              <>
                <div  className={
                          error?.ClientID && inputPosition >= 1
                            ? "validation-error body-section"
                            : "body-section"
                        }>
                  <div className="configuration-setting-wrapper">
                    <h5 className="mb-3">{hotelDetails.payrollIntegration} Configuration</h5>
                    <div className="configuration-setting d-flex align-items-center">
                      <Form.Label>
                        <span className="label">Client ID</span>
                      </Form.Label>
                      <div className="input-container">
                        <Form.Control                     
                          type="text"
                          id="netchexConfiguration"
                          disabled={ !this.props?.hasEditPermission}
                          name="netchex"
                          value={this.state.ClientID}
                          onChange={(event: any) => {
                            this.ClientID(event,1);
                          }}
                          maxLength={255}
                          onBlur={(event)=>this.saveOnBlur(event,1)}
                          
                        />
                        {this.getFieldError("ClientID", 1)}
                      </div>
                    </div>
                  </div>
                </div>
                {hotelDetails.payrollIntegration == "Paychex" ? 
                <>
                <div className={
                        error?.APIKey && inputPosition >= 2
                          ? "validation-error body-section"
                          : "body-section"
                      }>
                  <div className="configuration-setting d-flex align-items-center">
                    <Form.Label>
                      <span className="label">API Key</span>
                    </Form.Label>

                    <div className="input-container">
                      <Form.Control
                        type="text"
                        id="paychexConfiguration"
                        disabled={ !this.props?.hasEditPermission}
                        name="paychex"
                        value={this.state.APIKey}
                        onChange={(event: any) => {
                          this.APIKey(event,2);
                        }}
                        maxLength={255}
                        onBlur={(event)=>this.saveOnBlur(event,2)}
                      
                      />
                      {this.getFieldError("APIKey", 2)}
                    </div>
                  </div>
                </div>
                   
                <div className={
                  error?.APISecret && inputPosition >= 3
                    ? "validation-error  body-section"
                    : " body-section"
                }>
                  <div className="configuration-setting d-flex align-items-center">
                  <Form.Label>
                      <span className="label">API Secret</span>
                    </Form.Label>
                    <div className="input-container d-flex align-items-center">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        id="paychexConfigurations"
                        disabled={ !this.props?.hasEditPermission}
                        name="paychex"
                        value={this.state.APISecret}
                        onClick={()=> this.setState({ APISecret: ""})}
                        onChange={(event: any) => {
                          this.APISecret(event,3);
                        }}
                        maxLength={255}
                        onBlur={(event)=>this.saveOnBlur(event,3)}
                        className="text-security-password"
                        
                      />
                       <span className="icon">
                       <FaCopy onClick={() =>
                        this.copyToClipboard(this.state.APISecret, "API Secret")
                            } />
                         </span>
                      {this.getFieldError("APISecret", 3)}
                    </div>
                  </div>
                </div> 
                </>
                :<></>}
                </> 
              }
              
              {/* <div className="body-section">
                <div className="switch-heading d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    id="AllowBreakAttestation"
                    label=""
                    disabled={false}
                    name="chkAllowBreakAttestation"
                    checked={this.state.AllowBreakAttestation == 1 ? true : false}
                    onChange={(event: any) => {
                      this.onIsAllowBreakAttestation(event);
                    }}
                  />

                  <div className="heading">Break Attestation</div>
                  <span className="label">
                  Enable/Disable Break Attestation setting
                  </span>
                </div>
              </div> */}
            </div>
          )}
          {(row.moduleName === "Payroll" ||
            row.moduleName === "Payroll Service"
            || row?.moduleName === "ShowPayroll") && (
            <div className="rowExpended" id="laborDayDetails">
              <div className="body-section">
                <div className="switch-heading d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    id="AllowDDReview"
                    label=""
                    disabled={!this.props?.hasEditPermission}
                    name="chkAllowDDReview"
                    checked={this.state.AllowDDReview == 1 ? true : false}
                    onChange={(event: any) => {
                      this.onIsAllowDDReview(event);
                    }}
                  />
                  <div className="heading">Require Direct Deposit by Default</div>
                  <span className="label">
                  Defaults the Direct Deposit section for each user to enabled
                  </span>
                </div>

                <div className="switch-heading d-flex align-items-center mt-3">
                  <Form.Check
                    type="switch"
                    id="showLocalTaxFields"
                    label=""
                    disabled={false}
                    name="chkAshowLocalTaxFields"
                    checked={this.state.showLocalTaxFields == 1 ? true : false}
                    onChange={(event: any) => {
                      this.onShowLocalTaxFields(event);
                    }}
                  />
                  <div className="heading">Show Local Tax Fields</div>
                  <span className="label">
                  Enabling this will make the local tax fields visible on the user’s Payroll tab.
                  </span>
                </div>
              </div>
            </div>
          )}
          
          {row.moduleName === "Accounting" && (
            <>
              <div className="rowExpended" id="laborDayDetails">
                <div className="body-section">
                  <h5>Payroll Import Settings</h5>
                  <div className="dropdown-selector d-flex align-items-center">
                    <Form.Label>
                      <span className="label">
                        COA-Wise Payroll transactions will import into the
                        following account
                        
                      </span>
                    </Form.Label>
                    <DropDownList
                      placeHolder={"Select account"}
                      disabled={!this.props?.hasEditPermission}
                      data={PayrollAccountType}
                      defaultValue={PayrollSetting.OperatingID}
                      isSearchRequired={false}
                      label={"name"}
                      value={"oprId"}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onPayrollAccChange(item.oprId, "OperatingID");
                      }}
                      selectedItem={
                        [
                          ...[{ oprId: -2, name: "Select account" }],
                          ...PayrollAccountType,
                        ].find(
                          (r: any) => r?.oprId === PayrollSetting.OperatingID
                        ) || {
                          oprId: 0,
                          label: "Select account",
                        }
                      }
                    />
                  </div>
                </div>
                <div className="body-section">
                  <div className="dropdown-selector d-flex align-items-center">
                    <Form.Label>
                      <span className="label">
                        Check-Wise Payroll transactions will import into the
                        following account
                      </span>
                    </Form.Label>
                    <DropDownList
                      placeHolder={"Select account"}
                      data={CheckWise}
                      disabled={!this.props?.hasEditPermission}
                      defaultValue={PayrollSetting.PayOprId}
                      isSearchRequired={false}
                      label={"name"}
                      value={"oprId"}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onPayrollAccChange(item.oprId, "PayOprId");
                      }}
                      selectedItem={
                        [
                          ...[{ oprId: -2, name: "Select account" }],
                          ...CheckWise,
                        ].find(
                          (r: any) => r?.oprId === PayrollSetting.PayOprId
                        ) || {
                          oprId: 0,
                          label: "Select account",
                        }
                      }
                    />
                  </div>
                </div>
                {((PayrollSetting?.OperatingID !== PayrollSetting?.PayOprId) && PayrollSetting?.PayOprId  != -1) && (  <div className="body-section">
                
                    <div className="switch-heading d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        id="AutoTransfer"
                        label=""
                        disabled={!this.props?.hasEditPermission}
                        name="chkAutoTransfer"
                        checked={
                          PayrollSetting?.AutoTransfer === "True" ? true : false
                        }
                        onChange={(event: any) => {
                          this.onPayrollAccChange(event, "AutoTransfer");
                        }}
                      />
                      <div className="heading">Auto Transfer</div>
                    </div>
               
                </div>
                   )}
              </div>
            </>
          )}
          {row.moduleName !== "Labor Management" && (
            <div className="rowExpendedEmpty" id="emptydiv">
              {<></>}
            </div>
          )}
        </>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnSubscriptionRowExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const subsriptionsMainColumns = [
      {
        dataField: "moduleName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="active-subscription-details d-flex flex-column">
            {this.getModuleIcon(row.moduleName)}
          </div>
        ),
      },
      {
        dataField: "moduleName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="active-subscription-details d-flex flex-column">
            <span className="active-subscription-type">
              {row?.moduleName === "Payroll Service"|| row?.moduleName === "ShowPayroll"
                ? "Payroll"
                : row?.moduleName === "Accounting Services" ? "Bookkeeping Services":row?.moduleName}
            </span>
            <span className="active-subscription-type-label">
              {row?.status === "Active" ? "Active" : "Ended"}{" "}
              {row?.status === "Active" ? row?.startDate : row?.terminationDate}
            </span>
          </div>
        ),
      },
      {
        dataField: "subscriptionType",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
          {
            cell != "Full" &&
            <div className="badge color-blue">{cell}</div>
          }
          </>
        ),
      },
    ];

    const activeSubcriptions = hotelDetails?.hotelModules;
    const inActiveSubcriptions = hotelDetails?.inactiveHotelModules;
    const moduleNames =
      [
        ...(hotelDetails?.hotelModules || []),
        ...(hotelDetails?.inactiveHotelModules || []),
      ]?.map((x) => x?.moduleName) || [];
    const availableModules = allModules.filter(
      (x) => !moduleNames?.includes(x?.moduleName)
    );

    return (
      <div className="general-tab general-tab-content">
        {/* <ToastContainer containerId={"userSlideOut"} autoClose={3000} /> */}
        {activeSubcriptions?.length > 0 && (
          <div className="body-section">
            <div className="heading">Active Subscriptions</div>
            <div className="active-subscription-table">
              <BootstrapTable
                keyField="moduleName"
                data={activeSubcriptions}
                columns={subsriptionsMainColumns}
                expandRow={expandRowSubscriptions}
                rowClasses={rowClasses}
                hover
              />
            </div>
          </div>
        )}
        {inActiveSubcriptions?.length > 0 && (
          <div className="body-section">
            <div className="heading">Inactive Subscriptions</div>
            <div className="inactive-subscription-table">
              <BootstrapTable
                keyField="moduleName"
                data={inActiveSubcriptions}
                columns={subsriptionsMainColumns?.filter(
                  (x) => x?.dataField !== "subscriptionType"
                )}
                rowClasses={rowClasses}
                hover
              />
            </div>
          </div>
        )}
        {availableModules?.length > 0 && (
          <div className="body-section">
            <div className="heading">Available Products</div>
            {availableModules.map((item, i) => {
              return (
                <div key={i} className="available-modules-items">
                  <div>{this.getModuleIcon(item.moduleName)}</div>
                  <div className="title">
                    {item?.moduleName === "Payroll Service" || item?.moduleName === "ShowPayroll"
                      ? "Payroll"
                      : item?.moduleName === "Accounting Services" ? "Bookkeeping Services":item?.moduleName  }
                  </div>

                  <div className="description">{item?.moduleDescription}</div>
                  <Button
                    onClick={() => {
                      window.open(item?.externalLink, "_blank");
                    }}
                    className="btn-outline-primary"
                  >
                    Learn More
                  </Button>
                </div>
              );
            })}
          </div>
        )}

        {isFormValid && !isPayrollSettingNotChanged && (
          <div className="fixed-action ml-auto">
            <div className="d-flex align-content-center flex-wrap">
              <div className="mr-auto message">
                <span>You have unsaved changes</span>
              </div>
              <button
                disabled={isDataSubmitting ||  !this.props?.hasEditPermission}
                type="button"
                className="btn btn-primary btn-discard"
                onClick={this.handleDiscard}
              >
                Discard
              </button>

              <button
                disabled={isDataSubmitting}
                type="button"
                className="btn btn-primary"
                onClick={() => this.savePayrollImport()}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
