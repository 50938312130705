import React from 'react';
import { Modal, Button, Dropdown, ProgressBar, Form, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import { bankSyncServices } from "../../../Common/Services/bankSyncServices";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "../../Sales/component/component.scss";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import TableSearch from "../../../Common/Components/TableSearch";

const steps = [
    { label: 'Step 1', desc: 'Select Mapping rule and file' },
    { label: 'Step 2', desc: 'Map statement columns to Inn-Flow' },
    { label: 'Step 3', desc: 'Review Mapping and Import' }
];
let columnCounter = 5;
export class ManualStatementUploadModal extends React.Component<any, any> {
    private child: any;
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            activeStep: 0,
            activeRuleName: "",
            mappingRuleArray: [],
            selectedRule: {},
            uploadFiles: [],
            isAddNewRole: false,
            isProgress: Math.floor(Math.random() * 30),
            newArray: [],
            newRuleName: "",
            newRuleError: false,
            newRuleErrorSet: false,
            newRuleErrorName: "",
            formData: [],
            formDataHeaderItems: [],
            isUploadedCCS: false,
            isSelectedRoleHasValue: false,
            isFileSelected: false,
            fileNo: 0,
            hideProgress: false,

            //Manual Upload
            manualDropColumn: [],
            mappingTableData: [],
            mappingTable: [],
            mappingFields: [],
            countTable: [],
            saveMappingLoader: false,
            importTableData: [],
            defimportTblDta: [],
            saveImportLoader: false,
            selectedRows: [],
            nonSelectable: [],
            importFilterText: "All"
        };
        this.GetMappingRuleField = this.GetMappingRuleField.bind(this)
    }

    componentDidMount() {
        this.GetMappingRule();
        this.handleMapingLoad();
    }

    GetMappingRule = () => {
        let request: any = {};
        bankSyncServices.GetBankSyncMappingRules(request)
            .then(async (result: any) => {
                let data = result.data;
                data.map(item => (item["ruleName"] = item.ruleName.trim(), item["ruleNameNew"] = item.ruleName.toLowerCase().trim()))
                data = _.sortBy(data, "ruleNameNew");
                if (result.status === 200) {
                    this.setState({ mappingRuleArray: data })
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    handleSelectRule = (eventKey: any) => {
        if (eventKey === "addNewRule") {
            this.setState({ selectedRule: { "ruleID": -1, "ruleName": "Create Mapping Rule" }, isAddNewRole: true, isSelectedRoleHasValue: false });
        } else {
            this.setState({
                selectedRule: JSON.parse(eventKey),
                isSelectedRoleHasValue: true,
                isAddNewRole: false, newRuleName: "", newRuleError: false, newRuleErrorName: ""
            }, () => {
                this.GetBankSyncFieldMappedWithRule();
            });
        }
    };

    handleClose = () => {
        // Handle close logic
        this.props.toggleManualStatUploadModal(false);
        this.handleModalClose();
    };

    handleNext = () => {
        if (this.state.isAddNewRole) {
            this.addNewRuleName();
        } else {
            this.UpdateFileDetails();
        }
    };

    handleBack = () => {
        this.setState(prevState => ({
            isProgress: Math.floor(Math.random() * 30),
            activeStep: prevState.activeStep - 1,
            importFilterText: "All",
            selectedRows: [],
            nonSelectable: []
        }));
        if (this.state.activeStep === 1) {
            this.setState({
                mappingTableData: [],
                mappingTable: [],
                mappingFields: [],
                countTable: [],
                activeRuleName: "",
                selectedRule: {},
                uploadFiles: [],
                isAddNewRole: false,
                newArray: [],
                newRuleName: "",
                isUploadedCCS: false,
                isSelectedRoleHasValue: false,
                isFileSelected: false,
                fileNo: 0,
                hideProgress: false
            });
            columnCounter = 5;
        }
    };

    handleNewRuleName = (event) => {
        let { value } = event.target;
        this.setState({ newRuleName: value }, () => {
            (!this.NewRuleNameValidation("change") && this.validateRuleOnBlur("change"))
        });
    }

    validateRuleOnBlur = (type: any) => {
        let { newRuleName, newRuleError, newRuleErrorName } = this.state;
        if (!this.NewRuleNameValidation("blur")) {
            const request: any = {};
            request.ruleName = newRuleName.trim();
            request.action = "Validate";
            bankSyncServices.SaveBankSyncMappingRules(request)
                .then(result => {
                    if (result?.success) {
                        if (result?.result.result === "Exist") {
                            newRuleErrorName = "Rule name already exists";
                            newRuleError = true;
                            (type === "change") && Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
                            this.setState({ newRuleError, newRuleErrorName });
                            return newRuleError;
                        } else {
                            newRuleError = false;
                            this.setState({ newRuleError, isSelectedRoleHasValue: true });
                            return newRuleError;
                        }
                    } else {
                        Utils.toastError(result?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "mappingStatementRule",
                        });
                    }
                })
                .catch(error => {
                    Utils.toastError(error?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "mappingStatementRule",
                    });
                });
        }
    }

    NewRuleNameValidation = (event: any = "") => {
        let { newRuleName, newRuleError, newRuleErrorName } = this.state;
        if (newRuleName.trim() === "") {
            newRuleError = true;
            newRuleErrorName = "Please enter rule name.";
            (event === "change") && Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ newRuleError, newRuleErrorName });
            return newRuleError;
        } else if (newRuleName.trim().length > 50) {
            newRuleError = true;
            newRuleErrorName = "Maximum 50 characters allowed.";
            (event === "blur") && Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ newRuleError, newRuleErrorName });
            return newRuleError;
        }
    }

    addNewRuleName = () => {
        let { newRuleName, mappingRuleArray } = this.state;
        if (!this.NewRuleNameValidation()) {
            this.setState({ newRuleErrorSet: true })
            let request: any = {};
            request.ruleName = newRuleName.trim();
            request.action = "Insert";
            let newRule = {};
            bankSyncServices.SaveBankSyncMappingRules(request)
                .then(async (result: any) => {
                    if (result.success) {
                        toast.success("New rule added successfully", { position: toast.POSITION.BOTTOM_RIGHT });
                        let data = result?.result;
                        newRule = { "ruleID": data.id, "ruleName": newRuleName }
                        mappingRuleArray.push(newRule);
                        this.setState({ selectedRule: newRule, mappingRuleArray }, () => {
                            this.UpdateFileDetails();
                        });
                    } else {
                        this.setState({ newRuleErrorSet: false });
                        Utils.toastError(result?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    this.setState({ newRuleErrorSet: false })
                    Utils.toastError(error?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    reject();
                });
        }

    }

    UpdateFileDetails = () => {
        let { selectedRule, fileNo } = this.state;
        const request: any = {};
        request.fileNo = fileNo;
        request.ruleID = selectedRule.ruleID;
        bankSyncServices.UpdateFileDetails(request)
            .then(result => {
                if (result.success) {
                    this.setState({ activeStep: + 1 });
                } else {
                    Utils.toastError(result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "mappingStatementRule",
                    });
                }
            })
            .catch(error => {
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "mappingStatementRule",
                });
            });
    }

    onDrop = (uploadFiles: any) => {
        let size = 5242880
        if (uploadFiles.length === 1) {
            if (uploadFiles[0].size > size) {
                Utils.toastError("File is greater than 5 MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                this.setState({ uploadFiles }, () => {
                    this.BankSyncManualStatementExcelImport();
                });
            }
        } else if (uploadFiles.length > 1) {
            Utils.toastError("Multiple files are not allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            Utils.toastError("Only .xls and .csv file is acceptable.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    BankSyncManualStatementExcelImport = () => {
        let { newArray, uploadFiles, selectedRule } = this.state;
        let formDataHeaderItems: any = []
        let columnCount: any = [];
        let request: any = {};
        request.RuleID = (typeof (selectedRule.ruleID) !== "undefined") ? selectedRule.ruleID : 0;
        request.OprID = (typeof (this.props?.selectedAccount?.id) !== "undefined" ? this.props?.selectedAccount?.id : 5641);
        bankSyncServices.BankSyncStatementExcelImport(request, uploadFiles)
            .then(async (result: any) => {
                if (result.success) {
                    let data = result?.result.Data;
                    let fileNo = result?.result.FileNo;
                    data.forEach((item: any) => formDataHeaderItems = Object.keys(item))
                    formDataHeaderItems.forEach((i: any) => columnCount.push(i))
                    data.forEach((items: any, i: any) => {
                        if (i < columnCounter) {
                            newArray.push(data[i]);
                        }
                    });

                    this.setState({ isProgress: 100, formData: data, isUploadedCCS: true, newArray, formDataHeaderItems, columnCount, mappingTableData: data, fileNo }, () => {
                        this.handleMapingData(this.state.mappingTableData);
                        setTimeout(() => {
                            this.setState({ hideProgress: true, isFileSelected: true });
                        }, 500);
                    });
                } else if (result?.message.toString().toLowerCase() === "LengthExceed".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Import sheet should not have more than 100 characters in a cell.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30), isFileSelected: false });
                    }, 1500)
                } else if (result?.message.toString().toLocaleLowerCase() === "InvalidCallName".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("System keyword does not allowed please rename the column name.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30), isFileSelected: false });
                    }, 1500)
                } else {
                    setTimeout(() => {
                        Utils.toastError(result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30), isFileSelected: false });
                    }, 1500)
                }
                resolve();
            })
            .catch((error) => {
                setTimeout(() => {
                    Utils.toastError("No records are available to import.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }, 1000);
                setTimeout(() => {
                    this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30), isFileSelected: false });
                }, 1500)
                reject();
            });
    }

    resetFileUploader = () => {
        this.setState({
            uploadFiles: [], isProgress: Math.floor(Math.random() * 30), isFileSelected: false, fileNo: 0, formData: [], formDataHeaderItems: [], isUploadedCCS: false, newArray: [], hideProgress: false,
            mappingTable: [], mappingTableData: [], manualDropColumn: [], mappingFields: [],
            countTable: [], saveMappingLoader: false, importTableData: [], defimportTblDta: [], saveImportLoader: false
        });
        columnCounter = 5;
    }

    // Mapping Add
    handleMapingLoad = () => {
        this.GetMappingRuleField();
    }

    GetMappingRuleField() {
        let request: any = {};
        request.Type = "BankSyncStatement";
        bankSyncServices.GetMappingRuleField(request)
            .then(async (result: any | null) => {
                if (result != null) {
                    let ruleField = result.result;
                    ruleField.forEach((o: any) => {
                        let dName: any = o.displayFieldName.toString().toLocaleLowerCase();
                        o["hasInfo"] = dName === "custom field";
                        o["sort"] = (dName === "custom field" ? "z" : dName === "debit" ? "Crf" : o.displayFieldName)
                    });
                    ruleField = _.sortBy(ruleField, "sort")
                    this.setState({ manualDropColumn: ruleField })
                }
                resolve();
            })
            .catch((error) => {
                reject();
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    handleModalClose = () => {
        this.setState({
            mappingTableData: [], mappingTable: [], mappingRuleArray: [], selectedRule: {}, uploadFiles: [],
        })
        columnCounter = 5;
    }

    handleMapingData = (mappingTableData) => {
        let { mappingFields, mappingTable, countTable } = this.state;
        let data = mappingTableData;
        let mapFields: any = [];
        data.forEach((item: any) => mapFields = Object.keys(item));
        mapFields.forEach((i: any) => countTable.push(i));
        data.forEach((items: any, i: any) => {
            if (i < columnCounter) {
                mappingTable.push(data[i]);
            }
        });
        mapFields.forEach((o: any, i: any) => {
            let row: any = { id: i + 1, fieldName: o, fieldID: "", displayFieldName: "", showName: "" };
            mappingFields.push(row);
        });
        this.setState({ mappingFields, mappingTable, countTable }, () => {
            this.GetBankSyncFieldMappedWithRule();
        });
    }

    GetBankSyncFieldMappedWithRule = () => {
        let request: any = {};
        request.RuleID = (this.state.selectedRule.ruleID);
        request.OprID = (typeof (this.props?.selectedAccount?.id) !== "undefined" ? this.props?.selectedAccount?.id : 5641);
        bankSyncServices.GetBankSyncFieldMappedWithRule(request)
            .then(async (result: any | null) => {
                if (result.success) {
                    let data = result.result;
                    let { mappingFields } = this.state;
                    mappingFields.forEach((o: any, i: any) => {
                        data.forEach((item) => {
                            if (o.fieldName.toString().toLocaleLowerCase() === item.fileHeaderColumn.toString().toLocaleLowerCase()) {
                                o.showName = item.displayFieldName.replace(/\./g, '').replace(/ /g, '');
                                o.fieldID = item.fieldID;
                                o.displayFieldName = item.displayFieldName;
                            }
                        })
                    })
                    this.setState({ mappingFields });
                }
                resolve();
            })
            .catch((error) => {
                reject();
                Utils.toastError(`Server Error: ${error.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    loadNextRows = () => {
        let { mappingTableData, mappingTable } = this.state;
        columnCounter += 5;
        if (columnCounter - 5 < mappingTableData.length) {
            for (let i = columnCounter - 5; i < mappingTableData.length; i++) {
                if (i < columnCounter) {
                    mappingTable.push(mappingTableData[i]);
                }
            }
            this.setState({ mappingTable });
        } else {
            Utils.toastError("No more records found.", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    handleDropSelect = (e: any, id: any, sel: any, selnm: any) => {
        let isID = (id + 1);
        if (e === "") {
            this.resetMapping(isID, "");
        } else {
            let isRow = this.state.manualDropColumn.filter((o: any) => o.fieldID === +e)[0];
            let { mappingFields } = this.state;
            mappingFields.forEach((o: any) => {
                if (o.id === isID) {
                    o.showName = isRow.fieldName;
                    o.fieldID = isRow.fieldID;
                    o.displayFieldName = isRow.displayFieldName;
                }
                if (o.showName.toString().toLocaleLowerCase() === "amount") {
                    mappingFields.forEach((oi: any) => {
                        if (oi.showName.toString().toLocaleLowerCase() === "debit" || oi.showName.toString().toLocaleLowerCase() === "credit") {
                            oi.showName = "";
                            oi.fieldID = "";
                            oi.displayFieldName = "";
                        }
                    })
                } else if (o.showName.toString().toLocaleLowerCase() === "debit" || o.showName.toString().toLocaleLowerCase() === "credit") {
                    mappingFields.forEach((oi: any) => {
                        if (oi.showName.toString().toLocaleLowerCase() === "amount") {
                            oi.showName = "";
                            oi.fieldID = "";
                            oi.displayFieldName = "";
                        }
                    })
                }
            });
            let selMapping = mappingFields.filter(o => o.showName !== "");
            let valueArr = selMapping.map((item: any) => { return item.showName });
            let isDuplicate = valueArr.some((item: any, idx: any) => { return valueArr.indexOf(item) !== idx });
            let isMapingExist = mappingFields.filter((o: any) => isRow.fieldName !== "" && o.showName === isRow.fieldName);
            if (isDuplicate) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='viom_CustomConfirmAlert'>
                                <h1 className="viom_alertTitle">Change Column Mapping</h1>
                                <p className="viom_alertMessage"><strong>{isMapingExist[0].displayFieldName}</strong> is already being used in another column. Would you like to assign it to this column instead?</p>
                                <div className="viom_alertButtonGroup">
                                    <button className="viom_alertButton viom_success" onClick={() => { this.resetMapping(isID, isRow); this.saveMapping(isID, isRow); onClose(); }}>Yes</button>
                                    <button className="viom_alertButton viom_cancel" onClick={() => { [sel].length > 0 ? this.resetMapping(isID, sel, selnm) : this.resetMapping(isID, ""); onClose(); }}>Cancel</button>
                                </div>
                            </div>
                        );
                    },
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "overlay-viomjeet-confirmAlert"
                });
            } else {
                this.saveMapping(isID, isRow)
            }
        }
    }

    resetMapping = (id: any, item: any, selnm: any = "") => {
        let { mappingFields } = this.state;
        mappingFields.map((o: any) => o.showName === item.fieldName && (o.showName = "", o.fieldID = "", o.displayFieldName = ""));
        mappingFields.forEach((o: any) => {
            if (o.id === id) {
                o.showName = (selnm === "" ? "" : selnm.replace(/\./g, '').replace(/ /g, ''));
                o.fieldID = (selnm === "" ? "" : item.fieldID);
                o.displayFieldName = (selnm === "" ? "" : selnm)
            }
        })
        this.setState({ mappingFields });
    }

    saveMapping = (id: any, item: any) => {
        let { mappingFields } = this.state;
        mappingFields.forEach((o: any) => {
            if (o.id === id) {
                o.showName = item.fieldName;
                o.fieldID = item.fieldID;
                o.displayFieldName = item.displayFieldName;
            }
        })
        this.setState({ mappingFields });
    }

    validateMapping = () => {
        let { mappingFields, mappingTableData } = this.state;
        let selMapping: any[] = [];
        let isValidate: any = "";
        let mergeData: any = [];
        selMapping = mappingFields.filter((o: any) => o.showName !== "");
        let hasAmt = mappingFields.some((o: any) => o.showName.toString().toLocaleLowerCase() === "amount")
        mappingTableData.forEach((item: any, idx: any) => {
            selMapping.forEach((key: any) => {
                let row: any = {};
                let curVal: any = (mappingTableData[idx][key.fieldName]);
                let keyName = key.showName.toString().toLocaleLowerCase();
                row.key = keyName;
                row.value = (curVal === "" || curVal === null) ? "" : curVal;
                mergeData.push(row);
            });
        })
        let isAmount = mergeData.filter((o: any) => o.key === "amount" && o.value !== "");
        let isDate = mergeData.filter((o: any) => o.key === "date" && o.value !== "");
        let isCredit = mergeData.some((o: any) => o.key === "credit" && o.value !== "");
        let isDebit = mergeData.some((o: any) => o.key === "debit" && o.value !== "");
        if (isDate.length === 0) {
            isValidate = "Invalid Date mapping.";
        } else if (hasAmt && isAmount.length === 0) {
            isValidate = "Invalid Amount mapping.";
        } else if (!hasAmt && isCredit.length === 0) {
            isValidate = "Invalid Credit mapping.";
        } else if (!hasAmt && isDebit.length === 0) {
            isValidate = "Invalid Debit mapping.";
        } else {
            isValidate = "Valid";
        }
        //Check Description text length not grater than 200 char
        const dataWithDescription = mergeData.filter((n) => n.key === "description");
        console.log(dataWithDescription)
        if (dataWithDescription?.length > 0) {
            const maxLength = dataWithDescription.sort((x, y) => y.value.length - x.value.length)[0]?.value?.length;
            if (maxLength > 200) {
                isValidate = "Description must not exceed 200 characters";
            }
        }
        return isValidate;
    }

    SaveBSImportStatementDataMapping = () => {
        let { mappingFields, manualDropColumn } = this.state;
        let mappingItems: any[] = [];
        let selMapping = mappingFields.filter(o => o.fieldID !== "");
        let nonSelected: any[] = [];
        let mergeAll: any = [];
        let request: any = {};
        nonSelected = manualDropColumn.filter((o: any) => {
            return selMapping.filter((p: any) => {
                return p.fieldID === o.fieldID;
            }).length === 0
        });
        mergeAll = [...selMapping, ...nonSelected];
        request.ruleID = (this.state.selectedRule.ruleID);
        mergeAll.forEach((o: any) => {
            let row: any = { fieldID: o.fieldID, columnName: o.showName === undefined ? "" : o.fieldName }
            mappingItems.push(row);
        })
        request.mapping = mappingItems;
        bankSyncServices.SaveBSImportStatementDataMapping(request)
            .then(async (result: any | null) => {
                if (result.success) {
                    // toast.success(result?.result, { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ activeStep: + 2, saveMappingLoader: false });
                } else {
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ saveMappingLoader: false });
                }
                resolve();
            }).catch((error) => {
                reject();
                Utils.toastError(`Server Error: ${error.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ saveMappingLoader: false });
            });
    }

    InsertCashImportStatementTableData = () => {
        if (this.validateMapping() === "Valid") {
            this.setState({ saveMappingLoader: true });
            let { mappingFields, mappingTableData } = this.state;
            let request: any = {};
            let selMapping: any[] = [];
            let tableArray: any = [];
            let mappedCol: any[] = [];
            let isAmount = mappingFields.some((o: any) => o.showName.toString().toLocaleLowerCase() === "amount")
            mappingFields.forEach(o => {
                if (o.showName !== "") {
                    selMapping.push(o.fieldName);
                    mappedCol.push(o.showName.replace(/\./g, '').replace(/ /g, ''));
                }
            });
            mappingTableData.forEach((item: any, idx: any) => {
                selMapping.forEach((key: any) => {
                    let curVal: any = (mappingTableData[idx][key]);
                    tableArray.push((curVal === "" || curVal === null) ? "" : curVal.toString().includes(",") ? curVal.replace(/,/g, '') : curVal);
                });
                if (isAmount) {
                    tableArray.push('[stop]');
                } else {
                    if (!mappedCol.some((o: any) => o?.toLowerCase() === "debit")) {
                        tableArray.push('');
                    }
                    if (!mappedCol.some((o: any) => o?.toLowerCase() === "credit")) {
                        tableArray.push('');
                    }
                    tableArray.push('');
                }
                tableArray.push("<break>");
            })
            if (isAmount) {
                mappedCol.push('Debit');
                mappedCol.push('Credit');
            } else {
                mappedCol.push('Amount');
                if (!mappedCol.some((o: any) => o?.toLowerCase() === "debit")) {
                    mappedCol.push('Debit');
                }
                if (!mappedCol.some((o: any) => o?.toLowerCase() === "credit")) {
                    mappedCol.push('Credit');
                }
            }
            let requestRows = tableArray.toString();
            requestRows = requestRows.replaceAll(",", "[stop]");
            requestRows = requestRows.split("<break>[stop]");
            requestRows = requestRows.map((item) => item.replaceAll("<break>", ""));
            request.hid = this.props?.hidValue;
            request.colNames = selMapping.toString();
            request.mappedCol = mappedCol.toString();
            request.rowsValues = requestRows;
            request.ruleID = this.state.selectedRule.ruleID;
            request.oprId = this.props?.selectedAccount?.id;
            request.isAmount = isAmount;
            request.fileNo = this.state.fileNo;
            bankSyncServices.ProcessBankSyncData(request)
                .then(async (result: any | null) => {
                    let importData = result?.result;
                    let failmsg: any = importData[0]?.failmsg
                    if (failmsg !== undefined) {
                        Utils.toastError(`Server Error: ${failmsg}`, { position: toast.POSITION.BOTTOM_RIGHT });
                        this.setState({ saveMappingLoader: false });
                    } else {
                        if (result.success) {
                            let isMessage = importData[0]?.message;
                            if (importData.toString().toLocaleLowerCase() === "no data to import.") {
                                this.setState({ saveMappingLoader: false });
                                Utils.toastError("Improper data can not be import.", { position: toast.POSITION.BOTTOM_RIGHT });
                            } else {
                                if (isMessage.toString().toLocaleLowerCase() !== "validreq") {
                                    this.setState({ saveMappingLoader: false });
                                    Utils.toastError(isMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                                } else {
                                    let nonSelectable: any = [];
                                    importData.forEach((o: any) => {
                                        o["isDupSelected"] = false;
                                        o["sort"] = o.isDuplicate.toString().toLocaleLowerCase() === "yes" ? "a" : "z";
                                        if (o.isDuplicate.toString().toLocaleLowerCase() === "no") {
                                            nonSelectable.push(o.rowID);
                                        }
                                    })
                                    importData = _.sortBy(importData, "sort");
                                    this.setState({ nonSelectable, importTableData: importData, defimportTblDta: importData }, () => {
                                        this.SaveBSImportStatementDataMapping()
                                    })
                                }
                            }
                        } else {
                            Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                            this.setState({ saveMappingLoader: false });
                        }
                    }
                    resolve();
                }).catch((error) => {
                    reject();
                    Utils.toastError(`Server Error: ${error.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ saveMappingLoader: false });
                });
        } else {
            Utils.toastError(this.validateMapping(), { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    SaveBSImportData = () => {
        this.setState({ saveImportLoader: true });
        let { importTableData } = this.state;
        let filteredData = importTableData.filter((o: any) => o.blankDataRow !== 1 && (o.isDuplicate?.toString().toLocaleLowerCase() === 'no' || o.isDupSelected));
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID: any = storage === null ? 0 : (storage.tenantID);
        let username: any = (storage === null ? 0 : storage.userName);
        let request: any[] = [];
        filteredData.forEach((o: any) => {
            let row: any = {
                hid: this.props?.hidValue,
                oprId: this.props?.selectedAccount?.id,
                tenantID: tenantID,
                userName: username,
                ruleID: this.state.selectedRule.ruleID,
                fileNo: this.state.fileNo,
                date: o?.date === undefined ? "" : o?.date,
                amount: o?.amount === undefined ? "" : o?.amount,
                description: o?.description === undefined ? "" : o?.description,
                checkNo: o?.checkNo === undefined ? "" : o?.checkNo,
                customField: o?.customField === undefined ? "" : o?.customField,
                entryType: o?.checkType === undefined ? "" : o?.checkType,
                IsDuplicate: o.isDupSelected,
            }
            request.push(row);
        });
        bankSyncServices.SaveBSImportData(request)
            .then(async (result: any | null) => {
                if (result.success) {
                    toast.success("Upload Successful.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.props.toggleManualStatUploadModal(false, true);
                } else {
                    if (result.result.toLocaleLowerCase() === "no data to import") {
                        Utils.toastError("No data to import.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else {
                        Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
                this.setState({ saveImportLoader: false });
                resolve();
            }).catch((error) => {
                reject();
                Utils.toastError(`Server Error: ${error.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ saveImportLoader: false });
            });
    }

    handleOnSelect = (row: any, isSelect: any) => {
        let { importTableData, selectedRows } = this.state;
        if (isSelect) {
            importTableData.forEach((o: any) => o.rowID === row.rowID && (o.isDupSelected = true))
            selectedRows = [...this.state.selectedRows, row.rowID];
        } else {
            importTableData.forEach((o: any) => o.rowID === row.rowID && (o.isDupSelected = false))
            selectedRows = this.state.selectedRows.filter(x => x !== row.rowID);
        }
        this.setState(() => ({ importTableData, selectedRows }));
    }

    CleanSearch() {
        this.child.current.CleanSearch();
    };

    filterImportTable = (event: any) => {
        let isVal = event === "All" ? "" : "Yes"
        const updatedList = this.state.defimportTblDta.filter((item: any) => {
            return item.isDuplicate.toLowerCase().indexOf(isVal.toLowerCase()) > -1;
        });
        this.setState({ importTableData: updatedList, importFilterText: event });
    }

    render() {
        const { activeStep, isAddNewRole, isProgress, hideProgress } = this.state;
        const files = this.state.uploadFiles.map(file => (
            <li key={file.name}>
                <div className="d-flex align-items-center">
                    <div className={hideProgress ? "file-name w-100" : "file-name"}><EllipsisWithTooltip placement="bottom">{file.name}</EllipsisWithTooltip></div>
                    {!hideProgress &&
                        <>
                            <div className="file-loader mr-auto"><ProgressBar variant="success" now={isProgress} /></div>
                            <div className="file-percentage">{isProgress} %</div>
                        </>
                    }
                    {hideProgress &&
                        <div className='ml-auto close' onClick={this.resetFileUploader}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9497 1.05021C10.5933 0.693786 10.0149 0.693786 9.65851 1.05021L6 4.70872L2.34149 1.05021C1.98507 0.693786 1.40668 0.693786 1.05025 1.05021C0.693828 1.40664 0.693828 1.98502 1.05025 2.34145L4.70876 5.99996L1.05025 9.65847C0.693828 10.0149 0.693828 10.5933 1.05025 10.9497C1.40668 11.3061 1.98507 11.3061 2.34149 10.9497L6 7.2912L9.65851 10.9497C10.0149 11.3061 10.5933 11.3061 10.9497 10.9497C11.3062 10.5933 11.3062 10.0149 10.9497 9.65847L7.29124 5.99996L10.9497 2.34145C11.3062 1.98502 11.3062 1.40664 10.9497 1.05021Z" fill="#3F4347" />
                            </svg>
                        </div>
                    }
                </div>
            </li >
        ));
        let { mappingFields, importTableData } = this.state;
        let isDate = mappingFields.some(o => o.showName.toString().toLocaleLowerCase() === "date");
        let isAmount = mappingFields.some(o => o.showName.toString().toLocaleLowerCase() === "amount");
        let isDebit = mappingFields.some(o => o.showName.toString().toLocaleLowerCase() === "debit");
        let isCredit = mappingFields.some(o => o.showName.toString().toLocaleLowerCase() === "credit");
        let isErrorinStep1 = (isDate && (isAmount || isDebit || isCredit)) ? false : true;
        let duplicateCount = importTableData.length > 0 ? importTableData.filter((o: any) => o.duplicateCount > 0)[0]?.duplicateCount : 0;
        let hideDiscription = importTableData.length > 0 && importTableData[0].description === undefined ? true : false;
        let hideCustomField = importTableData.length > 0 && importTableData[0].customField === undefined ? true : false;
        let isDuplicateExist = importTableData.some((o: any) => o.duplicateCount > 0);
        let isDupSelected = this.state.importTableData.some((o: any) => o.isDupSelected);
        let isNonSelectedExist = this.state.importTableData.some((o: any) => o.isDuplicate?.toString().toLocaleLowerCase() === 'no');
        let isImportDisable = isNonSelectedExist ? false : (isDuplicateExist && !isDupSelected);

        const mapingColumn = [{
            dataField: 'date',
            text: 'Date',
            headerClasses: "ifColumnNameCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.date}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'description',
            text: 'Description',
            headerClasses: "ContainsCol",
            hidden: hideDiscription,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.description}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'checkType',
            text: 'Check / Type',
            headerClasses: "ThenCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.checkType.toString().toLocaleLowerCase() === "check" ? row.checkNo : row.checkType}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'amount',
            text: 'Amount',
            headerClasses: "ThenCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.amount)}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'customField',
            text: 'Custom Field',
            hidden: hideCustomField,
            headerClasses: "COACol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.customField}</EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'isDuplicate',
            text: '',
            hidden: duplicateCount ? false : true,
            headerClasses: "COACol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">
                        {row.isDuplicate.toString().toLocaleLowerCase() === "yes" && (
                            <span className={row.isDuplicate.toString().toLocaleLowerCase() === "yes" ? "ml-0 isImportBadge isBadgeOrange" : ""}>Duplicate</span>
                        )}
                    </EllipsisWithTooltip>
                )
            }
        }, {
            dataField: "checkNo",
            text: "",
            classes: 'd-none',
            headerClasses: "d-none",
            hidden: true,
        }, {
            dataField: "checkType",
            text: "",
            classes: 'd-none',
            headerClasses: "d-none",
            hidden: true,
        }];

        const selectRow = {
            mode: 'checkbox',
            selected: this.state.selectedRows,
            nonSelectable: this.state.nonSelectable,
            selectColumnPosition: 'right',
            hideSelectAll: true,
            onSelect: this.handleOnSelect,
        };
        return (
            <div>
                <ToastContainer containerId={"mappingStatementRule"} autoClose={3000} />
                <Modal
                    backdrop="static"
                    show={this.props.show}
                    onHide={this.handleClose}
                    className="change-log-modal manual-statement-upload"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton className='d-flex'>
                        <Modal.Title>
                            <div className='ttl-txt'><EllipsisWithTooltip placement="bottom">Manual Statement Upload</EllipsisWithTooltip></div>
                            {activeStep !== 0 && (
                                <div className='subHeader d-flex flex-wrap'>
                                    <div className='list d-flex file'>File:<div className="list-name"><EllipsisWithTooltip placement="bottom">{this.state.uploadFiles[0]?.path}</EllipsisWithTooltip></div></div>
                                    <div className='list d-flex mapping-rule'>Mapping Rule:<div className="list-name"><EllipsisWithTooltip placement="bottom">{this.state.selectedRule.ruleName}</EllipsisWithTooltip></div></div>
                                </div>
                            )}
                        </Modal.Title>
                        <div className='stepper d-flex justify-content-between align-items-center'>
                            {steps.map((step, index) => (
                                <React.Fragment key={step.label}>
                                    <div className={`step ${activeStep >= index ? 'active' : ''}`}>
                                        <div className="step-content d-flex align-items-center">
                                            <div className="step-icon">
                                                {index == 0 && (
                                                    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 0.5H2C1.175 0.5 0.5075 1.175 0.5075 2L0.5 14C0.5 14.825 1.1675 15.5 1.9925 15.5H11C11.825 15.5 12.5 14.825 12.5 14V5L8 0.5ZM11 14H2V2H7.25V5.75H11V14ZM3.5 10.2575L4.5575 11.315L5.75 10.13V13.25H7.25V10.13L8.4425 11.3225L9.5 10.2575L6.5075 7.25L3.5 10.2575Z" />
                                                    </svg>
                                                )}
                                                {index == 1 && (
                                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.75 0.25H2.25C1.425 0.25 0.75 0.925 0.75 1.75V12.25C0.75 13.075 1.425 13.75 2.25 13.75H12.75C13.575 13.75 14.25 13.075 14.25 12.25V1.75C14.25 0.925 13.575 0.25 12.75 0.25ZM12.75 1.75V4H2.25V1.75H12.75ZM12.75 5.5V8.5H2.25V5.5H12.75ZM2.25 12.25V10H12.75V12.25H2.25Z" />
                                                    </svg>
                                                )}
                                                {index == 2 && (
                                                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.09582 8.90625L1.96832 5.77875L0.90332 6.83625L5.09582 11.0287L14.0958 2.02875L13.0383 0.97125L5.09582 8.90625Z" />
                                                    </svg>
                                                )}
                                            </div>
                                            <div className="step-label">
                                                {step.label}
                                                <div className='desc'>{step.desc}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {index < steps.length - 1 && (
                                        <div className={`step-line ${activeStep > index ? 'active' : ''}`}></div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {activeStep === 2 && (
                            <div className={`mapping3rdBanner d-flex ${duplicateCount > 0 ? "isWarningBg" : "isSuccessBg"}`}>
                                <div className="flagIcon">
                                    {duplicateCount > 0 ? (
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0001 2.49372L17.5301 15.5037H2.47012L10.0001 2.49372ZM0.740121 14.5037C-0.0298787 15.8337 0.930121 17.5037 2.47012 17.5037H17.5301C19.0701 17.5037 20.0301 15.8337 19.2601 14.5037L11.7301 1.49372C10.9601 0.163716 9.04012 0.163716 8.27012 1.49372L0.740121 14.5037ZM9.00012 7.50372V9.50372C9.00012 10.0537 9.45012 10.5037 10.0001 10.5037C10.5501 10.5037 11.0001 10.0537 11.0001 9.50372V7.50372C11.0001 6.95372 10.5501 6.50372 10.0001 6.50372C9.45012 6.50372 9.00012 6.95372 9.00012 7.50372ZM9.00012 12.5037H11.0001V14.5037H9.00012V12.5037Z" fill="#D6742C" />
                                        </svg>
                                    ) : (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#0E9074" />
                                        </svg>
                                    )}
                                </div>
                                {duplicateCount > 0 ? (
                                    <div>{duplicateCount} duplicates were found. By default these will not be imported.</div>
                                ) : (
                                    <div>Successfully uploaded. Please review before importing.</div>
                                )}
                            </div>
                        )}
                        <div className="modal-body-container">
                            {/* Render step content based on activeStep */}
                            {activeStep === 0 && (
                                <div className="filter-sec d-flex flex-column align-items-center justify-content-center">
                                    <div className='section'>
                                        <Form.Group>
                                            <Form.Label>Choose Mapping Rule <span>*</span></Form.Label>
                                            <Dropdown className="drop-down-select" onSelect={(e) => this.handleSelectRule(e)}>
                                                <Dropdown.Toggle id="mapping-rule">
                                                    <div className="d-flex align-items-center">
                                                        <div className={this.state.selectedRule.ruleID === -1 ? "selectCreateRule" : ""}>
                                                            <EllipsisWithTooltip placement="bottom">{this.state.selectedRule.ruleName}</EllipsisWithTooltip>
                                                        </div>
                                                        <div className='arrow ml-auto'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="scrollableArea">
                                                        {this.state.mappingRuleArray.map(
                                                            (item: any, idx: any) => (
                                                                <Dropdown.Item eventKey={JSON.stringify(item)} key={idx}>
                                                                    <EllipsisWithTooltip placement="bottom">{item.ruleName}</EllipsisWithTooltip>
                                                                </Dropdown.Item>
                                                            )
                                                        )}
                                                    </div>
                                                    <Dropdown.Item className="filterException" eventKey={"addNewRule"}>
                                                        <strong>Create Mapping Rule</strong>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </div>
                                    {isAddNewRole ?
                                        <>
                                            <div className='section'>
                                                <Form.Group>
                                                    <Form.Label>New Mapping rule name</Form.Label>
                                                    <Form.Control
                                                        className={this.state.newRuleError ? "border-danger" : ""}
                                                        value={this.state.newRuleName}
                                                        type="text"
                                                        onChange={this.handleNewRuleName}
                                                        onBlur={() => this.validateRuleOnBlur("")}
                                                        placeholder={`Example "Amex Mapping Rule"`} />
                                                </Form.Group>
                                            </div>
                                        </> : null
                                    }

                                    <div className='section BulkUploadSection'>
                                        <Dropzone onDrop={this.onDrop} accept=".xlsx,.xls,.csv" multiple={false}>
                                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                <>
                                                    {this.state.uploadFiles.length == 0 && (
                                                        <section className={isDragActive ? "upload-area default drag-active " : "container upload-area default"}>
                                                            <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                                <input {...getInputProps()} />
                                                                <p>Drag and drop or <span>browse for a file</span>
                                                                    <br /><strong className="acceptedFiles">.xls or .csv</strong></p>
                                                            </div>
                                                        </section>
                                                    )}
                                                    {this.state.uploadFiles.length > 0 && (
                                                        <aside className="d-flex flex-column file-list">
                                                            <ul>{files}</ul>
                                                        </aside>
                                                    )}
                                                </>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className='section notes d-flex'>
                                        <div className='icon'>
                                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.25 5.75H9.75V7.25H8.25V5.75ZM8.25 8.75H9.75V13.25H8.25V8.75ZM9 2C4.86 2 1.5 5.36 1.5 9.5C1.5 13.64 4.86 17 9 17C13.14 17 16.5 13.64 16.5 9.5C16.5 5.36 13.14 2 9 2ZM9 15.5C5.6925 15.5 3 12.8075 3 9.5C3 6.1925 5.6925 3.5 9 3.5C12.3075 3.5 15 6.1925 15 9.5C15 12.8075 12.3075 15.5 9 15.5Z" fill="#6A6E73" />
                                            </svg>
                                        </div>
                                        <div className='info'>
                                            <p className='space'><strong className="txtScndry">What is a Mapping Rule?</strong></p>
                                            <p>A mapping rule automatically matches columns from your uploaded file to the correct fields in Inn-Flow, streamlining financial statement uploads.</p>
                                            <p className='space'><strong className="txtScndry">Creating a New Mapping Rule:</strong></p>
                                            <p>Select "Create Mapping Rule" from the dropdown, map the columns, and save. The same rule can be used across all your properties for future uploads to save time.</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeStep === 1 && (
                                <div className="uploadPanelBody">{/* Mapping */}
                                    <p className="page-tip pb-3">Map the columns from your uploaded statement to the corresponding Inn-Flow fields using the drop-downs.</p>
                                    <div className="react-bootstrap-table mappingPanel hasScroll">
                                        <table className="table table-bordered setMapingTable">
                                            <thead className="setfixHader">
                                                <tr className='setDropdownDrop'>
                                                    {(this.state.countTable || []).map((item: any, idx: any) => (
                                                        <td className='border-0 setboxes' key={item}>
                                                            <Dropdown className="boxes drop-down-select" onSelect={(e: any) => this.handleDropSelect(e, idx, this.state.mappingFields[idx], this.state.mappingFields[idx].displayFieldName)}>
                                                                <Dropdown.Toggle id="groups-dropdown">
                                                                    <div className='d-flex'>
                                                                        <div className={this.state.mappingFields[idx].displayFieldName === "" ? "selected-val placeholder-text" : "selected-val"}>
                                                                            <EllipsisWithTooltip placement="bottom">
                                                                                {this.state.mappingFields[idx].displayFieldName === "" ? "Select Inn-flow Column" : this.state.mappingFields[idx].displayFieldName}
                                                                            </EllipsisWithTooltip></div>
                                                                        <div className='arrow ml-auto'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="mh250">
                                                                    <Dropdown.Item eventKey="">Select Inn-flow Column</Dropdown.Item>
                                                                    <Dropdown.Divider className="m-1" />
                                                                    {(this.state.manualDropColumn || []).map((item: any, idx: any) => (
                                                                        <>
                                                                            <Dropdown.Item className="d-flex" key={item.fieldID} eventKey={item.fieldID}>
                                                                                <EllipsisWithTooltip placement="bottom">{item.displayFieldName}</EllipsisWithTooltip>
                                                                                <div className="ml-auto">{item.hasInfo ? (
                                                                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                                                                        overlay={<Tooltip id="Tooltip">Use Custom Field to map any additional data from your statement that does not fit into the standard categories of Amount, Date, and Description.</Tooltip>}
                                                                                    >
                                                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M8.25 5.75H9.75V7.25H8.25V5.75ZM8.25 8.75H9.75V13.25H8.25V8.75ZM9 2C4.86 2 1.5 5.36 1.5 9.5C1.5 13.64 4.86 17 9 17C13.14 17 16.5 13.64 16.5 9.5C16.5 5.36 13.14 2 9 2ZM9 15.5C5.6925 15.5 3 12.8075 3 9.5C3 6.1925 5.6925 3.5 9 3.5C12.3075 3.5 15 6.1925 15 9.5C15 12.8075 12.3075 15.5 9 15.5Z" fill="#6A6E73" />
                                                                                        </svg>
                                                                                    </OverlayTrigger>
                                                                                ) : ""}</div>
                                                                            </Dropdown.Item>
                                                                            {item.value === "" && (<Dropdown.Divider className="m-1" />)}
                                                                        </>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr className="setboxes setborderbottom setDropdownHeader">
                                                    {(this.state.countTable || []).map((item: any, idx: any) => (
                                                        <td className='boxes border-0' key={item}>
                                                            <div className="cusMapingHeader d-flex"><div className='str'><EllipsisWithTooltip placement="bottom">{Utils.ConvertToTitleCase(item)}</EllipsisWithTooltip></div><span className="ml-auto">Statement Column</span></div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="setFixScrollForTable">
                                                {this.state.mappingTable.map((item: any, index: any) => (
                                                    <tr key={item.id} className="setboxes setborderbottom">
                                                        {(this.state.countTable || []).map((colm: any, idx: any) => (
                                                            <td className='boxes border-0' key={colm}>
                                                                <div className="cusMapingColumn"><EllipsisWithTooltip placement="bottom">{item[colm]}</EllipsisWithTooltip></div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {(this.state.mappingTableData.length > 5 && this.state.mappingTableData.length !== this.state.mappingTable.length) && (
                                            <div className="showMoreLinks">
                                                <button onClick={this.loadNextRows}>Show 5 More Rows</button>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            )}
                            {activeStep === 2 && (
                                <div className="uploadPanelBody">
                                    <ToolkitProvider keyField="rowID" data={this.state.importTableData} columns={mapingColumn} search>
                                        {(props: {
                                            searchProps: JSX.IntrinsicAttributes;
                                            baseProps: JSX.IntrinsicAttributes;
                                        }) => (
                                            <>
                                                <div className="d-flex">
                                                    <div className="isImportSearchFilter">
                                                        <label id="labeltableSearch" className="search-label">
                                                            <TableSearch id="tableSearch" key="tableSearch" ref={this.child}
                                                                {...props.searchProps} CleanSearch={this.CleanSearch.bind(this)}
                                                                placeholder={"Search"}
                                                            />
                                                        </label>
                                                    </div>
                                                    {isDuplicateExist && (
                                                        <Dropdown className="boxes drop-down-select" onSelect={(e: any) => this.filterImportTable(e)}>
                                                            <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                                <div className='d-flex'>
                                                                    <div className="selected-val">{this.state.importFilterText}</div>
                                                                    <div className='arrow ml-auto'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="mh250">
                                                                <Dropdown.Item eventKey="All">All</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Duplicate">Duplicate</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                </div>
                                                <div className={`${isDuplicateExist ? "hasAfter" : ""} react-bootstrap-table mappingPanel isSelectionRight`}>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        keyField="rowID"
                                                        hover
                                                        selectRow={selectRow}
                                                        noDataIndication={'No record found.'}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </ToolkitProvider>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex">
                        {activeStep === 0 && (<>
                            <Button className='btn-link' onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.handleNext}
                                disabled={!this.state.isSelectedRoleHasValue || !this.state.isFileSelected || this.state.newRuleError}>
                                Next
                            </Button>
                        </>)}
                        {(activeStep === 1 || activeStep === 2) && (
                            <Button className="btn-outline-primary" onClick={this.handleBack}>Back</Button>
                        )}
                        {activeStep === 1 && (
                            <Button className="ml-auto" onClick={this.InsertCashImportStatementTableData} disabled={isErrorinStep1 || this.state.saveMappingLoader}>
                                {this.state.saveMappingLoader ? "Processing" : "Next"} {this.state.saveMappingLoader && (<Spinner size="sm" animation="border" />)}
                            </Button>
                        )}
                        {activeStep === 2 && (
                            <Button className="ml-auto" onClick={this.SaveBSImportData}
                                disabled={isImportDisable || this.state.saveImportLoader}>
                                Import {this.state.saveImportLoader && (<Spinner size="sm" animation="border" />)}
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}