import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { DropDownList } from '../Reports/common-components/dropdown-list';
import { PeriodSelector } from './Components/PeriodSelector';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ReactPageLoader } from '../../Common/Components/ReactPageLoader';
import { Dropdown, Form, FormLabel } from 'react-bootstrap';
import { LaborDashBoard } from '../../Common/Services/LaborDashBoard';
import { Utils } from '../../Common/Utilis';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from 'moment';
import _ from "lodash"
import LaborCustomHeader from './LaborCustomHeader';
import "./AccountingDashboardTable.scss";
import { toast } from 'react-toastify';
import ContentLoader from 'react-content-loader';
import { FiChevronDown } from 'react-icons/fi';
import OutsideClickHandler from 'react-outside-click-handler';
import { HotelGroupSelector } from './Components/HotelGroupSelector';
import { AccountingDashboardChart } from './AccountingDashboardChart';
import { TableColumnSelector } from './Components/Common-Components/TableColumnSelector';
import { SortSelector } from './Components/SortSelector';

const MAXLIMIT = 10
//Dropdown Values
const dataComparisonList: any[] = [
  { label: "Budget Variance", value: 3, type: 1 },
  { label: "Last Year Variance", value: 1, type: 1 },
  { label: "2-Year Variance", value: 2, type: 1 },

];

const sortDropdDownData: any[] = [
  { label: "Column Sort", value: "Column Sort" },
  { label: "Sort by Variance", value: "Variance" },
  { label: "Sort by Value", value: "Value" },
];


const getPeriods = (date = new Date()) => {
  return [
    { label: `Daily (${moment(date).format('MM/DD')})`, value: 1 },
    { label: `Month To Date (${moment(date).startOf('month').format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 2 },
    { label: `Year To Date  (${moment(date).startOf('year').format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 3 },
  ]
}

const numberComparator = (aVal, bVal, isInverted) => {
  if (aVal == bVal) {
      return 0;
  }
  if (isInverted == true) {
      if (_.isNil(aVal) || _.isNaN(aVal)) return -1;
      if (_.isNil(bVal) || _.isNaN(bVal)) return 1;
  } else {
      if (_.isNil(aVal) || _.isNaN(aVal)) return 1;
      if (_.isNil(bVal) || _.isNaN(bVal)) return -1;
  }
  return aVal < bVal ? -1 : 1;
}

const NumberComparatorValue = (aVal, bVal, nodeA, nodeB, isInverted, columnName) => {
  aVal = parseFloat(nodeA.data[columnName] || 0)
  bVal = parseFloat(nodeB.data[columnName] || 0)
  const result = numberComparator(aVal, bVal, isInverted)
  return result
};

const NumberComparatorVariance = (aVal, bVal, nodeA, nodeB, isInverted, columnName) => {
  aVal = parseFloat(nodeA.data[columnName] || 0)
  bVal = parseFloat(nodeB.data[columnName] || 0)
  const result = numberComparator(aVal, bVal, isInverted)
  return result
};


// const NumberComparatorVariance = (aVal, bVal, nodeA, nodeB, isInverted, columnName) => {
//   aVal = parseFloat(nodeA.data[columnName] || 0)
//   bVal = parseFloat(nodeB.data[columnName] || 0)

//   let aVal_Variance = parseFloat(nodeA.data[columnName + "_diff"] || 0)
//   let bVal_Variance = parseFloat(nodeB.data[columnName + "_diff"] || 0)

//   let aVal_diff, bVal_diff
 
//   aVal_diff = (aVal - aVal_Variance)
//   bVal_diff = (bVal - bVal_Variance)

//   const result = numberComparator(aVal_diff, bVal_diff, isInverted)
//   return result
// };


const StringComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
  return (valueA?.toLowerCase()  > valueB?.toLowerCase()) ? 1 : -1;
}

//Keys for MTD and YTD 
const MTDFields = {
  "rooms Occupied": "roomOccupiedMTD",
  "room Revenue": "roomRevenueMTD",
  "gross Revenue": "grossRevenueMTD",
  "occupancy %": "occupancyPerMTD",
  "adr": "adrmtd",
  "revPAR": "revPARMTD",
}

const YTDFields = {
  "rooms Occupied": "roomOccupiedYTD",
  "room Revenue": "roomRevenueYTD",
  "gross Revenue": "grossRevenueYTD",
  "occupancy %": "occupancyPerYTD",
  "adr": "adrytd",
  "revPAR": "revPARYTD",
}






let RenameKeys = (list) => {
  const newList = list.map(item => {
    if (item.valueActual == "LastYearVar") {
      const newItem = Object.keys(item).map(key => {
        const newKey = key == "hid" ? key : key + "_diff";
        return { [newKey]: item[key] };
      }
      )
      return Object.assign({}, ...newItem);

    }
    return item
  })

  return newList
}


const getFormattedTableData = (data) => {

  let newData = RenameKeys(data)
  newData = _.groupBy(newData, v => v["hid"])

  newData = Object.keys(newData).map(key => Object.assign({}, ...newData[key]));


  newData.sort(function (a, b) {
    if (a.lettercode.toLowerCase() < b.lettercode.toLowerCase()) {
      return -1;
    }
    if (a.lettercode.toLowerCase() > b.lettercode.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return newData
}


const getNewValuesWithOldHighligedValue = (oldData, newData) => {
  const newArray = newData.map(newItem => {
    const oldDatawithKey = oldData.find(item => item.hid == newItem.hid)

    if (oldDatawithKey) {
      return {
        ...newItem,
        highlightedColumnValue: oldDatawithKey?.highlightedColumnValue,
        manuallyChartActive: oldDatawithKey?.manuallyChartActive
      }
    }
    else {
      return newItem
    }
  })

  return newArray
}
// const getDashboardData=(params:any)=>{
//   let storage = JSON.parse(localStorage.getItem("storage")!);
//   let tenantID = storage === null ? 0 : storage.tenantID as any;
//   params.TenantID = tenantID;
//   const DashboardCancelToken=Axios.CancelToken.source(); 

//   const url = "/Dashboard/V2_Accounting_Dashboards_TopLevel"

//   return Instense.get(url,{params}).then((response) => {
//     let result = response.data.result ;
//     return result;
//   });
// }

const findChangedData = (oldArray, newArray) => {
  const changedData: any[] = [];
  // let maxCount=20
  // let changesCount = 0
  _.takeWhile(newArray, newObj => {
    // if(changesCount >= maxCount)
    // {
    //     return false
    // }
    const matchingOldObj = _.find(oldArray, { hid: newObj.hid });
    if (!_.isEqual(_.pick(matchingOldObj, ['hid', 'highlightedColumnValue']), _.pick(newObj, ['hid', 'highlightedColumnValue']))) {
      changedData.push(newObj);
      // changesCount++
    }

    return true
  });

  return changedData;
};

let gridApi, gridColumnApi



function AccountingDashboardTable({ hotelIds,subGroupSelectValue,moduleName, calendarDate,handleHotelChange, comparisonPeriodValue, chartPeriodValue, handleSaveDefaultView, onChartPeriodChange, onComparisonPeriodChange, isChartLoadingMain, handleMainChartLoading, isCellClicked, handleIsCellClicked, isDefaultSaveDataLoaded, columnSelectorData,accountingSortingData }) {
  const columnDefs: any[] = [
    {
      headerName: "Hotel Name",
      field: "hotelNameAbbr",
      colId: "hotelNameAbbr",
      // sortingOrder: ['asc', 'desc', null],
      //suppressMovable: true,
      lockPosition: true,
      hide: true,
      // checkboxSelection: true,
      // maxWidth: 100,
      minWidth: 148,
      width: 150,
      flex: 1,
      headerClass: "abbr-name",
      //pinned: true,
      cellRenderer: "hotelNameRender",
      tooltip: (params) => params?.data?.hotelName,
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
  
  
    },
    {
      headerName: "EHID",
      field: "lettercode",
      colId: "lettercode",
      //suppressMovable: true,
      lockPosition: true,
      // checkboxSelection: true,
      maxWidth: 150,
      minWidth: 100,
      pinned: "left",
      cellRenderer: "hotelNameRender",
      tooltip: (params) => params?.data?.hotelName,
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

  
    },
    {
      headerName: "Rooms Occupied",
      field: "highlightedColumnValue",
      colId: "rooms Occupied",
      cellRenderer: "valueRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      isChart: true,
      cellClass: 'cursorPointer',
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

    },
    {
      headerName: "Room Revenue",
      field: "highlightedColumnValue",
      cellRenderer: "valueRender",
      colId: "room Revenue",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1.2,
      lockPosition: true,
      isChart: true,
      cellClass: 'cursorPointer',
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

    },
  
    {
      headerName: "Gross Revenue",
      field: "highlightedColumnValue",
      colId: "gross Revenue",
      cellRenderer: "valueRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1.2,
      lockPosition: true,
      isChart: true,
      cellClass: 'cursorPointer',
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

    },
    {
      headerName: "Occupancy %",
      field: "highlightedColumnValue",
      colId: "occupancy %",
      cellRenderer: "valueRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      isChart: true,
      cellClass: 'cursorPointer',
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

    },
    {
      headerName: "ADR",
      field: "highlightedColumnValue",
      colId: "adr",
      cellRenderer: "valueRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      isChart: true,
      cellClass: 'cursorPointer',
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

    },
  
    {
      headerName: "RevPAR",
      field: "highlightedColumnValue",
      colId: "revPAR",
      cellRenderer: "valueRender",
      // maxWidth: 200,
      // minWidth: 100,
      flex: 1,
      lockPosition: true,
      isChart: true,
      cellClass: ["payroll-id", "cursorPointer"],
      headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

    },
    {
      headerName: "Rooms Occupied",
      field: "highlightedColumnValue",
      colId: "",
      cellRenderer: "checkBoxRender",
      // maxWidth: 100,
      // minWidth: 100,
      flex: 0.5,
      headerClass: 'checkBoxHeader',
      cellClass: "checkBox-header",
      type: 'rightAligned',
      headerComponentParams: { isIcon: true },
      lockPosition: true,
      isChart: true,
    },
  
  ];

  const _columnDefs = columnDefs.map((item, index) => {
    item.order = index;
    return item;
  })

  const [toggleColumnData, setToggleColumnData] = useState<any[]>([]);
  const [columnDropdownToggle, setColumnDropdownToggle] = useState(false);
  let [currentColumnSelected, setCurrentColumnSelected] = useState(6);
  const [maxColumnSelected, setMaxColumnSelected] = useState(7);
  const [isSelectAllCols, setIsSelectAllCols] = useState(false);
  const [gridColumns, setGridColumns] = useState(_columnDefs)
  const [tableData, setTableData] = useState<any[]>([])
  const [filterTableData, setFilterTableData] = useState<any[]>([])
  const [showChart, setShowChart] = useState(false)
  const [isInitialDataDataLoading, setIsInitialDataDataLoading] = useState(true)
  const [Periods, setPeriods] = useState<any[]>([])
  const [lastClickedColumn, setLastClickedColumn] = useState("")
  const [selectedHeaderName, setSelectedHeaderName] = useState("")
  const [selectedColumn, setSelectedColumn] = useState<any>("")
  const [resetCalled, setResetCalled] = useState(false);
  const [Processed, setProcessed] = useState(false);
  const [selectedHids, setSelectedHids] = useState<any>("")
  const [selectedSorting, setSelectedSorting] = useState<any>("Variance")
  const [lastSortedColumnData, setLastSortedColumnData] = useState<any>({
    columnName: "",
    sortingOrder: ""
  })
  const agGridRef = useRef<any>(null);



  useEffect(() => {
    //handle Filter Change
    if (calendarDate && hotelIds != 0) {
      getDashbaordTableData()
    }
    else {
      setTableData([])
      // setIsInitialDataDataLoading(false)
      setShowChart(false)
    }

    // setShowChart(false)
  }, [comparisonPeriodValue, chartPeriodValue, resetCalled, calendarDate])

  useEffect(() => {
    if (isDefaultSaveDataLoaded) {
      buildToggleColumnData();
    }

  }, [chartPeriodValue, gridColumns, isDefaultSaveDataLoaded])

  // useEffect(() => {
  //   //handle Filter Change
  //   if (calendarDate && hotelIds != 0) {
  //     getDashbaordTableData(true)
  //   }
  //   else {
  //     setTableData([])
  //     setIsInitialDataDataLoading(false)
  //   }
  // }, [comparisonPeriodValue, chartPeriodValue])


  useEffect(() => {
    if (calendarDate) {
      const _periods = getPeriods(calendarDate)
      setPeriods(_periods)
    }
  }, [calendarDate])

  useEffect(() => {
    //handle Filter Change
    setFilterTableData([])
    setTableData([])
    setLastClickedColumn("");
    setShowChart(false);
    setResetCalled((prev) => !prev);

  }, [hotelIds]);

  useEffect(() => {
    if (isDefaultSaveDataLoaded) {

        setLastSortedColumnData({ ...accountingSortingData.lastSortedColumnData })
        setSelectedSorting(accountingSortingData.selectedSorting || "Variance")
    }
    return ()=>{
      setProcessed(true)
    }
}, [isDefaultSaveDataLoaded, accountingSortingData])

useEffect(() => {
  if (Processed) {
  sortingOnColumns()
  }
}, [selectedSorting,chartPeriodValue,Processed,isInitialDataDataLoading])

function sortingOnColumns(columns = gridColumns,toggleData=toggleColumnData) {
  const _columns=getGridColums(columns,toggleData)
  const enableSort = selectedSorting === "Value" || selectedSorting === "Variance"
  
  const VisibleColumns= _columns.filter(x=>!x.hide).map(x=>x.colId)

  const isSort = (colId) => {
      let sort
      sort =  VisibleColumns.includes(colId) && colId === "lettercode"  && enableSort ? 'asc' :  VisibleColumns.includes(colId) && colId === "hotelNameAbbr" && enableSort ? "desc": null
      return sort
  }

  const cols = columns.map(item => {
      if (item.cellRenderer === "checkBoxRender") {
          return {
              ...item,
              enableSorting: false,
              sortable: false,
              sort: null
          }
      }
      
      else {

          const numbersData = ["rooms Occupied", "room Revenue", "gross Revenue", "occupancy %","adr","revPAR"]
         

          let comparator = StringComparator
          const field=item.colId
          let customField = chartPeriodValue == 1 ? field : chartPeriodValue == 2 ? MTDFields[field] : YTDFields[field]

          if (selectedSorting === "Value") {
            if (numbersData.includes(item.colId)) {
              

              comparator = (aVal, bVal, nodeA, nodeB, isInverted) => { return NumberComparatorValue(aVal, bVal, nodeA, nodeB, isInverted, customField) }
            }
          }
          else if(selectedSorting === "Variance"){
            if (numbersData.includes(item.colId)) {
            comparator = (aVal, bVal, nodeA, nodeB, isInverted) => { return NumberComparatorVariance(aVal, bVal, nodeA, nodeB, isInverted, customField + "_diff") }
            }
          }
         
          let sort = isSort(item.colId)


          if (lastSortedColumnData?.columnName != null && lastSortedColumnData?.columnName != ""  && (lastSortedColumnData?.columnName !== "lettercode" &&  lastSortedColumnData?.columnName !== "hotelNameAbbr" )) {
              sort = null
              if (lastSortedColumnData?.columnName === item.colId ) {
                  sort = lastSortedColumnData.sortingOrder
              }
            
          }


          return {
              ...item,
              comparator: comparator,
              enableSorting: enableSort,
              sortable: enableSort,
              sort: sort

          }
      }
  })

  setGridColumns(cols)
  let sort = enableSort ? 'desc' : null
  if (agGridRef.current) {
      
      const sortedColumn = cols.filter(x => !x.hide).find((x) => x.sort);

      if (sortedColumn) {

          agGridRef.current.columnApi.applyColumnState({
              state: [{ colId: sortedColumn.colId, sort: sortedColumn.sort }]
          });
          setLastSortedColumnData({
              columnName: sortedColumn.colId,
              sortingOrder: sortedColumn.sort
          })
          return
      }
      else{
        const colId=toggleColumnData.filter(x=>x.checked && x.disabled)[0]?.colId;
    
        agGridRef.current.columnApi.applyColumnState({
            state: [{ colId: colId, sort: sort }]
        });

        setLastSortedColumnData({
            columnName:colId,
            sortingOrder: sort
        })
      }
  }
  else {
    const sortedColumn = cols.filter(x => !x.hide).find((x) => x.sort);

      if (sortedColumn) {
          setLastSortedColumnData({
              columnName: sortedColumn.colId,
              sortingOrder: sortedColumn.sort
          })
          return
      }
      else{
        const colId=toggleColumnData.filter(x=>x.checked && x.disabled)[0]?.colName;
        setLastSortedColumnData({
            columnName: colId,
            sortingOrder: sort
        })
      }
  }


}




  const getDashbaordTableData = async () => {
    let params: any = {
      // TenantId:220,
      // HID:"1760,1759",
      // DataDate:"10/31/2022",
      HID: hotelIds,
      DataDate: calendarDate,
      DailyFlashType: chartPeriodValue,
      LastYearVar: comparisonPeriodValue,
      // TenantId:null,
    }

    try {
      setIsInitialDataDataLoading(true)
      handleIsCellClicked(false)
      const data = await LaborDashBoard.getDashboardData(params)

      let formattedData = data && data.length > 0 ? getFormattedTableData([...data]) : [...data]

      setTableData((prev) => {
        const oldData = _.cloneDeep(filterTableData)
        let _formattedData = getNewValuesWithOldHighligedValue(oldData, formattedData)
         _formattedData = updateShowChartToggle(_formattedData , true)
        if (_formattedData?.filter(x => x?.highlightedColumnValue)?.length > 0) {

          setShowChart(true)
        }
        return [..._formattedData]
      })
      setIsInitialDataDataLoading(false)
    }
    catch (error) {

      if ((error as any)?.message === "Cancel") {
        setIsInitialDataDataLoading(true)
      } else {
        Utils.toastError(
          `Something Went Wrong, Please Try Again`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "accountinghBoard",
          }
        );
        setIsInitialDataDataLoading(false)
      }
    }


  }

  const hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
  }

  const handleVisualize = (isChecked) => {
    setShowChart(prev => !prev)

    if (!isChecked) {
      setLastClickedColumn("")
    }

    // setTableData(prev => {
    const prev = getCurrentTableData()
    let _filteredTableData = _.cloneDeep(prev)

    _filteredTableData = _filteredTableData.map(item => ({ ...item, manuallyChartActive: isChecked, isChartActive: isChecked, highlightedColumnValue: "" }))


    updateTableData(_filteredTableData)

    // })

  }

  const toggleColumnDropdownToggle = (value: boolean) => {
    setColumnDropdownToggle(value);
  };

  const scrollToSelectedRow = (_filteredTableData) =>{
    const _tableData = agGridRef?.current ? agGridRef?.current?.api?.getRenderedNodes().map(x =>x.data) : _filteredTableData
    
    const selectedItem= _filteredTableData.find(item => item.highlightedColumnValue != "" && item.highlightedColumnValue != null && item.highlightedColumnValue != undefined)
  
    const index= _tableData.findIndex(item => item?.hid == selectedItem?.hid)
    const filteredHighlighted= _filteredTableData.filter(item => item.highlightedColumnValue != "" && item.highlightedColumnValue != null && item.highlightedColumnValue != undefined)
   
    if(filteredHighlighted.length == 1)
    {

      setTimeout(() => {
        gridApi?.ensureIndexVisible(index,null);
      }, 100);
    }
  }



  const buildToggleColumnData = () => {

    let _toggleColumnData = gridColumns
      .filter((item) => item?.colId)
      .map((item: any, index) => {
        let toggleColumnObject: any = {};
        toggleColumnObject.colDispalyName = item?.headerName;
        toggleColumnObject.colName = item?.colId;
        toggleColumnObject.checked = item?.hide ? false : true;
        toggleColumnObject.order = index;
        toggleColumnObject.disabled =
          ((item?.field === "lettercode" || item?.field === "hotelNameAbbr") && !item?.hide) ? true : false;
        return toggleColumnObject;
      });
    if (columnSelectorData?.length > 0 && toggleColumnData.length==0) {


      _toggleColumnData = _toggleColumnData.map(x => {

        const data = columnSelectorData.find(l => l?.colName === x?.colName);
        if (data?.colName) {
          x.checked = data.checked;
          x.disabled = data?.disabled || false;
        }
        return x;
      });




    }

    if (toggleColumnData?.length > 0) {

      _toggleColumnData = _toggleColumnData.map(x => {

        const data = toggleColumnData.find(l => l?.colName === x?.colName);
        if (data?.colName) {
          x.checked = data.checked;
          x.disabled = data?.disabled || false;

        }
        return x;
      });




    }


    setToggleColumnData(_toggleColumnData);
    setCurrentColumnSelected(_toggleColumnData.filter((item) => item.checked)
      .length);
    setMaxColumnSelected(_toggleColumnData?.length);
    // this.setState({
    //   toggleColumnData,
    //   currentColumnSelected: toggleColumnData.filter((item) => item.checked)
    //     .length,
    //   maxColumnSelected: toggleColumnData?.length,
    // });
  };

  const toggleColumnCheck = (e: any, data: any, idx: any) => {
    let checked = e.target.checked;
    let _gridColumns = _.cloneDeep(gridColumns);
    for (let i = 0; i < _gridColumns.length; i++) {
      if (_gridColumns[i].colId === data.colName) {
        _gridColumns[i].hide = !checked;
        break;
      }
    }




    if (checked) {
      currentColumnSelected = currentColumnSelected + 1;
    } else {
      currentColumnSelected = currentColumnSelected - 1;
    }

    let _toggleColumnData = _.cloneDeep(toggleColumnData);

    _toggleColumnData[idx].checked = checked;
    let isSelectAllCols = false;
    if (
      _toggleColumnData?.filter((item) => item?.checked)?.length ===
      maxColumnSelected
    ) {
      isSelectAllCols = true;
    }

    const toggleIndex = _gridColumns.findIndex(x => x.colId === data.colName);
    if (toggleIndex < 2) {
      if (toggleIndex === 0) {
        _toggleColumnData[0].disabled = true;
        _gridColumns[1].hide = true;
        _toggleColumnData[1].disabled = false;
        _toggleColumnData[1].checked = false;

      } else {
        _toggleColumnData[1].disabled = true;
        _gridColumns[0].hide = true;
        _toggleColumnData[0].disabled = false;
        _toggleColumnData[0].checked = false;

      }
    }




    if (_toggleColumnData?.filter(x => x.checked)?.length < 2 ||
      (_toggleColumnData?.filter(x => x.checked)?.length > 0 && _toggleColumnData?.filter(x => x.checked)?.length < 2)) {
      Utils.toastError("At least one chart data column should be selected", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "laborDashBoard",
      });

      return;
    }

    if (_toggleColumnData?.filter(x => x.checked)?.length > 7) {
      Utils.toastError("You can select a maximum of 7 columns at one time", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "laborDashBoard",
      });
      return;
    }

    setGridColumns(_gridColumns);
    sortingOnColumns(_gridColumns,_toggleColumnData)
    setCurrentColumnSelected(currentColumnSelected);
    setToggleColumnData(_toggleColumnData);
    setIsSelectAllCols(isSelectAllCols)


    if (!checked) {
      const tableData = getCurrentTableData()
      const _filteredTableData = tableData.map(item => {
        if (item?.highlightedColumnValue?.startsWith(_toggleColumnData[idx]?.colDispalyName)) {
          item.highlightedColumnValue = "";
        }
        return item;
      })

      updateTableData(_filteredTableData)
    }

  };

  const onGridReady = (params: any) => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    // columnWidthSizeToFit();

    const headerSelector = ".ag-header";
    const scrollSelector = ".ag-body-horizontal-scroll";

    const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

    // get scrollbar elements
    const scrollElement: any = document.querySelector(scrollSelector);

    const scrollContainerElement: any = document.querySelector(
      scrollContainerSelector
    );

  };

  function getLastSortedColumnDataFromHeader(data) {
    const colId = data?.props?.column?.colId
    const order = data?.order

    setLastSortedColumnData({
        columnName: colId,
        sortingOrder: order
    })

}

  const columnWidthSizeToFit = () => {
    console.log(gridApi)
    gridApi?.sizeColumnsToFit();
  }

  const columnDragStop = (params: any) => {
    let columnInstance =
      params.columnApi.columnController.displayedCenterColumns;
    const fields = columnInstance
      .map((item, index) => ({ name: item.colDef.field, order: index }))
      .filter((item) => item.name);

    const _toggleColumnData = toggleColumnData.map((item: any) => {


      return fields.find((x) => x?.name === item?.colName)?.order || 0;;
    });

    let columnData = columnDefs.map((item) => {
      if (
        _toggleColumnData.find(
          (col) => col.colName === item.field && col.checked
        ) ||
        !item.field
      ) {
        item.hide = false;
      } else {
        item.hide = true;
      }

      if (item.field) {
        item.order = _toggleColumnData.find(
          (col) => col.colName === item.field
        )?.order;
      } else {
        if (item.cellClass === "triple-dot") {
          item.order = 100;
        }
      }

      return item;
    });

    const _gridColumns = columnData.sort((a, b) => +a.order - +b.order);
    setToggleColumnData(_toggleColumnData);
    setGridColumns(_gridColumns);

  };

  const getLevelKeys = (params) => {
    return {
      level: 0,
      field: "lettercode"
    }
  }

  const updateShowChartToggle = (_filteredTableData, isDefaultLoad = false) => {

    const isShowChart = _filteredTableData?.filter(x => x?.manuallyChartActive || x?.highlightedColumnValue).length > 0
    _filteredTableData = _filteredTableData.map(item => ({ ...item, isChartActive: isShowChart }))


    if (isDefaultLoad) {
      setShowChart(isShowChart);
      handleMainChartLoading(isShowChart)
    } else {
      // setTableData(prev =>{
      const prev = getCurrentTableData()
      const _prevFilterTable = _.cloneDeep(prev)
      const previsShowChart = _prevFilterTable?.filter(x => x?.manuallyChartActive || x?.highlightedColumnValue).length > 0
      setShowChart(previsShowChart);
      handleMainChartLoading(previsShowChart)


      // return prev
      // })
    }



    if (!isShowChart) {
      setLastClickedColumn("")
    }

    scrollToSelectedRow(_filteredTableData)
    return _filteredTableData
  }

  const getCurrentTableData = () => {
    const currentData: any[] = []
    agGridRef?.current?.api?.forEachNode(node => {
      currentData.push(node?.data)
    })

    return currentData
  }

  const updateTableData = (data) => {
    if (data.length > 0) {
      const oldData = getCurrentTableData()
      const newData = findChangedData(oldData, data)
      agGridRef?.current?.api?.applyTransactionAsync({
        update: newData
      })


      setFilterTableData(data)
      const column = getSelectedColumn(data)
      const selectedids = getSelectedHIds(data)
      setSelectedColumn(column)
      setSelectedHids(selectedids)
      // agGridRef.current.api.refreshCells({
      //   force: true
      // });


    }
  }

  const downloadAccountingDashbord = () => {
    const COLUMNSELECTEDMAPPING= {
      "hotelNameAbbr": "hotelNameAbbr",
      "lettercode": "lettercode",
      "rooms Occupied": "Rooms Occupied",
      "gross Revenue": "Gross Revenue",
      "room Revenue": "Room Revenue",
      "occupancy %": "Occupancy %",
      "adr": "ADR",
      "revPAR": "RevPAR",
    }

    const EXPORTSORTCOLUMNSValue = {
      "hotelNameAbbr": "Hotel Name",
      "lettercode": "EHID",
      "rooms Occupied": "Rooms Occupied",
      "gross Revenue": "Gross Revenue",
      "room Revenue": "Room Revenue",
      "occupancy %": "Occupancy %",
      "adr": "ADR",
      "revPAR": "RevPAR",
    }

    const EXPORTSORTCOLUMNSVariamce = {
      "hotelNameAbbr": "Hotel Name",
      "lettercode": "EHID",
      "rooms Occupied": " RoomOccupiedMTD_Variance",
      "gross Revenue": "GrossRevenueMTD_Variance",
      "room Revenue": "RoomRevenueMTD_Variance",
      "occupancy %": "OccupancyPerMTD_Variance",
      "adr": "ADRMTD_Variance",
      "revPAR": "RevPARMTD_Variance",
    }


    const SelectedColumn = toggleColumnData.filter(x => x?.checked).map(x => COLUMNSELECTEDMAPPING[x.colName])
    const enableSort = selectedSorting === "Value" || selectedSorting === "Variance"
    const EXPORTSORTCOLUMNS = enableSort ? selectedSorting === "Value" ? EXPORTSORTCOLUMNSValue : EXPORTSORTCOLUMNSVariamce : null
    const columnName = enableSort && EXPORTSORTCOLUMNS ? EXPORTSORTCOLUMNS[lastSortedColumnData?.columnName] : "Lettercode"
    const sortDirection = enableSort ? lastSortedColumnData.sortingOrder ? lastSortedColumnData.sortingOrder === "asc" ? "ASC" : "DESC" : "DESC" : "ASC"


    let request: any = {
      HID: hotelIds,
      DataDate: calendarDate,
      DailyFlashType: chartPeriodValue,
      LastYearVar: comparisonPeriodValue,
      showColumns: SelectedColumn,
      sortDirection: sortDirection,
      columnName: columnName,



    }

    const fileName = `AccountingDashboard-${moment().format("YY-MMDD hh.mm.ss")}`;
    LaborDashBoard.downloadAccountingDashboardData(request, fileName).then(
      (res: any | null) => {

        toast.success(
          `Accounting data downloaded Successfully`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "accountinghBoard",
          }
        );
      }
    ).catch((err) => {
      Utils.toastError(
        `Something Went Wrong, Please Try Again`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "accountinghBoard",
        }
      );
    });
  }

  const isLimitValidation = (data, clickedColumn, isFromCheckbox = false) => {


    const filterData = data.filter(x => x?.highlightedColumnValue)
    const column = getSelectedColumn(filterData)
    if ((column === clickedColumn || isFromCheckbox) && filterData.length === MAXLIMIT) {
      Utils.toastError(
        "Please limit chart selections to 10 properties.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "laborDashBoard",
        }
      );

      return true
    }

    return false
  }

  const onColumnClick = (params, isChecked = true, isFromCheckbox = false) => {
    //  const updatedRows:any []=[]

    //  agGridRef.current.api.forEachNode(node =>{
    //   if(Math.random() > 0.5) return 
    //   const currentLevelData = getLevelKeys(params)


    //   let headerName = params?.colDef?.headerName
    //   let highlightedColumnValue = `${headerName}_${currentLevelData.level}_${params?.data?.hid}`;
    //   const item = node.data
    //   updatedRows.push({
    //     ...item,
    //     "rooms Occupied":Math.random()*100
    //   })
    //   console.log(node)
    //  })

    //  agGridRef.current.api.applyTransaction({
    //   update:updatedRows
    //  })
    // setTableData(prev => {
    const prev: any[] = getCurrentTableData()
    let headerName = params?.colDef?.headerName
    //  if(isChecked)
    //  {   if(isLimitValidation(prev,headerName))
    //      {
    //          return 
    //      }

    //  }
    handleIsCellClicked(true)
    const currentLevelData = getLevelKeys(params)
    if (prev?.filter(x => x.highlightedColumnValue)?.length == 0 && isFromCheckbox && agGridRef) {

      headerName = agGridRef?.current?.columnApi?.columnController?.columnDefs?.filter(x => !x.hide && x?.isChart)[0]?.headerName;

   }

   let firstColumnUniqueField = params?.data[currentLevelData.field]

    let highlightedColumnValue = `${headerName}_${currentLevelData.level}_${params?.data?.hid}`;



    let _filteredTableData = _.cloneDeep(prev)

   

    //When changing columns
    setLastClickedColumn(prevLastCLickedColumn => {
      if (isFromCheckbox) {

        if (prev?.filter(x => x.highlightedColumnValue)?.length == 0 && isFromCheckbox && agGridRef) {

          prevLastCLickedColumn = headerName;
      }
      
        highlightedColumnValue = `${prevLastCLickedColumn !== "" ? prevLastCLickedColumn : headerName}_${currentLevelData.level}_${params?.data?.hid}`

        if (prevLastCLickedColumn !== "")
          return prevLastCLickedColumn
        else
          return headerName

      }
      else if (prevLastCLickedColumn !== headerName && !isFromCheckbox) {
        _filteredTableData = _filteredTableData.map(item => {

          item.highlightedColumnValue = item.highlightedColumnValue ? "" : item.highlightedColumnValue

          return item
        })
        return headerName
      }

      return headerName
    })
   

    _filteredTableData = _filteredTableData.map(item => {
      if (isChecked) {

        if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
          item.highlightedColumnValue = highlightedColumnValue
        }
        else {
          item.highlightedColumnValue = item?.highlightedColumnValue || ""

        }

      }
      else {
        if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
          item.highlightedColumnValue = ""
        }

      }



      return item
    })

    _filteredTableData = _filteredTableData.map(x => ({ ...x, manuallyChartActive: false }))
    _filteredTableData = updateShowChartToggle(_filteredTableData, true)


    updateTableData(_filteredTableData)
    if (!isChecked) {
      if (_filteredTableData.filter(item => item.highlightedColumnValue).length == 0) {
        setIsInitialDataDataLoading(true)
        agGridRef.current?.api?.refreshCells({
          force: true
        });
        setIsInitialDataDataLoading(false)
      }

    }

    if (_filteredTableData.length > 20) {

      setTimeout(() => {
        handleIsCellClicked(false)
      }, 20)
    }
    else {
      handleIsCellClicked(false)
    }
    //   return _filteredTableData
    // })

  }

  const getSelectedHotelsIds = (data) => {
    const _tableData = _.cloneDeep(data)


    const filteredData = _tableData.filter(x => x?.highlightedColumnValue)
    let res: any[] = [];

    if (filteredData.length > 0) {
      res = filteredData.map(item => item.hid)
    }
    else {
      res = []
    }


    return res.join(",")
  }


  const getSelectedHIds = (data) => {

    const _tableData = _.cloneDeep(data)


    const filteredData = _tableData.filter(x => x?.highlightedColumnValue)
    let res
    if (filteredData.length > 0) {
      res = filteredData.map(item => item.hid)
    }
    else {
      res = []
    }

    console.log(res)
    return res.join(",")
  }



  const getSelectedColumn = (data) => {

    const _tableData = _.cloneDeep(data)


    const filteredItem = _tableData.find(x => x?.highlightedColumnValue)
    let res = null
    if (filteredItem) {
      res = filteredItem.highlightedColumnValue.split("_")[0]
    }

    console.log(res)
    return res
  }

  const onChangeMetricParameter = (item) => {
    // setTableData(prev => {

    const prev = getCurrentTableData()
    let headerName = item.displayName

    setLastClickedColumn(headerName)


    let _filteredTableData = _.cloneDeep(prev)

    _filteredTableData = _filteredTableData.map(item => {
      if (item.highlightedColumnValue) {
        let highlightedcolValue = item.highlightedColumnValue
        highlightedcolValue = highlightedcolValue.split("_")
        highlightedcolValue[0] = headerName

        highlightedcolValue = highlightedcolValue.join("_")
        return {
          ...item,
          highlightedColumnValue: highlightedcolValue
        }
      }
      else {
        return item
      }
    })


    _filteredTableData = _filteredTableData.map(item => ({ ...item, isChartActive: _filteredTableData?.filter(x => x?.highlightedColumnValue).length > 0 }))

    updateTableData(_filteredTableData)
    //   return _filteredTableData
    // })
  }

  const updateTableSelectedData = (selectedHids, selectedHeaderName) => {
    // setTableData(prev => {
    // debugger;
    setIsInitialDataDataLoading(true)
    const prev = getCurrentTableData()
    let _filteredTableData = _.cloneDeep(prev)

    _filteredTableData = _filteredTableData.map((item, index) => {

      if (selectedHids?.find(x => +x === +item.hid)) {


        let highlightedcolValue = `${selectedHeaderName}_0_${item.hid}`;
        if (!item.highlightedColumnValue) {
          highlightedcolValue = highlightedcolValue
        }
        else {
          highlightedcolValue = item.highlightedColumnValue
        }

        return {
          ...item,
          highlightedColumnValue: highlightedcolValue
        }
      }
      else {
        return { ...item, highlightedColumnValue: null }
      }
    })


    _filteredTableData = updateShowChartToggle(_filteredTableData, true)
    updateTableData(_filteredTableData)

    if (_filteredTableData.length > 20) {
      setTableData(_filteredTableData)
      setTimeout(() => {
        setIsInitialDataDataLoading(false)
      }, 20)
    }
    else {
      setIsInitialDataDataLoading(false)
    }


    //   return _filteredTableData
    // })
  }

  const getGridColums = (columns=gridColumns,toggleData=toggleColumnData) => {
    let _gridColumns = _.cloneDeep(columns);

    if (toggleData?.length > 0) {

      toggleData.forEach(data => {
            for (let i = 0; i < _gridColumns.length; i++) {
                if (_gridColumns[i].colId === data.colName) {
                    _gridColumns[i].hide = !data.checked;

                }
            }
        });

    }

    return _gridColumns;

};

  const ValueRender = React.memo((params: any) => {
    const [isLoading, setIsLoading] = useState(false)

    let field = params?.colDef?.colId

    const isDollarSign = ["room Revenue", "gross Revenue", "adr", "revPAR"].includes(field)
    const isPercentage = "occupancy %" === field
    const isDecimal = "rooms Occupied" !== field

    let customField = chartPeriodValue == 1 ? field : chartPeriodValue == 2 ? MTDFields[field] : YTDFields[field]
    let diff = params?.data?.[customField + "_diff"] || 0
    let isPositive = diff > 0

    const value = isPercentage ? Number(params?.data[customField] || 0).toFixed(2) : isDecimal ? Utils.currencyFormatWithOutdoller(Number(params?.data[customField] || 0).toFixed(2)) : Utils.currencyFormatWithOutdoller(params?.data[customField] || 0).replace(".00", "");
    diff = isPercentage ? Number(diff).toFixed(2) : isDecimal ? Utils.currencyFormatWithOutdoller(Number(diff).toFixed(2)) : Utils.currencyFormatWithOutdoller(Number(diff).toFixed(0)).replace(".00", "")


    const valueFormatted = `${isDollarSign ? "$" : ""}${value}${isPercentage ? "%" : ""}`
    const diffvalueFormatted = `${isPositive ? `+${diff}` : diff || "0"}${isPercentage ? "%" : ""}`



    const currentLevelData = getLevelKeys(params)

    let headerName = params?.colDef?.headerName
    const highlightedColumnValue = `${headerName}_${currentLevelData.level}_${params?.data?.hid}`;

    const handleColumnClick = (params, status = true) => {
      if (status) {
        const currentData = getCurrentTableData()
        if (isLimitValidation(currentData, headerName)) {
          return
        }

      }

      setIsLoading(true)
      onColumnClick(params, status)
      setTimeout(() => {
        setIsLoading(false)
      }, 10)

    }

    if (isLoading) {
      return <ContentLoader viewBox="0 0 380 70" style={{ width: '100%' }}>
        {/* Only SVG shapes */}
        <rect x="0" y="0" rx="5" ry="5" width="300" height="70" />

      </ContentLoader>
    }


    return (
      <div className='variance-neg-pos-value' key={highlightedColumnValue}>
        <div onClick={() => { if (highlightedColumnValue !== params.data.highlightedColumnValue) { handleColumnClick(params) } }} className='d-flex align-items-center'>
          <EllipsisWithTooltip>
            <div className='data-sets d-flex'>
              <div>
                <span className="value">{valueFormatted}</span> {" "}
                <span className={isPositive ? "show-pos-variance" : "show-neg-variance"}>{diffvalueFormatted}</span>
              </div>
              {highlightedColumnValue === params.data.highlightedColumnValue ?
                <span onClick={(event) => {
                  event.stopPropagation();
                  setTimeout(() => {
                    handleColumnClick(params, false)
                  }, 0)
                }
                } className='remove-highlight-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="white" />
                    <path d="M14.2425 4.34424L9.9995 8.58624L5.7575 4.34424L4.34351 5.75824L8.5855 10.0002L4.34351 14.2422L5.7575 15.6562L9.9995 11.4142L14.2425 15.6562L15.6565 14.2422L11.4145 10.0002L15.6565 5.75824L14.2425 4.34424Z" fill="#84888C" />
                    <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" stroke="#E8EAED" />
                  </svg>
                </span> : !params.data.isChartActive ? <span className='hover-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="white" />
                    <path d="M11 12.414C11.0928 12.5069 11.203 12.5807 11.3243 12.631C11.4456 12.6813 11.5757 12.7072 11.707 12.7072C11.8383 12.7072 11.9684 12.6813 12.0897 12.631C12.211 12.5807 12.3212 12.5069 12.414 12.414L17.414 7.414L16 6L11.707 10.293L9.414 8C9.32121 7.90705 9.21101 7.83332 9.08969 7.78301C8.96838 7.73269 8.83833 7.7068 8.707 7.7068C8.57567 7.7068 8.44562 7.73269 8.32431 7.78301C8.20299 7.83332 8.09279 7.90705 8 8L3 13L4.414 14.414L8.707 10.121L11 12.414Z" fill="#2CAF92" />
                    <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" stroke="#E8EAED" />
                  </svg>
                </span> : <></>
              }
            </div>
            {/* <span className="value">{valueFormatted}</span> <span className={isPositive? "positive_value":"negative_value"}>{diffvalueFormatted}</span> */}
          </EllipsisWithTooltip>
        </div>
        {highlightedColumnValue === params.data.highlightedColumnValue ?
          <div className='highlights'>
            <div className='highlight-green'>
              {/* Highlight */}
            </div>
          </div> :
          params.data.isChartActive ? <div className='highlights'>
            <div className='highlight-grey'>
              {/* Highlight */}
            </div>
          </div> : <></>
        }
      </div>
    );
  })


  const CheckBoxRender = React.memo((params: any) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleColumnClick = (params, status) => {
      if (status) {
        let clickedHeaderName = params?.colDef?.headerName
        const currentData = getCurrentTableData()
        if (isLimitValidation(currentData, clickedHeaderName, true)) {
          return
        }

      }


      // setIsLoading(true)
      onColumnClick(params, status, true)
      // setTimeout(()=>{
      //     setIsLoading(false)
      // },10)


    }


    if (isLoading) {
      return <ContentLoader viewBox="0 0 380 70" style={{ width: '100%' }}>
        {/* Only SVG shapes */}
        <rect x="0" y="0" rx="5" ry="5" width="50" height="70" />

      </ContentLoader>
    }

    return <Form.Check onChange={(e) => { handleColumnClick(params, e.target.checked) }}
      type={"checkbox"}
      id={`default`}
      checked={params.data?.highlightedColumnValue ? true : false}
      label={``} />
  })

  const HotelNameRender = React.memo((params: any) => {
    let field = params?.colDef?.field

    return (
      <div className='d-flex align-items-center'>
        <OverlayTrigger
          placement="bottom"
          defaultShow={false}
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id={"ehid-name"}>
              {params?.data["hotelName"]}
            </Tooltip>
          }
        >
          <span className="ehid-name width-auto cursorDefault inline-block" onWheel={hoverOutEllipsis}>{params?.data[field]}</span>

        </OverlayTrigger>
        {/* <EllipsisWithTooltip placement="bottom">
          <span className="ehid-name">{params?.data[field]}</span>
        </EllipsisWithTooltip> */}
      </div>
    );
  })



  const getAgGridOptions = () => {
    const gridOptions: GridOptions = {
      rowData: [],
      overlayNoRowsTemplate: 'No data found',
      rowClass: "user-table-row accountingDailyFlashRow",

      getRowHeight: (params) => {
        const dataLength = params.data.payRoll?.toString()?.split(",").length;
        return dataLength > 1
          ? 22 * dataLength + (22 * dataLength < 32 ? 32 - 22 * dataLength : 0)
          : 36;
      },
      headerHeight: 48,
      frameworkComponents: {
        valueRender: ValueRender,
        checkBoxRender: CheckBoxRender,
        hotelNameRender: HotelNameRender,
        agColumnHeader: React.memo(LaborCustomHeader),
      },
      rowSelection: "single",
    };
    return gridOptions;
  };

  const TripleMenu = () => {
    return (
      <Dropdown className="more-action" alignRight>
        <Dropdown.Toggle
          className="btn-outline-primary btn btn-primary more"
          id="dropdown-more"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>

          <Dropdown.Item
            onClick={() => {
              handleSaveDefaultView(toggleColumnData,{ selectedSorting, lastSortedColumnData })
            }}
          // eventKey={item?.eventKey}
          >
            Save as Default
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              downloadAccountingDashbord()
            }}
          >
            Download as Excel
          </Dropdown.Item>


        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <div className='labor-daily-flash-container main-page accounting-dashboard-table'>
  

      <div className="navigation-menus">
        <div className="d-flex table-navigation-items align-items-center flex-wrap">
          <div className="date-column">
              <div className="period-selector period-selector-dropdwn">
                {Periods?.length > 0 && <PeriodSelector
                  id={"chartPeriodSelector"}
                  defaultValue={chartPeriodValue}
                  onChange={onChartPeriodChange}
                  periods={Periods}
                ></PeriodSelector>}
              </div>
            </div>

            <div className="varience-column">
              {dataComparisonList?.length > 0 &&
                <div className="period-selector mr-auto">

                  <DropDownList
                    placeholderLabel={"Data Comparison"}
                    data={dataComparisonList}
                    label={"label"}
                    value={"value"}
                    isSearchRequired={false}
                    defaultValue={comparisonPeriodValue}
                    dependent={comparisonPeriodValue}
                    onDropDownChange={onComparisonPeriodChange}
                    selectedItem={dataComparisonList.find((x) => x.value === comparisonPeriodValue)}
                  />
                </div>
              }
            </div>
            <div className="d-flex group-selector">
              {(isDefaultSaveDataLoaded) && (
                  <>
                    <HotelGroupSelector
                    isDashboard={true}
                    hotelIds={hotelIds}
                    subGroupSelectValue={subGroupSelectValue}
                    onHotelChange={handleHotelChange}
                    notIncludeEnterpriseAccounts={true} // default Props
                    showOnlyHotelAccounts={true} // default Props
                    moduleName={moduleName}  // default Props
                  ></HotelGroupSelector>



                  </>
              )
              }
          </div> 

          
          <div className="sorting-column px-3">
            <div className="period-selector period-selector-dropdwn ">
                {sortDropdDownData?.length > 0 && <SortSelector
                    id={"chartPeriodSelector"}
                    defaultValue={selectedSorting}
                    onChange={(item) => setSelectedSorting(item.value)}
                    data={sortDropdDownData}
                ></SortSelector>}
            </div>
          </div>

          
            <div className='d-flex align-items-center'>
              <FormLabel className='mb-0'>Visualize</FormLabel>
              <Form.Check
                checked={showChart}
                onChange={(e) => { if (!isInitialDataDataLoading) { handleVisualize(e.target.checked) } }}
                type="switch"
                className="sm-controll toggleButtonRP"
                label=""
                id="daily-flash-switch"
              />
            </div>
          <TableColumnSelector toggleColumnData = {toggleColumnData}  drillDownLevel={0} toggleColumnCheck = {toggleColumnCheck} />
          <TripleMenu />
          </div>
                            
        </div> 

      <div className="user-management-table accounting-daily-flash-table">
        <div
          id="myGrid"
          className={"ag-theme-balham accounting-daily-flash-tbl-wrapper"}
        >
          {isInitialDataDataLoading ? (
            <ReactPageLoader useas={"dashboardTable"} />
          ) :
            (
            <div className={showChart ? "default-table-height" : ""}>
              <AgGridReact
                // enableCellChangeFlash
                getRowNodeId={data => { return data?.hid }}
                ref={agGridRef}
                onGridReady={onGridReady}
                domLayout={"autoHeight"}
                // onDragStopped={columnDragStop}
                // style ={{minHeight: 200}}
                rowData={[...tableData]}
                columnDefs={getGridColums()}
                suppressDragLeaveHidesColumns={true}
                suppressRowDrag={true}
                animateRows={false}
                gridOptions={getAgGridOptions()}
                />
            </div>
            )
          }

          {(isChartLoadingMain || isCellClicked) &&
            <div className="table-overlay">
              <ReactPageLoader useas={"dashboardTable"} />
            </div>
          }

        </div>
      </div>
      {showChart &&
        <AccountingDashboardChart
          selectedhotelIds={getSelectedHIds([...filterTableData])}
          hotelIds={getSelectedHotelsIds([...filterTableData])}
          selectedColumn={selectedColumn}
          calendarDate={calendarDate}
          comparisonPeriodValue={comparisonPeriodValue}
          chartPeriodValue={chartPeriodValue}
          onComparisonPeriodChange={onComparisonPeriodChange}
          onChangeMetricParameter={onChangeMetricParameter}
          updateTableSelectedData={updateTableSelectedData}
          toggleColumnData={toggleColumnData}
          handleMainChartLoading={handleMainChartLoading}
          isInitialDataDataLoading={isInitialDataDataLoading}
        />
      }
    </div>
  )
}

export default React.memo(AccountingDashboardTable)
