import Instense from "./Axios-config";
import axios from "axios";
export class bankSyncServices {
  private static ajaxRequestFindData: any = null;
  public static getBankSyncTableData = async (
    request: any
  ) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    let url: any = "";
    if (request.tranType === "Completed") {
      url = `/BankSync/GetBankSyncDataCompleted`;
    } else {
      url = `/BankSync/GetBankSyncData`;
    }
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static GetBankSyncAdjustmentValue = async (
    request: any
  ): Promise<any[] | null> => {
    const url = `/BankSync/GetBankSyncAdjustmentValue`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static OpenSlideoutParameter = async (
    request: any
  ) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = `/BankSync/OpenSlideoutParameter`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetBankSyncFindData = async (
    request: any
  ) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantId = tenantID;
    const url = `/BankSync/GetBankSyncFindData`;
    if (bankSyncServices.ajaxRequestFindData) {
      bankSyncServices.ajaxRequestFindData.cancel("Cancel");
    }
    bankSyncServices.ajaxRequestFindData = axios.CancelToken.source();
    return Instense.post(url, request, {
      cancelToken: bankSyncServices.ajaxRequestFindData.token
    }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static getBankBalance = async (
    request: any
  ) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = `/BankSync/GetSyncOprIdDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static SaveBankSyncTranctionStatus = async (
    request: any
  ): Promise<any[] | null> => {
    const url = `/BankSync/SaveBankSyncTranctionStatus`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };
  public static BankSyncValidation = async (
    request: any
  ): Promise<any[] | null> => {
    const url = `/BankSync/BankSyncValidation`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static SavePlaidTranSyncMatchingDetailsStatus = async (
    request: any
  ): Promise<any[] | null> => {
    const url = `/BankSync/SavePlaidTranSyncMatchingDetailsStatus`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static BankSyncAccounts = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = `/BankSync/BankSyncAccounts`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data?.result;
      return result;
    });
  };

  public static GetePayOnboardingTenantWiseList = async (request: any): Promise<any> => {
    const url = `/BankSync/Get_ePayOnboardingAutomationAcc`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data?.result;
      return result;
    });
  };

  public static GetePayOnboardingCustomerID = async (request: any): Promise<any> => {
    const url = `/BankSync/GetePayOnboardingCustomerID`;
    return Instense.post(url, request).then((response: any) => {
      let result: any = response.data;
      return result;
    });
  };

  public static IsEapyDocumentExists = async (request: any): Promise<any> => {
    const url = `/BankSync/IsEapyDocumentExists`;
    return Instense.get(url, { params: request, headers: { ImportUser: 1 } }).then((response) => {
      let result: any = response.data?.result;
      return result;
    });
  };

  public static DeleteePayOnboardingAutomationFile = async (tenantId: any): Promise<any> => {
    const url = `/BankSync/DeleteePayOnboardingAutomationDocument`;
    return Instense.post(url, null, { params: { tenantId: tenantId }, headers: { ImportUser: 1 } }).then((response: any) => {
      let result: any = response.data;
      return result;
    });
  };

  public static DownloadePayOnboardingAutomationDocument = async (file: any, tenantID: any, IsDelete: boolean): Promise<any> => {
    const url = `/BankSync/DownloadePayOnboardingAutomationDocument?TenantID=${tenantID}&filename=${file}&IsDelete=${IsDelete}`;
    return Instense.post(url, null, { responseType: "blob", headers: { ImportUser: 1 } }).then((response: any) => {
      if (file === "Samplechecks.pdf") {
        if (IsDelete) {
          let fileURL = window.URL.createObjectURL(response.data);
          let tempLink = document.createElement("a");
          tempLink.href = fileURL;
          tempLink.setAttribute("download", "Samplechecks_" + tenantID + ".pdf");
          tempLink.click();
        }
      } else {
        if (IsDelete) {
          let fileURL = window.URL.createObjectURL(response.data);
          let tempLink = document.createElement("a");
          tempLink.href = fileURL;
          tempLink.setAttribute("download", "epayonboardingAccounts_" + tenantID + ".xlsx");
          tempLink.click();
        }
      }
    });
  };

  public static BankSyncGroupMapping = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = `/BankSync/GetBankSyncGroupMapping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static UpdateIFTranDateToPlaidTranDate = async (
    request: any
  ): Promise<any[] | null> => {
    const url = `/BankSync/UpdateIFTranDateToPlaidTranDate`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static SaveBankSyncNotes = async (
    request: any
  ): Promise<any | null> => {
    const url = `/BankSync/SaveBankSyncNotes?SyncID=${request.SyncID}&Notes=${request.Notes}`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetBankSyncMappingRules = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = `/BankSync/GetBankSyncMappingRules`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response;
      return result;
    });
  };

  public static SaveBankSyncMappingRules = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = `/BankSync/SaveBankSyncMappingRules`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static BankSyncStatementExcelImport = async (
    request: any,
    files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    request.TenantID = tenantID;
    const url = `/BankSync/BankSyncStatementExcelImport`;
    const formData: FormData = new FormData();
    files.forEach((element) => {
      formData.append("file", element);
    });
    formData.append("formField", JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static UpdateFileDetails = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = `/BankSync/UpdateFileDetails`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  //Manual Upload
  public static GetMappingRuleField = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = `/Transfer/GetMappingRuleField`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data;
      return result;
    });
  };

  public static GetBankSyncFieldMappedWithRule = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = `/BankSync/GetBankSyncFieldMappedWithRule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static SaveBSImportStatementDataMapping = async (
    request: any
  ): Promise<any | null> => {
    let newRequestArray: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    newRequestArray.push(request);
    const url = `/BankSync/SaveBSImportStatementDataMapping`;
    return Instense.post(url, newRequestArray).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };
  
  public static ProcessBankSyncData = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const username: any = (storage === null ? 0 : storage.userName);
    const tenantId: any = (storage === null ? 0 : storage.tenantID);
    request.tenantID = tenantId;
    request.userName = username;
    const url = `/BankSync/ProcessBankSyncData`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };
  
  public static SaveBSImportData = async (request: any): Promise<any | null> => {    
    const url = `/BankSync/SaveBSImportData`;
    return Instense.post(url, request).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };
}