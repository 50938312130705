import Instense from "./Axios-config";
import { IAccountResponseDto } from "../Contracts/IAccount";

export class accountService {
  public static GetAccountsData = async (
    request: any
  ): Promise<IAccountResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let username = storage === null ? 0 : storage.userName;
    request.TenantID = tenantID;
    request.LoginUserName = username;
    const url = `/Accounting/GetAccountNoByHID`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IAccountResponseDto;
      return result;
    });
  };

  public static ExistingBankAccDetails = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/ExistingBankAccDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static BankAccDetailsRes = async (
    request: any
  ): Promise<any[] | null> => {
    const url = `/Accounting/BankAccDetailsRes`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static PositivePayFileDetails = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/PositivePayFileDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static COADetails = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/CreditCardCOADetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static COADetailsBank = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/COADetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static DigitalSignPermission = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/DigitalSignPermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static SaveBankDetails = async (
    request: any,
    files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;

    if (files.length === 0) {
      const url = `/Accounting/SaveBankDetails`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result;
        return result;
      });
    } else {
      const url = `/Accounting/SaveBankDetailsWithFiles`;
      const formData: FormData = new FormData();
      files.forEach((element) => {
        formData.append("file", element);
      });

      formData.append("formField", JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result = response.data.result;
        return result;
      });
    }
  };

  public static Delete_AccountDigitalSign = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/Delete_AccountDigitalSign`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static downloadSignature = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/DigitalSignDownload`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", request.fileName);
        tempLink.click();
      }
    );
  };

  public static GetLinkToken = async (): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/GetLinkToken`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetAccessToken = async (
    public_token
  ): Promise<any[] | null> => {
    const url = `/Accounting/GetAccessToken?LinkToken=` + public_token;
    return Instense.get(url).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetProcessorTokenForHotel = async (
    Access_token,
    bankRefID,
    AccountId,
    hid
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.AccessToken = Access_token;
    request.bankRefID = bankRefID;
    request.AccountId = AccountId;
    request.hotelID = hid;
    const url = `/Accounting/GetProcessorTokenForHotel`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetDwollaFundingToken = async (
    Processor_token,
    accountName,
    hid
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.PlaidToken = Processor_token;
    request.Name = accountName;
    request.hotelID = hid;
    const url = `/Accounting/GetDwollaFundingToken`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static saveFundingSourceHotel = async (
    hid,
    FundingSourceId,
    bankRefID
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.fundingSrcId = FundingSourceId;
    request.bankAccRefId = bankRefID;
    request.hotelID = hid;
    const url = `/Accounting/saveFundingSourceHotel`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static deleteFundingSource = async (
    hid,
    bankRefID
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.hotelID = hid;
    request.bankRefID = bankRefID;
    const url = `/Accounting/DeleteUnconfirmedFundingSource`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static IsPrimaryAccEXists = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/IsPrimaryAccEXists`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  //Reconnect bank account with plaid

  public static ReconectHotelLinkToken = async (
    bankRefID: any
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.bankAccRefId = bankRefID;
    const url = `/Accounting/ReconectHotelLinkToken`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static UpdateNewAccessToken = async (
    bankRefID,
    hid,
    Access_token
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.AccessToken = Access_token;
    request.bankRefID = bankRefID;
    request.hotelID = hid;
    const url = `/Accounting/UpdateNewAccessToken`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  //disablACH

  public static CheckUnpaidDigitalACH = async (
    bankRefID,
    hid
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.bankRefID = bankRefID;
    request.hotelID = hid;
    const url = `/Accounting/CheckUnpaidDigitalACH`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static CustomerFundingSourceUpdate = async (
    bankRefID,
    hid,
    status
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.bankRefID = bankRefID;
    request.hotelID = hid;
    request.createdStatus = status;
    const url = `/Accounting/CustomerFundingSourceUpdate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static PlaiddSetupStatus = async (
    bankRefID,
    hid
  ): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.bankRefID = bankRefID;
    request.hotelID = hid;
    const url = `/Accounting/PlaiddSetupStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetRolePermission_Ach = async (hid): Promise<any[] | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    request.hotelID = hid;
    const url = `/Accounting/GetRolePermission_Ach`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };

  //creadit card
  public static getCreditCardDetails = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/SaveCreditCardDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static SaveCreditCardDetails = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/SaveCreditCardAcct`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static PrintSampleCheck = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;

    const url = `/Accounting/PrintSampleCheck`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let file = new Blob([response.data], { type: "application/pdf" });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    );
  };

  public static OTPForAuthentication = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;

    const url = `/OTP/SendOTP`;

    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static ValidateOTP = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;

    const url = `/OTP/ValidateOTP`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static GetAccountOverviewStat = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetAccountOverviewStat`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetAccountOverviewUnapprovedVendor = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetAccountOverviewUnapprovedVendor`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static GetAccountOverviewUnpaidInvoice = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetAccountOverviewUnpaidInvoice`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetAccountOverviewFlaggedTransaction = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetAccountOverviewFlaggedTransaction`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetAccountOverviewUnapprovedInvoice = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetAccountOverviewUnapprovedInvoice`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static GetAccountOverviewDraftInvoice = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetAccountOverviewDraftInvoice`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static GetReconcileOverview = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetReconcileOverview`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetAccountOverviewTransReview = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    const request: any = {};
    request.tenantID = tenantID;
    const url = `/Accounting/GetAccountOverviewTransReview`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };

 
  /*New Dwolla Method for Micro-deposits*/
  public static SaveFundingSourceDetails = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let username = storage === null ? 0 : storage.userName;
    request.tenantID = tenantID;
    request.LoginUserName = username;

    const url = `/Accounting/AddDwollaFundingSource`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static SaveVerifyMicroDeposits = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;

    const url = `/Accounting/VerifyMicroDeposit`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static GetFundingSource = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Accounting/GetFundingSource`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetFundingSourceStatusByHid = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Hotel/GetFundingSourceStatusByHid`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static EnableFundingSource = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;

    const url = `/Accounting/EnableFundingSource`;
    return Instense.post(url, request).then((response: any) => {
      let result = response.data.result;
      return result;
    });
  };



    /////////////////////////////// Search Functions /////////////////////////////////////
    public static AccountSearchHotels = async (): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      const request: any ={};
      request.searchType = '""';
      request.searchVal = '""';
      request.tenantID = tenantID;
      const url = `/Search/AccountSearchHotels`;
      return Instense.get(url, {params:request}).then((response) => {
        let result = response.data.result;
        return result;
      });
    }


    public static AccountSearchFilterALLType = async (): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      const request: any ={};
      request.searchType = '""';
      request.searchVal = '""';
      request.tenantID = tenantID;
      const url = `/Search/AccountSearchFilterALLType`;
      return Instense.get(url, {params:request}).then((response) => {
        let result = response.data.result;
        return result;
      });
    }

    public static AccountSearchResult = async (request: any): Promise<any | null> => {
      const url = `/Search/AccountSearchFilterResult`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result;
        return result;
      });
    }

    public static SaveSearchExportByEHID = async (request: any): Promise<any | null> => {
      const url = `/Search/SaveSearchExportByEHID`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result;
        return result;
      });
    }

    /////////////////////////////// Search Functions /////////////////////////////////////

    public static sendPublicTokenData = async (
      public_token, accountID
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      let request: any ={};
      request.publicToken = public_token; 
      request.tenantID = tenantID;
      request.accountid = accountID;
      const url = `/Accounting/SetupPlaidAccessToken`;
      return Instense.get(url, {params:request}).then((response) => {
        let result = response.data.result;
        return result;
      });
    };


    public static getPlaidTransactionLog = async(): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      const request: any = {};
      request.tenantID = tenantID;
      const url = `/Accounting/GetPlaidTransactionLog`;
      return Instense.get(url, {params:request}).then((response) => {
        let result = response.data.result;
        return result;
      });
    };
    public static sendPublicTokenOprData = async (
      public_token, accountID, OprID, PullTranDate, callType
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      let request: any ={};
      request.PublicToken = public_token; 
      request.TenantId = tenantID;
      request.Accountid = accountID;
      request.oprID = OprID;
      request.PullTranDate = PullTranDate;
      const url = `/Accounting/SetupPlaidAccessTokenOprID`;
      return Instense.get(url, {params:request}).then((response) => {
        let result = response.data.result;
        return result;
      });
    };
    public static PlaidSetupDetails = async (
      request: any
    ): Promise<any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.tenantID = tenantID;
      const url = `/Accounting/PlaidSetupDetails`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static GetFeatureFlagForTenant = async (): Promise<any[] | null> => {
      let request: any={};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.tenantID = tenantID;
      const url = `/Accounting/GetFeatureFlagForTenant`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };
    public static AdvancedAccounting = async (): Promise<any[] | null> => {
      let request: any={};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      const url = `/Accounting/GetAdvancedAccounting`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };
    public static ReconectBank = async (
      HID: any, OPRID: any
    ): Promise<any[] | null> => {
      let request: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      request.HID = HID;
      request.OPRID = OPRID;
      const url = `/Accounting/ReconectBank`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static UpdateNewBankAccessToken = async (
      OPRID,
      hid,
      Access_token
    ): Promise<any[] | null> => {
      let request: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.tenantID = tenantID;
      request.AccessToken = Access_token;
      request.OPRID = OPRID;
      request.hotelID = hid;
      const url = `/Accounting/UpdateNewBankAccessToken`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static GetInstitutionName = async (
      request: any
    ): Promise<IAccountResponseDto | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      let username = storage === null ? 0 : storage.userName;
      request.TenantID = tenantID;
      request.LoginUserName = username;
      const url = `/BankSync/GetInstitutionName`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data;
        return result;
      });
    };

    public static checkConnectionName = async (
      request: any
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      const url = `/BankSync/checkConnectionName`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data;
        return result;
      });
    };

    public static InitializePlaidSetup = async (
      public_token, accountID, connectionName, bankName, selectedOprIDs
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      let request: any ={};
      request.PublicToken = public_token; 
      request.TenantId = tenantID;
      request.Accountid = accountID;
      request.ConnectionName = connectionName;
      request.BankName = bankName;
      request.OprIDs = selectedOprIDs;
      const url = `/BankSync/InitializePlaidSetup`;
      return Instense.get(url, {params:request}).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static ContinuePlaidSetup = async (
      bankName
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      let request: any ={};
      request.TenantId = tenantID;
      request.BankName = bankName;
      const url = `/BankSync/ContinuePlaidSetup`;
      return Instense.get(url, {params:request}).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static SaveBankPlaidMapping = async (
      request: any
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
  
      const url = `/BankSync/SaveBankPlaidMapping`;
      return Instense.post(url, request).then((response: any) => {
        let result = response.data.result;
        return result;
      });
    };

    public static GetConnectionList = async (
      request: any
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      const url = `/BankSync/GetConnectionList`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data;
        return result;
      });
    };

    public static GetBankUpdatedDetails = async (
      ConnectionID: any, connectionType: string
    ): Promise<any[] | null> => {
      let request: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      request.ConnectionID = ConnectionID;
      request.ConnectionType = connectionType;
      const url = `/BankSync/GetBankUpdatedDetails`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data;
        return result;
      });
    };

    public static InitializePlaidUpdateMode = async (
      ConnectionID: any, connectionType: string
    ): Promise<any[] | null> => {
      let request: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      request.ConnectionID = ConnectionID;
      request.ConnectionType = connectionType;
      const url = `/BankSync/InitializePlaidUpdateMode`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static InitializePlaidReconnect = async (
      ConnectionID: any, connectionType: string
    ): Promise<any[] | null> => {
      let request: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      request.ConnectionID = ConnectionID;
      request.ConnectionType = connectionType;
      const url = `/BankSync/InitializePlaidReconnect`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static UpdateBankConnectionStatus = async (
      OPRID, Access_token
    ): Promise<any[] | null> => {
      let request: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.tenantID = tenantID;
      request.AccessToken = Access_token;
      request.OPRID = OPRID;
      const url = `/BankSync/UpdateBankConnectionStatus`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static RemovePlaidConnection = async (
      CID : Number, OprId : Number, ActionType: string
    ): Promise<any[] | null> => {
      let request: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
      request.ConnectionID = CID;
      request.oprID = OprId;
      request.CallType = ActionType;
      const url = `/BankSync/RemovePlaidConnection`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };

    public static LogConnectionProcess = async (
      selectedOprIDs, ExceptionMethod, reqObj, ExceptionMsg
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let username = storage === null ? "" : storage.userName;
      let tenantID = storage === null ? 0 : storage.tenantID;
      let request: any ={};
      request.Tenantid = tenantID;
      request.ExceptionMethod = ExceptionMethod;
      request.ExceptionMsg = ExceptionMsg;
      request.RequestObj = reqObj;
      request.CreatedBy = username;
      request.OprID = selectedOprIDs;
      const url = `/BankSync/LogConnectionProcess`;
      return Instense.post(url, request).then((response: any) => {
        let result = response.data.result;
        return result;
      });
    };

    public static SaveTranPullDate = async (
      request: any
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID;
      request.TenantID = tenantID;
  
      const url = `/BankSync/SaveTranPullDate`;
      return Instense.post(url, request).then((response: any) => {
        let result = response.data.result;
        return result;
      });
    };
}
