/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // Link,
  Redirect,
} from "react-router-dom";
import { Container, Dropdown, Form, Row, Col, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../../../Common/Components/PageLoader";
import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import profile from "../../../../Common/Assets/Images/profile1.png";
import notesIcon from "../../../../Common/Assets/Images/icons/notes-icon.png";
import PerformanceScheduleDetails from "../Performance/PerformanceScheduleDetails";
import PerformanceSlideoutChild from "./PerformanceSlideoutChild";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import DatePicker from "react-datepicker";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { SingleSelectSearchWithImage } from "../../../../Common/Components/SingleSelectSearchWithImage";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import moment from "moment";
import {
  ILaborPerformaceRequest,
  ILaborPerformaceBudgetRequest,
} from "../../../../Common/Contracts/ILaborPerformance";
import { AddEntryModal } from "../Schedule/Modals & SlideOut/AddEntryModal";
import { LaborTimeSheet } from "../../../../Common/Services/LaborTimeSheet";
import { Utils } from "./../../../../Common/Utilis";
import _ from "lodash";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import "./Performance-slideout.scss";
import TableSearch from "../../../../Common/Components/TableSearch";

const { SearchBar } = Search;
export class PerformanceSlideout extends React.Component<any, any> {
  private singleSelect: any;
  private firstSingleSelect: any;
  private childActual: any;
  private childSchedule: any;
  private ddlNoShow: any;

  constructor(props: any) {
    //debugger;
    super(props);

    this.singleSelect = React.createRef();
    this.firstSingleSelect = React.createRef();
    this.childActual = React.createRef();
    this.childSchedule = React.createRef();
    this.ddlNoShow = React.createRef();
    this.state = {
      topHeaderDetails: {
        totalHours: "00:00",
        scheduledHours: "00:00",
        varianceHours: "00:00",
        breakHours: "00:00",
        overTimeHours: "00:00",
        doubleOverTimeHours: "00:00",
        budgetedHours: "00:00",
        isCaliforniaRule: false,
        shouldHightLightTotalvariance: false,
      },
      mainTableData: [],
      filteredData: [],
      hotelID: this.props.DataParameter?.HotelId,
      hotelName: this.props.DataParameter?.HotelName,
      positionId: this.props.DataParameter?.PositionId,
      departmentID: this.props.DataParameter?.DeptId,
      employee: this.props.DataParameter.Employee,
      parentName: this.props.parentName,
      date: this.props.DataParameter.Date,
      exportedDate: "",
      payPeriodDates: [],
      tableExpended: [],
      userRow: [],
      isExpand: false,
      loaderTrue: false,
      field: "variance",
      order: "asc",
      actualvariance: 0,
      isPTOdata: false,
      isOption: false,
      showAddEntryModal: false,
      minDate: new Date(),
      maxDate: new Date(),
      addEntryState: {},
      isDateapprove: "Unapprove",
      managesmileid: "Yes",
      lockStatus: "",
      approvePermission: "No",
      exportPermission: "No",
      exportedPayroll: "No",
      unApprovePermission: "No",
      mainTitle: "",
      sortOptions: [
        "Name",
        "Position",
        "First Punch-In Time",
        "Variance",
        "Total",
      ],
      selectedSortOption: "Name",
      isemployeeSlideOut: this.props.DataParameter?.userName ? true : false,
      breadDepartments: [],
      breadPositions: [],
      tableColumns: [],
      isRedirect: false,
      isEdit: false,
      budgetInMinutes: this.props?.DataParameter?.budgetedHours,
      reloadPerformanceData:false,
      noShowomment: "",
      mainPerformenceData: this.props.parentState,
      noShowCountList: [],
      isPayrollExported : false
    };
    this.closeSlideOut = this.closeSlideOut.bind(this);
  }

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (this.state.tableExpended.length > 0) {
      this.setState({ deptRow: {}, isExpand: false });
      this.setState(() => ({ tableExpended: [] }));
    } else {
      //row.action = "Expended";
      this.setState({ deptRow: row, isExpand: true });
      this.setState(() => ({ tableExpended: [row.scheduleNo] }));
      //scrollIntoView(e.currentTarget, { align: { top: 0, topOffset: 70 } });
    }
  };

  getTitle = () => {
    if (this.state.mainTitle) {
      return this.state.mainTitle;
    } else {
      const { userName, positionName, deptName } = this.props.DataParameter;
      return userName ? userName : positionName || deptName;
    }
  };

  noShowCommentDropdown = () => {
    $("#performance-dropdown-wrapper .dropdown-menu").hide();
    $(".notes-btn").removeClass("show");
  }

  columns = [
    {
      dataField: "userName",
      text: "User",
      formatter: (cell: any, row: any, rowIndex: any) => (       
        <div className="user-profile">
          <div className="d-flex">
            {row.imagePath !== null && (
              <div>
                {row.imagePath.length === 0 && (
                  <img
                    src={profile}
                    className="profileimg"
                    width="36"
                    height="36"
                    alt=""
                  />
                )}
                {row.imagePath.length > 0 && (
                  <img
                    src={row.imagePath}
                    className="profileimg"
                    width="36"
                    height="36"
                    alt=""
                  />
                )}
              </div>
            )}
            {row.imagePath === null && (
              <div>
                <img
                  src={profile}
                  className="profileimg"
                  width="36"
                  height="36"
                  alt=""
                />
              </div>
            )}
            <div className="d-flex align-items-center username">
              <div className={`main-ttl width-set ${row?.positionName.length > 18 ? "set-max-width" : ""}`}>
                <div><EllipsisWithTooltip placement="bottom">{row.userName}</EllipsisWithTooltip></div>
                <div>
                <EllipsisWithTooltip placement="bottom">
                  <div className="sub-ttl">{row?.positionName}</div>
                </EllipsisWithTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.action === "Expended")
            this.handleOnExpand(row, false, rowIndex, e);
          else this.handleOnExpand(row, true, rowIndex, e);
        },
      },
    },
    {
      dataField: "positionName",
      text: "positionName",
      sort: true,
      hidden: true,
    },
    {
      dataField: "deptName",
      text: "deptName",
      hidden: true,
      sort: true,
    },

    {
      dataField: "actualTotalhours",
      text: "Shift",
      formatter: (cell: any, row: any, rowIndex: any) => {
        console.log(row?.LaborActualCommentNoShow);
        return (
          <div className="bdr-rightt">
            <div className="main-ttl">
              {
                moment(row?.actualInDate).format("hh:mm A") !== "0:00" &&
                row?.actualInDate &&
                moment(row?.actualOutDate).format("hh:mm A") !== "0:00" &&
                row?.actualOutDate ? (
                  <>
                    {moment(row?.actualInDate).format("hh:mm A")} -{" "}
                    {moment(row?.actualOutDate).format("hh:mm A")}
                  </>
                ) : row?.actualInDate && !row?.actualOutDate ? (
                  `${moment(row?.actualInDate).format("hh:mm A")} - }`
                ) : !row?.actualInDate && Number(row.IsnoShow) === 1 ? (

                  // <span className="badge color-red">No Show</span>

                  <OutsideClickHandler onOutsideClick={(e: any) => this.noShowComentPoupOutSideClick(row.ddlNoShowRef)}>
                  <div>
                    <Dropdown
                      className={"more-action add-note-dropdown "}
                      alignRight
                      onClick={(e: any) => e.stopPropagation()}
                    >
                      <Dropdown.Toggle id="dropdown-more" className="p-0" ref={row.ddlNoShowRef}>
                        <div className="red-badges red-bg">No Show</div>
                      </Dropdown.Toggle >
                      <Dropdown.Menu   >
                        <div className="dropdown-header">
                          <div className="dropdown-ttl">Add No Show comments</div>
                          <Dropdown.Item className="cross-btn closeShowNotes" href=""    >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                fill="#84888C"
                              />
                            </svg>
                          </Dropdown.Item>
                        </div>
                        <div className="dropdown-body">
                          <Form.Group>
                            <Form.Label className="dropdown-ttl">Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              placeholder="Add comments here..."
                              maxLength={(200)}
                              // value={row.assocaiteedDateNotes}
                              // id={"txtchangenotes" + rowIndex}
                              onBlur={(e: any) =>
                                this.NoShowNotesChange(e, row, rowIndex)
                              }                            
                            />
                          </Form.Group>
                        </div>
                        <div className="action-link dropdown-footer">
                          <Button
                            className="btn btn-primary"
                            //ref={this.btnAssociateSave}
                            // id={"btnchange" + rowIndex}
                            onClick={() =>
                              this.saveNoShowComment(row, rowIndex)
                            }
                          >
                            Submit
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </OutsideClickHandler>


                ) : (
                  <span></span>
                )

                // {/* {row?.actualInDate && !row?.actualOutDate ?

                //   <>   {moment(row?.actualInDate).format("hh:mm A")} -{" "}</> :
                //   <span className="badge color-red">No Show</span>
                // } */}
              }
            </div>
            <div className="sub-ttl">
              {row?.scheduleInTime !== "0:00" &&
              row?.scheduleOutTime !== "0:00" ? (
                <>
                  {row?.scheduleInTime} - {row?.scheduleOutTime}
                </>
              ) : (
                <span className="badge color-blue">Manual</span>
              )}
            </div>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.action === "Expended")
            this.handleOnExpand(row, false, rowIndex, e);
          else this.handleOnExpand(row, true, rowIndex, e);
        },
      },
    },
    {
      dataField: "actualTotalhours",
      text: "Totals",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <>
          <div className="">
            <div className={"main-ttl " + (row.showred ? "color-red" : "")}>
              {row?.actualTotalhours}
            </div>
            <div className="sub-ttl">{row?.scheduleTotalhours}</div>
          </div>
        </>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.action === "Expended")
            this.handleOnExpand(row, false, rowIndex, e);
          else this.handleOnExpand(row, true, rowIndex, e);
        },
      },
    },
    {
      dataField: "variance",
      text: "Variance",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="">
          <div className={"main-ttl " + (row.showred ? "color-red" : "")}>
            {row?.variance}
          </div>
        </div>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.action === "Expended")
            this.handleOnExpand(row, false, rowIndex, e);
          else this.handleOnExpand(row, true, rowIndex, e);
        },
      },
    },
    {
      dataField: "ot",
      text: "Overtime",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="">
          <div className={"main-ttl " + (cell !== "00:00" ? "color-red" : "")}>
            {" "}
            {cell === "00:00" ? "-" : cell}
          </div>
        </div>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.action === "Expended")
            this.handleOnExpand(row, false, rowIndex, e);
          else this.handleOnExpand(row, true, rowIndex, e);
        },
      },
    },


    {
      dataField: "",
      text: "",
      // hidden: this.props.data.ruleType === "Standard Rule" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (     
        
         <OutsideClickHandler onOutsideClick={(e: any) => this.noShowComentOutSideClick(row?.ddlShowCommentRef)}>
          <div className="d-flex" id="performance-dropdown-wrapper"
          onClick={(e: any) => e.stopPropagation()}
          >
            {row?.LaborActualCommentNoShow.length > 0 &&  (
              <Dropdown className="more-action notes-btn performance-comment-dropdown" alignRight
                onMouseLeave={this.noShowCommentDropdown}>
                <Dropdown.Toggle
                  className="btn-outline-primary  more"
                  id="dropdown-more"
                 ref={row?.ddlShowCommentRef}
                >
                  <div className="d-flex">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                      <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                    </svg>
                    <span>{row?.LaborActualCommentNoShow.length}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu renderOnMount>
                  <div className="notes-sec">
                    <div className="notes-list">
                      {row?.LaborActualCommentNoShow.map((item: any, idx: any) => (
                        <div className="comment-list">
                          <div className="d-flex profile-details">
                            <div className="profile-img">
                              <img
                                src={
                                  item.imagepath === null
                                    ? profile
                                    : item.imagepath === ""
                                      ? profile
                                      : item.imagepath
                                }
                                className={
                                  item.imagepath === null
                                    ? "default"
                                    : item.imagepath === ""
                                      ? "default"
                                      : ""
                                }
                                width="40"
                                height="40"
                                alt={item.username}
                                onError={(e) => (e.currentTarget.src = profile)}
                              />
                            </div>
                            <div>
                              <div className="main-ttl">{item.username}</div>
                              <div className="sub-ttl">
                                {Utils.getDateTimeMddyyFromUtc(item.commentdate)}
                              </div>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <div className="d-flex">
                                <div className="detail-sec">
                                  <div className="comments">{item.comments}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>

        </OutsideClickHandler> 


      ),
      footer: (columnData: any) => "",
    },




    {
      dataField: "action",
      text: "",

      editable: false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <>
          {this.state.isemployeeSlideOut == false && (

<OutsideClickHandler onOutsideClick={(e: any) => this.noShowOutSideClick(row?.ddlChangeLogRef)}>
            <Dropdown className="more-action" alignRight
            onClick={(e: any) => e.stopPropagation()}
            >
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more"
                id="dropdown-more"
                ref={row?.ddlChangeLogRef}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e: any) =>
                    this.loadEmployeeDetails(e, row.userName)
                  }
                  eventKey={"EmployeeDetails"}
                >
                  Employee Details
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </OutsideClickHandler>
          )}
        </>
      ),
    },
  ];

  getDoubleOverTimeColumn = () => {
    return {
      dataField: "dot",
      text: "DOT",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="">
          <div
            className={"main-ttl " + (row?.dot !== "00:00" ? "color-red" : "")}
          >
            {" "}
            {row.dot === "00:00" ? "-" : row.dot}
          </div>
        </div>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.action === "Expended")
            this.handleOnExpand(row, false, rowIndex, e);
          else this.handleOnExpand(row, true, rowIndex, e);
        },
      },
    };
  };

  componentDidMount = () => {   
    this.setState({ tableColumns: this.columns });
    this.getLaborPerformance();

    if (this.props.DataParameter.Date) {
      this.getTimeSheetDatewithPermission(
        this.props.DataParameter.HotelId,
        this.props.DataParameter.Date
      );
      this.getPayPeriod();
    }
  };

  resetTopHeaderDetails = () => {
    const topHeaderDetails: any = {
      totalHours: "00:00",
      scheduledHours: "00:00",
      varianceHours: "00:00",
      breakHours: "00:00",
      overTimeHours: "00:00",
      doubleOverTimeHours: "00:00",
      budgetedHours: "00:00",
      isCaliforniaRule: false,
      shouldHightLightTotalvariance: false,
    };
    this.setState({ topHeaderDetails });
  };
  //  GetLaborPerformanceBudget

  fetchPerformanceData = (positionId = 0, departmentID = 0, employee = "") => {
    let mainTitle = this.state.mainTitle;
    if (positionId === 0 && departmentID > 0 && !employee) {
      mainTitle = this.state.breadDepartments.find(
        (item) => item?.id === departmentID
      )?.name;
    }

    if (positionId > 0 && departmentID > 0 && !employee) {
      mainTitle = this.state.breadPositions.find(
        (item) => item?.id === positionId
      )?.name;
    }
    this.setState({ positionId, departmentID, employee, mainTitle , isemployeeSlideOut: false }, () => {
      if (
        this.props.DataParameter.showBudgetedHours &&
        !this.state?.isemployeeSlideOut
      ) {
        this.GetLaborPerformanceBudget();
      } else {
        this.getLaborPerformance();
      }
    });
  };

  getPayPeriod() {
    LaborPerformance.getPayPeriodDates(
      this.props.DataParameter?.HotelId,
      this.props.DataParameter?.Date,
      "Weekly"
    ).then(async (result: any[] | null) => {
      if (result != null) {
        let addEntryState = {
          calenderDates: [result[0].startdate],
          hidValue: this.props.DataParameter?.HotelId,
          payPeriodDates: [result[0].startdate, result[0].enddate],
          exportedDate: result[0].exporteddateMMMMDDYYYY,
          showAddEntryModal:true
        };
        this.setState({
          addEntryState,
          payPeriodDates: [result[0].startdate, result[0].enddate],
          exportedDate: result[0].exporteddateMMMMDDYYYY,
        });
      }
    });
  }

  toggleLoader = () => {
    this.setState({ loaderTrue: !this.state.loaderTrue });
  };

  getTimeSheetDatewithPermission(hotelID: Number, selectedDate: any) {
    LaborTimeSheet.TimeSheetDatewithPermission(hotelID, selectedDate).then(
      async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            approvePermission: result[0].approvePermission,
            exportPermission: result[0].exportPermission,
            exportedPayroll: result[0].exportedPayroll,
            unApprovePermission: result[0].unApprovePermission,
            lockStatus: result[0].lockStatus,
          });
        }
      }
    );
  }

  loadEmployeeDetails = (e, username) => {
   // debugger;
    this.toggleLoader();
    const request: ILaborPerformaceRequest = {} as ILaborPerformaceRequest;
    request.date = this.state.date;
    request.hotelId = this.state.hotelID;
    request.deptId = 0;
    request.positionId = 0;
    request.username = username;

    LaborPerformance.GetLaborPerformance(request)
      .then((response: any) => {
       // debugger;
        this.setState(
          {
            isemployeeSlideOut: true,
            positionId: 0,
            departmentID: 0,
            employee: username,
            mainTitle: username,
          },
          () => {
            const { ScheduleNo } = this.props.DataParameter;
            let  result = response.result;
           
            if(this.props.DataParameter?.isCalenderLocked) {
              result = result.filter(
                (item) => item?.employeeType !== "ExcludefromPayroll"
              );
            }
            if(result.length > 0) {
              result = result.map(item => {
                item.adj = item?.adjMin;
                return item;
              } );
                 if (ScheduleNo) {
              result = result.filter(
                (item) => +item?.scheduleNo === +ScheduleNo
              );
                 
            }
            this.prepareMainTableData(result);
            this.buildDepartMentAndPositionBreadCrumbDDLData(result);
          }
        }
        );
      })
      .finally(() => {
        this.toggleLoader();
      });
  };

  buildDepartMentAndPositionBreadCrumbDDLData = (result) => {
    let breadDepartments: any[] = [];
    let breadPositions: any[] = [];
    if (result.length > 0) {
      breadDepartments = _.uniqBy(
        result
          .map((item) => ({ id: item.deptID, name: item.deptName }))
          .sort((a, b) => a.name - b.name),
        "name"
      );
      breadPositions = _.uniqBy(
        result
          .map((item) => ({
            id: item.position,
            name: item.empTitle,
            deptID: item?.deptID,
          }))
          .sort((a, b) => a.name - b.name),
        "name"
      );
    } else {
      breadDepartments = this.props.DataParameter?.breadDepartments;
      breadPositions = this.props.DataParameter?.breadPositions;
    }

    this.setState({ breadDepartments, breadPositions });
  };

  getLaborPerformance = (isBudgetReloaded = false) => {
    this.toggleLoader();
    this.resetTopHeaderDetails();
    const request: ILaborPerformaceRequest = {} as ILaborPerformaceRequest;
    const { positionId, departmentID, employee } = this.state;
    request.date = this.state.date;
    request.hotelId = this.props.DataParameter.HotelId;
    request.deptId = departmentID;
    request.positionId = positionId;
    request.username = employee;
    LaborPerformance.GetLaborPerformance(request)
      .then((response: any) => {
       // debugger;
        this.setState(
          {
            isemployeeSlideOut: request.username != "" ? true : false,
          },
          () => {
            let result = response.result;
            if (result.length > 0) {

              // result = result.map((x) => {
              //   x.ddlShowCommentRef = React.createRef();
              // x.ddlChangeLogRef = React.createRef();
              //   return x;
              // });

             

if(this.props.DataParameter?.isCalenderLocked) {
  result = result.filter(
    (item) => item?.employeeType !== "ExcludefromPayroll"
  );
}
              const { ScheduleNo } = this.props.DataParameter;
              if (ScheduleNo) {
                result = result.filter(
                  (item) => +item?.scheduleNo === +ScheduleNo
                );
              }
              result = result.map((item) => {
                item.adj = item?.adjMin;
                return item;
              });
            }

            this.prepareMainTableData(result, isBudgetReloaded);
            this.buildDepartMentAndPositionBreadCrumbDDLData(result);
          }
        );
      })
      .finally(() => {
        this.toggleLoader();
      });
  };

  GetLaborPerformanceBudget = () => {
    this.resetTopHeaderDetails();
    const request: ILaborPerformaceBudgetRequest = {} as ILaborPerformaceBudgetRequest;
    const { positionId, departmentID } = this.state;
    request.selectdate = this.state.date;
    request.hotelid = this.props.DataParameter.HotelId;
    request.deptid = departmentID;
    request.positionid = positionId;
    LaborPerformance.GetLaborPerformanceBudget(request)
      .then((response: any) => {
        this.setState({ budgetInMinutes: response[0].budget }, () =>
          this.getLaborPerformance(true)
        );
      })
      .finally(() => {
      });
  };

  handleSortChange = (selectedSortOption: string) => {
    let filteredData = [...this.state.filteredData];
    let sortKeyName = "";
    switch (selectedSortOption) {
      case "userName":
        sortKeyName = "userName";
        break;
      case "Position":
        sortKeyName = "positionName";
        break;
      case "First Punch-In Time":
        sortKeyName = "actualInDate";
        break;
      case "Variance":
        sortKeyName = "variance";
        break;
      case "Total":
        sortKeyName = "actualTotalhoursMinutes";
        break;
      default:
        sortKeyName = "userName";
        break;
    }
    filteredData = _.sortBy(filteredData, sortKeyName);

    this.setState({ filteredData, selectedSortOption });
  };

  calculateTopHeadersValues = (isBudgetReloaded = false): void => {
    const result = this.state.mainTableData;
    let topHeaderDetails = {
      totalHours: "00:00",
      scheduledHours: "00:00",
      varianceHours: "00:00",
      breakHours: "00:00",
      overTimeHours: "00:00",
      doubleOverTimeHours: "00:00",
      isCaliforniaRule: false,
      budgetedHours:
        (isBudgetReloaded)
          ? Utils.getMinutesInHHMMFormat(this.state.budgetInMinutes)
          : Utils.getMinutesInHHMMFormat(
              this.props?.DataParameter?.budgetedHours
            ),
      shouldHightLightTotalvariance: false,
    };
    let budgetedHours: any = "00:00";

    if (result?.length) {
      const totalHours = Utils.getMinutesInHHMMFormat(
        result
          .map((item) => +item?.actualTotalhoursMinutes)
          ?.reduce((a, b) => a + b)
      );
      const scheduledHours = Utils.getMinutesInHHMMFormat(
        result
          .map((item) => +item?.scheduleTotalhoursMinutes)
          ?.reduce((a, b) => a + b)
      );

      const overTimeHours = Utils.getMinutesInHHMMFormat(
        result?.map((item) => +item?.otMinutes)?.reduce((a, b) => a + b)
      );

      const doubleOverTimeHours = Utils.getMinutesInHHMMFormat(
        result?.map((item) => +item?.dotMinutes)?.reduce((a, b) => a + b)
      );
      let varianceHours: any = 0;
      let shouldHightLightTotalvariance = false;
      const totalHourMinutes = result
        .map((item) => +item?.actualTotalhoursMinutes)
        ?.reduce((a, b) => a + b);
      if (this.props.DataParameter.showBudgetedHours && !this.state.isemployeeSlideOut) {
        budgetedHours = Utils.getMinutesInHHMMFormat(
          this.state.budgetInMinutes
        );
        if (totalHourMinutes > +this.state.budgetInMinutes) {
          shouldHightLightTotalvariance = true;
        }
        varianceHours = totalHourMinutes - +this.state.budgetInMinutes;
      } else {
        const totalScheduleMinutes = result
          .map((item) => +item?.scheduleTotalhoursMinutes)
          ?.reduce((a, b) => a + b);

        if (totalHourMinutes > +totalScheduleMinutes) {
          shouldHightLightTotalvariance = true;
        }
        varianceHours = totalHourMinutes - totalScheduleMinutes;
      }
      varianceHours = Utils.getMinutesInHHMMFormat(varianceHours);
      // debugger;
      const isCaliforniaRule = result
        ?.map((item) => item?.rule)
        ?.includes("California Rule");
      let tableColumns = this.state.tableColumns;
      if (
        isCaliforniaRule &&
        tableColumns.filter((item) => item?.dataField === "dot")?.length === 0
      ) {
        tableColumns.push(this.getDoubleOverTimeColumn());
        const arrayLength = this.state.tableColumns?.length;
        const tmpItem = tableColumns[arrayLength - 1];
        tableColumns[arrayLength - 1] = tableColumns[arrayLength - 2];
        tableColumns[arrayLength - 2] = tmpItem;
        this.setState({ tableColumns });
      }

      const breakHours = Utils.getMinutesInHHMMFormat(
        result.map((item) => +item?.breakMinutes)?.reduce((a, b) => a + b)
      );

      topHeaderDetails = {
        totalHours,
        scheduledHours,
        varianceHours,
        breakHours,
        overTimeHours,
        doubleOverTimeHours,
        isCaliforniaRule,
        budgetedHours,
        shouldHightLightTotalvariance,
      };
    }

    this.setState({ topHeaderDetails });
  };

  prepareMainTableData = (result, isBudgetReloaded = false): void => {
if(result?.length > 0) {
 const  isEdit  = result?.filter(item => item?.isEdit  === "Yes")?.length > 0;
 this.setState({isEdit});
}
    const mainTableData = result.map((row) => {
      
      const item: any = {};
      item.scheduleNo = row?.scheduleNo;
      item.deptName = row?.deptName;
      item.deptID = row?.deptID;
      item.isemployeeSlideOut = this.state.isemployeeSlideOut;
      item.actualInTime = row?.actualInTime ? row?.actualInTime : "0:00";
      item.actualOutTime = row?.actualOutTime ? row?.actualOutTime : "0:00";
      item.rule = row?.rule;
      const data = row?.laborPunchDetails?.filter((r) => r?.actualOutTime);
      if (data.length > 1) {
        item.actualOutTime = data[data.length - 1]?.actualOutTime;
      }

      item.scheduleInTime = row?.scheduleInTime ? row?.scheduleInTime : "0:00";
      item.dot = Utils.getMinutesInHHMMFormat(row?.dot);
      item.dotMinutes = row?.dot;
      item.otMinutes = row?.ot;
      item.ot = Utils.getMinutesInHHMMFormat(row?.ot);
      item.scheduleOutTime = row?.scheduleOutTime
        ? row?.scheduleOutTime
        : "0:00";
      item.userName = row?.employee;
      item.positionID = row?.position;
      item.positionName = row?.empTitle;
      item.actualInDate = row?.actualInDate;
      item.actualOutDate = row?.actualOutDate;
      item.imagePath = row?.imagePath;
      item.IsnoShow = row?.noShow;
      item.date = moment(row?.scheduleInDate)?.format("DD/MM/YY");


      item.scheduleTotalhours = Utils.getMinutesInHHMMFormat(
        row?.totalScheduleHours
      );
      item.scheduleTotalhoursMinutes = row?.totalScheduleHours;
      if( +row?.adj > 0 || +row?.adj< 0) {
      item.actualTotalhours  =    Utils.getMinutesInHHMMFormat(row.totalActualHours  +  row?.adj);
      item.actualTotalhoursMinutes = row.totalActualHours  +  row?.adj; 
    }
     else { item.actualTotalhours = Utils.getMinutesInHHMMFormat(
        row?.totalActualHours
      );
      item.actualTotalhoursMinutes = row?.totalActualHours;
     }
    

      if (row?.adj > 0 || row?.adj < 0) {
        const newChildRow: any = {};
        newChildRow.actualInDate = "";
        newChildRow.actualInTime = "Manual";
        newChildRow.actualOutDate = "";
        newChildRow.actualOutTime = "";
        newChildRow.breakDuration = row?.adj;
        newChildRow.isManual = true;
        newChildRow.position = row?.empTitle;
        newChildRow.rowNo = 0;
        newChildRow.scheduleNo = row?.scheduleNo;
        newChildRow.totalActualHours = "";
        row?.laborPunchDetails.push(newChildRow);
      }

      let breakDuration = 0;
      if (row?.laborPunchDetails?.length > 0) {
        breakDuration = row?.laborPunchDetails
          ?.map((x) => x?.breakDuration)
          ?.reduce((a, b) => a + b);
      }

      item.laborPunchDetails = row?.laborPunchDetails;
      item.breakTotalhours = Utils.getMinutesInHHMMFormat(breakDuration);
      item.breakMinutes = breakDuration;
      item.variance = Utils.getMinutesInHHMMFormat((+item?.actualTotalhoursMinutes - +row?.totalScheduleHours));
      
      item.showred = +item?.actualTotalhoursMinutes > +row?.totalScheduleHours ? true : false;
      item.varianceMinutes = item.variance;
      item.varianceFlag = row?.varianceFlag;
      item.dayStatus = null;
      item.overTime = Utils.getMinutesInHHMMFormat(row?.ot);
      item.doubleOverTime = Utils.getMinutesInHHMMFormat(row?.dot);
      item.notesStatus = row?.notesStatus;
      item.userUniqueno = 2784;
      item.isPto = row?.isPTO;
      item.isPtorow = row?.isPtorow;
      item.isUto = row?.isUto;
      item.isUtorow = row?.isUtorow;
      item.totalSchedulerow = 2;
      item.actualNotes = row?.actualNotes;
      item.isDateapprove = row?.isDateApprove;
      item.varianceInper = row?.varianceInper;
      item.scheduleDaytotal = 0;
      item.actualDaytotal = 479;
      item.varianceInmin = 479;
      item.breakVariance = "0:00";
      item.nowShow = "";
      item.apo = row?.apo;
      item.manuals = row?.manuals;
      item.pto = row?.isPTO ? "Yes" : "No";
      item.uto = row?.isUTO ? "Yes" : "No";
      item.otf = "";
      item.actualvariance = 5;
      item.opens = "";
      item.timeOff = row?.timeOff;
      item.timeOffRow = row?.timeOffRow;
      item.tenantID = null;
      item.isedit = row?.isEdit;
      item.managesmileid = row?.manageSmileID;
      item.lockstatus = row?.lockStatus;
      item.LaborActualCommentNoShow = row?.laborActualCommentNoShow;
      item.scheduleInDate1 = moment(row?.scheduleInDate)?.format("MM/DD/YYYY");
      item.ddlShowCommentRef = React.createRef();
      item.ddlChangeLogRef = React.createRef();
      item.ddlNoShowRef = React.createRef();
      item.noShowCnt = row?.noShowCnt;
      item.isConCusativeDay = row?.isConCusativeDay;
      
      return item;
    });
    
    this.setState(
      { mainTableData, filteredData: _.sortBy(mainTableData, "userName") },

      () => {
        this.calculateTopHeadersValues(isBudgetReloaded);
      }
    );
  };

  handleSelectDate = (date: any) => {
    this.updateDate(moment(date).format("MM/DD/YY"));
  };

  handleDateIncrement = () => {
    let date = this.state.date;
    const nextDate = moment(date).add(1, "days").format("MM/DD/YY");
    if(  moment(nextDate).isAfter(moment().format("MM/DD/YY"))) {
      return;
    }
    this.updateDate(nextDate);
  };
  
  handleDateDecrement = () => {
    let date = this.state.date;
    this.updateDate(moment(date).subtract(1, "days").format("MM/DD/YY"));
  };

  updateDate = (date) => {
    this.setState({ date }, () => {

        if (this.props.DataParameter.showBudgetedHours && !this.state?.isemployeeSlideOut) {
          this.GetLaborPerformanceBudget();
        } else {
          this.getLaborPerformance();
      }
    });
  };

  onDepartmentChange = (department: any) => {
    const breadDepartments = this.state.breadDepartments.filter(
      (item) => +item.id === +department?.id
    );
    this.setState({ mainTitle: department?.name, breadDepartments }, () => {
      this.fetchPerformanceData(0, +department?.id, "");
    });
  };

  onPositionChange = (position: any) => {
    const { breadDepartments, breadPositions } = this.state;
    const _breadPositions = breadPositions.filter(
      (item) => +item.id === +position?.id
    );
    const _breadDepartments = breadDepartments.filter(
      (item) => +item.id === +position?.deptID
    );
    this.setState(
      {
        mainTitle: position?.name,
        breadPositions: _breadPositions,
        breadDepartments: _breadDepartments,
      },
      () => {
        this.fetchPerformanceData(position.id, +position?.deptID, "");
      }
    );
  };
  closeSlideOut() {
    this.props.hideSlideOut(this.state.reloadPerformanceData);
  }
  showAddEntryModal = (e: any) => {
    // let hid = this.state.hidValue
    this.setState({
      showAddEntryModal: true,
      isRefresh: false,
    });
  };

  hideAddEntryModal = () => {
    this.setState({
      showAddEntryModal: false,
    });
  };

  addEntryRefresh = () => {
    this.getLaborPerformance();
    this.setState({
      showAddEntryModal: false,
      isRefresh: true,
      reloadPerformanceData:true
    });
  };

  resetList = () => {
    this.getLaborPerformance();
  };

  setReloadPerformanceData = () =>{
    this.setState({reloadPerformanceData:true});
  }
  openOverView() {
    this.setState({ isRedirect: true });
    localStorage.setItem("mainMenu", "Labor");
    localStorage.setItem("subMenu", "Payroll");
  }


  NoShowNotesChange = (e: any, row: any, index: any) => {
    let txtValue = e.target.value;
    this.setState({
      noShowomment: txtValue,
    });
  };
  noShowOutSideClick = (ref) => {
    if(ref!=undefined){
    if (ref.current?.ariaExpanded == "true") {
      ref.current.click();
    }
  }
  }

  noShowComentOutSideClick = (ref) => {
   
    if(ref!=undefined){
    if (ref.current?.ariaExpanded == "true") {
      ref.current.click();
    }
  }
  }

  noShowComentPoupOutSideClick = (ref) => {
    
    if(ref!=undefined){
    if (ref.current?.ariaExpanded == "true") {
      ref.current.click();
    }
  }
  }

  
  saveNoShowComment = (row: any, index: any) => {     
//debugger;

this.getNowShowCount(row, index,"");

//  if (row?.isConCusativeDay==="Yes") {
//       this.isValidateModelPoupConsecutiveDays(row, index, row?.noShowCnt);
//     }else{
//      this.SaveNoShowCommentDetails(row, index, "No");
//     }
    //  if(this.ddlNoShow.current!==null){
    //   this.ddlNoShow.current.click();
    //  }   
  }

  isCancusativesaved = (row: any, index: any) => {
    this.SaveNoShowCommentDetails(row, index, "Yes");
  }
  ClosePoup = (row: any, index: any) => {
    this.SaveNoShowCommentDetails(row, index, "No");
    reject();
  }

  isValidateModelPoupConsecutiveDays(row: any, index: any, NumberOfNoShowcount: any) {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "This employee has been a No Show for " + NumberOfNoShowcount + " consecutive days. Would you like to apply this comment for the additional days?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isCancusativesaved(row, index),
        },
        {
          label: "No",
          onClick: () => this.ClosePoup(row, index),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  getNowShowCount=(row: any, index: any, Type: any)=>{
   // debugger;   
  
    LaborPerformance.CheckNoShowComment(row?.userName,row?.scheduleInDate1, this.state.hotelID)
    .then(async (result: any[] | null) => {
      if (result!==null) {
      //  debugger;
        if (result.length > 0) {
        this.setState({noShowCountList:result});
        if(result[0].noshowCount > 1){
          this.isValidateModelPoupConsecutiveDays(row, index, result[0].noshowCount);
        }else{
          this.SaveNoShowCommentDetails(row, index, "No");
        }
        }
  
        } else {
          //this.btnhHandleSave.current.disabled = false;
         // Utils.toastError(result.result.message);
        }
     
  
      resolve();
    })
    .catch((err) => {
      //this.btnhHandleSave.current.disabled = false;
      Utils.toastError(`Server Error: ${err}`);
      reject();
    });
  }

  SaveNoShowCommentDetails = (row: any, index: any, Type: any) => {
  //  debugger;
   
    let saveNoShowComment: any[] = [];    
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
   


    let data=[...this.state.noShowCountList];

    if (Type === "Yes") {

      for (let i = 0; i < this.state.noShowCountList.length; i++) {
        //let nodhowDate = this.state.dayDetails[i].timesheet[0];
        //let nodhowDate = this.state.dayDetails[i];
       // for(let y =0;y< nodhowDate.timesheet.length;y++ ){       
        
          let newItem: any = {};
          newItem.tenantID = tenantID;
          newItem.hotelID = this.state.hotelID;
          newItem.employeeName = data[i].employee;
          newItem.selectDate = data[i].date;
          newItem.scheduleId = data[i].scheduleID;
          newItem.entryType = "PerforMance";
          newItem.notes = this.state.noShowomment;
          saveNoShowComment.push(newItem);
        
     // }
    }
    }
    if (Type === "No") {
      

    //   for (let i = 0; i < this.state.noShowCountList.length; i++) {       
    //       let newItem: any = {};
    //       newItem.tenantID = tenantID;
    //       newItem.hotelID = this.state.hotelID;
    //       newItem.employeeName = data[i].employee;
    //       newItem.selectDate = data[i].date;
    //       newItem.scheduleId = data[i].scheduleID;
    //       newItem.entryType = "PerforMance";
    //       newItem.notes = this.state.noShowomment;
    //       saveNoShowComment.push(newItem);
        
    
    // }

    if (Number(row?.IsnoShow) === 1  && !row?.actualInDate) {
              let newItem: any = {};
              newItem.tenantID = tenantID;
              newItem.hotelID = this.state.hotelID;
              newItem.employeeName = row?.userName;
              newItem.selectDate = row?.scheduleInDate1;
              newItem.scheduleId = row?.scheduleNo;
              newItem.entryType = "PerforMance";
              newItem.notes = this.state.noShowomment;
              newItem.isConcusativeDay = Type;
              saveNoShowComment.push(newItem);
            }

    }

  //   //if (Type === "No") {
    
  //     // for (let i = 0; i < this.state.mainTableData.length; i++) {
  //     //   let nodhowDate = this.state.mainTableData[i];
  //       if (Number(row?.IsnoShow) === 1  && !row?.actualInDate) {
  //         let newItem: any = {};
  //         newItem.tenantID = tenantID;
  //         newItem.hotelID = this.state.hotelID;
  //         newItem.employeeName = row?.userName;
  //         newItem.selectDate = row?.scheduleInDate1;
  //         newItem.scheduleId = row?.scheduleNo;
  //         newItem.entryType = "PerforMance";
  //         newItem.notes = this.state.noShowomment;
  //         newItem.isConcusativeDay = Type;
  //         saveNoShowComment.push(newItem);
  //       }
  //    // }
  //  // }



    LaborPerformance.SaveLaborNoShowComments(saveNoShowComment)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.addEntryRefresh();
            //this.btnhHandleSave.current.disabled = false;
           // this.setState({ isDataChange: false,noShowomment:"" });
           // toast.success("Manual entry successfully edited.");
          //  this.loadLaborTimesheetDetails();
           // this.loadTimesheetDateWise("updateActualDetails");
           // this.props.slideOutRefresh();
           // this.hideExportedDate();
           
          } else {
            //this.btnhHandleSave.current.disabled = false;
            Utils.toastError(result.result.message);
          }
        } else {
          // this.btnhHandleSave.current.disabled = false;
        }

        resolve();
      })
      .catch((err) => {
        //this.btnhHandleSave.current.disabled = false;
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });

  }


  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/labor-management/payroll-export",
            state: {
              payPeriod: this.state.payPeriodDates,
              pageTitle: "performance",
            },
          }}
        />
      );
    }
    const isButtonShow = () => {
      if (this.state.lockStatus === "Locked") {
        return false;
      } else {
        if (this.state.exportPermission === "Yes") {
          return true;
        } else if (
          this.state.exportedPayroll === "Yes" &&
          this.state.exportPermission === "No"
        ) {
          return false;
        } else if (
          this.state.exportedPayroll === "No" &&
          this.state.approvePermission === "Yes"
        ) {
          return true;
        } else if (
          this.state.unApprovePermission === "Yes" &&
          this.state.approvePermission === "No"
        ) {
          return true;
        } else if (
          this.state?.showAddEntryButton &&
          this.state.unApprovePermission === "Yes"
        ) {
          return true;
        }
      }
      return false;
    };

    const rowClasses = (row: any, rowIndex: any) => {
      if (
        this.state.isExpand === true &&
        row.scheduleNo === this.state.userRow.scheduleNo
      ) {
        return "row-Expended";
      } else {
        return "row-Action";
      }
    };

    const expandRow = {
      onlyOneExpanding: true,
      parentClassName: "parent-expand-foo",
      renderer: (
        row: {
          positionID;
          scheduleNo;
          userName: any;
          manuals: any;
          isDateapprove: any;
          isedit: any;
          opens: any;
          managesmileid: any;
          laborPunchDetails: any[];
          actualInDate: any;
          actualOutDate: any;
        },
        rowIndex: any
      ) => (
        <div>
          <PerformanceSlideoutChild
            ref={this.childActual}
            hotelID={this.state.hotelID}
            positionID={row?.positionID}
            departmentID={this.state.departmentID}
            date={this.state.date}
            userName={row.userName}
            manuals={row.manuals}
            rowIndex={rowIndex}
            isemployeeSlideOut={this.state.isemployeeSlideOut}
            opens={row.opens}
            isDateapprove={this.state.isDateapprove}
            isedit={row.isedit}
            managesmileid={row.managesmileid}
            resetList={this.resetList}
            scheduleNo={row?.scheduleNo}
            loadEmployeeDetails={this.loadEmployeeDetails}
            setReloadPerformanceData={this.setReloadPerformanceData}
            noShow ={ 
              !row?.actualInDate &&
              !row?.actualOutDate

            }
            parentState ={this.state}
            //  hideExportedDate={this.hideExportedDate.bind(this)}
          />
        </div>
      ),
      showExpandColumn: true,
      onExpand: this.handleOnExpand,
      expandByColumnOnly: true,
      expanded: this.state.tableExpended,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right performanceSlideout" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right performanceSlideout" />
          </span>
        );
      },
    };

    const {
      filteredData,
      breadDepartments,
      breadPositions,
      positionId,
      departmentID,
      employee,
      tableColumns,
      isemployeeSlideOut,
      topHeaderDetails: {
        totalHours,
        scheduledHours,
        varianceHours,
        breakHours,
        overTimeHours,
        doubleOverTimeHours,
        isCaliforniaRule,
        budgetedHours,
        shouldHightLightTotalvariance,
      },
    } = this.state;

    const {
      DataParameter: {
        deptName,
        positionName,
        userName,
        showBudgetedHours,
        isCalenderLocked,
      },
    } = this.props;
    console.log("this.state.exportedDate");
    console.log(this.state.exportedDate);
    return (
      <>
        {" "}
        {tableColumns?.length && (
          <div id="performanceSlideOut" className="pos-fxd index-1000">
            {/* <ToastContainer autoClose={3000} /> */}
            {/* {loadShow} */}
            <div className="d-flex">
              <div className="back-drop" onClick={this.closeSlideOut}></div>
              <Container
                fluid
                className="body-sec performance-position updated-slideout performance-slideout-container justify-content-end"
              >
                {this.state.showAddEntryModal && (
                  <AddEntryModal
                  callPage={"Performace"}
                    deptId={this.state.departmentID}
                    positionId={this.state.positionId}
                    parentState={this.state.addEntryState}
                    hideAddEntryModal={this.hideAddEntryModal}
                    addEntryRefresh={this.addEntryRefresh}
                    approvePermission={this.state.approvePermission}
                    pageType={"Performace"}
                    payrollExportPerformance = {this.state.exportedDate}

                  />
                )}

                <div className="container-wrapper">
                <div className="breadcrumb d-flex">
                  <div className="level-1">Performance</div>
                  <>
                    {(departmentID > 0 || employee) &&
                      (positionId > 0 || employee) && (
                        <>
                          <div className="forward-arrow">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.13798 11.8046L10.9426 7.99998L7.13798 4.19531L6.19531 5.13798L9.05731 7.99998L6.19531 10.862L7.13798 11.8046Z"
                                fill="#84888C"
                              />
                            </svg>
                          </div>

                          {breadDepartments.length == 1 && (
                            <div
                              onClick={() =>
                                this.fetchPerformanceData(
                                  0,
                                  +breadDepartments[0]?.id
                                )
                              }
                              className="level-2"
                            >
                              {breadDepartments[0]?.name}
                            </div>
                          )}
                          {breadDepartments.length > 1 && (
                            <div className="level-2">
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-ptoType">
                                  {breadDepartments.length} Departments
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {breadDepartments.map(
                                    (item: any, idx: any) => (
                                      <Dropdown.Item
                                        onClick={() =>
                                          this.onDepartmentChange(item)
                                        }
                                        key={item.id}
                                        eventKey={item.name}
                                      >
                                        {item.name}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          )}
                        </>
                      )}
                  </>

                  <>
                    {breadDepartments.length > 0 &&
                      breadPositions?.length > 0 &&
                      employee && (
                        <>
                          {breadPositions.length > 0 && (
                            <div className="forward-arrow">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.13798 11.8046L10.9426 7.99998L7.13798 4.19531L6.19531 5.13798L9.05731 7.99998L6.19531 10.862L7.13798 11.8046Z"
                                  fill="#84888C"
                                />
                              </svg>
                            </div>
                          )}
                          {breadPositions.length == 1 && (
                            <div
                              onClick={() =>
                                this.fetchPerformanceData(
                                  +breadPositions[0]?.id,
                                  +breadPositions[0]?.deptID
                                )
                              }
                              className="level-2"
                            >
                              {breadPositions[0]?.name}
                            </div>
                          )}
                          {breadPositions.length > 1 && (
                            <div className="level-2">
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-ptoType">
                                  {breadPositions.length} Positions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {breadPositions.map((item: any, idx: any) => (
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.onPositionChange(item)
                                      }
                                      key={item.id}
                                      eventKey={item.name}
                                    >
                                      {item.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          )}
                        </>
                      )}
                  </>
                </div>
                <div className="page-heading underline d-flex">
                  <div className="hid-set d-flex align-items-center">
                    {this.props.hotelName}
                  </div>

                  <span>{this.getTitle()}</span>
                  <div className="mr-auto d-flex align-items-center">
                    {this.state.positionName}
                  </div>
                  <div className="action-group d-flex flex-row">
                  {this.state?.isEdit && !this.state.loaderTrue && this.state.addEntryState?.showAddEntryModal && (
                      <Button
                        className="add-shift btn-outline-primary"
                        onClick={(e: any) => this.showAddEntryModal(e)}
                      >
                        Add Entry
                      </Button>
                    )}
                    
                    <button
                      type="button"
                      className="btn wht-bg cross"
                      onClick={this.closeSlideOut}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                          fill="#84888C"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                </div>
                
                <div className="bdy-sec">

                  {(
                    <ToolkitProvider
                      keyField="userUniqueno"
                      data={filteredData}
                      columns={tableColumns}
                      sort={{
                        dataField: this.state.field,
                        order: this.state.order,
                      }}
                      search
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          <div className="search-header d-flex">
                            {this.state.parentName !== "autoPunchOut" && (
                              // <SearchBar
                              //   autoComplete="off"
                              //   className="search-area"
                              //   {...props.searchProps}
                              //   placeholder={"Search..."}
                              // />
                              <TableSearch id="tableSearch" key="tableSearch"
                                {...props.searchProps}
                                placeholder={"Search..."}
                              />
                            )}

                            <div className="mr-auto date-picker calender-picker">
                              <div className="d-flex">
                                <div
                                  onClick={() =>
                                    !isCalenderLocked
                                      ? this.handleDateDecrement()
                                      : {}
                                  }
                                  className="prev-arrow d-flex align-items-center justify-content-center"
                                >
                                  <svg
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z"
                                      fill="#3F4347"
                                    />
                                  </svg>
                                </div>

                                <div className="datepickerInputSet d-flex">
                                  <div className="calender-icon">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z"
                                        fill="#3F4347"
                                      />
                                      <path
                                        d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z"
                                        fill="#3F4347"
                                      />
                                    </svg>
                                  </div>
                                  <DatePicker
                                    key="datePicker"
                                    selected={new Date(this.state.date)}
                                    readOnly={isCalenderLocked ? true : false}
                                    onSelect={this.handleSelectDate}
                                    placeholderText={"Select date"}
                                    dateFormat="MM/dd/yy"
                                    maxDate={new Date()}
                                    className="removeBorder form-control"
                                  />
                                </div>

                                <div
                                  onClick={() =>
                                    !isCalenderLocked
                                      ? this.handleDateIncrement()
                                      : {}
                                  }
                                  className="next-arrow d-flex align-items-center justify-content-center"
                                >
                                  <svg
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z"
                                      fill="#3F4347"
                                    />
                                  </svg>
                                </div>
                              </div>

                              {isCalenderLocked && (
                                <div className="lock-date">
                                  <img src={ForecastLock} alt="ForecastLock" />
                                </div>
                              )}
                            </div>

                            {this.state.parentName !== "autoPunchOut" && (
                              <div>
                                <Dropdown className="more-action bg-blue">
                                  <Dropdown.Toggle id="dropdown-ptoType">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                                    </svg>
                                    {this.state.selectedSortOption}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {this.state.sortOptions.map(
                                      (item: any, idx: any) => (
                                        <Dropdown.Item
                                          key={item}
                                          onClick={() =>
                                            this.handleSortChange(item)
                                          }
                                          eventKey={item}
                                        >
                                          {item}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}
                          </div>
                          {this.state.loaderTrue && (
                            <ReactPageLoader useas={"performanceSlideout"} />
                          )}
                          {this.state.exportedDate.length > 0 && !this.state.loaderTrue &&
                            this.state.exportedDate !== "0" && (
                              <div className="info-badges exported-date">
                                <div className="d-flex">
                                  <div>
                                    This week was exported as part of the 
                                  </div>
                                  <a
                                    className="d-flex"
                                    onClick={() => this.openOverView()}
                                  >
                                    {this.state.payPeriodDates[0]} {"-"}{" "}
                                    {this.state.payPeriodDates[1]} {"payroll"}
                                  </a>
                                  <span>on</span>{" "}
                                  <div className="bold">
                                    {this.state.exportedDate}
                                  </div>
                                </div>
                              </div>
                            )}
                          {
                            !this.state.loaderTrue &&
                            <>
                              <div className="d-flex details-sec overFlow-scroll">
                              <div
                                className="flex-fill align-content-center box"
                                style={{ marginTop: "0" }}
                              >
                                <div className="list">
                                  <ul>
                                    <li>
                                      <div
                                        className={`ttl ${
                                          shouldHightLightTotalvariance
                                            ? "color-red"
                                            : ""
                                        }`}
                                      >
                                        {totalHours}
                                      </div>
                                      <div className="desc">Total Hours</div>
                                    </li>
                                    {(!showBudgetedHours ||
                                      isemployeeSlideOut) && (
                                      <li>
                                        <div className="ttl">
                                          {scheduledHours}
                                        </div>
                                        <div className="desc">Scheduled</div>
                                      </li>
                                    )}
                                    {showBudgetedHours && !isemployeeSlideOut && (
                                      <li>
                                        {/* <div className="ttl">{budgetedHours}</div> */}
                                        <div className="ttl">{budgetedHours.slice(0,5)}</div>
                                        <div className="desc">Budgeted</div>
                                      </li>
                                    )}
                                    <li>
                                      <div
                                        className={`ttl ${
                                          shouldHightLightTotalvariance
                                            ? "color-red"
                                            : ""
                                        }`}
                                      >
                                        {/* {varianceHours} */}
                                        {varianceHours.slice(0,5)}
                                      </div>
                                      <div className="desc">Variance</div>
                                    </li>
                                    <li>
                                      <div className="separator"></div>
                                    </li>
                                    <li>
                                      <div className="ttl">
                                        {/* {breakHours} */}
                                        {breakHours.slice(0,5)}
                                      </div>
                                      <div className="desc">Break</div>
                                    </li>
                                    <li>
                                      <div className="separator"></div>
                                    </li>
                                    <li>
                                      <div
                                        className={`ttl ${
                                          overTimeHours !== "00:00"
                                            ? "color-red"
                                            : ""
                                        }`}
                                      >
                                        {overTimeHours === "00:00"
                                          ? "-"
                                          : overTimeHours}
                                      </div>
                                      <div className="desc">Overtime</div>
                                    </li>
                                    {isCaliforniaRule && (
                                      <li>
                                        <div
                                          className={`ttl ${
                                            doubleOverTimeHours !== "00:00"
                                              ? "color-red"
                                              : ""
                                          }`}
                                        >
                                          {doubleOverTimeHours === "00:00"
                                            ? "-"
                                            : doubleOverTimeHours}
                                        </div>
                                        <div className="desc">DOT</div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
  
                            <div
                              id="scrollarea"
                              className={
                                this.state.exportedDate.length > 0 &&
                                this.state.exportedDate !== "0"
                                  ? "scroll-area height"
                                  : "scroll-area height-without-banner"
                              }
                            >
                              {/* <OutsideClickHandler
                                                      onOutsideClick={(e) => {this.onOutsideClick(e);}}
                                                      > */}
                            <div className={`${this.state.tableColumns.some(col => col.text == "DOT") ? "set-table-column-width" : ""}`}>
                              <BootstrapTable
                                {...props.baseProps}
                                keyField="scheduleNo"
                                expandRow={expandRow}
                                rowClasses={rowClasses}
                                hover
                                // sort={{
                                //   dataField: this.state.field,
                                //   order: this.state.order,
                                // }}
                                filter={filterFactory()}
                              />
                              {/* </OutsideClickHandler> */}
                              </div>
                            </div>
                            </>
                            
                          }
                         
                         </div>
                      )}
                    </ToolkitProvider>
                  )}
                </div>
              </Container>
            </div>
          </div>
        )}{" "}
      </>
    );
  }
}
