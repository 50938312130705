import React from "react";
import { Container, Dropdown, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Hotel as hotel } from "../../../Common/Services/Hotel";
import { User } from "../../../Common/Services/User";
import { ReconcileService as reconcileService } from "../../../Common/Services/ReconcileService";
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import OutsideClickHandler from "react-outside-click-handler";
import { ToastContainer, toast } from "react-toastify";
import cellEditFactory from 'react-bootstrap-table2-editor';
import "../../Register/slideout.scss";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import { Utils } from "../../../Common/Utilis";

let localStorageUser: any;




export class ReconcileStatus extends React.Component<any, any> {
  private childHID: any;
  private yearList: any;



  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.yearList = React.createRef();
    this.state = {
      reconcilePermissionArray: [],
      isNewReconcile: "",
      selectedYear: '',
      hidList: [],
      hidFilterList: [],
      hidValue: "All",
      hotelName: "All",
      selectedAccount: { id: "", year: "" },
      yearValue: "Select Year",
      yearType: [],
      //   { id: 2021, year: 2021 },
      //   { id: 2020, year: 2020 },
      //   { id: 2019, year: 2019 },
      //   { id: 2018, year: 2018 },
      // ],
      reconsiletData: [],
      blankData: [{
        accDisPlayNo: '', lettercode: '', acctType: '', january: '', february: '', march: '',
        april: '', may: '', june: '', july: '', august: '', september: '', october: '', november: '', december: ''
      }],
      densityView: [
        { id: 1, densityType: "densityView_1", name: "WIDE" },
        { id: 2, densityType: "densityView_3", name: "NARROW" }
      ],
      defaultDensityView: "densityView_3",
    };
    this.handleActiveItem = this.handleActiveItem.bind(this);
  }
  componentDidMount() {
    this.getDefaultView();
    this.getReconcilePermission();
    this.GetReconcilefeatureflag();
    this.bindYear();

  }

  getReconcilePermission = () => {
    reconcileService.GetReconcilePermission()
      .then(async (result: any | null) => {
        // debugger;
        if (result != null) {
          this.setState({ reconcilePermissionArray: result });
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
    })
    .catch((error) => {
      Utils.toastError(`Server Error: ${error}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      reject();
    });
  }

  GetReconcilefeatureflag = () => {
    let request: any = {};
    request.hotelId = 0;
    request.OprID = 0;
    reconcileService.GetReconcilefeatureflag(request)
      .then(async (result: any | null) => {
        //debugger;
        if (result != null) {
          this.setState({ isNewReconcile: result[0].status });
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
    })
    .catch((error) => {
      Utils.toastError(`Server Error: ${error}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      reject();
    });
  }

  bindHotel() {
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    const hotelid = JSON.parse(localStorage.getItem("hotelid")!);
    const hotelName = JSON.parse(localStorage.getItem("hotelName")!);
    hotel.getUserHotelAccess("Accounting")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null && result.length > 0) {


          const allAppendedHotel = {
            ehidName: "All",
            hotelID: "All",
            hotelName: "All",
            hotelType: "0",
            lettercode: "All",
            tenantID: localStorageUser.tenantID,
          };
          this.setState({ hidList: result }, () => {
            if (result.length > 1) {
              this.state.hidList.unshift(allAppendedHotel);
            }
          });
          this.setState({ hidFilterList: result });
          if (hotelName === null) {
            if (result.length > 1) {
              this.setState(
                {
                  hotelName: this.state.hidList[0].lettercode,
                  hidValue: this.state.hidList[0].hotelID,
                },
                () => {
                  this.bindTable();
                }
              );
            }
          } else {
            this.setState({ hotelName: hotelName });
          }
          if (hotelid === null) {
            if (result.length === 1) {
              this.setState(
                {
                  hidValue: this.state.hidList[0].hotelID,
                  hotelName: this.state.hidList[0].lettercode,
                },
                () => {
                  this.bindTable();
                }
              );
            }
          } else {
            this.setState({ hidValue: hotelid }, () => {
              this.bindTable();
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });

  }


  bindTable() {
    
    let request: any = {};
    request.hotelID = this.state.hidValue === "All" ? -1 : this.state.hidValue;
    request.fYear = this.state.selectedYear;
    reconcileService.GetReconcileStatus(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          this.setState({ reconsiletData: [] }, () => {
            this.setState({ reconsiletData: result });
          });
        }
        else {
          this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });

  }

  bindYear() {
    reconcileService.GetReconcileStatusYear()
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {

          this.setState({ yearType: result, selectedYear: result[0].defaultYear }, () => {
            this.bindHotel();
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });

  }

  searchItems = (e: any) => {
    const { hidFilterList } = this.state;
    let result = e.target.value;
    this.setState({ search: result });
    if (result !== "") {
      this.setState({
        hidList: hidFilterList.filter((hName: any) =>
          hName.hotelName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ hidList: this.state.hidFilterList });
    }
  };

  hotelChange = (hotelDetails: any) => {
    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,

      },
      () => { this.bindTable(); });
  };

  yearSelect = (eventKey: any) => {
    this.yearList.current.scrollTo(0, 0);
    this.setState({ selectedYear: eventKey }, () => {
      this.bindTable();
    });
  };
  handleActiveItem = () => {
    setTimeout(() => {
      let offset = $("#yearList").find(".active").position()?.top;
      this.yearList.current.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }, 100)
  }
  handleOutsideClick = () => {
    this.yearList.current.scrollTo(0, 0);
  }

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any, column: any) => {

    }
  };
  handleRedirect = (row, rowvalue, monthno) => {
    // debugger;
   // if (this.state.isNewReconcile === "Yes") {
      if (rowvalue.length > 0) {
        let acctType = "";
        switch (row.acctType) {
          case "Operating":
            acctType = "O";
            break;
          case "Misc":
            acctType = "M";
            break;
          case "Payroll":
            acctType = "P";
            break;
          case "Credit Card":
            acctType = "CC";
            break;
          default:
            break;
        }
        if(this.state.reconcilePermissionArray.filter(i => i.acctType === acctType).length > 0) {
          let Accper = monthno > 0 ? monthno + "/" + row.reconcileYear.toString().substring(2) : "";
          this.props.history.push('/reconcile', { hotelId: row.hid.toString(), letterCode: row.lettercode, oprID: row.oprID, Accper: Accper, fromPage: "reconcileStatus" });
        }
        else {
          Utils.toastError("You do not have permission to view this page. Please contact your administrator.", { position: toast.POSITION.BOTTOM_RIGHT });
        }
      }
    // }
    // else {
    //   if (rowvalue.length > 0) {
    //     this.handleConcile(row, monthno);
    //   }
  
    // }
  }



  handleConcile = (row, monthno) => {

    User.GenerateToken()
      .then(async (result: any | null) => {
        
        if (result) {
          let mergeURL = result.result.mergeURL;
          let token = result.result.token;
          let tokenID = result.result.tokenID;
          let pageURL,
            queryStringKey,
            queryStringValue,
            functionName = "";
          queryStringKey = "PageName";
          queryStringValue = "Reconcile";

          if (row.acctType === "Operating") {
            functionName = "O";
          }
          else if (row.acctType === "Misc") {
            functionName = "M";
          }
          else if (row.acctType === "Payroll") {
            functionName = "P";
          }
          else if (row.acctType === "Credit Card") {
            functionName = "C";
          }



          let hotelID = row.hid;
          let hCode = row.lettercode;
          let oprID = row.oprID;
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let userName = storage.userName;
          let accper = "";
          let pageUrl = "";
          if (monthno > 0) {
            accper = monthno + '/1/' + this.state.selectedYear;
            pageUrl = "?logInUser=" +
              btoa(userName) + "&tokenID=" + btoa(tokenID) + "&module=" + btoa("Accounting") + "&logInType=2&PageUrl=" + pageURL + "&Key=" + btoa(queryStringKey) +
              "&Value=" + btoa(queryStringValue) + "&function=" + btoa(functionName) + "&hid=" + hotelID + "&HCode=" + hCode + "&AcctID=" + oprID +
              "&Accper=" + accper

          }
          else {
            pageUrl = "?logInUser=" +
              btoa(userName) + "&tokenID=" + btoa(tokenID) + "&module=" + btoa("Accounting") + "&logInType=2&PageUrl=" + pageURL + "&Key=" + btoa(queryStringKey) +
              "&Value=" + btoa(queryStringValue) + "&function=" + btoa(functionName) + "&hid=" + hotelID + "&HCode=" + hCode + "&AcctID=" + oprID

          }




          window.open(
            mergeURL +
            // "http://localhost:50491/login.aspx" +
            pageUrl

          );
        }


        resolve();
      })
      .catch((error) => {
        reject();
      });

  };

  setColorCode = (value: any) => {
    //"badge blank" : "badge"
    let classname = ""
    if (value.length === 0) {
      return classname = "badge blank";
    }
    let val1 = value.split('_')[0].toString();
    let val2 = value.split('_')[1].toString();

    // if (val2.toString().toLowerCase() === "OverDue".toLowerCase()) {
    //   classname = "badge red"
    // }
    // else if (val2.toString().toLowerCase() === "Due".toLowerCase()) {
    //   classname = "badge green"
    // }
    // else
    //  if (val2.toString().toLowerCase() === "open".toLowerCase()) {
    //   classname = "badge blue"
    // }
    // else 
    if (val1.toString().toLowerCase() === "Recon".toLowerCase()) {
      classname = "badge green"
    }
    else if (val1.toString().toLowerCase() === "UNRECON".toLowerCase()) {
      classname = "badge"
    }     
    else {
      classname = "badge blank"
    }
    if ((val2.toString().toLowerCase() === "Locked".toLowerCase())) {
      classname = classname + " locked"
    }
    return classname;
  }

  getStatus = (value: any) => {
    //"badge blank" : "badge"
    if (value.length === 0) {
      return value;
    }
   // return value.split('_')[1].toString().toUpperCase() === "OPEN" ? "OPEN" : value.split('_')[0].toString().toUpperCase();
   return value.split('_')[0].toString().toUpperCase();

  }
  getStatus1 = (value: any) => {

    if (value.length === 0) {
      return "";
    }
    // return value.split('_')[1].toString().toLowerCase();
    if (value.split('_')[1].toString().toLowerCase() === "Locked".toLowerCase()) {
      return <svg width="8" height="10" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0C3.27688 0 1.875 1.3458 1.875 3V4.8H1.25C0.56 4.8 0 5.3376 0 6V10.8C0 11.4624 0.56 12 1.25 12H2.5H4.375H5.625H6.875H8.75C9.44 12 10 11.4624 10 10.8V6C10 5.3376 9.44 4.8 8.75 4.8H8.125V3C8.125 1.3458 6.72312 0 5 0ZM3.125 3C3.125 2.0076 3.96625 1.2 5 1.2C6.03375 1.2 6.875 2.0076 6.875 3V4.8H3.125V3ZM5.625 9.4338V10.8H4.375V9.4338C3.92062 9.1806 3.64437 8.667 3.78813 8.0994C3.89812 7.6674 4.27563 7.3182 4.72938 7.2276C5.53813 7.0644 6.25 7.6518 6.25 8.4C6.25 8.8434 5.9975 9.2262 5.625 9.4338Z" fill="#1d1e20" />
      </svg>
    }
    else {
      return "";
    }

  }

  handleDensityView = (viewName) => {
    this.setState({ defaultDensityView: viewName })
  }

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: this.state.defaultDensityView,
      pageName: "ReconcileStatus",
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ showLoader: false });
      });
  };

  getDefaultView() {
    laborPerformance
      .getDefaultViewProperties("ReconcileStatus")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          this.setState({
            defaultDensityView: response[0].fieldValue,
          });
        } else {
          this.setState({ defaultDensityView: this.state.densityView[1].densityType, })
        }
      })
      .catch((error) => {
        this.setState({ defaultDensityView: this.state.densityView[1].densityType, })
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }


  render() {


    const columns_1 = [
      {
        dataField: "accDisPlayNo",
        text: "Account Name",
        headerClasses: 'acct-no',
        classes: 'acct-no',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="cursor-pointer" onClick={() => this.handleRedirect(row, row.accDisPlayNo, 0)}>
            <EllipsisWithTooltip placement="bottom">{row.accDisPlayNo}</EllipsisWithTooltip>
          </div>
        ),
      }, {
        dataField: "lettercode",
        text: "EHID",
        hidden: this.state.hotelName !== 'All' ? true : false,
        headerClasses: 'ehid',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="cursor-pointer" onClick={() => this.handleRedirect(row, row.lettercode, 0)}>
            {row.lettercode}
          </div>
        ),
      }, {
        dataField: "acctType",
        text: "Type",
        headerClasses: 'acct-type',
        classes: 'acct-type',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="cursor-pointer" onClick={() => this.handleRedirect(row, row.acctType, 0)}>
            <EllipsisWithTooltip placement="bottom">{row.acctType}</EllipsisWithTooltip>
          </div>
        ),
      }, {
        dataField: "january",
        text: "Jan",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.january)} onClick={() => this.handleRedirect(row, row.january, 1)}>
            {this.getStatus(row.january)}
            {this.getStatus1(row.january) !== "" &&(
              <span>{this.getStatus1(row.january)}</span>
            )}
            
          </div>

        ),
      }, {
        dataField: "february",
        text: "Feb",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.february)} onClick={() => this.handleRedirect(row, row.february, 2)}>
            {this.getStatus(row.february)}
            {this.getStatus1(row.february) !== "" &&(
              <span>{this.getStatus1(row.february)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "march",
        text: "Mar",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.march)} onClick={() => this.handleRedirect(row, row.march, 3)}>
            {this.getStatus(row.march)}
            {this.getStatus1(row.march) !== "" &&(
              <span>{this.getStatus1(row.march)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "april",
        text: "Apr",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.april)} onClick={() => this.handleRedirect(row, row.april, 4)}>
            {this.getStatus(row.april)}
            {this.getStatus1(row.april) !== "" &&(
              <span>{this.getStatus1(row.april)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "may",
        text: "May",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.may)} onClick={() => this.handleRedirect(row, row.may, 5)}>
            {this.getStatus(row.may)}
            {this.getStatus1(row.may) !== "" &&(
              <span>{this.getStatus1(row.may)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "june",
        text: "Jun",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.june)} onClick={() => this.handleRedirect(row, row.june, 6)}>
            {this.getStatus(row.june)}
            {this.getStatus1(row.june) !== "" &&(
              <span>{this.getStatus1(row.june)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "july",
        text: "Jul",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.july)} onClick={() => this.handleRedirect(row, row.july, 7)}>
            {this.getStatus(row.july)}
            {this.getStatus1(row.july) !== "" &&(
              <span>{this.getStatus1(row.july)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "august",
        text: "Aug",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.august)} onClick={() => this.handleRedirect(row, row.august, 8)}>
            {this.getStatus(row.august)}
            {this.getStatus1(row.august) !== "" &&(
              <span>{this.getStatus1(row.august)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "september",
        text: "Sep",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.september)} onClick={() => this.handleRedirect(row, row.september, 9)}>
            {this.getStatus(row.september)}
            {this.getStatus1(row.september) !== "" &&(
              <span>{this.getStatus1(row.september)}</span>
            )}
            </div>
        ),
      }, {
        dataField: "october",
        text: "Oct",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.october)} onClick={() => this.handleRedirect(row, row.october, 10)}>
            {this.getStatus(row.october)}
            {this.getStatus1(row.october) !== "" &&(
              <span>{this.getStatus1(row.october)}</span>
            )}
            
            </div>
        ),
      }, {
        dataField: "november",
        text: "Nov",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.november)} onClick={() => this.handleRedirect(row, row.november, 11)}>
            {this.getStatus(row.november)}
            {this.getStatus1(row.november) !== "" &&(
              <span>{this.getStatus1(row.november)}</span>
            )}            
            </div>
        ),
      }, {
        dataField: "december",
        text: "Dec",
        headerClasses: 'month-name',
        classes: 'month-name',
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={this.setColorCode(row.december)} onClick={() => this.handleRedirect(row, row.december, 12)}>
            {this.getStatus(row.december)}
            {this.getStatus1(row.december) !== "" &&(
              <span>{this.getStatus1(row.december)}</span>
            )}
            
            </div>
        ),
      }
    ];



    return (
      <div className="reconcile-status">
        <Container fluid className="body-sec">
          <div className="page-heading underline d-flex">
            <Dropdown className="hid-select">
              <Dropdown.Toggle
                id="dropdown-hid"
                disabled={this.state.showLoader}
              >
                <div className="d-flex align-items-center justify-content-center">
                  {this.state.hotelName}
                  <div className="drop-arrow d-flex">
                    <FiChevronDown />
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="cusEhidSelector">
                <div className="EhidSearch">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      name="search"
                      value={this.state.search}
                      autoComplete="off"
                      onChange={this.searchItems}
                      placeholder="Filter EHIDs"
                    />
                  </Form.Group>
                </div>
                <div className="dropdownItems">
                  {this.state.hidList.length > 0 ? (
                    this.state.hidList.map(
                      (item: any, idx: number) => (
                        <React.Fragment key={idx}>

                          {(idx > 0
                            && item?.hotelType !== this.state.hidList[idx - 1]?.hotelType) && (
                              <Dropdown.Divider />

                            )}

                          <Dropdown.Item
                            eventKey={item.lettercode}
                            onClick={() => this.hotelChange(item)}
                          >
                            <div
                              className={
                                item.lettercode === this.state.hotelName
                                  ? "dropDownListTitle hasCheck"
                                  : "dropDownListTitle"
                              }
                            >
                              <span className="name">
                                {item.hotelName.split(" - ")[0]}
                              </span>
                              <span className="title">
                                {
                                  item.hotelName
                                    .replace(" - ", ".")
                                    .split(".")[1]
                                }
                              </span>
                              {item.lettercode === this.state.hotelName && (
                                <span className="itemcheck">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                      fill="#2CAF92"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </Dropdown.Item>
                          {/* {item.lettercode === "All" && <Dropdown.Divider />} */}
                        </React.Fragment>
                      )
                    )
                  ) : (
                    <Dropdown.Item>
                      <b>No Item Found</b>
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className="mr-auto">Reconciliation Status</div>
          </div>
          <div className="main-Page">
            <div className="navigation-menus registerSearchHeader d-flex">
              <Dropdown className="custom-selectbox width-236 mr-auto" onClick={this.handleActiveItem} onSelect={this.yearSelect}>
                <Dropdown.Toggle id="">
                  <div className={this.state.yearValue != "Select Year" ? "selected-value" : "selected-value placeholder"}>{this.state.selectedYear}</div>
                  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="transform" id="yearList" ref={this.yearList}>
                  <OutsideClickHandler onOutsideClick={() => this.handleOutsideClick()}>
                    {this.state.yearType.map(
                      (item: any, idx: any) => (
                        <Dropdown.Item eventKey={item.fYear} key={idx} className={this.state.selectedYear == item.fYear ? "active" : ""}>
                          <div className="d-flex">
                            <div className="mr-auto">
                              {item.fYear}
                            </div>
                            <div className="checked">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                  fill="#2CAF92"
                                />
                              </svg>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )
                    )}
                  </OutsideClickHandler>
                </Dropdown.Menu>
              </Dropdown>
              <div className="filter-section ml-1 hasGearIcon">
                <Dropdown className="more-action bg-blue dropdown single-select-image">
                  <Dropdown.Toggle id="rolesList">
                    <div className="d-flex align-items-center">
                      <svg width="14" height="14" viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                      </svg>
                      <div className="drop-arrow ml-1 d-flex">
                        <FiChevronDown />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="gearIconHeader headerTop"><strong>Density View Control</strong></div>
                    {this.state.densityView.map((items, index) => (
                      <div
                        className={this.state.defaultDensityView === items.densityType ? 'dropdownItem active' : 'dropdownItem'}
                        key={items.id}
                        onClick={(e: any) => this.handleDensityView(items.densityType)}
                      >{items.name}
                        {this.state.defaultDensityView === items.densityType && (
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92"></path>
                          </svg>
                        )}
                      </div>

                    ))}
                    <div className="m-0 dropdown-divider"></div>
                    <Dropdown.Item className="saveDefaultBtn" onClick={() => this.saveDefaultView()}>Save as Default View</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {this.state.reconsiletData.length > 0 && (
              <div className={`reconsile-table ${this.state.defaultDensityView} fixHeader100 forRePeatParentTable tableToggleRow regular rePeatScrollArea`}>
                <BootstrapTable
                  id="tblReconcileStatus"
                  keyField="rno"
                  data={this.state.reconsiletData}
                  columns={columns_1}
                  noDataIndication=""
                //cellEdit={ cellEditFactory({ mode: 'click' }) }
                // rowEvents={this.rowEvents}
                />

              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}
