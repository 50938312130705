import * as React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Container, Dropdown, Form, Button, Modal, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
import "./comment.scss";
import profile from "../Assets/Images/profile.png";
import { Register as registersevice } from "../../Common/Services/Register";
import { toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from 'moment';

export class CommentBox extends React.Component<any, any> {
    private scrollToBottomlist: any;
    constructor(props: any) {
        super(props);
        this.scrollToBottomlist = React.createRef();
        this.state = {
            hotelid: this.props.hotelid,
            entryType: this.props.entryType,
            uniqueNo: this.props.uniqueNo,
            precommentlist: this.props.precommentlist,
            canReview: this.props?.canReview,
            canFlag: this.props?.canFlag,
            selectedReviewStatus: this.props?.currentReviewStatus,
            value: '',
            mentionData: null,
            commentlist: [],
            users: [],
            htmlcomment: [],
            userComments: [],
            logInUser: "",
            logInUserImage: "",
            isCommentAdd: false,
            addCommentDisabled: this.props.addCommentDisabled,
            isReviewed: false,
            isCommentBtn:true,
            commentTypeSelection: [{ id: 1, name: "Add and Flag for Review", eventName: "Flag for Review", tolltip: "This will add your comment and flag this transaction for review."},
            { id: 2, name: "Add as Needs Review", eventName: "Needs Review", tolltip: "This will add your comment and change status to Needs Review." },
            { id: 3, name: "Add as Reviewed", eventName: "Reviewed", tolltip: "This will add your comment and change status to reviewed." },
            { id: 4, name: "Add and Review Complete", eventName: "Review Complete", tolltip: "This will add your comment and change status to Review Complete." },
            { id: 5, name: "Add a Comment", eventName: "AddComment", tolltip: "" }
            ],


        };
    }

    onTAB = (event) => {
        if (this.state.value.trim().length > 0) {
            if (event.key.toLowerCase() === "tab") {
                let txtID = "btncomment";

                setTimeout(() => {
                    $("button[id^='" + txtID + "']").focus();
                }, 50);
            }
            if (event.key.toLowerCase() === "enter") {
                // this.addComment();
            }
        }
    }

    handleChange = (event, newValue, newPlainTextValue, mentions) => {
        let text = newPlainTextValue;
        this.setState({
            value: newValue,
            mentionData: { newValue, newPlainTextValue: text, mentions: mentions }
        })
        setTimeout(() => {
            this.props.parentScrollMethod();
        }, 50)
    }
    handleFocus = () => {
        setTimeout(() => {
            this.props.parentScrollMethod();
        }, 50)
    }


    componentDidMount() {
        
  
        this.usernameList();
        this.addReviewStatus(this.state.selectedReviewStatus);

        // let isReviewed = false;
        // if (this.state.currentReviewStatus.toLowerCase() === 'markforreview' || this.state.currentReviewStatus.toLowerCase() === "review") {
        //     isReviewed = true;
        // }
        // this.setState({ isReviewed });

    }


    loadPrevComment = () => {

        if (this.state.precommentlist.length > 0) {
            let itemComment: any[] = [];
            this.state.precommentlist.forEach(element => {
                let savedComment = element.htmlComment;
                if (element.displayUserTo.length > 0) {
                    let displayusers = element.displayUserTo.split(',')
                    displayusers.forEach(userTo => {

                        let logInUser = this.state.users.filter(x => x.username === userTo.replace("@", ""));

                        if (logInUser.length === 0) {
                            savedComment = savedComment.replaceAll(userTo, '<span class ="isNotPermission">' + userTo + '</span>')
                        }

                    });
                }

                // let  comment1 = element.htmlComment.replaceAll(element.display, '<span class ="isNotPermission">' + element.display + '</span>')

                itemComment.push(<li className="d-flex">
                    <div className="profile"><img className={element.profileimage === "" ? 'default' : ''}
                        src={element.profileimage === "" ? profile : element.profileimage}
                        width="36" height="36" alt={element.createdBy} /></div>
                    <div className="profile-details">
                        <div className="name">{element.createdBy}<span> {element.commentDate.length > 0 ?
                            // moment(element.commentDate).format("LLL") : ""}
                            moment.utc(element.commentDate).local().format("MMMM Do YYYY, h:mm a") : ""}
                            {/* Utils.getLocalDateTimeWithoutUtc(element.commentDate) :""} */}
                        </span>
                            {element.commentStatus !== '' && (
                                <span className={`status ${element.commentStatus == 'Flag for Review' || element.commentStatus == 'Needs Review' ? 'orange' : ''} ${element.commentStatus == 'Reviewed' ? 'blue' : ''} ${element.commentStatus == 'Review Complete' ? 'green' : ''}`}>
                                    {element.commentStatus =='Flag for Review' ? 'Needs Review' : element.commentStatus}</span>
                            )}
                        </div>
                        <div className="comment" dangerouslySetInnerHTML={{ __html: savedComment.replace('\n', '<br/>') }}></div>
                    </div>
                </li>)

            });

            this.setState({ htmlcomment: itemComment },()=>{ this.props.callBackCommentLoad !== undefined && this.props.callBackCommentLoad(); });

        }
    }

    CommentToSave = () => {
        //debugger;
        let array: any = [];
        if (this.state.uniqueNo > 0) {
            return array;
        }
        return this.state.userComments;
    }

    isCommentAdd = () => {
        return this.state.value.trim().length > 0 ? true : false;
    }

    retsetComment = () => {
        this.setState({ userComments: [] })
    }

    addReviewComment = (status: string, newcomment) => {
        // let  comment = this.state.mentionData.newPlainTextValue.trim();
        this.addComment(status, newcomment);
        this.addReviewStatus(status);
    }

    addNewCommnent = (status: string) => {

        //let  comment = this.state.mentionData.newPlainTextValue.trim();
        let mentionData: any[] = [];
        mentionData = this.state.mentionData;
        this.addComment(status, mentionData);

        if (status != 'AddComment') {
            this.addReviewStatus(status);
            this.props.showbanner(status);
        }
    }

    addReviewStatus = (status) => {
        let statusTypeList: any[] =[{ id: 1, name: "Add and Flag for Review", eventName: "Flag for Review", tolltip: "This will add your comment and flag this transaction for review."},
        { id: 2, name: "Add as Needs Review", eventName: "Needs Review", tolltip: "This will add your comment and change status to Needs Review." },
        { id: 3, name: "Add as Reviewed", eventName: "Reviewed", tolltip: "This will add your comment and change status to reviewed." },
        { id: 4, name: "Add and Review Complete", eventName: "Review Complete", tolltip: "This will add your comment and change status to Review Complete." },
        { id: 5, name: "Add a Comment", eventName: "AddComment", tolltip: "" }
        ];

        // let isReviewed = false;
        // if (status.toLowerCase() === 'markforreview') {
        //     isReviewed = true;
        // }


        let statusList: any[] = [];
        statusTypeList.forEach((element, index) => {



            if (element.eventName === "Flag for Review" && this.state.canFlag === "Yes" && (status==="" || status==="Review Complete")) {
                statusList.push(element);
              }
              if (element.eventName === "Needs Review" && this.state.canFlag === "Yes" && (status==="Reviewed") && this.state.uniqueNo > 0) {
                statusList.push(element);
              }
              if (element.eventName === "Reviewed" && this.state.canReview === "Yes" && (status==="Flag for Review" || status==="Needs Review") && this.state.uniqueNo > 0) {
                statusList.push(element);
              }
              if (element.eventName === "Review Complete" && this.state.canFlag === "Yes" && (status==="Flag for Review" 
              || status==="Needs Review" || status==="Reviewed" ) && this.state.uniqueNo > 0) {
                statusList.push(element);
              }

            if (element.eventName === "AddComment") {
                statusList.push(element);
            }

        });

        let isCommentBtn =true;
        if(statusList.length>1)
        {
            isCommentBtn =false;
        }

        this.setState({ commentTypeSelection: [],selectedReviewStatus:status,isCommentBtn }, () => {
            this.setState({ commentTypeSelection: statusList });
        });


    }



    addComment = (status: string, mentionData: any) => {
        //debugger;
        let comment: any = "";
        let comment1: any = "";
        let itemComment: any[] = [];
        let displayUser: any[] = [];

        let updatedCommentItem: any = {};
        updatedCommentItem = this.props.updatedCommentItem();

        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let currentDateTime = new Date().toLocaleString(); //March 22nd at 3:32pm
        let userComments = Object.assign([], JSON.parse(JSON.stringify(this.state.userComments)));
        

        if (status == "AddComment") {
            status = "";
        }
        this.setState({ selectedReviewStatus: status });

        if (this.state.uniqueNo > 0) {
            userComments = [];
        }

        //comment = this.state.mentionData.newPlainTextValue.trim();
        comment = mentionData.newPlainTextValue.trim();
        if (comment.length > 0) {
            if (mentionData.mentions.length > 0) {
                mentionData.mentions.forEach(element => {
                    let userCount = displayUser.filter(x => x.display === element.display.trim()).length;

                    if (userCount === 0) {
                        comment1 = comment.replaceAll(element.display.trim(), '<span>' + element.display.trim() + '</span>')
                        comment = comment1;
                        displayUser.push(element.display.trim());
                    }

                    let usercomment: any = {};
                    usercomment.uniqueNo = this.state.uniqueNo;
                    usercomment.entryType = this.state.entryType;
                    usercomment.logInuser = userName;
                    usercomment.createDate = currentDateTime.toString();
                    usercomment.toUser = element.display.trim().replace("@", "");
                    usercomment.comment = mentionData.newPlainTextValue;
                    usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
                    usercomment.tenantID = tenantID;
                    usercomment.date = updatedCommentItem.transcationDate;
                    usercomment.vendor = updatedCommentItem.vendorName;
                    usercomment.amount = updatedCommentItem.amountTotal;
                    usercomment.status = status;

                    userComments.push(usercomment);

                });

                if (userComments.length > 0) {
                    userComments.forEach(ele => {
                        // ele.htmlcomment = ele.htmlcomment.replaceAll('\n', '<br/>');
                        ele.htmlcomment = comment1.replaceAll('\n', '<br/>');
                    });
                }
            }
            else {
                comment1 = mentionData.newPlainTextValue;
                let usercomment: any = {};
                usercomment.uniqueNo = this.state.uniqueNo;
                usercomment.entryType = this.state.entryType;
                usercomment.logInuser = userName;
                usercomment.createDate = currentDateTime;
                usercomment.toUser = "";
                usercomment.comment = mentionData.newPlainTextValue;
                usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
                usercomment.tenantID = tenantID;
                usercomment.date = updatedCommentItem.transcationDate;
                usercomment.vendor = updatedCommentItem.vendorName;
                usercomment.amount = updatedCommentItem.amountTotal;
                usercomment.status = status;
                userComments.push(usercomment);
            }



            let newcomment: any = {};


            this.state.htmlcomment.forEach(element => {
                itemComment.push(element);
            });

            itemComment.push(<li className="d-flex">
                <div className="profile"><img className={this.state.logInUserImage === "" ? 'default' : ''}
                    src={this.state.logInUserImage === "" ? profile : this.state.logInUserImage} width="36" height="36" alt="Inn-Flow" /></div>
                <div className="profile-details">
                    <div className="name">{userName}<span> {Utils.getLocalDateTimeWithoutUtc(new Date())}</span>
                        {status !== '' && (
                            <span className={`status ${status == 'Flag for Review' || status == 'Needs Review' ? 'orange' : ''} ${status == 'Reviewed' ? 'blue' : ''} ${status == 'Review Complete' ? 'green' : ''}`}>
                                {status  =='Flag for Review' ? 'Needs Review' : status}
                                </span>
                        )}
                    </div>
                    <div className="comment" dangerouslySetInnerHTML={{ __html: comment1.replaceAll('\n', '<br/>') }}></div>
                </div>
            </li>)

            let isReviewed = false;
            if (status.toLowerCase() === 'markforreview' || status.toLowerCase() === "review") {
                isReviewed = true;
            }


            this.setState({
                htmlcomment: [],
                userComments: [],
                value: '',
                mentionData: {}
            }, () => {
                this.setState({ htmlcomment: itemComment, userComments, isReviewed }, () => {
                    if (this.state.uniqueNo > 0) {
                        this.saveComments(status);
                    }
                })
            })
        }
        setTimeout(() => {
            this.scrollToBottomlist.current.scrollTo({
                top: this.scrollToBottomlist.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 50)
    }



    usernameList = () => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        let userRequest: any = {};
        userRequest.hotelid = this.state.hotelid;
        registersevice.usernameList(userRequest)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.length > 0) {
                        let logInUser = result.filter(x => x.username === userName);
                        let logInUserimage = logInUser[0].image
                        this.setState({ users: result, logInUserImage: logInUserimage }, () => {
                            this.loadPrevComment();                            
                        })
                    }

                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };


    saveComments = (status:string) => {
        //debugger
        registersevice.SaveTransactionComment(this.state.userComments)
            .then(async (result: any | null) => {
                if (result !== null) {

                    //debugger
                    if (result.saveStatus === "Success" && status === "Review Complete") {
                        Utils.toastSuccess("This transaction review has been successfully completed.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "reviewSuccess",
                        });

                    }
                    else {

                        if (result.messageCode === "Validation") {
                            Utils.toastError(result.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "reviewError",
                            });
                            // this.setState(() => {
                            this.props.commentModalClose();
                            //})
                        }
                    }

                }
                resolve();
            })
            .catch((error) => {
                reject();
            });

    }


    render() {
        const userMentionData = this.state.users.map(myUser => ({
            id: myUser.username,
            username: myUser.username,
            name: myUser.name,
            image: myUser.image,
            email: myUser.email,
            position: myUser.position,
            display: `@${myUser.username + ' '}`,
        }))
        return (

            <div className={this.state.value.trim().length > 0 ? "comment-box add-comment-button" : "comment-box"}>

                <div className="comment-list">
                    <div className="sec-ttl">Comments</div>
                    {this.state.htmlcomment.length === 0 && (
                        <div className='no-comment-placeholder'>None</div>
                    )}
                    <ul ref={this.scrollToBottomlist}>
                        {this.state.htmlcomment}
                    </ul>
                </div>
                <div className={this.state.addCommentDisabled ? "comment-area d-flex disabled" : "comment-area d-flex"}>
                    <div className="profile"><img className={this.state.logInUserImage === "" ? 'default' : ''}
                        src={this.state.logInUserImage === "" ? profile : this.state.logInUserImage} width="36" height="36" alt="Inn-Flow" /></div>
                    <div className="inpute-field">
                        <MentionsInput
                            id="txtcomment"
                            className="mentions__mention"
                            markup="@{{__type__||__id__||__display__}}"
                            value={this.state.value}
                            onChange={this.handleChange}
                            onClick={this.handleFocus}
                            placeholder="Type @ to notify another user."
                            onKeyDown={(e: any) => this.onTAB(e)}
                        //singleLine

                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={userMentionData}
                                className="mentions__mention"
                                //displayTransform={(id, display) => `@${display}`}
                                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className="profile">
                                            <img className={entry.image === "" ? 'default' : ''} src={entry.image === "" ? profile : entry.image} width="36" height="36" alt={entry.username} />
                                        </div>
                                        <div>
                                            <div className="user-name">{entry.username}</div>
                                            <div className="user-pos">{entry.position}</div>
                                        </div>
                                    </div>
                                )}
                            />
                        </MentionsInput>
                        {(this.state.value.trim().length > 0) && (
                            // <div className='d-flex align-items-center justify-content-end'>
                            <div className="d-flex justify-content-end add-comment">
                                {this.state.isCommentBtn && (
                                <button id="btncomment" type="button" className="btn btn-primary" onClick={() => this.addNewCommnent("AddComment")} >
                                    Add Comment
                                </button>
                                )}
                                {/* </div> */}
                                {this.state.commentTypeSelection.length > 1 && (
                                    <Dropdown className="custom-selectbox add-comment dropdown" drop={'up'} alignRight>
                                        <Dropdown.Toggle id="dropdownStatusList" className="d-flex align-items-center btn-primary">
                                            <div className="value"><EllipsisWithTooltip placement="bottom"> {'Select'}</EllipsisWithTooltip></div>
                                            <div className="arrow ml-auto">
                                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.29268 0.29248L5.00001 3.58581L1.70735 0.29248L0.292679 1.70715L5.00001 6.41382L9.70735 1.70715L8.29268 0.29248Z" />
                                                </svg>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {this.state.commentTypeSelection.map(
                                                (item: any, index: any) => (
                                                    <Dropdown.Item
                                                        className={`d-flex align-items-center ${item.eventName == 'Flag for Review' || item.eventName == 'Needs Review'  ? "orange" : ""} ${item.eventName == 'Reviewed' ? "blue" : ""} ${item.eventName == 'Review Complete' ? "green" : ""}`}
                                                        key={item.eventName}
                                                        eventKey={item.name}
                                                        onClick={() => this.addNewCommnent(item.eventName)}
                                                    >
                                                        <div className='value'>
                                                            <EllipsisWithTooltip placement="bottom">
                                                                {item.name}
                                                            </EllipsisWithTooltip>
                                                        </div>
                                                        {(item.tolltip !== '') && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={<Tooltip id={`custom-ttoltip = ${item.name}`}>{item.tolltip}</Tooltip>}

                                                            >
                                                                <div className="status-icn ml-auto">
                                                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z" />
                                                                    </svg>
                                                                </div>
                                                            </OverlayTrigger>
                                                        )}
                                                    </Dropdown.Item>
                                                )
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}

                            </div>
                        )}


                        {/* <hr />
                        {JSON.stringify(this.state.mentionData)}
                        <hr />
                        <p>The raw text is:</p>
                        <p>
                            {this.state.value}
                        </p> */}
                    </div>
                </div>
            </div>
        );
    }
}