import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Button, Dropdown, Form, Modal, Col, Row, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ReconcileService as reconcileService } from "../../../Common/Services/ReconcileService";
import { resolve, reject } from "q";
import "./reconcile.scss";
import { Register as registersevice } from "../../../Common/Services/Register";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import { TransactionSlideout } from "../../Register/TransactionSlideout";
import { TransferSlideout } from "../../Register/TransferSlideout";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { Hotel } from "../../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import { AnyCnameRecord } from "dns";
import { confirmAlert } from "react-confirm-alert";
import { CreditCardSlideout } from '../../Register/CreditCardSlideout';
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import _ from "lodash";

export class ReconcileWithdrawals extends React.Component<any, any> {
    private reconcileList: any;
    private accpr: any;
    constructor(props: any) {
        super(props);
        this.reconcileList = React.createRef();
        this.accpr = React.createRef();
        this.state = {
            ishorizontalScroll: false,
            defaultCoa: [],
            tableData: [],
            selectedRows: [],
            periodValue: this.props.periodValue,
            accper: this.props.accper,
            hidValue: this.props.hidValue,
            acctType: this.props.acctType,
            hidName: "",
            OprID: this.props.OprID,
            ClMonth: "",
            ClYear: "",
            EndDate: "",
            status: "",
            maxDate: new Date(),
            AccountType: this.props.AccountType,
            adjustmentAmount: "0.00",
            netAmount: "0.00",
            totalAmount: "0.00",
            verianceAmount: "0.00",
            transNetAmount: "0.00",
            sortingOrder: "asc",
            sortingField: "date1",
            pageTitle: this.props.pageType,
            isNextCheck: false,
            isInvoiceSlideOut: false,
            isTransactionSlideOut: false,
            isTransferSlideOut: false,
            addTransactionAmount: false,
            isSummaryEnabled: false,
            transAmount: "",
            ehidList: [],
            coaList: [],
            isLoader: false,
            isAddTransaction: false,
            isSaveClicked: false,
            isDeleteClicked: false,
            sildeoutHid: "",
            sildeoutCode: "",
            accPerStatus: "0",
            adjustmentReq: {
                UniqueNo: "",
                SplitHid: 0,
                BackHID: 0,
                hotelName: "",
                OprID: 0,
                Date: new Date(new Date(this.props.accper).getFullYear(), new Date(this.props.accper).getMonth() + 1, 0),
                AccPer: "",
                COA: "",
                COAName: "",
                Sign: "",
                Adjustment: "",
                Discount: 0,
                NetAmount: 0,
                Type: "",
                Description: "",
                CLMonth: "",
                CLYear: "",
                AmtType: "",
                CheckNo: "",
                EntryType: "",
                EntryStatus: ""
            },
            isCreditCardSlideout: false,
            ccVid: 0,
            voidDate: new Date(),
            voidPeriod: "",
            defaultvoidPeriod: "Accounting Period",
            minDate: new Date(),
            expandedEpayList: [],
            nonExpandList: [],
            BtnStatus: "",
            chunkSize: 200,
        };
    }

    componentDidMount() {
        localStorage.setItem("scrollPos", "0");
        let newDate = new Date(this.state.accper);
        let strMonth = Number((newDate).getMonth()) + 1;
        let strYear = (newDate).getFullYear();
        let enddate = (Number(strMonth) > 9 ? strMonth : "0" + strMonth) + "/01/" + strYear;
        let accountPeriod = (Number(strMonth) > 9 ? strMonth : "0" + strMonth) + "/01/" + strYear.toString().substring(2, 4);
        this.setState({ EndDate: enddate, accountPeriod, ClMonth: strMonth, ClYear: strYear }, () => {
            if (this.state.AccountType === "All") {
                this.getReconcileAllData();
            } else {
                this.getAccountStatus();
                this.getReconcilationData();
                this.getEhidList();
                if (this.state.acctType !== "C") {
                    this.getCOAList();
                }
            }
        })

    }

    getEhidList = () => {
        let request: any = {};
        request.Type = this.state.acctType === "C" ? "CCReconcileAdj" : "ReconcileAdj";
        request.OprId = this.state.OprID;
        request.Accper = this.state.accper;
        reconcileService.GetHotelsForAddAdjustment(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ ehidList: result })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    getCOAList = () => {
        let request: any = {};
        request.Vid = 0;
        reconcileService.GetCOAListForAddAdjustment(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    result.map((items) => ((items["label"] = items.name)));
                    this.setState({ coaList: result })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    getAccountStatus = () => {
        let request: any = {};
        request.Hid = this.state.hidValue;
        request.Type = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.Accper = this.state.accper;
        reconcileService.GetReconcileStatusOtherWithdrawal(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ status: result[0].result })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    getAccountPeriodStatus = (hid, mode, EntryStatus) => {
        let request: any = {};
        request.hotelID = hid;
        request.Type = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.Accperiod = this.state.accper;
        this.setState({ isSaveClicked: true, isDeleteClicked: true })
        reconcileService.GetHotelStatusAccPer(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    if (result[0].status === "1") {
                        if (mode === "del") {
                            Utils.toastError("Record cannot be delete in inactive period.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "Reconcile",
                            });
                            this.setState({ isSaveClicked: false, isDeleteClicked: false });
                            return false;
                        } else {
                            Utils.toastError("Record cannot be saved in inactive period.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "Reconcile",
                            });
                            this.setState({ isSaveClicked: false, isDeleteClicked: false });
                            return false;
                        }
                    } else {
                        if (mode === "del") {
                            this.adjustmentAction(EntryStatus);
                        } else if (mode === "add") {
                            this.showAddTransaction(this.state.sildeoutHid, this.state.sildeoutCode);
                        } else {
                            this.adjustmentAction(EntryStatus);
                        }
                    }
                } else {
                    return false;
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "Reconcile",
                });
                this.setState({ isSaveClicked: false, isDeleteClicked: false });
                reject();
            });
    }

    getReconcileAllData = () => {
        this.setState({ isLoader: true })
        let request: any = {};
        request.Hid = this.state.hidValue;
        request.Flag = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.ClMonth = this.state.ClMonth;
        request.ClYear = this.state.ClYear;
        request.EndDate = this.state.EndDate;
        request.acctType = this.state.acctType;
        reconcileService.GetReconcileAllData(request)
            .then(async (result: any | null) => {
                if (result != null && result.length > 0) {
                    result.forEach(element => {
                        element.isReconcile = element.isReconcile === 1 ? true : false;
                        element.payment = Number(Number(element.payment).toFixed(2));
                        element.netAmount = Number(Number(element.netAmount).toFixed(2));
                        element.date1 = new Date(element.date);
                        element.deposit = Number(Number(element.deposit).toFixed(2));
                        element.adjustment = Number(Number(element.adjustment).toFixed(2));
                        element.description1 = element.description.toString().toLowerCase().replace("|", "!").replace("@", "!").replace("\\", "!").replace("[", "!").replace("]", "!").replace("?", "!").replace("`", "!").replace("^", "!").replace("_", "!").replace("~", "!").replace("{", "!").replace("}", "!").replace(">", "!").replace("<", "!").replace(";", "!").replace(":", "!").replace("=", "!");
                        element.clMonth = element.clMonth === null ? "" : element.clMonth;
                        element.vendor1 = element.vendor.toString().toLowerCase();
                        if (isNaN(element.ckNo)) {
                            element.ckNo1 = element.ckNo.toString().toUpperCase();
                            element.ckNo2 = element.ckNo.length === 0 ? 0 : 9999999999999;
                        } else {
                            element.ckNo1 = element.ckNo;
                            element.ckNo2 = Number(element.ckNo);
                        }
                        element.adjustment1 = element.ckNo.toLowerCase() !== "adj" ? -9999 : Number(Number(element.adjustment).toFixed(2));
                    });
                    this.calculateHeaderData(result, "onload")
                }
                else {
                    this.setState({ tableData: [], isLoader: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ tableData: [], isLoader: false }, () => {
                    Utils.toastError(error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                });
                reject();
            });
    }

    getReconcilationData = () => {
        this.setState({ isLoader: true })
        let request: any = {};
        request.Hid = this.state.hidValue;
        request.Flag = this.state.AccountType;
        request.OprID = this.state.OprID;
        request.ClMonth = this.state.ClMonth;
        request.ClYear = this.state.ClYear;
        request.EndDate = this.state.EndDate;
        request.acctType = this.state.acctType;
        reconcileService.GetReconcileData(request)
            .then(async (result: any | null) => {
                if (result != null && result.length > 0) {
                    result.forEach(element => {
                        element.isReconcile = element.isReconcile === 1 ? true : false;
                        //element.payment = Number(Number(element.payment).toFixed(2));
                        element.netAmount = Number(Number(element.netAmount).toFixed(2));
                        element.date1 = new Date(element.date);
                        element.adjustment = Number(Number(element.adjustment).toFixed(2));
                        element.description1 = element.description.toString().toLowerCase().replace("|", "!").replace("@", "!").replace("\\", "!").replace("[", "!").replace("]", "!").replace("?", "!").replace("`", "!").replace("^", "!").replace("_", "!").replace("~", "!").replace("{", "!").replace("}", "!").replace(">", "!").replace("<", "!").replace(";", "!").replace(":", "!").replace("=", "!");
                        element.clMonth = element.clMonth === null ? "" : element.clMonth;
                        element.vendor1 = element.vendor.toString().toLowerCase();
                        if (isNaN(element.ckNo)) {
                            element.ckNo1 = element.ckNo.toString().toUpperCase();
                            element.ckNo2 = element.ckNo.length === 0 ? 0 : 9999999999999;
                        } else {
                            element.ckNo1 = element.ckNo;
                            element.ckNo2 = Number(element.ckNo);
                        }
                        element.payment = this.props.pageType === "Checks" ? (element.ckNo.toLowerCase() === "adj" ? Number(Number(0).toFixed(2)) :
                            Number(Number(element.payment).toFixed(2))) : Number(Number(element.payment).toFixed(2));
                        element.adjustment1 = element.ckNo.toLowerCase() !== "adj" ? -9999 : Number(Number(element.adjustment).toFixed(2));
                    });
                    this.calculateHeaderData(result, "onload")
                }
                else {
                    this.setState({ tableData: [], isLoader: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ tableData: [], isLoader: false }, () => {
                    Utils.toastError(error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                });
                reject();
            });
    }

    calculateHeaderData = (result, type) => {
        let data = result.filter(e => e.isReconcile && e.viewType === 1);
        let netAmount = "0.00";
        let depositAmount = "0.00";
        let paymentAmount = "0.00";
        let adjustmentAmount = "0.00";
        let totalAmount = "0.00";
        data.forEach(element => {
            if (element.orderNo === "2") {
                if (this.state.AccountType === "All") {
                    paymentAmount = (Number(paymentAmount) + Number(element.payment)).toFixed(2);
                    depositAmount = (Number(depositAmount) + Number(element.deposit)).toFixed(2);
                } else {
                    netAmount = (Number(netAmount) + Number(element.netAmount)).toFixed(2);
                    if (element.ckNo.toLowerCase() === "adj") {
                        adjustmentAmount = (Number(adjustmentAmount) + Number(element.adjustment)).toFixed(2);
                    } else {
                        totalAmount = (Number(totalAmount) + Number(element.payment)).toFixed(2);
                    }
                }
            }
        });
        this.setState({ netAmount: this.state.AccountType === "All" ? (Number(depositAmount) - Number(paymentAmount)) : netAmount, totalAmount, adjustmentAmount, transNetAmount: Number(result[0].transAmount), isLoader: false }, () => {
            this.setState({ verianceAmount: Number(this.state.transNetAmount) !== 0 ? (Number(this.state.netAmount) - Number(this.state.transNetAmount)).toFixed(2) : "0.00" })
        });
        if (type === "onload") {
            this.sortTableData(result, this.state.sortingField);
        }
        setTimeout(() => {
            let isHozontalScroll = this.reconcileList.current?.childNodes[0];
            if (isHozontalScroll.scrollWidth > isHozontalScroll.clientWidth) {
                this.setState({ ishorizontalScroll: true });
            } else {
                this.setState({ ishorizontalScroll: false });
            }
            this.reconcileList.current?.childNodes[0].scrollTo({ top: localStorage.getItem("scrollPos"), behavior: 'smooth', });
        }, 100);
    }

    hideOtherWithdrawalsModal = () => {
        this.props.handlePendingReconcileModal(false)
    }

    onSingleReconcile = (event, rowIndex, rowInfo) => {
        let selectedRows: any = [];
        let data = [...this.state.tableData];
        this.setState({ BtnStatus: "Single" });
        if (event.shiftKey) {
            if (this.state.selectedRows.length > 0) {
                if (this.state.selectedRows[0].index !== rowIndex) {
                    if (Number(this.state.selectedRows[0].index) < Number(rowIndex)) {
                        for (let i = this.state.selectedRows[0].index; i <= rowIndex; i++) {
                            if (data[i].type.toLowerCase() !== "nothing") {
                                selectedRows.push(data[i]);
                            }
                        }
                    } else {
                        for (let i = rowIndex; i <= this.state.selectedRows[0].index; i++) {
                            if (data[i].type.toLowerCase() !== "nothing") {
                                selectedRows.push(data[i]);
                            }
                        }
                    }
                } else {
                    this.onSingleRowEvent(rowInfo);
                }
            } else {
                rowInfo.index = rowIndex;
                selectedRows.push(rowInfo);
            }
            this.setState({ selectedRows }, () => {
                if (this.state.selectedRows.length > 2) {
                    this.saveReconcileData(this.state.selectedRows);
                }
            })

        } else {
            this.onSingleRowEvent(rowInfo);
        }
    }

    onSingleRowEvent(rowInfo) {
        this.setState({ selectedRows: [rowInfo] }, () => {
            this.saveReconcileData(this.state.selectedRows);
        })
    }

    onReconcileAll = () => {
        let msg = "";
        let msg2 = "";
        let title = "";
        let BtnStatus = "";
        let selectedRows: any = [];
        let data = [...this.state.tableData];
        if (this.state.tableData.some(e => !e.isReconcile && e.orderNo === "2" && e.viewType === 1 && e.islocked === 0)) {
            selectedRows = data.filter(e => e.isReconcile === false && e.orderNo === "2" && e.islocked === 0);
        } else {
            selectedRows = data.filter(e => !e.isReconcile === false && e.orderNo === "2" && e.islocked === 0);
        }
        let bankSyncData = selectedRows.some(e => e.bankSyncID !== null && e.bankSyncID !== "" && e.bankSyncID !== 0);

        if (this.state.tableData.some(e => !e.isReconcile && e.orderNo === "2" && e.viewType === 1 && e.islocked === 0)) {
            title = "Reconcile All";
            BtnStatus = "ReconcileAll";
            msg = "Are you sure you want to reconcile all transactions?";
        } else {
            title = "Unreconcile All";
            BtnStatus = "UnreconcileAll";
            if (bankSyncData) {
                msg = "Are you sure you would like to unreconcile all transactions?";
                msg2 = "Some transactions are grouped with 1 or more transactions when they were reconciled. Unreconciling these transactions will unreconcile any associated transactions."
            } else {
                msg = "Are you sure you would like to unreconcile all transactions?";
            }
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-overlay">
                        <div className="react-confirm-alert">
                            <div className="react-confirm-alert-body">
                                <h1>{title}</h1>
                                <div className="msg">{msg}</div>
                                {msg2 !== "" && <div className="msg">{msg2}</div>}
                                <div className="react-confirm-alert-button-group">
                                    <button onClick={() => {
                                        this.onReconcileAllTransactions(selectedRows, BtnStatus);
                                        onClose();
                                    }}>Confirm</button>
                                    <button onClick={onClose}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };

    onReconcileAllTransactions = (selectedRows, BtnStatus) => {
        this.setState({ isSummaryEnabled: true, BtnStatus: BtnStatus }, () => {
            this.saveReconcileTransaction(selectedRows);
        });
    }

    isModalShowUp = (selectedRows) => {
        confirmAlert({
            title: "Reconcile confirmation",
            message:
                "This transaction was grouped with 1 or more transactions when this was reconciled. Unreconciling this transaction will unreconcile any associated transactions and the transaction will be deleted",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.saveReconcileTransaction(selectedRows),
                },
                {
                    label: "No",
                    onClick: () => this.setState({ isSummaryEnabled: false }, () => { reject() }),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    };

    saveReconcileData(selectedRows) {
        if (selectedRows.length > 0) {
            let bankSyncData = selectedRows.some(e => e.bankSyncID !== null && e.bankSyncID !== 0);
            if (bankSyncData) {
                this.isModalShowUp(selectedRows);
            } else {
                this.saveReconcileTransaction(selectedRows);
            }
        }
    }

    saveReconcileTransaction(selectedRows) {
        localStorage.setItem("scrollPos", this.reconcileList.current?.childNodes[0].scrollTop);
        this.setState({ selectedRows: [] });
        let tableData = [...this.state.tableData];
        let requestArr: any = [];
        selectedRows.forEach(element => {
            let request: any = {};
            request.Etype = element.etype;
            request.Uniqueno = element.uniqueno;
            request.Accper = this.state.accper;
            request.Type = element.type;
            request.isReconcile = !element.isReconcile;
            request.acctType = element.acctType;
            request.Oprid = element.oprid;
            request.Hid = element.hid;
            request.Date = element.date;
            requestArr.push(request);
        });

        selectedRows.forEach(element => {
            if (element.bankSyncID === null || element.bankSyncID === 0) {
                let index = tableData.indexOf(element);
                element.isReconcile = !element.isReconcile;
                element.clMonth = element.isReconcile ? this.state.periodValue.replace("/", "-") : "";
                tableData[index] = element;
            } else {
                let data = tableData.filter(e => e.bankSyncID === element.bankSyncID);
                data.forEach(ele => {
                    let index = tableData.indexOf(ele);
                    if (ele.ckNo === 'ADJ') {
                        tableData.splice(index, 1);
                    } else {
                        ele.isReconcile = !ele.isReconcile;
                        ele.clMonth = ele.isReconcile ? this.state.periodValue.replace("/", "-") : "";
                        tableData[index] = ele;
                    }
                });
            }
        });
        this.setState({ tableData: [] }, () => {
            this.calculateHeaderData(tableData, "reload");
            this.setState({ tableData: tableData }, () => {
                if (requestArr.length > 0) {
                    this.saveReconcileDataAPI(requestArr);
                    // this.sliceIntoChunks(requestArr, this.state.chunkSize);
                }

            })
        })
        ///==== For update reconcile date in table state=====/////
    }


    // sliceIntoChunks(arr, chunkSize) {
    //     for (let i = 0; i < arr.length; i += chunkSize) {
    //         let chunk = arr.slice(i, i + chunkSize);
    //         this.saveReconcileDataAPI(chunk);
    //     }
    // }


    saveReconcileDataAPI(requestArr) {
        if (this.props.pageType === "Reconcile All") {
            let request: any = {};

            //"Recocnile All"? BtnName ="single" ? row[0]?.hid :-1 :  state.hidvalue 
            request.Hid = this.state.BtnStatus === "Single" ? requestArr[0]?.Hid : -1;
            request.Oprid = this.state.OprID;
            request.Accper = this.state.accper;
            request.Type = "";
            request.amount = Utils.removeInvaildCharFromAmount("0");
            request.PageName = this.props.pageType === "Reconcile All" ? "ReconcileAll" : "NewGroup"
            request.BtnStatus = this.state.BtnStatus;
            reconcileService.SaveReconcileStatus(request)
                .then(async (result: any | null) => {
                    //debugger;
                    if (result.message === "Success") {
                        this.setState({ status: result.result[0].result });
                        this.SaveReconcileDetails(requestArr);

                    } else {
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                });

        }
        else if (this.state.status === "Reconciled" && this.props.pageType !== "Reconcile All") {

            let request: any = {};
            let actionType = "reconcile";
            request.Hid = -1;//this.state.hidValue;
            request.Oprid = this.state.OprID;
            request.Accper = this.state.accper;
            request.Type = this.state.AccountType;
            request.Etype = "Other";
            request.TransType = "Operating"
            request.EntryConcile = "Yes";
            request.Status = this.state.status === "Unreconciled" ? "Reconciled" : "Unreconciled";
            request.actionType = actionType;
            request.amount = Utils.removeInvaildCharFromAmount("0");
            reconcileService.SaveCompleteOtherWithdrawal(request)
                .then(async (result: any | null) => {
                    if (result.saveStatus === "Success") {
                        this.setState({ status: request.Status });
                        this.SaveReconcileDetails(requestArr);
                    } else {
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        }
        else {
            this.SaveReconcileDetails(requestArr);
        }

    }

    SaveReconcileDetails(requestArr) {
        reconcileService.SaveReconcileOtherWithdrawal(requestArr)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.saveStatus === "Success") {

                        if (this.state.isSummaryEnabled) {
                            let tabData = [...this.state.tableData];
                            this.setState({ isSummaryEnabled: false }, () => {
                                this.setState({ tableData: [] }, () => {
                                    this.setState({ tableData: tabData })
                                })
                            });
                        }
                    } else {
                        this.refreshPage(result);
                    }
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                    this.setState({ isSummaryEnabled: false });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "Reconcile",
                });
                this.setState({ isSummaryEnabled: false });
                reject();
            });
    }
    refreshPage = (result) => {
        confirmAlert({
            title: "Alert!",
            message: result.message,
            buttons: [
                {
                    label: "Ok",
                    onClick: () => this.reloadTable(result),
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    reloadTable = (result) => {
        if (result.messageCode.toString().toLowerCase() === "Refresh".toLowerCase()) {
            if (this.state.AccountType === "All") {
                this.getReconcileAllData();
            } else {
                this.getReconcilationData();
            }
        }
    }

    addReconcileVoid = (selectedRow) => {
        let tableData = [...this.state.tableData]
        let request: any = {};
        request.UniqueNo = selectedRow.uniqueno;
        request.AcctType = this.state.acctType;
        request.Date = selectedRow.date;
        request.Hid = selectedRow.hid;
        let index = this.state.tableData.indexOf(selectedRow);
        reconcileService.AddReconcileVoid(request)
            .then(async (result: any | null) => {
                if (result.success) {
                    if (result.result[0].result === "VOID") {
                        toast.success("Entry voided successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });

                        let tableData = this.state.tableData.splice(index, 1);
                        this.calculateHeaderData(tableData, "reload");

                    } else if (result.result[0].result === "Closed") {
                        Utils.toastError("Record cannot be saved in inactive period.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    } else if (result.result[0].result === "Duplicate") {
                        Utils.toastError("Entry already voided. Please refresh the page.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    } else if (result.result[0].result === "Reconciled") {
                        Utils.toastError("Entry already reconciled. Please refresh the page.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    }

                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                }
                this.setState({ showVoidModal: false })
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    isVoidModalShowUp = (selectedRow) => {
        confirmAlert({
            title: "Void Payroll Entries",
            message:
                "Are you sure you want to void this entry?",
            buttons: [
                {
                    label: "Void",
                    onClick: () => this.addReconcileVoid(selectedRow),
                },
                {
                    label: "Close",
                    onClick: () => reject(),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    };

    getDropDownArrowIcon() {
        return (
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" fill="#6A6E73" />
            </svg>
        );
    }

    getDropDownHomeIcon() {
        return (
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.466553 4.31579V7.19369H1.22661V11.5087H0.466553V13.6666H1.22661H3.50677H5.02688H7.30704H8.82715H11.1073H12.6274L14.9076 13.6674V13.6666H15.6676V11.5087H14.9076V7.19369H15.6676V4.31579L8.06709 1.90735e-05L0.466553 4.31579ZM3.50677 11.5087V7.19369H5.02688V11.5087H3.50677ZM7.30704 11.5087V7.19369H8.82715V11.5087H7.30704ZM12.6274 11.5087H11.1073V7.19369H12.6274V11.5087ZM9.5872 4.31579C9.5872 5.10989 8.90619 5.75438 8.06709 5.75438C7.228 5.75438 6.54699 5.10989 6.54699 4.31579C6.54699 3.52169 7.228 2.8772 8.06709 2.8772C8.90619 2.8772 9.5872 3.52169 9.5872 4.31579Z" fill="white" />
            </svg>
        );
    }

    handleSaveButton = (actionType, value) => {
        let request: any = {};
        request.Hid = -1;//this.state.hidValue;
        request.Oprid = this.state.OprID;
        request.Accper = this.state.accper;
        request.Type = this.state.AccountType;
        request.Etype = "Other";
        request.TransType = "Operating"
        request.EntryConcile = "Yes";
        request.Status = actionType === "transaction" ? this.state.status : this.state.status === "Unreconciled" ? "Reconciled" : "Unreconciled";
        request.actionType = actionType;
        request.amount = Utils.removeInvaildCharFromAmount(value);
        reconcileService.SaveCompleteOtherWithdrawal(request)
            .then(async (result: any | null) => {
                if (result.saveStatus === "Success") {
                    this.setState({ status: request.Status }, () => {
                        if (actionType === "transaction") {
                            toast.success("Statement Total added successfully.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "Reconcile",
                            });
                            if (this.state.AccountType === "All") {
                                this.getReconcileAllData();
                            } else {
                                this.getReconcilationData();
                            }

                        } else {
                            if (this.state.isNextCheck) {
                                this.props.handleNextbutton();
                            } else {
                                let msg = this.state.status === "Unreconciled" ? "Account unreconciled successfully." : "Account reconciled successfully.";
                                toast.success(msg, {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    containerId: "Reconcile",
                                });
                            }
                        }
                    })
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    sortTableData = (data: any, fieldName) => {
        localStorage.setItem("scrollPos", this.reconcileList.current?.childNodes[0].scrollTop);


        if (fieldName === "date1") {
            if (this.state.sortingOrder === "asc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'ckNo2', "ckNo1", 'vendor1'], ['asc', 'asc', 'asc', 'asc', 'asc'])
            } else if (this.state.sortingOrder === "desc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'ckNo2', "ckNo1", 'vendor1'], ['asc', 'desc', 'desc', 'desc', 'desc'])
            }
        } else if (fieldName === "ckNo1") {
            if (this.state.sortingOrder === "asc") {
                data = _.orderBy(data, ['orderNo', 'ckNo2', fieldName], ['asc', 'asc', 'asc'])
            } else if (this.state.sortingOrder === "desc") {
                data = _.orderBy(data, ['orderNo', 'ckNo2', fieldName], ['asc', 'desc', 'desc'])
            }
        } else {
            if (this.state.sortingOrder === "asc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'date1'], ['asc', 'asc', 'asc'])
            } else if (this.state.sortingOrder === "desc") {
                data = _.orderBy(data, ['orderNo', fieldName, 'date1'], ['asc', 'desc', 'desc'])
            }
        }

        this.setState({ tableData: data }, () => {
            let nonExpandList: any = [];
            data.forEach(element => {
                if (element.refID === "") {
                    nonExpandList = [...nonExpandList, element.rowId]
                }
            });
            this.setState({ nonExpandList });
            this.reconcileList.current?.childNodes[0].scrollTo({ top: localStorage.getItem("scrollPos"), behavior: 'smooth', });
        });
    }

    handleCheckNext = (e) => {
        this.setState({ isNextCheck: e.target.checked })
    }

    rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            if (row.ckNo !== "ePay") {
                this.rowEventsClick(row);
            }
        }
    }

    rowEventsChild = {
        onClick: (e: any, row: any, rowIndex: any) => {
            this.rowEventsClick(row);
        }
    }

    rowEventsClick = (row) => {
        localStorage.setItem("scrollPos", this.reconcileList.current?.childNodes[0].scrollTop);
        let rowData = row;
        let etype = this.getEtype(row.etype, "main");
        if (etype === "payment") {
            this.addTransaction(rowData, "invoice");
        } else if (etype === "Withdrawal") {
            if (row.uniqueno.includes(",")) {
                this.addTransaction(rowData, "invoice");
            } else {
                this.addTransaction(rowData, "withdrawal");
            }
        } else if (etype === "Transfer") {
            this.addTransfer(rowData);
        } else if (etype === "VendorDeposit" || etype === "Deposits" || etype === "Deposit") {
            rowData.etype = "Deposit"
            this.addTransaction(rowData, "Deposit");
        } else if ((row.etype.toLowerCase() === "newpayroll" || row.etype.toLowerCase() === "payroll"
            || row.etype.toLowerCase() === "payrollcheckmanual" || row.etype.toLowerCase() === "payrollcheckauto")) {
            if (this.props.pageType === "Checks" && !row.isReconcile) {
                this.isVoidModalShowUp(rowData);
            } else {
                Utils.toastError("Payroll entries cannot be opened from here.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "Reconcile",
                });
            }

        } else if ((row.etype === "ReconcileAdjustment" || row.etype === "MiscReconcileAdjustment" || row.etype === "PayrollAdjustment") && this.props.pageType === "Checks") {
            if (row.isReconcile) {
                Utils.toastError("Reconciled entry cannot be edited. Please unreconcile the entry before editing.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "Reconcile",
                });
            } else if (this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled") {
                this.setState({
                    defaultCoa: [{ id: rowData.coa, label: rowData.coaName }],
                    adjustmentReq: {
                        UniqueNo: rowData.uniqueno,
                        SplitHid: rowData.hid,
                        BackHID: rowData.hid,
                        hotelName: rowData.lettercode,
                        OprID: rowData.oprid,
                        Date: new Date(rowData.date),
                        AccPer: rowData.accper,
                        COA: rowData.coa,
                        COAName: rowData.coaName,
                        Sign: "1",
                        Adjustment: Number(rowData.adjustment).toFixed(2),
                        Discount: 0,
                        NetAmount: rowData.netAmount,
                        Type: rowData.type,
                        Description: rowData.description,
                        CLMonth: this.state.ClMonth,
                        CLYear: this.state.CLYear,
                        AmtType: "Adjustment",
                        CheckNo: "",
                        EntryType: "Other",
                        EntryStatus: "",
                    }
                }, () => {
                    this.setState({ isAddAdjustment: !this.state.isAddAdjustment })
                })
            }
        } else if (this.state.acctType === "C") {
            if (row.etype.toLowerCase().includes("ccdeposit") || row.etype.toLowerCase().includes("ccwithdrawal") || row.etype.toLowerCase().includes("ccinvoice")) {
                this.showCCTransaction(rowData, row.etype);
            }
        }
    }

    showCCTransaction = (row, type) => {
        if (type.toLowerCase().includes("ccdeposit") || type.toLowerCase().includes("ccwithdrawal")) {
            let registerRequest = {} as any;
            registerRequest.UniqueNo = row.uniqueno;
            registerRequest.oprID = Number(row.oprid);
            registerRequest.acctType = row.acctType;
            this.setState({
                registerRequest, sildeoutHid: Number(row.hid), sildeoutCode: row.lettercode,
                trUniqueNo: row.uniqueno,
            }, () => {
                this.setState({ isCreditCardSlideout: true, });
            });
        }
        else if (type.toLowerCase().includes("ccinvoice")) {
            let selectedRow: any = {};
            selectedRow.hid = row.hid;
            selectedRow.groupID = row.groupID;
            selectedRow.oprId = row.oprid;
            selectedRow.checkno = row.checkNo;
            selectedRow.series = row.series;
            selectedRow.invPostFix = row.invPostFix === null ? "" : row.invPostFix;
            let registerRequest = {} as any;
            registerRequest.UniqueNo = row.uniqueno;
            registerRequest.oprID = Number(row.oprid);
            registerRequest.acctType = row.acctType;
            this.setState({
                trUniqueNo: row.uniqueno, pageType: "payment",
                sildeoutHid: Number(row.hid), registerRequest, selectedRow, sildeoutCode: row.lettercode,
            }, () => {
                this.setState({ isTransactionSlideOut: true });
            });
        }
    }

    addInvoice = (row) => {
        let selectedRow: any = {};
        selectedRow.hid = row.hid;
        selectedRow.groupID = row.groupID;
        selectedRow.oprId = row.oprid;
        selectedRow.checkno = row.checkNo;
        selectedRow.series = row.series;
        selectedRow.invPostFix = row.invPostFix === null ? "" : row.invPostFix;
        let registerRequest = {} as any;
        registerRequest.UniqueNo = row.uniqueno;
        registerRequest.oprID = Number(row.oprid);
        registerRequest.acctType = row.acctType;
        this.setState({
            selectedRow,
            registerRequest,
            trUniqueNo: row.uniqueno, pageType: 'Invoice',
            sildeoutHid: row.hid, sildeoutCode: row.lettercode, uploadedDate: row.date, uploadedBy: row.userName
        }, () => {
            this.setState({ isInvoiceSlideOut: true })
        })
    }

    addTransaction = (row, type) => {
        let selectedRow: any = {};
        selectedRow.hid = row.hid;
        selectedRow.groupID = row.groupID;
        selectedRow.oprId = row.oprid;
        selectedRow.checkno = row.checkNo;
        selectedRow.series = row.series;
        selectedRow.invPostFix = row.invPostFix === null ? "" : row.invPostFix;
        let registerRequest = {} as any;
        registerRequest.UniqueNo = row.uniqueno;
        registerRequest.oprID = Number(row.oprid);
        registerRequest.acctType = row.acctType;
        let etype = this.getEtype(row.etype, "main");
        this.setState({
            trUniqueNo: row.uniqueno, pageType: type === "invoice" ? "payment" : etype,
            sildeoutHid: Number(row.hid), registerRequest, selectedRow, sildeoutCode: row.lettercode,
        }, () => {
            this.setState({ isTransactionSlideOut: true });
        });
    }

    onAddTransaction = (item, mode) => {
        if (this.state.ehidList.length > 1) {
            if (mode === "show") {
                this.setState({ isAddTransaction: true })
            } else if (mode === "hide") {
                this.setState({ isAddTransaction: false, sildeoutHid: "", sildeoutCode: "" })
            } else if (mode === "hid") {
                this.setState({ sildeoutHid: item.hotelId, sildeoutCode: item.letterCode })
            } else if (mode === "add") {
                if (this.state.sildeoutHid === "") {
                    Utils.toastError("Please select ehid.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                    return false;
                }
                this.getAccountPeriodStatus(this.state.sildeoutHid, "add", "")
            }
        } else if (this.state.ehidList.length === 1) {
            this.setState({ sildeoutHid: this.state.ehidList[0].hotelId, sildeoutCode: this.state.ehidList[0].letterCode }, () => {
                this.getAccountPeriodStatus(this.state.ehidList[0].hotelId, "add", "")
            })
        } else if (this.state.acctType === "C") {
            this.setState({ isAddTransaction: true });
        } else {
            Utils.toastError("Something went wrong.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
        }
    }

    showAddTransaction(hid, hName) {
        let registerRequest = {} as any;
        registerRequest.UniqueNo = 0;
        registerRequest.oprID = Number(this.state.OprID);
        registerRequest.acctType = this.state.acctType;
        registerRequest.accountName = this.props.accountName;
        registerRequest.hotelID = Number(this.state.hidValue);
        registerRequest.hotelName = this.state.hidName;
        this.setState({
            trUniqueNo: 0, registerRequest,
            sildeoutHid: Number(hid), sildeoutCode: hName, isAddTransaction: false,
            pageType: this.props.pageType === "ACH Withdrawals" ? "Withdrawal" : "Deposit", ccVid: 0,
        }, () => {
            if (this.state.acctType === "C") {
                this.setState({ isCreditCardSlideout: true, });
            }
            else {
                this.setState({ isTransactionSlideOut: true, });
            }
        })
    }

    addTransfer = (row) => {
        let registerRequest1 = { ...this.state.registerRequest };
        registerRequest1.oprID = Number(row.oprid);
        let transferRequest = {} as any;
        let accName = row.vendor.split(" - ")[1].trim().charAt(0);
        transferRequest.acctType = accName;
        transferRequest.hasRecouncil = false;
        this.setState({
            trUniqueNo: row.uniqueno, registerRequest: registerRequest1, transferRequest,
            isTransferSlideOut: true, sildeoutHid: Number(row.hid), sildeoutCode: row.lettercode,
        })
    }

    hideSlideOut(isSave) {
        this.setState({ isInvoiceSlideOut: false, isTransactionSlideOut: false, isTransferSlideOut: false, isCreditCardSlideout: false, sildeoutHid: "", sildeoutCode: "" }, () => {
            if (isSave) {
                if (this.state.AccountType === "All") {
                    this.getReconcileAllData();
                } else {
                    this.getReconcilationData();
                }
            }
        });
    }

    OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType) => {
        if (!this.state.isBgBlue) {
            let row: any = {};
            row.hid = newrow.hotelid;
            row.etype = "Invoice";
            row.vendor = newrow.vendor;
            row.backHIDCode = newrow.lettercode;
            row.vid = newrow.vid;
            row.oprId = newrow.oprID;
            row.uniqueno = newrow.uniqueno;
            row.checkno = newrow.ckno;
            row.series = newrow.series;
            row.invPostFix = newrow.postFix;
            row.groupID = newrow.groupID;
            this.hideSlideOut(false);
            this.handlerowEvents(e, row, rowIndex, rowType);
        }
    }

    handlerowEvents = (e: any, row: any, rowIndex: any, rowType) => {
        let registerRequest1 = { ...this.state.registerRequest }
        let etype = '';
        etype = this.getEtype(row.etype, rowType);
        if (etype === "payment") {
            this.setState({
                trUniqueNo: row.uniqueno, pageType: etype, sildeoutHid: Number(row.hid),
                registerRequest: registerRequest1, selectedRow: row,
            }, () => {
                this.setState({ isTransactionSlideOut: true });
            });
        } else {
            this.setState({
                selectedRow: row, pageType: etype,
                trUniqueNo: row.uniqueno, registerRequest: registerRequest1, vid: row.vid, oprId: row.oprId,
                isInvoiceSlideOut: true, sildeoutHid: row.hid, sildeoutCode: row.backHIDCode
            })
        }
    }

    getEtype(mtype, rowType) {
        let etype = '';
        etype = registersevice.getEtype(mtype, rowType);
        return etype;
    }

    onAddAdjustment = () => {
        this.setState({
            defaultCoa: [],
            maxDate: new Date(new Date(this.state.accper).getFullYear(), new Date(this.state.accper).getMonth() + 1, 0),
            adjustmentReq: {
                UniqueNo: "",
                SplitHid: this.state.ehidList.length === 1 ? this.state.ehidList[0].hotelId : 0,
                BackHID: this.state.ehidList.length === 1 ? this.state.ehidList[0].hotelId : 0,
                hotelName: this.state.ehidList.length === 1 ? this.state.ehidList[0].letterCode : "",
                OprID: 0,
                Date: new Date(new Date(this.state.accper).getFullYear(), new Date(this.state.accper).getMonth() + 1, 0),
                AccPer: "",
                COA: "",
                COAName: "",
                Sign: "",
                Adjustment: Utils.currencyFormat(0),
                Discount: Utils.currencyFormat(0),
                NetAmount: 0,
                Type: "",
                Description: "",
                CLMonth: "",
                CLYear: "",
                AmtType: "",
                CheckNo: "",
                EntryType: "",
                EntryStatus: ""
            }
        }, () => {
            this.setState({ isAddAdjustment: !this.state.isAddAdjustment, isSaveClicked: false, isDeleteClicked: false })
        })
    }

    toggleAddAmountPP = (toggle) => {
        this.setState({ transAmount: Utils.currencyFormat(Number(this.state.transNetAmount).toFixed(2)) }, () => {
            this.setState({ addTransactionAmount: toggle })
        })
    }

    onAddTransactionAmount = () => {
        if (this.state.transAmount.length > 0) {
            this.handleSaveButton("transaction", this.state.transAmount);
            this.toggleAddAmountPP(false)
        } else {
            Utils.toastError("Please enter transaction amount.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
        }
    }

    handleAmount = (e) => {
        let newInputVal = Utils.removeInvaildCharFromAmount(e.target.value, 10);
        e.target.value = newInputVal;

        const rx_live = /^[-]?\d*(?:[.]\d*)?$/;

        let amount = this.state.transAmount;
        if (rx_live.test(e.target.value)) {
            if (e.target.value.includes(".")) {
                const timeArray = (e.target.value)?.split(".");
                if (timeArray[0].length <= 8 && timeArray[1].length <= 2) {
                    amount = e.target.value;
                }
            } else {
                if (e.target.value.length <= 8) {
                    amount = e.target.value;
                }
            }
        } else if (e.target.value.includes(".") && e.target.value.length === 1) {
            amount = e.target.value;
        }
        this.setState({ transAmount: amount })
    }

    handleEndBalBlur = (event) => {
        let amount = this.state.transAmount;
        if (!isNaN(event.target.value)) {
            let endBal = Utils.currencyFormat(amount);
            amount = endBal;
        } else {
            amount = Utils.currencyFormat(0);
        }
        if (amount === "-$0.00") {
            amount = "$0.00";
        }
        this.setState({ transAmount: amount });
    }

    onEndBalFocus = (event: any) => {
        let newval = Utils.removecurrencyFormat(event.target.value);
        let amount = newval;
        if (newval !== "0.00") {
            amount = amount;
        } else {
            amount = "";
        }
        this.setState({ transAmount: amount })
    }

    dateFormat = (date) => {
        let newDate = new Date(date);
        let strday = Number((newDate).getDate());
        let strMonth = Number((newDate).getMonth()) + 1;
        let strYear = (newDate).getFullYear();
        return strMonth + "/" + strday + "/" + strYear;
    }

    handleSelectedCoa(e) {
        this.setState({ defaultCoa: e }, () => {
            if (e.length > 0) {
                this.handleAddAdjustmentVal(e[0], "coa", "")
            } else {
                this.handleAddAdjustmentVal({ id: "", name: "" }, "coa", "")
            }
        })
    }

    handleAddAdjustmentVal = (item, type, EntryStatus) => {
        let adjustmentReq = { ...this.state.adjustmentReq }

        if (type === "save") {
            if (this.isValidAmount()) {
                this.getAccountPeriodStatus(adjustmentReq.BackHID, "adj", EntryStatus)
            }
        } else if (type === "delete") {
            this.getAccountPeriodStatus(adjustmentReq.BackHID, "del", EntryStatus)
        } else {
            if (type === "hid") {
                adjustmentReq.SplitHid = item.hotelId;
                adjustmentReq.BackHID = item.hotelId;
                adjustmentReq.hotelName = item.letterCode;
            }

            if (type === "coa") {
                adjustmentReq.COA = item.id;
                adjustmentReq.COAName = item.name;
            }

            if (type === "date") {
                adjustmentReq.Date = new Date(item);
            }

            if (type === "amount") {
                let e = item;
                let newInputVal = Utils.removeInvaildCharFromAmount(e.target.value, 10);
                e.target.value = newInputVal;

                const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
                let amount = adjustmentReq.Adjustment;
                if (rx_live.test(e.target.value)) {
                    if (e.target.value.includes(".")) {
                        const timeArray = (e.target.value)?.split(".");
                        if (timeArray[0].length <= 8 && timeArray[1].length <= 2) {
                            amount = e.target.value;
                        }
                    } else {
                        if (e.target.value.length <= 8) {
                            amount = e.target.value;
                        }
                    }
                } else if (e.target.value.includes(".") && e.target.value.length === 1) {
                    amount = e.target.value;
                }
                adjustmentReq.Adjustment = amount;
                adjustmentReq.NetAmount = amount;
            }

            if (type === "amtblur") {
                let amount = "";
                let e = item;
                amount = adjustmentReq.Adjustment;

                if (!isNaN(e.target.value)) {
                    let endBal = Utils.currencyFormat(amount);
                    amount = endBal;
                } else {
                    amount = Utils.currencyFormat(0);
                }
                if (amount === "-$0.00") {
                    amount = "$0.00";
                }

                adjustmentReq.Adjustment = amount;
                adjustmentReq.NetAmount = amount;
            }

            if (type === "amtfocus") {
                let amount = "";
                let e = item;
                let newval = Utils.removecurrencyFormat(e.target.value);
                amount = newval;

                if (newval !== "0.00") {
                    adjustmentReq.Adjustment = amount;
                } else {
                    adjustmentReq.Adjustment = "";
                }
                adjustmentReq.NetAmount = amount;
            }

            if (type === "description") {
                adjustmentReq.Description = item.target.value;
            }

            this.setState({ adjustmentReq });
        }

    }

    adjustmentAction(EntryStatus) {
        let adjustmentReq = { ...this.state.adjustmentReq }
        adjustmentReq.OprID = this.state.OprID;
        adjustmentReq.AccPer = this.state.accper;
        adjustmentReq.Type = this.state.acctType === "M" ? "Withdrawals" : this.state.AccountType;
        adjustmentReq.CLMonth = this.state.ClMonth;
        adjustmentReq.CLYear = this.state.ClYear;
        adjustmentReq.EntryType = "Other";
        adjustmentReq.AmtType = "Adjustment";
        adjustmentReq.Sign = "1";
        adjustmentReq.EntryStatus = EntryStatus;
        adjustmentReq.acctType = this.state.acctType;
        adjustmentReq.Date = this.dateFormat(new Date(adjustmentReq.Date));
        adjustmentReq.Adjustment = Utils.removecurrencyFormat(adjustmentReq.Adjustment);
        adjustmentReq.NetAmount = Utils.removecurrencyFormat(adjustmentReq.NetAmount);
        adjustmentReq.Discount = Utils.removecurrencyFormat(adjustmentReq.Discount);

        reconcileService.AddAdjustmentsWithdrawal(adjustmentReq)
            .then(async (result: any | null) => {
                if (result.saveStatus === "Success") {
                    if (result.messageCode === "INSERT") {
                        toast.success("Adjustment added successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    } else if (result.messageCode === "UPDATE") {
                        toast.success("Adjustment updated successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    } else if (result.messageCode === "DELETE") {
                        toast.success("Adjustment deleted successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "Reconcile",
                        });
                    }

                    this.onAddAdjustment();
                    this.getReconcilationData();
                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }


    isValidAmount = () => {
        let adjustmentReq = { ...this.state.adjustmentReq }
        if (adjustmentReq.SplitHid === 0) {
            Utils.toastError("Please select hotel.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if (adjustmentReq.COA === 0 || adjustmentReq.COA === "") {
            Utils.toastError("Please select COA.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if (adjustmentReq.Date === "") {
            Utils.toastError("Please select date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if (Utils.removecurrencyFormat(adjustmentReq.Adjustment) === "") {
            Utils.toastError("Please enter amount.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if (Utils.removecurrencyFormat(adjustmentReq.Adjustment) === "0.00") {
            Utils.toastError("Please enter valid amount.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }

        if (adjustmentReq.Description.trim().length === 0) {
            Utils.toastError("Please enter description.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
            });
            return false;
        }
        return true;
    }

    exportData = () => {
        let exportData: any = {}
        exportData.tableData = this.state.tableData.filter(e => e.viewType === 1);;
        exportData.type = this.state.AccountType;
        reconcileService.reconcileExportData(exportData)
            .then(async (result: any | null) => {
                if (result.saveStatus === "Success") {

                } else {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "Reconcile",
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    sortImage = (order) => {
        if (!order) return (
            <span className="ascending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        else if (order === 'asc') return (
            <span className="ascending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        else if (order === 'desc') return (
            <span className="descending">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
                </svg>
            </span>
        );
        return null;
    }

    handleSortByColumn = (field) => {
        this.setState({
            sortingField: field,
            sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
        }, () => {
            this.sortTableData(this.state.tableData, this.state.sortingField);
        });
    }

    render() {
        const rowClasses = (row, rowIndex) => {
            let classes = '';

            if (row.isReconcile) {
                classes = classes + ' reconcile-entry';
            }

            if (row.ckNo !== "ePay") {
                classes = classes + ' hideExpndIcn';
            }

            if (row.orderNo === "4") {
                classes = classes + ' tblDivider';
            }
            return classes;
        };

        const columns = [
            {
                dataField: 'date1',
                text: 'Date',
                headerClasses: "regColDate",
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`}
                                onClick={() => this.handleSortByColumn(column.dataField)}>
                                {column.text}{this.sortImage(this.state.sortingOrder)}
                            </div>
                        </div>

                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.type.toLowerCase() === "nothing" ? "" : row.date}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'vendor1',
                text: 'Vendor',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`}
                                onClick={() => this.handleSortByColumn(column.dataField)}>
                                {column.text}{this.sortImage(this.state.sortingOrder)}
                            </div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">{row.vendor}</EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'lettercode',
                text: 'EHID',
                headerClasses: 'center-align',
                classes: 'center-align',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex justify-content-center">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">{row.lettercode}</EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'description1',
                text: 'Description',
                hidden: (this.props.pageType === "Checks" || this.state.AccountType === "All") ? true : false,
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">{row.description}</EllipsisWithTooltip>
                        </div>

                    );
                },
            }, {
                dataField: 'ckNo1',
                text: 'Check No.',
                headerClasses: 'center-align',
                classes: 'center-align',
                hidden: this.state.AccountType === "All" ? false : true,
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex justify-content-center">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.type.toLowerCase() === "nothing" ? "-" : row.ckNo}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'payment',
                text: this.state.AccountType === "All" ? "Payment" : 'Amount',
                headerClasses: 'right-align rm-pos',
                classes: 'right-align',
                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <>
                            {this.props.pageType === "Checks" ? (
                                <div className="d-flex justify-content-end">
                                    <div className={`sort-col header-ttl ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                                    <div className="caption-val">{Utils.currencyFormat(this.state.totalAmount)}</div>
                                </div>
                            ) :
                                <div className="d-flex justify-content-end">
                                    <div className={`sort-col header-ttl ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                                    <div className="caption-val">
                                        <div
                                            onClick={(e) => {
                                                ((this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled" && this.state.AccountType !== "All")) ? this.toggleAddAmountPP(true) : e.preventDefault()
                                            }}>
                                            {this.state.AccountType !== "All" ? (Number(this.state.transNetAmount) !== 0 ? Utils.currencyFormat(this.state.transNetAmount) : Utils.currencyFormat(this.state.netAmount)) : Utils.currencyFormat(this.state.netAmount)}
                                        </div>
                                        {this.state.AccountType !== "All" && (
                                            <div className="grey-color">{Utils.currencyFormat(this.state.verianceAmount)}</div>
                                        )}
                                        {this.state.addTransactionAmount && (
                                            <div className="transaction-type-modal">
                                                <div className="header d-flex">
                                                    <div className="title mr-auto">Transaction Type Total</div>
                                                    <div className="close" onClick={() => { this.toggleAddAmountPP(false) }}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.4933 5.28667L9.95747 8.82167L6.42247 5.28667L5.24414 6.465L8.77914 10L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 10L14.6716 6.465L13.4933 5.28667Z" fill="#84888C" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    <Form.Group controlId="statement-ttl" className="text-left">
                                                        <Form.Label>
                                                            <div className="d-flex">
                                                                <div>Statement Total</div>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={
                                                                        <Tooltip id={"statement-ttl"}>
                                                                            {"Enter the total for this transaction type for the account statement. This will be used to show the variance."}
                                                                        </Tooltip>
                                                                    }>
                                                                    <div className="info-icn">
                                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M8.66649 7.33327H7.33315V11.3333H8.66649V7.33327Z" fill="#6A6E73" />
                                                                            <path d="M8.66649 4.6666H7.33315V5.99994H8.66649V4.6666Z" fill="#6A6E73" />
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1998 8C15.1998 11.9765 11.9763 15.2 7.9998 15.2C4.02335 15.2 0.799805 11.9765 0.799805 8C0.799805 4.02355 4.02335 0.800003 7.9998 0.800003C11.9763 0.800003 15.1998 4.02355 15.1998 8ZM14.3998 8C14.3998 11.5346 11.5344 14.4 7.9998 14.4C4.46518 14.4 1.5998 11.5346 1.5998 8C1.5998 4.46538 4.46518 1.6 7.9998 1.6C11.5344 1.6 14.3998 4.46538 14.3998 8Z" fill="#6A6E73" />
                                                                        </svg>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Form.Label>
                                                        <Form.Control placeholder="$0.00"
                                                            value={this.state.transAmount}
                                                            onChange={(e) => { this.handleAmount(e) }}
                                                            onBlur={this.handleEndBalBlur}
                                                            onFocus={this.onEndBalFocus}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="footer"><Button className="btn-primary" onClick={() => { this.onAddTransactionAmount() }}>Save</Button></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {this.props.pageType === "Checks" ? row.ckNo.toLowerCase() === "adj" ? "-" : Utils.currencyFormatWithOutdoller(row.payment) : Utils.currencyFormatWithOutdoller(row.payment)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'deposit',
                text: 'Deposit',
                hidden: this.state.AccountType === "All" ? false : true,
                headerClasses: 'right-align',
                classes: 'right-align',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {Utils.currencyFormatWithOutdoller(row.deposit)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'ckNo1',
                text: 'Check No.',
                headerClasses: 'center-align',
                classes: 'center-align',
                hidden: this.props.pageType === "Checks" ? false : true,
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex justify-content-center">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`}
                                onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.type.toLowerCase() === "nothing" ? "-" : row.ckNo}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'adjustment1',
                text: 'Adjustment',
                hidden: this.props.pageType === "Checks" ? false : true,
                headerClasses: 'right-align rm-pos',
                classes: 'right-align',

                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <>
                            {this.props.pageType === "Checks" && (
                                <div className="d-flex justify-content-end">
                                    <div className={`sort-col header-ttl ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                                    <div className="caption-val">
                                        {Utils.currencyFormat(this.state.adjustmentAmount)}
                                    </div>
                                </div>
                            )}
                        </>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.ckNo.toLowerCase() !== "adj" ? "-" : Utils.currencyFormatWithOutdoller(row.adjustment)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'netAmount',
                text: 'Net',
                hidden: this.props.pageType === "Checks" ? false : true,
                headerClasses: 'right-align rm-pos',
                classes: 'right-align',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {Utils.currencyFormatWithOutdoller(row.netAmount)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
                headerFormatter: (column: any, colIndex: any) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <div className={`sort-col header-ttl ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                            <div className="caption-val">
                                <div
                                    onClick={(e) => {
                                        ((this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled" && this.state.AccountType !== "All")) ? this.toggleAddAmountPP(true) : e.preventDefault()
                                    }}>
                                    {this.state.AccountType !== "All" ? (Number(this.state.transNetAmount) !== 0 ? Utils.currencyFormat(this.state.transNetAmount) : Utils.currencyFormat(this.state.netAmount)) : Utils.currencyFormat(this.state.netAmount)}
                                </div>
                                {this.state.AccountType !== "All" && (
                                    <div className="grey-color">{Utils.currencyFormat(this.state.verianceAmount)}</div>
                                )}
                                {this.state.addTransactionAmount && (
                                    <div className="transaction-type-modal">
                                        <div className="header d-flex">
                                            <div className="title mr-auto">Transaction Type Total</div>
                                            <div className="close" onClick={() => { this.toggleAddAmountPP(false) }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.4933 5.28667L9.95747 8.82167L6.42247 5.28667L5.24414 6.465L8.77914 10L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 10L14.6716 6.465L13.4933 5.28667Z" fill="#84888C" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="body">
                                            <Form.Group controlId="statement-ttl" className="text-left">
                                                <Form.Label>
                                                    <div className="d-flex">
                                                        <div>Statement Total</div>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip id={"statement-ttl"}>
                                                                    {"Enter the total for this transaction type for the account statement. This will be used to show the variance."}
                                                                </Tooltip>
                                                            }>
                                                            <div className="info-icn">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.66649 7.33327H7.33315V11.3333H8.66649V7.33327Z" fill="#6A6E73" />
                                                                    <path d="M8.66649 4.6666H7.33315V5.99994H8.66649V4.6666Z" fill="#6A6E73" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1998 8C15.1998 11.9765 11.9763 15.2 7.9998 15.2C4.02335 15.2 0.799805 11.9765 0.799805 8C0.799805 4.02355 4.02335 0.800003 7.9998 0.800003C11.9763 0.800003 15.1998 4.02355 15.1998 8ZM14.3998 8C14.3998 11.5346 11.5344 14.4 7.9998 14.4C4.46518 14.4 1.5998 11.5346 1.5998 8C1.5998 4.46538 4.46518 1.6 7.9998 1.6C11.5344 1.6 14.3998 4.46538 14.3998 8Z" fill="#6A6E73" />
                                                                </svg>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Form.Label>
                                                <Form.Control placeholder="$0.00"
                                                    value={this.state.transAmount}
                                                    onChange={(e) => { this.handleAmount(e) }}
                                                    onBlur={this.handleEndBalBlur}
                                                    onFocus={this.onEndBalFocus}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="footer"><Button className="btn-primary" onClick={() => { this.onAddTransactionAmount() }}>Save</Button></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                },
            }, {
                dataField: 'clMonth',
                text: 'CL',
                headerClasses: 'center-align width-132',
                classes: 'center-align',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="d-flex justify-content-center">
                            <div className={`sort-col ${this.state.sortingField === column.dataField ? 'active' : ''}`} onClick={() => this.handleSortByColumn(column.dataField)}>{column.text}{this.sortImage(this.state.sortingOrder)}</div>
                        </div>
                    )
                },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={row.isReconcile ? "reconcile-sec" : ""}>
                            <EllipsisWithTooltip placement="bottom">
                                {row.clMonth}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: '',
                text: '',
                headerClasses: 'stick-column width-132',
                classes: 'stick-column',
                headerFormatter: (column, colIndex) => {
                    return (
                        <div className="border-line"></div>
                    );
                },
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <div style={{ pointerEvents: this.state.isSummaryEnabled ? 'none' : 'auto', opacity: this.state.isSummaryEnabled ? 0.5 : 1 }}>
                        {(row.type.toLowerCase() !== "nothing" && row.orderNo !== "4") && (
                            <div className="cursor-pointer" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                {!row.isReconcile && (
                                    <div onClick={(event) => { row.islocked === 0 ? this.onSingleReconcile(event, rowIndex, row) : event.preventDefault() }}>
                                        {row.islocked === 1 ? (
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id={"rc1"}>
                                                        {"Please re-open reconciliation to make changes"}
                                                    </Tooltip>
                                                }>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" rx="12" fill="#F4F6F8" />
                                                    <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="#84888C" />
                                                </svg>
                                            </OverlayTrigger>
                                        ) : (
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="24" height="24" rx="12" fill="#F4F6F8" />
                                                <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="#84888C" />
                                            </svg>
                                        )}
                                    </div>
                                )}
                                {row.isReconcile && (
                                    <div onClick={(event) => { row.islocked === 0 ? this.onSingleReconcile(event, rowIndex, row) : event.preventDefault() }}>
                                        {row.islocked === 1 ? (
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id={"rc1"}>
                                                        {"Please re-open reconciliation to make changes"}
                                                    </Tooltip>
                                                }>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" rx="12" fill="#2CAF92" />
                                                    <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="white" />
                                                </svg>
                                            </OverlayTrigger>
                                        ) : (
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="24" height="24" rx="12" fill="#2CAF92" />
                                                <path d="M10.711 13.294L8.412 10.999L7 12.415L10.713 16.12L17.419 9.414L16.005 8L10.711 13.294Z" fill="white" />
                                            </svg>
                                        )}
                                    </div>
                                )}

                            </div>
                        )
                        }
                        <div className="border-line"></div>
                    </div>
                ),
            }];

        const childcolumns = [
            {
                dataField: '',
                text: '',
                headerClasses: 'date-col',
                classes: 'date-col',
            },
            {
                dataField: 'vendor',
                text: 'Vendor',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">{row.vendor}</EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'lettercode',
                text: 'EHID',
                headerClasses: 'center-align',
                classes: 'center-align',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">{row.lettercode}</EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'description',
                text: '',
                hidden: (this.props.pageType === "Checks" || this.state.AccountType === "All") ? true : false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">{""}</EllipsisWithTooltip>
                        </div>

                    );
                },
            }, {
                dataField: 'ckNo',
                text: 'Check No.',
                headerClasses: 'center-align',
                classes: 'center-align',
                hidden: this.state.AccountType === "All" ? false : true,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">
                                {row.type.toLowerCase() === "nothing" ? "-" : row.ckNo}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'payment',
                text: this.state.AccountType === "All" ? "Payment" : 'Amount',
                headerClasses: 'right-align',
                classes: 'right-align',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">
                                {this.props.pageType === "Checks" ? row.ckNo.toLowerCase() === "adj" ? "-" : Utils.currencyFormatWithOutdoller(row.payment) : Utils.currencyFormatWithOutdoller(row.payment)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: '',
                text: 'Deposit',
                hidden: this.state.AccountType === "All" ? false : true,
                headerClasses: 'right-align',
                classes: 'right-align',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">
                                {Utils.currencyFormatWithOutdoller(row.deposit)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'ckNo',
                text: 'Check No.',
                headerClasses: 'center-align',
                classes: 'center-align',
                hidden: this.props.pageType === "Checks" ? false : true,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">
                                {row.type.toLowerCase() === "nothing" ? "-" : row.ckNo}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'Adjustments',
                text: 'Adjustment',
                hidden: this.props.pageType === "Checks" ? false : true,
                headerClasses: 'right-align',
                classes: 'right-align',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">
                                {row.ckNo.toLowerCase() !== "adj" ? "-" : Utils.currencyFormatWithOutdoller(row.adjustment)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: 'net',
                text: 'Net',
                hidden: this.props.pageType === "Checks" ? false : true,
                headerClasses: 'right-align',
                classes: 'right-align',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div>
                            <EllipsisWithTooltip placement="bottom">
                                {Utils.currencyFormatWithOutdoller(row.netAmount)}
                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: '',
                text: '',
                headerClasses: 'cl-col',
                classes: 'cl-col',
            }, {
                dataField: '',
                text: '',
                headerClasses: 'blank-col',
                classes: 'blank-col',
            }];
        const dropDownHomeIcon = this.getDropDownHomeIcon();
        const dropDownArrowIcon = this.getDropDownArrowIcon();

        const ePayOnExpand = (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                // setTimeout(() => {
                //     let pos = this.reconcileList.current?.childNodes[0].scrollTop + this["myRef" + rowIndex].current?.offsetTop;
                //     this.reconcileList.current?.childNodes[0].scrollTo({ top: pos, behavior: 'smooth', });
                // }, 1000);
                this.setState(() => ({
                    expandedEpayList: [...this.state.expandedEpayList, row.rowId]
                }));
            } else {
                this.setState(() => ({
                    expandedEpayList: this.state.expandedEpayList.filter(x => x !== row.rowId)
                }));
            }
        }

        const expandEpayRow = {
            parentClassName: "parent-expand-foo",
            renderer: (row: any, rowIndex: any) => renderEpayChild(row, rowIndex),
            expanded: this.state.expandedEpayList,
            showExpandColumn: true,
            expandByColumnOnly: true,
            onExpand: ePayOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
            nonExpandable: this.state.nonExpandList,

        };

        const renderEpayChild = (row, rowIndex) => {
            //this["myRef" + rowIndex] = React.createRef();
            let { tableData } = this.state;
            let splitlist = [];
            splitlist = getRenderEpayChild(tableData, row)
            return (
                <div className={`ePayChildTbl ${row.isReconcile ? "row-reconcile" : ""}`}>
                    <BootstrapTable
                        data={splitlist}
                        keyField="rowId"
                        rowEvents={this.rowEventsChild}
                        columns={childcolumns}
                        hover
                        noDataIndication={'No activity for the selected time period'}
                    />
                    {/* <div ref={this["myRef" + rowIndex]}></div> */}
                </div>
            );
        }

        const getRenderEpayChild = (tableData, row) => {
            return tableData.filter(item => (item.viewType === 2 && item.refID === row.refID)
            );
        }

        return (
            <>
                <ToastContainer
                    autoClose={3000}
                    containerId={"Reconcile"}
                    enableMultiContainer
                />
                <div className="reconciliation-modal modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="d-flex align-items-center modal-header">
                                <div className="modal-title page-title mr-auto">
                                    <div className="tag-name">Reconcile Account</div>
                                    <div className="ac-name">{this.props.accountName}</div>
                                </div>
                                <div className="action d-flex">
                                    <div className="d-flex justify-content-end">
                                        <Dropdown className={this.props.headerControlsDisabled ? "ehid-selector disabled" : "ehid-selector"} alignRight>
                                            <Dropdown.Toggle id="dropdown-pending-account">
                                                <div className="d-flex align-items-center">
                                                    <div className="home-icon">{dropDownHomeIcon}</div>
                                                    <div className="ehid-name"><EllipsisWithTooltip placement="bottom">{this.props.accountName}</EllipsisWithTooltip></div>
                                                    <div className="arrow-icon">{dropDownArrowIcon}</div>
                                                </div>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                        <Dropdown className={this.props.headerControlsDisabled ? "margin-left-16 period-selector disabled" : "margin-left-16 period-selector"} alignRight>
                                            <Dropdown.Toggle id="dropdown-pending-period">
                                                <div className="d-flex align-items-center">
                                                    <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.state.periodValue}</EllipsisWithTooltip></div>
                                                    <div className="arrow-icon">{dropDownArrowIcon}</div>
                                                </div>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                        <div className="separator"></div>
                                        <Button variant="primary" type="button" className="btn-outline-primary exit-button" disabled={this.state.isSummaryEnabled} onClick={() => this.hideOtherWithdrawalsModal()}>Exit</Button>
                                        {/* {(this.props.pageType === "ACH Withdrawals" || this.props.pageType === "Checks") && ( */}
                                        <Dropdown className="more-action three-dot margin-left-16" alignRight>
                                            <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                </svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="editaccount" key="editaccount" onClick={() => this.exportData()}>Export</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* )} */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="bdy-sec rm-padding">
                                    <div className="body-header d-flex align-items-center">
                                        <div className="header-title d-flex mr-auto">
                                            <div className="sec-ttl">{this.state.acctType === "C" ? this.props.pageType === "ACH Withdrawals" ? "Withdrawals" : "Deposits" : this.state.pageTitle}</div>
                                            {this.state.AccountType !== "All" && (
                                                <div className={`header-status 
                                                ${this.state.status === "Unreconciled" ? "blue" : ""}
                                                ${this.state.status === "Locked" ? "grey" : ""}
                                                ${this.state.status === "Reconciled" ? "green" : ""}
                                                `}>{this.state.status}
                                                </div>
                                            )}
                                        </div>
                                        <div className="table-cell pos-rel">
                                            {this.state.AccountType !== "All" && (
                                                <>
                                                    {this.props.pageType === "Checks" ? (
                                                        <Button variant="primary" type="button" className="btn-link" disabled={(this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled" && !this.state.isSummaryEnabled) ? false : true} onClick={() => { this.onAddAdjustment() }}>Add Adjustment</Button>
                                                    ) : (
                                                        <>
                                                            <Button variant="primary" type="button" className="btn-link" disabled={(this.props.acctStatus === "Unreconciled" && this.state.status === "Unreconciled" && !this.state.isSummaryEnabled) ? false : true} onClick={() => { this.onAddTransaction("", "show") }}>Add Transaction</Button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {this.state.isAddTransaction && (
                                                <div className="transaction-type-modal">
                                                    <div className="header d-flex">
                                                        <div className="title mr-auto">Add Transaction</div>
                                                        <div className="close" onClick={() => { this.onAddTransaction("", "hide") }}>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.4933 5.28667L9.95747 8.82167L6.42247 5.28667L5.24414 6.465L8.77914 10L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 10L14.6716 6.465L13.4933 5.28667Z" fill="#84888C" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="body">
                                                        <Form.Group controlId="EHID">
                                                            <Form.Label>EHID</Form.Label>
                                                            <Dropdown className="custom-selectbox" alignRight>
                                                                <Dropdown.Toggle id="dropdown-periodhid">
                                                                    <EllipsisWithTooltip placement="bottom">{this.state.sildeoutCode.length === 0 ? "Select" : this.state.sildeoutCode}</EllipsisWithTooltip>
                                                                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {this.state.ehidList.map(
                                                                        (item: any, idx: any) => (
                                                                            <Dropdown.Item eventKey={item.hotelId} key={idx} onClick={() => { this.onAddTransaction(item, "hid") }}>{item.letterCode}</Dropdown.Item>
                                                                        )
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="footer"><Button className="btn-primary" disabled={this.state.isSummaryEnabled} onClick={() => { this.onAddTransaction("", "add") }}>Add Transaction</Button></div>
                                                </div>
                                            )}
                                        </div>
                                        {this.state.tableData.some(e => e.orderNo === "2" && e.viewType === 1) && (
                                            <Button variant="primary" type="button" className="btn-link"
                                                disabled={(this.props.acctStatus === "Unreconciled" ? ((this.state.AccountType === "All" || this.state.status === "Unreconciled") && !this.state.isSummaryEnabled) ? false : this.state.status === "Reconciled" ? false : true : true)}
                                                onClick={() => { this.onReconcileAll() }}>
                                                {this.state.tableData.some(e => !e.isReconcile && e.orderNo === "2" && e.viewType === 1 && e.islocked === 0) ? "Reconcile All" : "Unreconcile All"}
                                            </Button>
                                        )}



                                    </div>
                                    {this.state.isLoader ? (
                                        <ReactPageLoader useas={"reconcile"} />
                                    ) : (<div className={`body-list ${this.state.ishorizontalScroll ? "ishorizontalScroll" : ""}`} ref={this.reconcileList}>
                                        <BootstrapTable keyField='rowId'
                                            data={this.state.tableData.filter(e => e.viewType === 1)}
                                            columns={columns}
                                            rowEvents={this.rowEvents}
                                            expandRow={expandEpayRow}
                                            rowClasses={rowClasses}
                                        />
                                        <div className="headser-bg"></div>
                                        <div className="scroll-header-bg"></div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="modal-footer unsaved justify-content-start">
                                <Button variant="primary" type="button" className="mr-auto btn-outline-primary" disabled={this.state.isSummaryEnabled} onClick={() => this.hideOtherWithdrawalsModal()}>Return to Summary</Button>
                                {this.state.AccountType !== "All" && (
                                    <>
                                        <Form.Group controlId="chkNext">
                                            <Form.Check type="checkbox" id="chkNext" label="Proceed to Next" checked={this.state.isNextCheck} onChange={(e) => { this.handleCheckNext(e) }} custom />
                                        </Form.Group>
                                        <Button variant="primary" type="button" disabled={this.props.acctStatus === "Unreconciled" && !this.state.isSummaryEnabled ? false : true} onClick={() => this.handleSaveButton("reconcile", "0")} >{this.state.status === "Unreconciled" ? "Mark as Complete" : "Mark as Incomplete"}</Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="move-invoice-modal mark-invoice-as-paid reconcile-modal" show={this.state.isAddAdjustment} onHide={this.onAddAdjustment}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Adjustment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group controlId="EHID">
                                    <Form.Label>EHID</Form.Label>
                                    <Dropdown className="custom-selectbox" alignRight>
                                        <Dropdown.Toggle id="dropdown-periodhid">
                                            <EllipsisWithTooltip placement="bottom">{this.state.adjustmentReq.hotelName.length === 0 ? "Select" : this.state.adjustmentReq.hotelName}</EllipsisWithTooltip>
                                            <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {this.state.ehidList.map(
                                                (item: any, idx: any) => (
                                                    <Dropdown.Item eventKey={item.hotelId} key={idx} onClick={() => { this.handleAddAdjustmentVal(item, "hid", "") }}>{item.letterCode}</Dropdown.Item>
                                                )
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="COA" className="more-action single-select-image dropdown coa-select">
                                    <Form.Label>COA</Form.Label>
                                    <Typeahead
                                        id="id"
                                        onChange={(event) => {
                                            this.handleSelectedCoa(event);
                                        }}
                                        options={this.state.coaList}
                                        placeholder="Enter COA"
                                        selected={this.state.defaultCoa}
                                        renderMenu={(results: any, menuProps) => (
                                            <Menu {...menuProps}>
                                                {results.map((result: any, index: any) => (
                                                    <>
                                                        {result.label !== "" && (
                                                            <MenuItem option={result} position={index}>
                                                                <EllipsisWithTooltip placement="bottom">
                                                                    {result.label}
                                                                </EllipsisWithTooltip>
                                                            </MenuItem>
                                                        )}
                                                    </>
                                                ))}
                                            </Menu>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="date">
                                    <Form.Label>Date</Form.Label>
                                    <DatePicker
                                        id="paymentdate"
                                        className="form-control"
                                        placeholderText={"MM/DD/YY"}
                                        dateFormat="MM/dd/yy"
                                        maxDate={new Date(this.state.maxDate)}
                                        selected={this.state.adjustmentReq.Date === "" ? new Date() : new Date(this.state.adjustmentReq.Date)}
                                        onChange={(event: any) => this.handleAddAdjustmentVal(event, 'date', "")}
                                        disabled={this.state.adjustmentReq.UniqueNo !== "" ? true : false}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="amount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control placeholder="Amount" pattern="[+-]?\d+(?:[.,]\d+)?"
                                        value={this.state.adjustmentReq.Adjustment}
                                        onBlur={(e) => { this.handleAddAdjustmentVal(e, "amtblur", "") }}
                                        onChange={(e) => { this.handleAddAdjustmentVal(e, "amount", "") }}
                                        onFocus={(e) => { this.handleAddAdjustmentVal(e, "amtfocus", "") }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="Description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control id="txtDescription" as="textarea" placeholder="Add Description" wrap="hard" rows={3} value={this.state.adjustmentReq.Description} onChange={(e) => { this.handleAddAdjustmentVal(e, "description", "") }}
                                maxLength={99} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.adjustmentReq.UniqueNo !== "" && (
                            <Button className="btn-outline-primary" disabled={this.state.isDeleteClicked} onClick={() => { this.handleAddAdjustmentVal("", "delete", "Delete") }}>Delete</Button>
                        )}
                        <Button className="btn-primary" disabled={this.state.isSaveClicked} onClick={() => { this.handleAddAdjustmentVal("", "save", this.state.adjustmentReq.UniqueNo !== "" ? "Update" : "Insert") }}>Save</Button>
                    </Modal.Footer>
                </Modal>
                {
                    this.state.isInvoiceSlideOut && (
                        <InvoiceEntrySlideout
                            hidValue={this.state.sildeoutHid}
                            hotelName={this.state.sildeoutCode}
                            uniqueNo={this.state.trUniqueNo}
                            pageType={this.state.pageType}
                            hideSlideOut={this.hideSlideOut.bind(this)}
                            uploadedDate={this.state.uploadedDate}
                            uploadedBy={this.state.uploadedBy}
                            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                            selectedRow={this.state.selectedRow}
                        />
                    )
                }
                {
                    this.state.isTransactionSlideOut && (
                        <TransactionSlideout
                            accDetails={this.state.registerRequest}
                            hidValue={this.state.sildeoutHid}
                            hotelName={this.state.sildeoutCode}
                            uniqueNo={this.state.trUniqueNo}
                            pageType={this.state.pageType}
                            hideSlideOut={this.hideSlideOut.bind(this)}
                            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                            selectedRow={this.state.selectedRow}
                            isFromRepeating={false}
                            isReconcilePage={true}
                            accper={this.state.accountPeriod}
                        />
                    )
                }
                {
                    this.state.isTransferSlideOut && (
                        <TransferSlideout
                            accDetails={this.state.registerRequest}
                            hidValue={this.state.sildeoutHid}
                            hotelName={this.state.sildeoutCode}
                            uniqueNo={this.state.trUniqueNo}
                            pageType={this.state.pageType}
                            valuesArray={this.state.valuesArray}
                            transferRequest={this.state.transferRequest}
                            hideTrSlideOut={this.hideSlideOut.bind(this)}
                            accountStatus={this.state.accountStatus}
                            isfromSearch={this.state.isfromSearch}
                        />
                    )
                }
                {
                    this.state.isCreditCardSlideout && (
                        <CreditCardSlideout
                            accDetails={this.state.registerRequest}
                            hidValue={this.state.sildeoutHid}
                            hotelName={this.state.sildeoutCode}
                            uniqueNo={this.state.trUniqueNo}
                            oprId={this.state.OprID}
                            vid={this.state.ccVid}
                            pageType={this.props.pageType === "ACH Withdrawals" ? "CCDEBIT" : "CCCREDIT"}
                            hideCCSlideOut={this.hideSlideOut.bind(this)}
                            isReconcilePage={true}
                            accper={this.state.accountPeriod}
                        />
                    )
                }
            </>
        );
    }
}