import React, { useState, useRef } from 'react';
// import default react-pdf entry
import { Document, Page, pdfjs } from "react-pdf";
import { Spinner } from "react-bootstrap";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFTextract(props, ref) {
 // const inputRef = useRef<any>(null);
  const [file, setFile] = useState(props);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {   
    setNumPages(nextNumPages);
    props.LoadSuccess(true);
  }
  function onDocumentLoadProgress({ loaded, total }){
    console.log('Loading a document: ' + (loaded / total) * 100 + '%');
  }
  function renderLoader(){
    return (
      <div className="spiner d-flex align-items-center justify-content-center"><Spinner animation="border" variant="success" /></div>
    )    
  }
  function renderError(){
    return (
      <div className="custom-error-msg">Cannot be previewed - {file.fileName}</div>
    )    
  }
  
  return (
    <Document file={file.filePreview} onLoadSuccess={onDocumentLoadSuccess} loading={renderLoader} error={renderError}>
          {Array.from(new Array(numPages), (_el, index) => ( 
             <div className='wrap-sec'>
                <div className="custom-page-action d-flex justify-content-between align-items-center doc-header">
                    <div className="page-ttl">{file.fileName}</div>
                    <div className="page-count">Page {index + 1} of {numPages}</div>
                </div>                
                  <Page                  
                      key={`page_${index}`}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      scale={props.selectedZoom}
                  />                
            </div>
            
        ))}
    </Document>
    
  );
}

export default PDFTextract;