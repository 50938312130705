import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, Dropdown, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { HidSelector } from "../../Common/Components/HidSelector";
import Dropzone from 'react-dropzone';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { InvoiceEntrySlideout } from "./InvoiceEntrySlideout";
import "./invoiceInbox.scss";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../Common/Services/Register";
import { confirmAlert } from "react-confirm-alert";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { Hotel } from "../../Common/Services/Hotel";
import { BulkImportInvoiceSlideout } from './BulkImportInvoice/bulkImportInvoiceSlideout';
import TableSearch from "../../Common/Components/TableSearch";
import { Utils } from "../../Common/Utilis";


const { SearchBar } = Search;
export class InvoiceInbox extends React.Component<any, any> {
    private childHID: any;
    private tableSearch: any;
    constructor(props: any) {
        super(props);
        this.childHID = React.createRef();
        this.tableSearch = React.createRef();
        this.state = {
            bookKeepingService:'No',
            unApproveInvoiceCount: 0,
            vendorApprovePermission: 'No',
            isPermission: 'No',
            isInvoiceEntry: "No",
            isBulkImport: 'No',
            progress: 0,
            filterValue: '',
            tablefilterValue: '',
            allfiles: [],
            newSelectfiles: [],
            files: [],
            layout: "table",
            hidValue: "Select",
            hotelName: "Select",
            isSlideOut: false,
            trUniqueNo: 0,
            pageType: "",
            fileSize: 5242880,
            uploadedDate: "", uploadedBy: "",
            moreActionFileUpload: [
                { name: "Delete", eventKey: "delete" },
                { name: "Download", eventKey: "download" },

            ],
            tableData: [],
            uploadAction: [
                { name: "Link 1", eventKey: "link1" },
                { name: "Link 2", eventKey: "link2" },
                { name: "Link 3", eventKey: "link3" },
            ],

            formData: {
                cardUniqueno: 0, gDate: null,
                uniqueno: 0, hotelID: -1, invoiceno: '', transcationDate: null, dueDate: null, endDate: null, accPeriod: '', vendorID: 0, vendorName: '',
                internalNotes: '', checkNo: '', transactionTotal: '', tenantID: 0, pageType: '',
                paymenttype: '', oprID: -1, entryType: 'INCOMPLETEINVOICE', accountType: '', isRepeat: 'No',
                repeatDate: '', accPeriodstatus: '', concilestatus: 'No', saletax: '', vendorContract: '',
                aid: 0, incompleteUniqueno: 0, hotelEmail: ''
            },
            isBulkInvoiceSlideout: false,
            bulkInvoiceRequest: { hotelName: "", hidValue: "", oprId: -1, },
            isLoading: false,
            updatePipOnly: false,      // added to fix NW-33292
            refreshHidSelector: true,  // added to fix NW-33292
            statusFilter: [
                { id: 1, name: "Needs Review", class: "orange", isChecked: false },
                { id: 2, name: "Reviewed", class: "blue", isChecked: false },
                { id: 3, name: "Review Complete", class: "green", isChecked: false }
            ],
            statusFilterSelected: "Flagged",
            refreshTable: true,
        }
        this.onDrop = this.onDrop.bind(this);
        this.handleGalleryFilter = this.handleGalleryFilter.bind(this);
        this.handleTableFilter = this.handleTableFilter.bind(this);
        this.addInvoicewithoutFile = this.addInvoicewithoutFile.bind(this);
        this.addInvoice = this.addInvoice.bind(this);
    }

    componentDidMount() {
        let hotelId = this.getURLParamsValues()?.hotelId;
        if (hotelId) {
            hotelId = atob(hotelId);
            this.loadHotelsDetails(hotelId);
        } else {
            this.setState({ shouldHotelsLoaded: false });
        }

        this.getFileSize();
        this.IsTransactionRolePermission();
    }

    CleanSearch = () => {
        if (this.tableSearch.current !== null) {
            this.tableSearch.current.CleanSearch();
        }
    };

    loadHotelsDetails(hid) {
        Hotel.getUserHotelAccess("Accounting")
            .then(async (result: IHotelResponseDto[] | null) => {
                if (result != null) {

                    const hotelDetail: any = result.find(
                        (r) => +r?.hotelID === +hid);

                    localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
                    sessionStorage.setItem(
                        "hotelSelected",
                        JSON.stringify(hotelDetail)
                    );

                    let formData = { ...this.state.formData }
                    formData.hotelID = hotelDetail.hotelID;
                    formData.hotelEmail = hotelDetail.hotelEmail;
                    this.setState({ hidValue: hotelDetail.hotelID, formData: formData }, () => {
                        this.selectHotelDropDown(hotelDetail);
                    });
                }
            })
            .catch((error) => {
                reject();
            });
    }


    getParamValueByParamName(paramName: string): any {
        const valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
        const item = valuesArray?.find(r => r?.includes(paramName));
        return item?.split('=')[1];
    }
    getURLParamsValues = (): any => {
        const paramObject: any = {
            hotelId: this.getParamValueByParamName("hotelId")
        }
        return paramObject;
    }

    IsTransactionRolePermission = () => {
        let request: any = {};
        request.etype = "Invoice";
        registersevice.IsTransactionRolePermission(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.length > 0) {
                        this.setState({ isPermission: result[0].isPermission, isInvoiceEntry: result[0].isInvoiceEntry, isBulkImport: result[0].bulkImportInvoice });
                    }
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    getFileSize() {
        registersevice.GetFileSize()
            .then(async (result: any | null) => {
                if (result > 0) {
                    this.setState({ fileSize: result })
                }
            });
    }

    InvoiceWithUnapproveVendor() {

        let request: any = {};
        request.hotelID = this.state.hidValue
        registersevice.InvoiceWithUnapproveVendor(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ unApproveInvoiceCount: result.length, vendorApprovePermission: result[0].vendorApprovalPermission });
                }
                else {
                    this.setState({ unApproveInvoiceCount: 0, vendorApprovePermission: 'No' });
                }

            });
    }

    addInvoice = (file) => {
        if (file.uniqueno > 0) {
            this.setState({
                isSlideOut: true, trUniqueNo: file.uniqueno, pageType: 'INCOMPLETEINVOICE',
                sildeoutHid: this.state.hidValue, sildeoutCode: this.state.hotelName, uploadedDate: file.createDate, uploadedBy: file.userName
            })
        }
    }

    addInvoicewithoutFile = () => {

        this.setState({
            isSlideOut: true, trUniqueNo: 0, pageType: 'INCOMPLETEINVOICE',
            sildeoutHid: this.state.hidValue, sildeoutCode: this.state.hotelName
        })

    }

    BulkImportInvoice = () => {
        let { bulkInvoiceRequest } = this.state;
        bulkInvoiceRequest.hotelName = this.state.hotelName;
        bulkInvoiceRequest.hidValue = this.state.hidValue
        this.setState({ bulkInvoiceRequest, isBulkInvoiceSlideout: true })
    }

    hideBulkInvoiceSlideout = (isSave) => {
        if (isSave) {
            this.setState({ isBulkInvoiceSlideout: false }, () => { this.hideSlideOut(isSave) });
        } else {
            this.setState({ isBulkInvoiceSlideout: false });
        }
    }

    hideSlideOut(isSave) {
        this.setState({
            isSlideOut: false, filterValue: "", statusFilterSelected: "Flagged", tablefilterValue: "",
            statusFilter: [
                { id: 1, name: "Needs Review", class: "orange", isChecked: false },
                { id: 2, name: "Reviewed", class: "blue", isChecked: false },
                { id: 3, name: "Review Complete", class: "green", isChecked: false }
            ],
        }, () => {
            this.CleanSearch();
            this.GetPostToInvoice(this.state.hidValue);
            if (isSave) {
                this.InvoiceWithUnapproveVendor();
                this.setState({ updatePipOnly: true, refreshHidSelector: false, }, () => {
                    this.setState({ refreshHidSelector: true, });
                })
            }
        });
    }

    handleGalleryFilter = (e) => {
        let searchval = e.target.value;
        const updatefileState = this.state.allfiles.filter((file: any) =>
        (
            file.userName.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.fileName.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.createDate.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.vendor.toLowerCase().indexOf(searchval.toLowerCase()) > -1)
        );
        this.setState({
            newSelectfiles: updatefileState,
            filterValue: searchval,
            tablefilterValue: ''
        });
    }

    onFileChange = (event: any, mode) => {
        let newfiles: any = [];
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                let fileRow = event.target.files[i];
                newfiles.push(fileRow)
            }
            this.onDrop(newfiles);
            let fileList = document.getElementById('formControlsFile') as any
            fileList.value = "";
        }

    };

    onDrop = (filelist: any) => {
        let size = this.state.fileSize;//5242880//5MB
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        let newfiles: any = [...this.state.allfiles];
        let files: any = [];
        let isReturn = true;
        let filestext = "";

        filelist.forEach(element => {
            if (element.size > size) {
                filestext = filestext + element.name + ","
                isReturn = false;
            }
        })

        if (!isReturn) {
            let msg = "One or more files are greater than " + filesizeval + "MB.";
            Utils.toastError(msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "InvoiceInbox",
            });
        }

        let isInvalidFile = false;

        filelist.forEach((element, index) => {
            if ((element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pdf'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'doc'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'docx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xls'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xlsx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'csv'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'txt'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'bmp'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'tif'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'ppt'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pptx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'rtf'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpeg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'png'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'msg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'eml'
            ) && element.size <= size) {
                files.push(element)
                element.uniqueno = 0;
                element.progress = 0;
                element.status = 'new';
                element.fileSent = '0';
                element.fileName = element.name;
                element.fileCode = "";
                newfiles.push(element)
            } else {
                isInvalidFile = true;
            }
        });

        if (isInvalidFile && isReturn) {
            let msg = "";
            if (filelist.length === 1) {
                msg = "This file type is not supported."
            } else {
                msg = "Some files are not supported."
            }
            Utils.toastError(msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "InvoiceInbox",
            });
        }


        this.setState({ files, newSelectfiles: newfiles, filterValue: "", tablefilterValue: "" }, () => {
            this.saveInvoiceData();
        })

    }

    selectHotelDropDown = (hotelDetails: any) => {
        let formData = { ...this.state.formData }
        formData.hotelID = hotelDetails.hotelID;
        formData.hotelEmail = hotelDetails.hotelEmail;
        this.setState({
            hotelName: hotelDetails.lettercode,
            hidValue: hotelDetails.hotelID,
            formData: formData,
            filterValue: "",
            tablefilterValue: "",
            layout: "table",
            statusFilterSelected: "Flagged",
            statusFilter: [
                { id: 1, name: "Needs Review", class: "orange", isChecked: false },
                { id: 2, name: "Reviewed", class: "blue", isChecked: false },
                { id: 3, name: "Review Complete", class: "green", isChecked: false }
            ],
        }, () => {
            this.getReviewPermissions(hotelDetails.hotelID);
            this.GetPostToInvoice(hotelDetails.hotelID);
            this.InvoiceWithUnapproveVendor();
            this.IsTransactionRolePermission();
        });
    };

    handleSelectFileUpload = (eventKey: any, file, index) => {
        if (eventKey === "delete") {
            this.deleteConfirm(file);
        }
        else if (eventKey === "download") {
            this.downloadTranactionFile(file);
        }
    };

    deleteConfirm(file: any) {
        confirmAlert({
            title: "Delete Transaction",
            message:
                "Are you sure you want to delete this transaction?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.isDelete(file),
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });

    }

    isDelete(file: any) {
        let newfiles: any = [];
        this.state.newSelectfiles.forEach((element, idx) => {
            if (Number(file.uniqueno) !== Number(element.uniqueno)) {
                newfiles.push(element)
            }
        });
        this.handleFileDelete(newfiles, file);
    }

    downloadTranactionFile = (file: any) => {
        let request: any = {};
        request.Rno = 1;
        request.UniqueNo = file.uniqueno;
        request.name = file.fileName;
        request.size = 0;
        request.FileUniqueno = file.fileUniqueno;
        request.UploadFile = file.uploadFile;
        request.Etype = "IncompleteInvoice";
        registersevice.DownloadTranactionFile(request)
            .then(async (result: any | null) => {

                resolve();
            })
            .catch((error) => {
                reject();
            });
    };


    handleFileDelete(newfiles, file) {
        let request: any = {};
        request.uniqueno = file.uniqueno;
        request.fileNo = 0;
        request.allDelete = "YES";
        request.type = "INCOMPLETEINVOICE";
        registersevice.DeleteUploadedFile(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.saveStatus === "Success") {
                        toast.success("Invoice deleted successfully", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "InvoiceInbox",
                        });
                        this.setState({ newSelectfiles: newfiles, allfiles: newfiles, updatePipOnly: true, refreshHidSelector: false, }, () => {
                            this.setState({ refreshHidSelector: true, });
                        });
                    }
                    else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "InvoiceInbox",
                        });
                    }
                }
                resolve();
            })
            .catch((error) => {

                reject();
            });
    }

    GetPostToInvoice(hotelId) {
        this.setState({ isLoading: true });
        let request: any = {};
        request.hotelId = hotelId;
        registersevice.GetInvoiceToPost(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    result.forEach(element => {
                        element.status = "";
                        element.fileSent = "1";

                    });

                    this.setState({ newSelectfiles: [], refreshTable: false }, () => {
                        this.setState({ newSelectfiles: result, allfiles: result, refreshTable: true })
                    })
                }
                this.setState({ isLoading: false });
                resolve();
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                reject();
            });
    }

    getReviewPermissions = (hidValue) => {
        let request: any = {};
        request.HotelId = hidValue;
        registersevice
          .GetReviewPermissions(request)
          .then(async (result: any | null) => {
            if (result !== null) {
              if (result.length > 0) {
                this.setState({bookKeepingService: result[0].bookKeepingService});
              }
            }
            resolve();
          })
          .catch((error) => {
            reject();
          });
      }

    saveInvoiceData() {
        debugger;
        let request: any = {}
        request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
        request.SplitItems = [];
        let uploadfileArray: any = [];
        this.state.newSelectfiles.forEach((element, index) => {
            uploadfileArray.push(element);
        })
        uploadfileArray.forEach(async (element, index) => {
            if (element.status === 'new' && element.fileSent === '0') {

                let fileArray: any = [];
                let guid = Date.now().toString(36) + Math.random().toString(36).substr(2);
                element.guid = guid
                element.fileSent = "1"
                fileArray.push(element);
                request.guid = guid;
                registersevice.SaveInboxInvoice(request, fileArray, (event) => {
                    element.progress = Math.round((100 * event.loaded) / event.total)
                    // this.setState({ newSelectfiles: uploadfileArray })
                }).then(async (result: any | null) => {
                    if (result !== null) {
                        let newSelectfiles1 = Object.assign([], JSON.parse(JSON.stringify(this.state.newSelectfiles)));
                        newSelectfiles1.forEach(element => {
                            if (result.result[0].autoGuid === element.guid) {
                                element.uniqueno = result.result[0].uniqueno;
                                element.vendor = result.result[0].vendor;
                                element.createDate = result.result[0].createDate;
                                element.fileCode = result.result[0].fileCode;
                                element.fileName = result.result[0].fileName;
                                element.fileUniqueno = result.result[0].fileUniqueno;
                                element.id = result.result[0].id;
                                element.uploadFile = result.result[0].uploadFile;
                                element.userName = result.result[0].userName;
                                element.pageNo = result.result[0].pageNo;
                                element.vid = result.result[0].vid;
                                element.status = "";
                                element.etype = "";
                                element.reviewStatus = "";
                                element.reviewOrder = 1;
                                element.createDate1 = result.result[0].createDate;
                            }
                        });
                        this.setState({ newSelectfiles: newSelectfiles1, allfiles: newSelectfiles1, updatePipOnly: true, refreshHidSelector: false }, () => {
                            this.setState({ refreshHidSelector: true });
                        });
                        this.CleanSearch();
                    }
                    resolve();
                })
                    .catch((error) => {
                        console.log(error);
                        reject();
                    });
            }
        });
    }

    rowEvents = {

        onClick: (e: any, row: any, rowIndex: any) => {
            if (row.uniqueno > 0) {
                this.addInvoice(row);
            }
        }
    }

    OpenVendorApproval = () => {

        const location = {
            pathname: "/invoiceapproval",
            search: "",
        };
        let parmvalue = this.state.hidValue.toString() + "_VendorApproval"
        let url = this.props.history.createHref(location) + `?parm=${btoa(parmvalue)}`;
        url = encodeURI(url);
        window.location.replace(url);
    };

    itemCheckChange = (event: any, item: any, type: any) => {
        debugger;
        if (type === "status") {
            let { statusFilter, statusFilterSelected } = this.state;
            statusFilter.forEach((o: any) => (o?.name?.toString()?.toLocaleLowerCase() === item.name?.toString()?.toLocaleLowerCase()) && (o.isChecked = event.target.checked))
            statusFilterSelected = this.selectedStatus();
            this.setState({ statusFilter, statusFilterSelected }, () => {
                this.setStatusFilter();
            });
        }
    }

    selectedStatus = () => {
        let { statusFilter } = this.state;
        let checkedLen = statusFilter.filter((o: any) => o.isChecked);
        let isStatusSelected = (checkedLen.length > 1 ? `${checkedLen.length} Statuses Selected` : (checkedLen.length === 1 ? checkedLen[0].name : "Flagged"));
        return isStatusSelected;
    }

    setStatusFilter = () => {
        // let { newSelectfiles, statusFilter } = this.state;
        // newSelectfiles = [];
        // this.state.allfiles.forEach((o: any) => {
        //     statusFilter.forEach((st: any) => {
        //         if (o.reviewStatus === st.name && st.isChecked) {
        //             newSelectfiles.push(o);
        //         }
        //     })
        // });
        // // this.setState({ commDataList, isLoading: false, searchFilter: "" });
        // let checkedLen = statusFilter.filter((o: any) => o.isChecked)?.length;
        // if (checkedLen > 0) {
        //     this.setState({ newSelectfiles });
        // }
        // else {
        //     this.setState({ newSelectfiles: this.state.allfiles });
        // }

        this.handleTableSearch();

    }


    handleItemSelectAll(event: any, type: any) {
        debugger;
        if (type === "status") {
            let { statusFilter, statusFilterSelected } = this.state;
            statusFilter.forEach((o: any) => o.isChecked = event.target.checked)
            statusFilterSelected = this.selectedStatus();
            this.setState({ statusFilter, statusFilterSelected }, () => {
                this.setStatusFilter();
            })
        }
    }

    handleSelectLayout = (eventKey: any) => {
        let { statusFilter, statusFilterSelected } = this.state;
        statusFilter.forEach((o: any) => o.isChecked = false)
        let allfiles = Object.assign([], JSON.parse(JSON.stringify(this.state.allfiles)));
        let sortList: any[] = [];
        if (eventKey === "gallery") {
            sortList = allfiles.sort((a, b) => (a.uniqueno - b.uniqueno));
        }
        else {
            sortList = allfiles.sort((a, b) => (a.createDate1 - b.createDate1 && a.reviewOrder - b.reviewOrder));
        }



        this.setState({ layout: eventKey, filterValue: "", tablefilterValue: "", newSelectfiles: sortList, statusFilterSelected: "Flagged" }, () => {

            this.CleanSearch();
        });
    }

    searchedData = (newResult: any) => {
        this.setState({ newSelectfiles: newResult });
    };
    handleTableFilter = (e) => {
        let searchval = e.target.value;
        let newSelectfiles = Object.assign([], JSON.parse(JSON.stringify(this.state.newSelectfiles)));
        this.setState({ tablefilterValue: searchval }, () => {
            this.handleTableSearch();
        });

    }

    handleTableSearch = () => {
        let { newSelectfiles, statusFilter } = this.state;
        newSelectfiles = [];
        let searchval = this.state.tablefilterValue;
        const updatefileState =  this.state.allfiles.filter((file: any) =>
        (
            file.userName?.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.fileName?.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.createDate?.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.vendor?.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.etype?.toLowerCase().indexOf(searchval.toLowerCase()) > -1 ||
            file.reviewStatus?.toLowerCase().indexOf(searchval.toLowerCase()) > -1)
        );

        updatefileState.forEach((o: any) => {
            statusFilter.forEach((st: any) => {
                if (o.reviewStatus === st.name && st.isChecked) {
                    newSelectfiles.push(o);
                }
            })
        });

        let checkedLen = statusFilter.filter((o: any) => o.isChecked)?.length;
        



        this.setState({newSelectfiles: [],filterValue: ''}, () => {
            if (checkedLen > 0) {
                this.setState({ newSelectfiles });
            }
            else {
                this.setState({ newSelectfiles: updatefileState });
            }
        });

    }



    render() {
        const statusCheckLabel = (item: any, statusClass: any) => (<strong className={`${statusClass} tag`}>{item.name}</strong>);
        const isStatusClass = (status: any) => {
            let statusClass = "";
            if (status.toString().toLowerCase() === "needs review") {
                statusClass = "orange";
            } else if (status.toString().toLowerCase() === "reviewed") {
                statusClass = "blue";
            } else if (status.toString().toLowerCase() === "review complete") {
                statusClass = "green";
            } else if (status.toString().toLowerCase() === "flag for review") {
                statusClass = "light";
            } else if (status.toString().toLowerCase() === "complete review") {
                statusClass = "light";
            }
            return statusClass;
        }
        const hoverOutEllipsis = () => {
            $(".tooltip").removeClass("show");
        };

        const columns = [
            {
                dataField: 'createDate',
                text: 'Upload Date',
                headerClasses: 'uploadDate',
            }, {
                dataField: 'userName',
                text: 'Username',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.userName}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'pageNo',
                text: 'Pages'
            }, {
                dataField: 'fileName',
                text: 'Filename',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.fileName}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'vendor',
                text: 'Vendor Name',
                headerClasses: 'venderName',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.vendor}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'etype',
                text: 'Type',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{row.etype}</EllipsisWithTooltip>
                    );
                },
            }, {
                dataField: 'reviewStatus',
                text: 'Flagged',
                headerClasses: 'status-col',
                classes: 'rm-column-ellipsis',
                hidden: this.state.bookKeepingService ==="Yes" ? false : true,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className={`status ${row.reviewStatus == 'Flag for Review' || row.reviewStatus == 'Needs Review' ? 'orange' : ''} 
                        ${row.reviewStatus == 'Reviewed' ? 'blue' : ''} ${row.reviewStatus == 'Review Complete' ? 'green' : ''}`}>
                            <EllipsisWithTooltip placement="bottom">{row.reviewStatus}</EllipsisWithTooltip>
                        </div>
                    );
                },
            }, {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <Dropdown
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className="more-action" alignRight>
                            <Dropdown.Toggle className="btn-outline-primary btn btn-primary more"
                                id={"dropdown-more" + rowIndex} >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.isPermission === "Yes" && (
                                    <Dropdown.Item eventKey={"delete"} onClick={() => this.handleSelectFileUpload("delete", row, rowIndex)}>Delete</Dropdown.Item>
                                )}
                                {row.fileName.length > 0 ? <Dropdown.Item eventKey={"download"} onClick={() => this.handleSelectFileUpload("download", row, rowIndex)}>Download</Dropdown.Item> : ""}
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
            }
        ];
        const files = this.state.newSelectfiles.map((file, index) => (
            <>
                <li key={file.fileName}>
                    <div className="d-flex align-items-start flex-column">
                        <div className="img-sec" onClick={() => this.addInvoice(file)}>
                            {(this.state.files.length > 0 && file.status === 'new') && (
                                <div className="loader-sec d-flex align-items-center justify-content-center">
                                    <div className="loader-area">
                                        <div className="fileName">{file.fileName}</div>
                                        <div className="progress">
                                            <div
                                                className="progress-bar progress-bar-info"
                                                role="progressbar"
                                                aria-valuenow={file?.progress}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: file?.progress.toString() + "%" }}
                                            >
                                            </div>
                                        </div>
                                        <div className="percentage">{file?.progress}%</div>
                                    </div>
                                </div>
                            )}

                            {file.fileCode.length > 0 && (
                                <div className="react-pdf__Document">
                                    {(!file.fileName.toString().toLowerCase().substring(file.fileName.length - 3, file.fileName.length).includes("pdf") && file.fileName.length > 0) && (

                                        <div className="custom-error-msg">Cannot be previewed - {file.fileName}</div>
                                    )}

                                    {file.fileCode.includes(".png") && (
                                        <img src={file.fileCode} alt={file.fileName} width="160" height="120" />
                                    )}
                                    <div className="d-flex align-items-center justify-content-center pageCount">
                                        <div className="icon">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.2687 5.72392C13.2361 5.65325 13.1954 5.58592 13.1381 5.52858L9.13808 1.52859C9.08075 1.47125 9.01341 1.43059 8.94275 1.39792C8.92275 1.38859 8.90141 1.38325 8.88008 1.37592C8.82408 1.35725 8.76675 1.34525 8.70741 1.34192C8.69341 1.34059 8.68075 1.33325 8.66675 1.33325H4.00008C3.26475 1.33325 2.66675 1.93125 2.66675 2.66659V13.3333C2.66675 14.0686 3.26475 14.6666 4.00008 14.6666H12.0001C12.7354 14.6666 13.3334 14.0686 13.3334 13.3333V5.99992C13.3334 5.98592 13.3261 5.97325 13.3247 5.95858C13.3214 5.89925 13.3101 5.84192 13.2907 5.78592C13.2841 5.76459 13.2781 5.74392 13.2687 5.72392ZM11.0574 5.33325H9.33341V3.60925L11.0574 5.33325ZM4.00008 13.3333V2.66659H8.00008V5.99992C8.00008 6.36859 8.29808 6.66658 8.66675 6.66658H12.0001L12.0014 13.3333H4.00008Z" fill="#3F4347" />
                                                <path d="M5.33325 7.99992H10.6666V9.33325H5.33325V7.99992ZM5.33325 10.6666H10.6666V11.9999H5.33325V10.6666ZM5.33325 5.33325H6.66659V6.66659H5.33325V5.33325Z" fill="#3F4347" />
                                            </svg>
                                        </div>
                                        <div className="page-count">{file.pageNo}</div>
                                    </div>
                                </div>

                            )}
                            {file.fileCode.length === 0 && (
                                <div className="react-pdf__Document">
                                </div>
                            )}

                        </div>
                        <div className="d-flex align-items-center info">
                            <div className="mr-auto" onClick={() => this.addInvoice(file)}>
                                <div className="date">{file.createDate}</div>

                                {file.isEmail === 'Yes' && (
                                    <div className="name">
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id={"email-address"}>
                                                    {file.userName}
                                                </Tooltip>
                                            }
                                        >

                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z" fill="#1D1E20">
                                                </path>
                                            </svg>
                                        </OverlayTrigger>
                                    </div>
                                )}
                                {file.isEmail !== 'Yes' && (
                                    <EllipsisWithTooltip placement="bottom"><div className="name">{file.userName}</div></EllipsisWithTooltip>
                                )}

                                <EllipsisWithTooltip placement="bottom"><div className="name">{file.vendor}</div></EllipsisWithTooltip>
                            </div>
                            <Dropdown className="more-action" alignRight onSelect={(event: any) => this.handleSelectFileUpload(event, file, index)}>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.moreActionFileUpload.map(
                                        (item: any, idx: any) => (
                                            <>
                                                {this.state.isPermission === "No" && this.state.moreActionFileUpload[idx].eventKey === "delete" ?
                                                    "" :
                                                    <>
                                                        {(file.fileCode.length === 0 && this.state.moreActionFileUpload[idx].eventKey === "download") ?
                                                            "" :
                                                            <>
                                                                {file.uniqueno > 0 && (
                                                                    <Dropdown.Item
                                                                        eventKey={this.state.moreActionFileUpload[idx].eventKey}
                                                                        key={idx}
                                                                    >
                                                                        {this.state.moreActionFileUpload[idx].name}
                                                                    </Dropdown.Item>
                                                                )}
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        )
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </li>
            </>
        ));
        return (
            <>
                {this.state.isSlideOut && (
                    <InvoiceEntrySlideout
                        hidValue={this.state.sildeoutHid}
                        hotelName={this.state.sildeoutCode}
                        uniqueNo={this.state.trUniqueNo}
                        pageType={this.state.pageType}
                        hideSlideOut={this.hideSlideOut.bind(this)}
                        uploadedDate={this.state.uploadedDate}
                        uploadedBy={this.state.uploadedBy}
                    />
                )}
                {this.state.isBulkInvoiceSlideout && (<>
                    <BulkImportInvoiceSlideout
                        {...this.state.bulkInvoiceRequest}
                        hideBulkInvoiceSlideout={this.hideBulkInvoiceSlideout.bind(this)}
                    />
                </>)}
                <div className="invoice-inbox">
                    <ToastContainer
                        autoClose={3000}
                        containerId={"InvoiceInbox"}
                        enableMultiContainer
                    />
                    <Container fluid className="body-sec">

                        <div className="page-heading underline d-flex">
                            {this.state.refreshHidSelector &&  // added to fix NW-33292
                                <HidSelector
                                    onClickEvent={this.selectHotelDropDown}
                                    ref={this.childHID}
                                    modulename={'Invoice'}
                                    extraSelector={this.state.updatePipOnly}  // extraSelector = updatePipOnly = true means only calls hotel list API and updates pip and doesn't call onClickEvent function
                                />
                            }
                            <>
                                <div className="mr-auto d-flex align-items-center">AP Inbox</div>

                                {(this.state.newSelectfiles.filter(x => x?.fileSent.toString() === "0").length === 0 && this.state.isInvoiceEntry === "Yes") && (
                                    <>
                                        <div className={(this.state.hidValue === "Select") ? 'disable-area action-group d-flex' : 'action-group d-flex'}>
                                            <Dropdown className={"more-action btn-dropdown"} alignRight>
                                                <Dropdown.Toggle className="btn btn-primary" id="dropdown-more">Add Draft Invoice</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={this.addInvoicewithoutFile}>Add New</Dropdown.Item>
                                                    <div className="upload-link">
                                                        <Dropdown.Item onClick={this.addInvoicewithoutFile}>Upload</Dropdown.Item>
                                                        <Form.Control id="formControlsFile" title="" type="file" multiple onChange={(event: any) => this.onFileChange(event, "1")}
                                                            accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml" />
                                                    </div>
                                                    {this.state.isBulkImport === "Yes" && (
                                                        <Dropdown.Item onClick={this.BulkImportInvoice}>Bulk Import</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                        {this.state.refreshTable &&
                            <div className={this.state.hidValue === "Select" ? 'disable-area main-Page' : 'main-Page'}>
                                <div className="bdy-sec">
                                    <ToolkitProvider
                                        keyField="rNo"
                                        data={this.state.newSelectfiles}
                                        columns={columns}
                                        // search
                                        rowEvents={this.rowEvents}
                                    >
                                        {(props: {
                                            searchProps: JSX.IntrinsicAttributes;
                                            baseProps: JSX.IntrinsicAttributes;
                                        }) => (
                                            <div>
                                                <div className="search-header d-flex">
                                                    <div className="d-flex mr-auto">
                                                        {this.state.layout !== "gallery" && (

                                                            <>
                                                                <div className="registerSearch">
                                                                    {/* <TableSearch
                                                                        id="tableSearch"
                                                                        key="tableSearch"
                                                                        ref={this.tableSearch}
                                                                        {...props.searchProps}
                                                                        CleanSearch={this.CleanSearch.bind(this)}
                                                                        placeholder={"Search"}
                                                                    /> */}


                                                                    <Form.Control type="text" placeholder="Search" value={this.state.tablefilterValue}
                                                                        autoComplete="off" data-lpignore="true" onChange={(event: any) => this.handleTableFilter(event)} />
                                                                </div>
                                                                {this.state.bookKeepingService ==="Yes" && (

                                                              
                                                                <div className='status-filter'>
                                                                    <Dropdown className="drop-down-select status-selector">
                                                                        <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='selected-val'><EllipsisWithTooltip placement="bottom">
                                                                                    {this.state.statusFilterSelected}
                                                                                </EllipsisWithTooltip></div>
                                                                                <div className='arrow ml-auto'>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>

                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <div className="ListItems ListWithMultiSelect">
                                                                                {  this.state.statusFilter.map((item: any, idx: any) => {
                                                                                    let statusClass = isStatusClass(item?.name);
                                                                                    return (
                                                                                        <div className="dropdownItems border-0" key={item.id}>
                                                                                            <Form.Check
                                                                                                type="checkbox"
                                                                                                id={`checkId_${item.id}`}
                                                                                                name={item.name}
                                                                                                label={statusCheckLabel(item, statusClass)}
                                                                                                value={item.id}
                                                                                                onChange={(event) => this.itemCheckChange(event, item, "status")}
                                                                                                checked={item.isChecked}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                            {(this.state.statusFilter.length > 0 ) && (
                                                                             
                                                                                <div className="selectClearAll dropdownItems">
                                                                                    
                                                                                    <Form.Check
                                                                                        type="checkbox"
                                                                                        id={`itemSelectAllStatus`}
                                                                                        label={this.state.statusFilter.some(o => o.isChecked === false) ? "Select All" : "Clear All"}
                                                                                        value={this.state.statusFilter.some(o => o.isChecked === false) ? "selectAll" : "clearAll"}
                                                                                        onChange={(event) => this.handleItemSelectAll(event, "status")}
                                                                                        checked={this.state.statusFilter.some(o => o.isChecked === false) ? false : true}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                  )}
                                                            </>
                                                        )}
                                                        {this.state.layout === "gallery" && (
                                                            <Form.Control type="text" placeholder="Search" value={this.state.filterValue}
                                                                autoComplete="off" data-lpignore="true" onChange={(event: any) => this.handleGalleryFilter(event)} />
                                                        )}
                                                    </div>
                                                    {this.state.formData.hotelEmail !== "" && (
                                                        <div className="mail-to d-flex align-items-center">{this.state.formData.hotelEmail}</div>
                                                    )}
                                                    <Dropdown className="more-action bg-blue setting" onSelect={this.handleSelectLayout}>
                                                        <Dropdown.Toggle className="d-flex align-items-center" id="">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.56274 10.1806L2.89607 12.4873C3.08007 12.8059 3.48807 12.9153 3.80674 12.7306L4.7374 12.1933C5.12407 12.4979 5.5514 12.7479 6.00074 12.9346V13.9999C6.00074 14.3679 6.29874 14.6666 6.6674 14.6666H9.33407C9.70273 14.6666 10.0007 14.3679 10.0007 13.9999V12.9346C10.4501 12.7479 10.8767 12.4979 11.2641 12.1933L12.1947 12.7306C12.5127 12.9139 12.9221 12.8039 13.1054 12.4873L14.4387 10.1806C14.6221 9.86325 14.5121 9.45325 14.1954 9.26992L13.2807 8.74125C13.3154 8.49525 13.3334 8.24725 13.3334 7.99992C13.3334 7.75259 13.3154 7.50459 13.2794 7.25858L14.1941 6.72992C14.5114 6.54659 14.6214 6.13659 14.4374 5.81925L13.1041 3.51259C12.9201 3.19392 12.5121 3.08459 12.1934 3.26925L11.2627 3.80659C10.8761 3.50192 10.4494 3.25125 10.0001 3.06525V1.99992C10.0001 1.63192 9.70207 1.33325 9.3334 1.33325H6.66674C6.29807 1.33325 6.00007 1.63192 6.00007 1.99992V3.06525C5.55074 3.25192 5.12407 3.50192 4.73674 3.80659L3.80674 3.26925C3.4874 3.08459 3.08007 3.19392 2.89607 3.51259L1.56274 5.81925C1.3794 6.13659 1.4894 6.54659 1.80607 6.72992L2.72074 7.25858C2.68474 7.50459 2.66674 7.75259 2.66674 7.99992C2.66674 8.24725 2.68474 8.49525 2.72074 8.74125L1.80607 9.26992C1.48874 9.45325 1.37874 9.86325 1.56274 10.1806ZM8.00007 5.33325C9.47073 5.33325 10.6667 6.52925 10.6667 7.99992C10.6667 9.47059 9.47073 10.6666 8.00007 10.6666C6.5294 10.6666 5.3334 9.47059 5.3334 7.99992C5.3334 6.52925 6.5294 5.33325 8.00007 5.33325Z" />
                                                            </svg>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item eventKey={"gallery"}>Gallery view</Dropdown.Item>
                                                            <Dropdown.Item eventKey={"table"}>Table view</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>

                                                {this.state.unApproveInvoiceCount > 0 && (
                                                    <div className="notification-banners orange-bg">
                                                        <div className="mr-auto"><span className="bold">{this.state.unApproveInvoiceCount + " invoice/s"}</span> are pending for vendor approval.</div>
                                                        {this.state.vendorApprovePermission === 'Yes' && (
                                                            <button type="button" className="btn btn-primary btn-ghost" onClick={this.OpenVendorApproval}>
                                                                View vendors
                                                            </button>
                                                        )}
                                                    </div>
                                                )}

                                                <div className="sub-ttl">{this.state.newSelectfiles.length > 0 ? "Draft Invoices" : ""}</div>
                                                {/* <div className="sub-ttl">You're all caught up!</div>                                              */}

                                                {/* {(this.state.layout === "gallery") && ( */}
                                                    <div className="file-list">
                                                        <Dropzone onDrop={this.onDrop}>
                                                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                                                                <section className={`upload-area default ${isDragActive ? "drag-active" : ""} ${this.state.newSelectfiles.length < 1 ? "no-files" : ""} ${this.state.unApproveInvoiceCount > 0 ? "unApproveInvoiceCount" : ""} ${this.state.hidValue === "Select" ? "noHotleSelect" : ""}`} onWheel={() => hoverOutEllipsis()}>
                                                                    <div className="pos-rel">
                                                                        <div className={isDragAccept ? "accept" : isDragReject ? "reject" : ""}>
                                                                            {(this.state.newSelectfiles.filter(x => x?.fileSent.toString() === "0").length === 0 && this.state.isInvoiceEntry === "Yes") && (
                                                                                <div {...getRootProps({
                                                                                    className: 'dropzone',
                                                                                    onClick: (this.state.newSelectfiles.length > 0) ?
                                                                                        event => event.stopPropagation() : event => event
                                                                                })}>
                                                                                    <input {...getInputProps()} />
                                                                                    {(this.state.newSelectfiles.length < 1 || isDragActive) && (
                                                                                        <div className={this.state.unApproveInvoiceCount > 0 ? "vertical-align unApproveInvoiceCount" : "vertical-align"}>
                                                                                            <div className="pos-rel">
                                                                                                <div>
                                                                                                    <p className="drop-icon">
                                                                                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <circle cx="32" cy="32" r="32" fill="#E5F5FF" />
                                                                                                            <path d="M33.3333 41.3333V35.9999H37.3333L32 29.3333L26.6666 35.9999H30.6666V41.3333H33.3333Z" fill="#15A4FA" />
                                                                                                            <path d="M25.3333 41.3334H28V38.6667H25.3333C23.128 38.6667 21.3333 36.8721 21.3333 34.6667C21.3333 32.7947 22.932 30.9921 24.8973 30.6467L25.672 30.5107L25.928 29.7667C26.8653 27.0321 29.1933 25.3334 32 25.3334C35.676 25.3334 38.6666 28.3241 38.6666 32.0001V33.3334H40C41.4706 33.3334 42.6666 34.5294 42.6666 36.0001C42.6666 37.4707 41.4706 38.6667 40 38.6667H36V41.3334H40C42.9413 41.3334 45.3333 38.9414 45.3333 36.0001C45.3333 33.4934 43.5933 31.3841 41.2586 30.8161C40.676 26.2267 36.7466 22.6667 32 22.6667C28.3253 22.6667 25.1333 24.8147 23.676 28.2001C20.812 29.0561 18.6666 31.7601 18.6666 34.6667C18.6666 38.3427 21.6573 41.3334 25.3333 41.3334Z" fill="#15A4FA" />
                                                                                                        </svg>
                                                                                                    </p>
                                                                                                    {(this.state.newSelectfiles.length < 1) && (
                                                                                                        <p>Drag and drop invoices here or <span>browse files</span> to upload.</p>
                                                                                                    )}
                                                                                                    {(this.state.newSelectfiles.length > 0 && isDragActive) && (
                                                                                                        <p>Drag and drop invoices here to upload.</p>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {this.state.layout == "gallery" && (
                                                                            <aside className="files">
                                                                                <ul className="d-flex flex-wrap align-items-start">
                                                                                    {files}
                                                                                </ul>
                                                                            </aside>
                                                                        )}
                                                                        {this.state.layout !== "gallery" && (
                                                                            <div className={`scroll-area tbl-view ${this.state.unApproveInvoiceCount > 0 ? "unApproveInvoiceCount" : ""}`}>
                                                                                <BootstrapTable
                                                                                    {...props.baseProps}
                                                                                    keyField="id"
                                                                                    hover
                                                                                    rowEvents={this.rowEvents}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                {/* )} */}
                                                {/* {this.state.layout !== "gallery" && (
                                                    <div className={`scroll-area tbl-view ${this.state.unApproveInvoiceCount > 0 ? "unApproveInvoiceCount" : ""}`}>
                                                        <BootstrapTable
                                                            {...props.baseProps}
                                                            keyField="id"
                                                            hover
                                                            rowEvents={this.rowEvents}
                                                        />
                                                    </div>
                                                )} */}
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                </div>
                            </div>
                        }
                        {this.state.isLoading && (
                            <div className="loader-spinner d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant="success" />
                            </div>
                        )}
                    </Container>
                </div >
            </>
        )
    }
}