import React, { Fragment } from "react";
import {
  Container,
  Dropdown,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from "react-outside-click-handler";
import { FiChevronDown } from "react-icons/fi";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { resolve, reject } from "q";
import { PayrollExportSlideOut } from "./PayrollExportSlideOut";
import { EmployeeNameFormatter } from "./EmployeeNameFormatter";
import { PtoPayoutModal } from "./PtoPayoutModal";
import { ReassignAllModal } from "../Schedule/components/ReassignAllModal";
import { TimeClockCommonFunc } from "../../../../WebTimeClock/TimeClockCommonFunc";
import { HidSelector } from "../../../../Common/Components/HidSelector";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { PayrollExportService } from "../../../../Common/Services/PayrollExport";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import {
  IGetPayrollExportData,
  ISaveAsDefautView,
  ILaborSaveScheduleDetails,
  IExportPayrollData,
  ISaveUserCheckStatus,
  IGetPayrollExcelExportData,
  IMarkASUnexport,
} from "../../../../Common/Contracts/IPayrollExport";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../../Common/Services/RoleSubPermissionSetting";
import { UserDetailSlideOut } from "../../../UserManagement/components/UserDetailSlideOut";
import { UserManagement } from "../../../../Common/Services/UserManagement";
import { Utils } from "../../../../Common/Utilis";
import { Redirect } from "react-router-dom";
import { ContractorNameRender } from "./ContractorNameRender"; 

let localStorageUser: any;
let checkBoxSelection: boolean = false;
let tripleDotSelection: boolean = false;
let tripleDotListSelection: boolean = false;
let changeInEmpTableHeight: boolean = false;
let userCheckArray: Array<any> = [];
let originalPayrollSort: string = "";
let originalColumnView: Array<string> = [];
const employeeParameter: string = "empCode!!-1";
const employeeRegPrameter: string = "reG!!104";
const userSalHurParameter: string = "s/H!!0";
const positionParameter: string = "positioN!!0";
const densityHeight: Object = { Wide: 84, Narrow: 42 };
const densityView: Array<string> = ["Wide", "Narrow"];

export class PayrollExportIndex extends React.Component<any, any> {
  private childHID: any;
  private searchInput: any;
  gridApi: any;
  gridColumnApi: any;
  contGridApi: any;
  contGridColumnApi: any;

  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.searchInput = React.createRef();
    this.state = {
      loaderTrue: false,
      exportButtonSpinner: false,
      hidValue: "",
      hotelName: "",
      hotelFullName: "",
      hidTimeZone: "",
      displayId: "",
      Ecode: "",
      viewExportDropdown: false,
      flyoutMenuItems: ["Add User", "Download as Excel"],
      flyOutDropdownView: "flyOutMenu",
      addUserRow: {},
      exportDate: "",
      exportBanner: false,
      viewCalender: false,
      navigationCalenderDates: [],
      calenderSelectDateString: "",
      calenderSelectDate: {},
      navMenuStatic: false,
      changeInNavMenu: false,
      tableHeaderStatic: false,
      payrollSortOrder: "",
      sortOrderList: ["Name", "Position"],
      densityViewOrder: "",
      isPartialExport: false,
      payrollHeadingDates: "",
      payrollHeadingdata: [],
      exportHeadingData: [],
      viewSlideOut: false,
      slidwOutRowData: {},
      columnDropdownToggle: false,
      currentColumnSelected: 0,
      maxColumnSelected: 50,
      userDefinedColumnsToggle: [],
      columnData: [],
      toggleColumnData: [],
      rowData: [],
      rowCount: 0,
      context: { componentParent: this },
      frameworkComponents: {
        emplyeeNameRender: EmployeeNameFormatter,
        threeDotRender: this.threeDotRender,
      },
      frameworkComponentsContractor: {
        contractorNameRender: ContractorNameRender,
      },
      permissionManagePayroll: false,
      permissionExportPayroll: false,
      customToggleMaskDisplay: false,
      missingPayrollUsers: [],
      filteredUserList: [],
      showSlideout: false,
      childStatusList: [],
      statusList: [],
      isChangeEHIDAccess: false,
      isChangeUserRole: false,
      isPageCreateNewUser: false,
      isNewUserAdded: false,
      userRowData: {},
      userSlideOutDataChange: false,
      changeInColCount: false,
      navMenuOpen: false,
      pendingPtoRequestUsers: [],
      horizontalScrollFixed: true,
      contColumnData: [],
      contRowData: [],
      contHeaderStatic: false,
      fileName: "",
      navOpen:false,
      exportingData:false,
      IsEmployeeZeroHrs: false,
      confirmation: true,
      permissionUnExportPayroll : false,
      payrollIntegrationHids: [],
      IsPayrollIntegration: false,
    };
  }

  componentDidMount() {
    this.GetPayrollIntegrationHidsByTenant();
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.resizeHandler);
    var navToggleBtn: any = document.getElementById('nav-toggle-btn');
    navToggleBtn.addEventListener('click', this.navbuttonClicked);
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);

    const fileName = `${"PayrollContractor"}-${JSON.parse(localStorage.storage)
      .currentUtcTime.replace(" PM", "")
      .replace(" AM", "")
      .replace(" ", "-")
      .replace(":", "-")
      .replace(":", "-")}`;
    this.setState({ fileName });
  
    this.getPayrolPermission();
    this.getUserStatusList();
    this.getAddUserPermission();
    this.getPayrollUnexportPermission();
    const location = this.props.location;
    const oPValue = location.state;

    if (oPValue === null || oPValue === undefined) {
    } else if (
      oPValue.pageTitle === "timesheet" ||
      oPValue.pageTitle === "performance"
    ) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        let { payPeriod } = location.state;
        this.setState({
          calenderSelectDateString: payPeriod[0],
          calenderSelectDate: new Date(payPeriod[0]),
        });
      }
    } else {
      let tempDate = oPValue.dueFrom.split("-")[0];
      this.setState({
        calenderSelectDateString: tempDate,
        calenderSelectDate: new Date(tempDate),
      });
      let hotelDetails: any[] = [];
      let details: any = {};
      details.ehidName = `${oPValue.hid} -${oPValue.pname}`;
      details.hotelID = oPValue.hid;
      details.hotelName = `${oPValue.lettercode} - ${oPValue.pname}`;
      details.lettercode = oPValue.lettercode;
      details.tenantID = 0;
      details.timeZone = "EST";
      hotelDetails.push(details);
      sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetails[0]));
      localStorage.setItem("hotelSelected", JSON.stringify(hotelDetails[0]));
      this.selectHotelDropDown(hotelDetails[0]);
    }
    LaborPerformance.getDefaultViewProperties("NavigationHeader")
    .then(async (response: any | null) => {
      if (response?.length > 0) {
        this.setState({ navOpen: response[0].fieldValue === "false" ? false : true });
      }
      if (response?.length == 0) {
        if (window.innerWidth < 1200 && window.innerWidth >= 320) {
          this.setState({ navOpen: false });
        }
      }
    })
    .catch((error) => {
      Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
    });
  }

  componentWillUnmount = () => {
    userCheckArray = [];
    document.removeEventListener("scroll", this.handleScroll, true);
    document.removeEventListener("scroll", this.resizeHandler);
  };

  GetPayrollIntegrationHidsByTenant = () => {
    UserManagement.GetPayrollIntegrationHidsByTenant()
      .then((data: any[]) => {
        const hids = this.props.userGeneralInfo?.hids?.map((x) => x.hotelID);
        this.setState({ payrollIntegrationHids: data });
      })
      .catch(() => {
        this.setState({ payrollIntegrationHids: [] });
      });
  };

  handleScroll = (event: any) => {
    if (event.target.className === "body-sec container-fluid") {
      let scrollPosition = event.target.scrollTop;
      const horizontalScroll = document
        .getElementsByClassName("ag-body-horizontal-scroll")[0]
        ?.getBoundingClientRect();
      const contHeaderPosition = document
        .getElementsByClassName("ag-header")[1]
        ?.getBoundingClientRect();
      let EmptableHeight = document.getElementsByClassName(
        "ag-root"
      ) as HTMLCollectionOf<HTMLElement>;
      let navigationMenuHeight: number = 90;
      let tableHeight: number = 303;
      let innerHeight: number = window.innerHeight;

      if (scrollPosition >= navigationMenuHeight) {
        this.setState({
          navMenuStatic: true,
          changeInNavMenu: true,
          viewExportDropdown: false,
          flyOutDropdownView: "flyOutMenu",
        });
      } else {
        this.setState({ navMenuStatic: false });
      }
      if (contHeaderPosition?.top < innerHeight - 5) {
        this.setState({ horizontalScrollFixed: false });
      } else {
        this.setState({ horizontalScrollFixed: true });
      }

      if (scrollPosition >= tableHeight && horizontalScroll?.top >= 135) {
        if (!changeInEmpTableHeight && EmptableHeight[0]?.style !==undefined) {
          EmptableHeight[0].style.height =
            EmptableHeight[0]?.clientHeight + "px";
          changeInEmpTableHeight = true;
        }
        this.setState({ tableHeaderStatic: true });
      } else {
        if (changeInEmpTableHeight && EmptableHeight[0]?.style !==undefined) {
          EmptableHeight[0].style.height =
            EmptableHeight[0]?.clientHeight + "px";
        }
        changeInEmpTableHeight = false;
        this.setState({ tableHeaderStatic: false });
      }
      if (horizontalScroll?.top < 135) {
        this.setState({ contHeaderStatic: true, tableHeaderStatic: true });
      } else {
        this.setState({ contHeaderStatic: false });
      }
    }
  };

  resizeHandler = (event: any) => {
    let scrollWidth = document.getElementsByClassName(
      "ag-body-horizontal-scroll-container"
    ) as HTMLCollectionOf<HTMLElement>;
    const scrollHeight = document.getElementsByClassName(
      "ag-body-horizontal-scroll-container"
    )[0]?.clientHeight;
    if (scrollHeight > 0 && scrollWidth[0].style !==undefined) {
         scrollWidth[0].style.width = scrollWidth[0].clientWidth - 5 + "px";
    }
  };

  navbuttonClicked = () => {
    let scrollWidth = document.getElementsByClassName(
      "ag-body-horizontal-scroll-container"
    ) as HTMLCollectionOf<HTMLElement>;
    const scrollHeight = document.getElementsByClassName(
      "ag-body-horizontal-scroll-container"
    )[0]?.clientHeight;
    if(this.state.navOpen){
      if (scrollHeight > 0 && scrollWidth[0].style !==undefined) {
           scrollWidth[0].style.width = scrollWidth[0].clientWidth + 5 + "px";
      }
    }else{
      if (scrollHeight > 0 && scrollWidth[0].style !==undefined) {
        scrollWidth[0].style.width = scrollWidth[0].clientWidth - 5 + "px";
   }
    }
    this.setState((prevState)=>({ navOpen: !prevState.navOpen }));
  }

  getHidPayrollDate = (hidvalue: any, type: string) => {
    this.setState({ loaderTrue: true });
    let request: any = {};
    request.hotelID = hidvalue;
    PayrollExportService.GetPayRollDate(request)
      .then(async (result: any | null) => {
        if (result.success) {
          this.setState(
            {
              calenderSelectDateString: result.message,
              calenderSelectDate: new Date(result.message),
            },
            () => {
              this.getPayrollData("ehidDropdownSelect");
            }
          );
        } else {
          let calenderSelectDateString = moment().format("MM/DD/YYYY");
          let calenderSelectDate = moment();
          this.setState({
            calenderSelectDateString,
            calenderSelectDate,
          });
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    userCheckArray = [];
    let hidValue = hotelDetails.hotelID;
    let hotelName = hotelDetails.lettercode;
    let hotelFullName = hotelDetails.hotelName;
    let hidTimeZone = hotelDetails.timeZone;
    let displayId = hotelDetails.displayID;
    let Ecode = hotelDetails.eCode;

    this.setState(
      {
        hidValue,
        hotelName,
        hotelFullName,
        hidTimeZone,
        displayId,
        Ecode
      },
      () => {
        this.getHidPayrollDate(this.state.hidValue, "ehidDropdown");
      }
    );
  };

  getPayrollData = (type: string) => {
    if (
      this.state.calenderSelectDateString === "" ||
      this.state.calenderSelectDateString === "Success" ||
      this.state.hidValue === ""
    ) {
      return false;
    }

    // Managing Loader State
    if (
      type === "slideOutChangesSave" ||
      type === "addUserPayroll" ||
      type === "markAsUnexport" ||
      type === "exportPayroll" ||
      type === "addPtoPayout"
    ) {
    } else {
      this.setState({ loaderTrue: true });
    }
    // this.setState({IsEmployeeZeroHrs: "Yes"});
    let requestObject = {} as IGetPayrollExportData;
    requestObject.date = this.state.calenderSelectDateString;
    requestObject.Hotelid = this.state.hidValue;
    requestObject.IsPrevious = "false";

    PayrollExportService.GetPayrollData(requestObject)
      .then(async (result: any | null) => {
        
       
        let apiResult = result.result;

        let navigationCalenderDates: Array<string> = [];
        let payrollHeadingDates: string = "";
        let payrollHeadingdata: any = [];
        let readyToExportDates: String = "";
        let exportHeadingData: any = [];
        let total: number | string = 0;
        let totalName: string = "";
        let ot: number | string = 0;
        let otName: string = "";
        let dot: number | string = 0;
        let dotName: string = "";
        let bonus: number | string = 0;
        let bonusName: string = "";
        let payrollSortOrder: string = "";
        let densityViewOrder: string = "";
        let defaultViewColumns: any = [];
        let userDefinedColumnsToggle: any = [];
        let columnData: any = [];
        let contColumnData: any = [];
        let toggleColumnData: any = [];
        let currentColumnSelected: number = 0;
        let rowData: any = [];
        let contRowData: any = [];
        let isPartialExport: boolean = false;
        let isPayrollSetting: boolean = false;
        let missingPayrollUsers: any = [];
        let pendingPtoRequestUsers: any = [];
        let Isintegration:  boolean = false;
        if (apiResult !== null && apiResult.length > 0) {
          let isEmployeeZeroHrs: boolean = false;
          apiResult.forEach((data: any, idx: any) => {
 debugger;
// if((data.isEmployeeZeroHrs ==="Yes"  || data.checkStatus==="No") && data.userName!=="Header_Row")

            // Getting Navigation Calender Dates and Default view First
            if (idx === 0) {
              // Setting Up Navigation Calender Dates
              let displayDates: any = data.payPeriod_Date.split("-");
              navigationCalenderDates = displayDates;

              // Checking for parial export
              if (
                moment().isSameOrBefore(navigationCalenderDates[1], "day") &&
                moment().isSameOrAfter(navigationCalenderDates[0], "day")
              ) {
                isPartialExport = true;
              }

              // Setting Up Default View If Exists
              if (data.saveDefaultView) {
                defaultViewColumns = data.saveDefaultView.split(",");
              }

              // Setting Up Default Sort If Exists
              if (data.sortOredr && data.sortOredr === "Position") {
                payrollSortOrder = "Position";
              } else {
                payrollSortOrder = "Name";
              }

              if (data.rowDensity && data.rowDensity === "Narrow") {
                densityViewOrder = "Narrow";
              } else {
                densityViewOrder = "Wide";
              }

              // Setting Up Ready To Export if Exists
              readyToExportDates = data.readyToExportDate;
              if (readyToExportDates !== "" && readyToExportDates !== null) {
                let tempExportData: any = readyToExportDates.split(",");
                if (tempExportData.length > 0) {
                  tempExportData.forEach((data: any) => {
                    if (data.includes("-")) {
                      let temp = data.split("-");

                      let startDate = moment(temp[0], "MM-DD-YYYY").format(
                        "Do"
                      );
                      let startMonth = moment(temp[0], "MM-DD-YYYY").format(
                        "MMMM"
                      );
                      let startYear = moment(temp[0], "MM-DD-YYYY").format(
                        "YYYY"
                      );
                      let endDate = moment(temp[1], "MM-DD-YYYY").format("Do");
                      let endMonth = moment(temp[1], "MM-DD-YYYY").format(
                        "MMMM"
                      );
                      let endYear = moment(temp[1], "MM-DD-YYYY").format(
                        "YYYY"
                      );

                      let readyString = `${startMonth} ${startDate}, ${startYear} - ${endMonth} ${endDate}, ${endYear}`;
                      if (startYear === endYear) {
                        readyString = `${startMonth} ${startDate} - ${endMonth} ${endDate}, ${endYear}`;
                      }

                      exportHeadingData.push(readyString);
                    }
                  });
                }
              }
            }

            // Setting Up the Columns for table
            if (idx === 0) {
              let apiColumnData = Object.getOwnPropertyNames(data);

              apiColumnData.forEach((colVal: any, id: any) => {
                let columnObject: any = {};
                let contColumnObject: any = {};
                let columnDefinationObejct: any = {};
                let apiColumnName = "";
                let apiCoumnNumber = "";
                let tempUniqueNumber: any = -1000;

                if (colVal.includes("!!")) {
                  let tempColVal = colVal.split("!!");
                  apiColumnName = tempColVal[0].toUpperCase();
                  apiCoumnNumber = tempColVal[1];
                } else {
                  let newUniqueNumber = tempUniqueNumber + id;
                  apiColumnName = colVal.toUpperCase();
                  apiCoumnNumber = newUniqueNumber;
                }

                // Setting Total, OT, DOT and Bonus name for navigation display
                if (apiCoumnNumber === "104") {
                  totalName = colVal;
                }
                if (apiCoumnNumber === "105") {
                  otName = colVal;
                }
                if (apiCoumnNumber === "106") {
                  dotName = colVal;
                }
                if (apiCoumnNumber === "2") {
                  bonusName = colVal;
                }

                columnDefinationObejct.name = apiColumnName;
                columnDefinationObejct.uniqueNumber = apiCoumnNumber;

                // Table Columns
                columnObject.headerName = apiColumnName.toUpperCase();
                columnObject.field = colVal;
                columnObject.uniqueNumber = apiCoumnNumber;

                //  contract table column

                contColumnObject.headerName = apiColumnName.toUpperCase();
                contColumnObject.field = colVal;
                contColumnObject.uniqueNumber = apiCoumnNumber;
                // Table Column Conditions
                // Default Non Changable Columns
                if (apiColumnName === "EMPLOYEE") {
                  columnObject.lockPosition = true;
                  columnObject.pinned = true;
                  // columnObject.sortable = true;
                  columnObject.sortingOrder = [null, "asc"];
                  columnObject.sort =
                    // payrollSortOrder === "Employee" ? "asc" : null;
                    payrollSortOrder === "Name" ? "asc" : null;
                  columnObject.cellRenderer = "emplyeeNameRender";

                  // setting col for contract table
                  contColumnObject.cellRenderer = "contractorNameRender";
                  contColumnObject.pinned = true;
                  contColumnObject.suppressSizeToFit = true;
                  contColumnObject.lockPosition = true;
                  contColumnObject.sortingOrder = [null, "asc"];
                  contColumnObject.sort =
                    // payrollSortOrder === "Employee" ? "asc" : null;
                    payrollSortOrder === "Name" ? "asc" : null;
                }
                if (apiColumnName === "S/H") {
                  columnObject.lockPosition = true;
                  columnObject.pinned = true;
                }
                if (apiColumnName === "POSITION") {
                  columnObject.sortingOrder = [null, "asc"];
                  columnObject.sort =
                    payrollSortOrder === "Position" ? "asc" : null;
                  columnObject.hide = true;

                  // setting col for contract table
                  contColumnObject.lockPosition = true;
                  contColumnObject.sortingOrder = [null, "asc"];
                  contColumnObject.sort =
                    // payrollSortOrder === "Employee" ? "asc" : null;
                    payrollSortOrder === "Position" ? "asc" : null;
                  contColumnObject.hide = true;
                }

                // Table Heder CSS properties
                if (apiColumnName === "EMPLOYEE") {
                  columnObject.width = 280;
                  columnObject.minWidth = 280;

                  contColumnObject.width = 280;
                  contColumnObject.minWidth = 280;
                } else if (
                  apiColumnName.length >= 10 ||
                  apiColumnName === "PAYMENT"
                ) {
                  columnObject.width = 180;
                  columnObject.minWidth = 180;

                  contColumnObject.width = 180;
                  contColumnObject.minWidth = 180;
                } else {
                  columnObject.width = 100;
                  columnObject.minWidth = 100;

                  contColumnObject.width = 100;
                  contColumnObject.minWidth = 100;
                }

                // Show Hide According to Default View
                // Check if User has already set his view first and the api call is only for the slideout save
                if (
                  (type === "slideOutChangesSave" ||
                    type === "addUserPayroll" ||
                    type === "markAsUnexport" ||
                    type === "exportPayroll" ||
                    type === "addPtoPayout") &&
                  this.state.userDefinedColumnsToggle.length > 0
                ) {
                  if (apiColumnName === "EMPLOYEE" || apiColumnName === "S/H") {
                    columnObject.hide = false;
                  } else if (
                    this.state.userDefinedColumnsToggle.includes(apiCoumnNumber)
                  ) {
                    columnObject.hide = false;
                  } else {
                    columnObject.hide = true;
                  }
                } else {
                  if (defaultViewColumns.length > 0) {
                    if (
                      apiColumnName === "EMPLOYEE" ||
                      apiColumnName === "S/H"
                    ) {
                      columnObject.hide = false;
                    } else if (defaultViewColumns.includes(apiCoumnNumber)) {
                      columnObject.hide = false;
                    } else {
                      columnObject.hide = true;
                    }
                  } else {
                    if (
                      apiColumnName === "EMPLOYEE" ||
                      apiColumnName === "S/H"
                    ) {
                      columnObject.hide = false;
                    } else if (apiColumnName === "POSITION") {
                      // dont perfrom any action
                    } else if (id <= this.state.maxColumnSelected) {
                      columnObject.hide = false;
                      if (apiCoumnNumber !== "0") {
                        userDefinedColumnsToggle.push(apiCoumnNumber);
                      }
                    } else {
                      columnObject.hide = true;
                    }
                  }
                }
            

                // Column Insert Conditionalaly to display
                if (
                  apiColumnName === "PAYPERIOD_DATE" ||
                  apiColumnName === "USERUNIQUENO" ||
                  apiColumnName === "SAVEDEFAULTVIEW" ||
                  apiColumnName === "SORTOREDR" ||
                  apiColumnName === "ROWDENSITY" ||
                  apiColumnName === "READYTOEXPORTDATE" ||
                  apiColumnName === "EMPSTATUS" ||
                  apiColumnName === "CHECKSTATUS" ||
                  apiColumnName === "SHOWINFOBANNER" ||
                  apiColumnName === "DEFAULTPOPUP" ||
                  apiColumnName === "ISPTOPOLICY" ||
                  apiColumnName === "USERNAME" ||
                  apiColumnName === "SHOWINFOBANNER" ||
                  apiColumnName === "ISPARTIALEXPORT" ||
                  apiColumnName === "ISUSERPUNCH" ||
                  apiColumnName === "ISPENDINGPTO" ||
                  apiColumnName === "ISPAYROLLSETTING" ||
                  apiColumnName === "USER_UNIQUEID" ||
                  apiColumnName === "USEREDIT" ||
                  apiColumnName === "ISEXISTCONTRACOREMAIL"
                ) {
                } else if (apiColumnName === "EMPLOYEE") {
                  columnObject.headerName = "Employee Name";
                  contColumnObject.headerName = "Contractor";
                  columnData.unshift(columnObject);
                  contColumnData.push(contColumnObject);
                } else if (apiColumnName === "S/H") {
                  columnData.splice(1, 0, columnObject);
                } else if (apiColumnName === "POSITION") {
                  columnData.splice(2, 0, columnObject);
                  contColumnData.push(contColumnObject);
                   
                } else {
                  if (
                    apiColumnName === "AGENCYVENDOR" ||
                    apiColumnName === "REG" ||
                    apiColumnName === "PAYMENT" ||
                    apiColumnName === "OT" ||
                    apiColumnName === "DOT"
                  ) {
                    contColumnObject.lockPosition = true;
                    if (apiColumnName === "AGENCYVENDOR") {
                      contColumnObject.headerName = "AGENCY/VENDOR";
                    }
                    contColumnData.push(contColumnObject);
                  }
                  columnData.push(columnObject);
                }

                // Dynamic Toggle Column Management
                let toggleColumnObject: any = {};
                toggleColumnObject.colDispalyName = apiColumnName.toUpperCase();
                toggleColumnObject.colName = colVal;
                toggleColumnObject.checked = columnObject.hide ? false : true;
                toggleColumnObject.disabled = false;
                // toggleColumnObject.disabled = columnObject.hide ? true  false;
                if (
                  apiColumnName === "EMPLOYEE" ||
                  apiColumnName === "Employee Name" 
                ) {
                  toggleColumnObject.colDispalyName = "EMPLOYEE NAME";

                }
                if (
                  apiColumnName === "EMPLOYEE" ||
                  apiColumnName === "Employee Name" ||
                  apiColumnName === "S/H"
                ) {
                  toggleColumnObject.checked = true;
                  toggleColumnObject.disabled = true;
                }

                if (
                  columnObject.hide ||
                  apiColumnName === "POSITION" ||
                  apiColumnName === "PAYPERIOD_DATE" ||
                  apiColumnName === "USERUNIQUENO" ||
                  apiColumnName === "SAVEDEFAULTVIEW" ||
                  apiColumnName === "SORTOREDR" ||
                  apiColumnName === "ROWDENSITY" ||
                  apiColumnName === "READYTOEXPORTDATE" ||
                  apiColumnName === "EMPSTATUS" ||
                  apiColumnName === "CHECKSTATUS" ||
                  apiColumnName === "SHOWINFOBANNER" ||
                  apiColumnName === "DEFAULTPOPUP" ||
                  apiColumnName === "ISPTOPOLICY" ||
                  apiColumnName === "USERNAME" ||
                  apiColumnName === "SHOWINFOBANNER" ||
                  apiColumnName === "ISPARTIALEXPORT" ||
                  apiColumnName === "ISUSERPUNCH" ||
                  apiColumnName === "ISPAYROLLSETTING" ||
                  apiColumnName === "USER_UNIQUEID" ||
                  apiColumnName === "USEREDIT" ||
                  apiColumnName === "ISPENDINGPTO" ||
                  apiColumnName === "PAYMENT" ||
                  apiColumnName === "AGENCYVENDOR" ||
                  apiColumnName === "ISEXISTCONTRACOREMAIL" ||
                  apiColumnName === "NOTES" ||
                  apiColumnName === "ISEMPLOYEEZEROHRS"
                  
                ) {
                } else {
                  currentColumnSelected = currentColumnSelected + 1;
                }
                if (
                  apiColumnName === "EMPLOYEE" ||
                  apiColumnName === "Employee Name"
                ) {
                  toggleColumnData.unshift(toggleColumnObject);
                } else if (
                  apiColumnName === "POSITION" ||
                  apiColumnName === "PAYPERIOD_DATE" ||
                  apiColumnName === "USERUNIQUENO" ||
                  apiColumnName === "SAVEDEFAULTVIEW" ||
                  apiColumnName === "SORTOREDR" ||
                  apiColumnName === "ROWDENSITY" ||
                  apiColumnName === "READYTOEXPORTDATE" ||
                  apiColumnName === "EMPSTATUS" ||
                  apiColumnName === "CHECKSTATUS" ||
                  apiColumnName === "SHOWINFOBANNER" ||
                  apiColumnName === "DEFAULTPOPUP" ||
                  apiColumnName === "ISPTOPOLICY" ||
                  apiColumnName === "USERNAME" ||
                  apiColumnName === "SHOWINFOBANNER" ||
                  apiColumnName === "ISPARTIALEXPORT" ||
                  apiColumnName === "ISUSERPUNCH" ||
                  apiColumnName === "ISPAYROLLSETTING" ||
                  apiColumnName === "USER_UNIQUEID" ||
                  apiColumnName === "USEREDIT" ||
                  apiColumnName === "ISPENDINGPTO" ||
                  apiColumnName === "PAYMENT" ||
                  apiColumnName === "AGENCYVENDOR" ||
                  apiColumnName === "ISEXISTCONTRACOREMAIL"||
                  apiColumnName === "NOTES"||
                  apiColumnName === "ISEMPLOYEEZEROHRS"
                ) {
                } else if (apiColumnName === "S/H") {
                  toggleColumnData.splice(1, 0, toggleColumnObject);
                } else {
                  toggleColumnData.push(toggleColumnObject);
                }
              });
            }

            // Setting up the Row Data
            if (idx > 0) {
              let rowNames = Object.getOwnPropertyNames(data);
              let rowPushObject: any = {};
              let contRowPushObject: any = {};
              let hasContractData = false;
              // debugger;
              if (data["s/H!!0"] === "C") {
                hasContractData = true;
              }
              if (data["isPayrollIntegration"] === "Yes") {
                Isintegration = true;
              }

              rowNames.forEach((rowName: any) => {
                let rowObjectValue = data[rowName];

                if (rowName !== "user_UniqueID") {
                  if (typeof rowObjectValue === "number") {
                    rowObjectValue = rowObjectValue.toFixed(2);
                  }
                }

                // Format with $ symbol with conditional columns
                if (rowObjectValue !== null && rowName.includes("!!")) {
                  let rowNameArray: any = rowName.split("!!");
                  let rowUniqueNumber = rowNameArray[1];

                  if (
                    rowUniqueNumber === "1" ||
                    rowUniqueNumber === "2" ||
                    rowUniqueNumber === "3" ||
                    rowUniqueNumber === "4" ||
                    rowUniqueNumber === "5" ||
                    rowUniqueNumber === "6" ||
                    rowUniqueNumber === "7" ||
                    rowUniqueNumber === "9" ||
                    rowUniqueNumber === "10" ||
                    rowUniqueNumber === "11" ||
                    rowUniqueNumber === "12" ||
                    rowUniqueNumber === "13" ||
                    rowUniqueNumber === "14" ||
                    rowUniqueNumber === "15" ||
                    rowUniqueNumber === "16" ||
                    rowUniqueNumber === "17"
                  ) {
                   
                    rowObjectValue =
                      Utils.currencyFormatWithOutdoller(rowObjectValue);
                    rowObjectValue = `$ ${rowObjectValue}`;
                  } else {
                  }
                }

                // Dealing up with the null values
                if (rowObjectValue === null) {
                  rowObjectValue = "-";
                }

                rowPushObject[rowName] = rowObjectValue;

                // row object for contractor data
                if (hasContractData) {
                  contRowPushObject[rowName] = rowObjectValue;
                  contRowPushObject.push = true;
                  
                }
              });

              // Checkbox checked management for row
              let rowIsChecked: boolean = false;
              if (isPartialExport) {
                rowIsChecked = false;
              } else if (
                rowPushObject.isPartialExport === "Yes" ||
                rowPushObject.isUserPunch === "Yes"
              ) {
                rowIsChecked = false;
              } else if (
                rowPushObject.empStatus === "Active" ||
                rowPushObject.checkStatus === "Yes"
              ) {
                rowIsChecked = true;
              } else {
                rowIsChecked = false;
              }
              // Mainting User Saved State for check uncheck
              if (
                type === "slideOutChangesSave" ||
                type === "addUserPayroll" ||
                type === "markAsUnexport" ||
                type === "addPtoPayout"
              ) {
                let foundIndex: number = userCheckArray.findIndex(
                  (elem: any) =>
                    elem.UserUniqueno ===
                    Number(rowPushObject[employeeParameter])
                );

                if (foundIndex >= 0) {
                  rowIsChecked = true;
                }
              }

              if (data["isPayrollSetting"] === "No") {
                // debugger;
                rowIsChecked = false;
                isPayrollSetting = true;
                missingPayrollUsers.push(data);
              }

              if (data["isPendingPTO"] > 0) {
                pendingPtoRequestUsers.push(data);
              }

              rowPushObject.hasEditPermission = false;

              if (data.userEdit === "Yes") {
                rowPushObject.hasEditPermission = true;
              }

              rowPushObject.isChecked = rowIsChecked;
              rowPushObject.id = idx;
              if (contRowPushObject?.push) {
                contRowData.push(rowPushObject);
              }
              if (!hasContractData) {
                rowData.push(rowPushObject);
              }
            
              // debugger;
              // if((data.isEmployeeZeroHrs ==="Yes" || rowIsChecked===false) && data.userName!=="Header_Row")
              // {
              //   isEmployeeZeroHrs=true;
             
              
              // }

              // debugger;
              // Setting Total, OT, DOT, and Bonus
              if (data[totalName] === undefined || data[totalName] === null) {
              } else {
                total = total + data[totalName];
              }
              if (data[otName] === undefined || data[otName] === null) {
              } else {
                ot = ot + data[otName];
              }
              if (data[dotName] === undefined || data[dotName] === null) {
              } else {
                dot = dot + data[dotName];
              }
              if (data[bonusName] === undefined || data[bonusName] === null) {
              } else {
                bonus = bonus + data[bonusName];
              }
            }
            
          });

          // Payroll Heading Dates Manipulation
          let navStartDate = navigationCalenderDates[0];
          let navEndDate = navigationCalenderDates[1];
          let startDate = moment(navStartDate, "MM-DD-YYYY").format("Do");
          let startMonth = moment(navStartDate, "MM-DD-YYYY").format("MMMM");
          let startYear = moment(navStartDate, "MM-DD-YYYY").format("YYYY");
          let endDate = moment(navEndDate, "MM-DD-YYYY").format("Do");
          let endMonth = moment(navEndDate, "MM-DD-YYYY").format("MMMM");
          let endYear = moment(navEndDate, "MM-DD-YYYY").format("YYYY");

          if (startYear === endYear) {
            payrollHeadingDates = `${startMonth} ${startDate} - ${endMonth} ${endDate}, ${endYear}`;
          } else {
            payrollHeadingDates = `${startMonth} ${startDate}, ${startYear} - ${endMonth} ${endDate}, ${endYear}`;
          }

          // Payroll Heading Data Manipulation
          total = total.toFixed(2).toString();
          ot = ot.toFixed(2);
          dot = dot.toFixed(2);

          bonus = `$${bonus.toFixed(2)}`;

          payrollHeadingdata = [
            { name: "TOTALS", value: total, unit: "hours" },
            { name: "OT", value: ot, unit: "hours" },
            { name: "DOT", value: dot, unit: "hours" },
          ];

          // Assigning the toggle state in case of slide out changes
          if (
            type === "slideOutChangesSave" ||
            type === "addUserPayroll" ||
            type === "markAsUnexport" ||
            type === "exportPayroll" ||
            type === "addPtoPayout"
          ) {
            userDefinedColumnsToggle = this.state.userDefinedColumnsToggle;
          } else {
            if (defaultViewColumns.length > 0) {
              userDefinedColumnsToggle = defaultViewColumns;
            }
          }

          // Aligning Columns as per the oreder specified
          for (let i = 0; i < userDefinedColumnsToggle.length; i++) {
            for (let j = 0; j < columnData.length; j++) {
              if (userDefinedColumnsToggle[i] === columnData[j].uniqueNumber) {
                let indexToMove = i + 2; // Defining first two columns for employee name and s/h status
                let removedColumn = columnData.splice(j, 1);
                let columnToInsert = removedColumn[0];
                columnData.splice(indexToMove, 0, columnToInsert);
              }
            }
          }

          // Managing Toggle Colmun Data in case less than 8 itmes has selected as default view
          if (currentColumnSelected < 8) {
            for (let i = 0; i < toggleColumnData.length; i++) {
              if (
                toggleColumnData[i].checked === false &&
                toggleColumnData[i].disabled === true
              ) {
                toggleColumnData[i].disabled = false;
              }
            }
          }

          let filteredUserList: any = [];
          for (let x = 0; x < missingPayrollUsers.length; x++) {
            if (x === 0) {
              filteredUserList.push(missingPayrollUsers[x]);
            } else {
              if (
                missingPayrollUsers[x - 1].userName !==
                missingPayrollUsers[x].userName
              ) {
                filteredUserList.push(missingPayrollUsers[x]);
              }
            }
          }

          // Generating three dot menu in last column
          let threeDotColumnObject: any = {};
          threeDotColumnObject.lockPosition = true;
          threeDotColumnObject.pinned = "right";
          threeDotColumnObject.suppressMovable = true;
          threeDotColumnObject.cellRenderer = "threeDotRender";
          threeDotColumnObject.uniqueNumber = "0";
          threeDotColumnObject.field = "threeDotRender";
          threeDotColumnObject.headerName = "";
          threeDotColumnObject.width = 100;
          columnData.splice(columnData.length, 0, threeDotColumnObject);

          // Assgning values to manage the save as default in non row density case
          originalPayrollSort = payrollSortOrder;
          originalColumnView = userDefinedColumnsToggle;
          this.setState(
            {
              payrollSortOrder,
              densityViewOrder,
              navigationCalenderDates,
              payrollHeadingDates,
              payrollHeadingdata,
              exportHeadingData,
              isPartialExport,
              userDefinedColumnsToggle,
              columnData,
              rowData,
              contColumnData,
              contRowData,
              toggleColumnData,
              currentColumnSelected,
              isPayrollSetting,
              missingPayrollUsers,
              filteredUserList,
              pendingPtoRequestUsers,
              rowCount:rowData.length,
              contTableRowCount:contRowData.length,
              IsPayrollIntegration:Isintegration
          //  IsEmployeeZeroHrs: false
            },
            () => {
              setTimeout(() => {
                // this.columnWidthSizeToFit("getPayrollData");
              }, 0);
              this.changeUserTableHeight(this.state.densityViewOrder)
              this.getPayrollExportStatus();
            }
          );
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  getPayrollExportStatus = () => {
    let startDate = moment(this.state.navigationCalenderDates[0]);
    let endDate = moment(this.state.navigationCalenderDates[1]);
    let diffInDays = endDate.diff(startDate, "days");

    let hotelID = this.state.hidValue;
    let selectedDate = this.state.calenderSelectDateString;
    let PayType = "Weekly";
    if (diffInDays > 7) {
      PayType = "bi-Weekly";
    }

    LaborPerformance.getPayPeriodDates(hotelID, selectedDate, PayType)
      .then(async (result: any) => {
        if (result != null) {
          let apiResult = result[0];
          if (apiResult.exporteddate !== "") {
            let exportDate = apiResult.exporteddateMMMMDDYYYY;
            this.setState(
              {
                exportDate,
                exportBanner: true,
              },
              () => {
                this.gridApi.redrawRows();
              }
            );
          } else {
            this.setState(
              {
                exportDate: "",
                exportBanner: false,
              },
              () => {
                this.gridApi.redrawRows();
              }
            );
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  getUserStatusList = () => {
    UserManagement.GetUserStatusList().then((response: string[]) => {
      const statusList = [
        { id: "", name: "All" },
        ...response.map((item) => ({ id: item, name: item })),
      ];

      const childStatusList = response.map((item) => {
        if (item === "Incomplete") {
          return { id: item, name: item, disabled: true };
        } else {
          return { id: item, name: item };
        }
      });

      this.setState({ statusList, childStatusList });
    });
  };

  getAddUserPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 57;
    requestObject.Module = "All";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        // 2213	Change E/HID Access
        // 2214	Change User Role
        // 2215	Change User Status
        // 2218	Create New User
        // 2229	Edit User Information
        // 2231	Payroll Deduction Settings
        // 2234	Reset Password
        // 2235	Role Permission
        // 2237	Training Setup
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionID === 2218) {
              this.setState({ isPageCreateNewUser: true });
            }
            if (element.permissionID === 2229) {
              this.setState({ isEditUserInfo: true, isSendWelcomeEmail: true });
            }
            if (element.permissionID === 2214) {
              this.setState({ isChangeUserRole: true });
            }
            if (element.permissionID === 2213) {
              this.setState({ isChangeEHIDAccess: true });
            }
            if (element.permissionID === 2234) {
              this.setState({ isResetPassword: true });
            }
            if (element.permissionID === 2215) {
              this.setState({ isChangeStatus: true });
            }
          });
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      });
  };
  getPayrolPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10010;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";

    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)

      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionName) {
              case "Manage Payroll":
                this.setState({ permissionManagePayroll: true });
                break;
              case "Export Payroll":
                this.setState({ permissionExportPayroll: true });
                break;
            }
          });
        } else {
          Utils.toastError(result.message);
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  getPayrollUnexportPermission = () => {
    debugger;
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10028;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";

    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)

      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            debugger;
            if(element.permissionName === "Unexport Payroll"){
              this.setState({ permissionUnExportPayroll: true });
            }
            else{
              this.setState({ permissionUnExportPayroll: false });
            }
            // switch (element.permissionName) {
            //   case "Unexport Payroll":
            //     this.setState({ permissionUnExportPayroll: true });
            //     break;
            // }
            debugger
            if(this.state.permissionUnExportPayroll === true)
            {
              let unexportpermission : any[] = [];
              unexportpermission = this.state.flyoutMenuItems
              unexportpermission.push("Mark as Unexported");
              this.setState({flyoutMenuItems : unexportpermission });
              console.log("flyoutMenuItems",this.state.flyoutMenuItems);
              
            }
          });
        } else {
          Utils.toastError(result.message);
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
      // if(this.state.permissionUnExportPayroll === true)
      // {
      //   let unexportpermission : any[] = [];
      //   unexportpermission = this.state.flyoutMenuItems
      //   unexportpermission.push("Mark as Unexported")
      // }
  };

  methodFromParent = (e: any, rowValues: any) => {
    checkBoxSelection = true; // condition for maintian the slideout click on checkbox

    // Conditon for check if row values are zero for Hourly User in Partial Export
    if (this.state.isPartialExport && rowValues[userSalHurParameter] === "H") {
      let rowData = [...this.state.rowData];
      let userData: Array<object> = [];

      for (let i = 0; i < rowData.length; i++) {
        if (rowData[i][employeeParameter] === rowValues[employeeParameter]) {
          userData.push(rowData[i]);
        }
      }

      let showNoDataAlert: boolean = true;
      let rowPropName: Array<string> = Object.getOwnPropertyNames(rowValues);
      let rowTotal: number = 0;
      for (let j = 0; j < userData.length; j++) {
        for (let k: number = 0; k < rowPropName.length; k++) {
          let rowPropSplit: Array<string> = rowPropName[k].split("!!");
          if (rowPropSplit.length === 2) {
            // Check if the field is in the calculated feild or not
            if (Number(rowPropSplit[1]) > 0) {
              let userValue = userData[j][rowPropName[k]];
              userValue = userValue.replace("$", "");
              userValue = userValue.replace(":", "");
              userValue = userValue.replace("-", "");
              userValue = userValue.replace("_", "");
              rowTotal = rowTotal + Number(userValue);
              if (rowTotal > 0) {
                showNoDataAlert = false;
                break;
              }
            }
          }
        }
      }

      if (showNoDataAlert) {
        Utils.toastError(
          "No value added for the employee, can not select user for partial pay export."
        );
      } else {
        this.toggleUserCheckBox(e, rowValues);
      }
    } else {
      this.toggleUserCheckBox(e, rowValues);
    }
  };

  toggleUserCheckBox = (e: any, rowValues: any) => {
    let value = e.target.checked;

    // Maintianing User Check Uncheck Array
    let newCheckArray: Array<any> = [...userCheckArray];

    if (value) {
      let pushObject: any = {};
      pushObject.UserUniqueno = Number(rowValues[employeeParameter]);
      pushObject.employeeName = rowValues.userName;
      pushObject.Regular = rowValues[employeeRegPrameter];
      pushObject.EmpStatus = rowValues.empStatus;
      pushObject.Checked = 1;

      newCheckArray.push(pushObject);
    } else {
      let removalIndex: number = newCheckArray.findIndex(
        (elem) => elem.UserUniqueno === Number(rowValues[employeeParameter])
      );

      if (removalIndex >= 0) {
        newCheckArray.splice(removalIndex, 1);
      }
    }
    userCheckArray = newCheckArray;

    // Updating Check Uncheck Table State
    let newRowData = [...this.state.rowData];
    newRowData = newRowData.map((d) => {
      if (d[employeeParameter] === rowValues[employeeParameter]) {
        return {
          ...d,
          isChecked: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        rowData: newRowData,
      }),
      () => {
        this.setUserCheckUncheck(value, rowValues); // Calling Api to update status in DB
      }
    );
  };

  setUserCheckUncheck = (status: boolean, empRow: any) => {
   
    let rowData = [...this.state.rowData];

    let emplyeeCheckList: Array<any> = [];

    for (let i = 0; i < rowData.length; i++) {
      let pushObject: any = {};
      pushObject.UserUniqueno = Number(rowData[i][employeeParameter]);
      pushObject.EmpStatus = rowData[i].empStatus;
      pushObject.Checked = rowData[i].isChecked ? 1 : 0;
      pushObject.Regular = rowData[i][employeeRegPrameter];
      // if(rowData[i].isChecked === true){
      //   toast.success("ldfjhgdkjf")
      // }
      if (pushObject.Checked) {
        emplyeeCheckList.push(pushObject);
      }
      
    }

    let requestObject = {} as ISaveUserCheckStatus;

    requestObject.Hotelid = this.state.hidValue;
    requestObject.UserUniqueno = Number(empRow[employeeParameter]);
    requestObject.DateFrom = this.state.navigationCalenderDates[0];
    requestObject.DateTo = this.state.navigationCalenderDates[1];
    requestObject.EmpStatus = empRow.empStatus;
    requestObject.Checked = status ? 1 : 0;
    requestObject.Regular = empRow[employeeRegPrameter];
    requestObject.emplyeeCheckList = emplyeeCheckList;

    PayrollExportService.SaveUserCheckStatus(requestObject)
      .then(async (result: any | null) => {
        console.log("result",requestObject);
        
        let toastMessage: string = result.result;
        if (result != null && result.message === "Success") {
        } else {
          Utils.toastError(toastMessage);
        }
        if(status === true){
          toast.success("User has been selected for payroll export");
        }
        else{
          Utils.toastError("User will not be included in payroll export")
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  columnWidthSizeToFit = (callFrom: string) => {
    const { currentColumnSelected, loaderTrue, changeInColCount } = this.state;
    let navMenuOpen = false;
    if (callFrom !== "getPayrollData" && !loaderTrue) {
      const tableWidth = document.getElementsByClassName(
        "ag-center-cols-viewport"
      )[0]?.clientWidth;
      let scrollWidth = document.getElementsByClassName(
        "ag-body-horizontal-scroll-container"
      ) as HTMLCollectionOf<HTMLElement>;
      const scrollHeight = document.getElementsByClassName(
        "ag-body-horizontal-scroll-container"
      )[0]?.clientHeight;
      if (changeInColCount && scrollHeight > 0 && scrollWidth[0].style !==undefined) {
        scrollWidth[0].style.width = scrollWidth[0].clientWidth - 5 + "px"; 
      }
      let allColumnsWidth = 100 * (currentColumnSelected - 2);
      let hasSpace = false;
      if (tableWidth - allColumnsWidth > 0) {
        hasSpace = true;
      }
      
      if (currentColumnSelected <= 10 || hasSpace) {
        this.gridApi?.sizeColumnsToFit();
    if(this.state.contRowData.length > 0){ 
      this.contGridApi?.sizeColumnsToFit();
    }
      } else {
        const allColumnIds: any = [];
        this.gridColumnApi?.getAllColumns()?.forEach((column: any) => {
          // debugger;
          allColumnIds.push(column.getId());
        });
        this.gridColumnApi?.autoSizeColumns(allColumnIds, false);
    if(this.state.contRowData.length > 0){ 
      this.contGridApi?.sizeColumnsToFit();
    }
    
      }
    }
    const navMenu = $(".hide-area.opened");
    if (navMenu.length !== 0) {
      navMenuOpen = true;
    }
    this.setState({ navMenuOpen });
  };

  onContGridReady = (params: any) => {
    this.contGridApi = params.api;
    this.contGridColumnApi = params.columnApi;
    this.columnWidthSizeToFit("gridReady");

    let rowCount = this.contGridApi?.getDisplayedRowCount();
    this.setState({ contTableRowCount: rowCount });
  };

  onGridReady = (params: any) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.columnWidthSizeToFit("gridReady");
    let rowCount = this.gridApi?.getDisplayedRowCount();
    this.setState({ rowCount },()=>{
      this.changeUserTableHeight(this.state.densityViewOrder)
    });

    const headerSelector = ".ag-header";
    const scrollSelector = ".ag-body-horizontal-scroll";
    const scrollViewportSelector = ".ag-body-horizontal-scroll-viewport";
    const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

    // get scrollbar elements
    const scrollElement: any = document.querySelector(scrollSelector);
    const scrollViewportElement: any = document.querySelector(
      scrollViewportSelector
    );
    const scrollContainerElement: any = document.querySelector(
      scrollContainerSelector
    );

     
  };

  payrollExportSearch = () => {
    this.gridApi.setQuickFilter(this.searchInput.current.value);
    let rowCount = this.gridApi?.getDisplayedRowCount();
    if(this.state.contRowData.length > 0){ 
      this.contGridApi.setQuickFilter(this.searchInput.current.value);
      let contTableRowCount = this.contGridApi?.getDisplayedRowCount();
      this.setState({ rowCount, contTableRowCount });
    }else{
      this.setState({ rowCount });
    }

   
  };

  rowClicked = (params: any) => {
    let rowData = params.data;

    const hasEditPermission = rowData.hasEditPermission;
    const payrollMissing = rowData["isPayrollSetting"] === "No";
    
    if (!checkBoxSelection && !tripleDotSelection && !tripleDotListSelection) {
      if (payrollMissing && !this.state.exportBanner) {
        if (hasEditPermission) {
          this.showUserSlideOut("", rowData);
        }
      } else {
        this.setState({ slidwOutRowData: rowData }, () => {
          this.showSlideOut();
        });
      }
    }

    // Setting CheckBox Select again to maintain row Cick
    checkBoxSelection = false;
    tripleDotSelection = false;
    tripleDotListSelection = false;
  };

  columnDragStop = (params: any) => {
    let columnInstance =
      params.columnApi.columnController.displayedCenterColumns;
    let userDefinedColumnsToggle: any = [];

    for (let i = 0; i < columnInstance.length; i++) {
      let columnUniqueNumber: string = columnInstance[i].colDef.uniqueNumber;

      if (columnUniqueNumber === "0") {
      } else {
        userDefinedColumnsToggle.push(columnUniqueNumber);
      }
    }
    this.setState({ userDefinedColumnsToggle }, () => {
      // this.columnWidthSizeToFit('columnDragStop');
    });
  };

  toggleColumnCheck = (e: any, data: any, idx: any) => {
    let checked = e.target.checked;

    let copiedObject = JSON.parse(JSON.stringify(this.state.columnData));
    let columnData: any = [...copiedObject];
    let toggleColumnData: any = [...this.state.toggleColumnData];
    let userDefinedColumnsToggle: any = [];
    let currentColumnSelected: number = this.state.currentColumnSelected;
    let changeInColCount = false;

    // Hide Show Column on Toggle
    for (let i = 0; i < columnData.length; i++) {
      if (columnData[i].field === data.colName) {
        columnData[i].hide = !checked;
        break;
      }
    }

    // Managing Total Number of Checks
    if (checked) {
      currentColumnSelected = currentColumnSelected + 1;
      changeInColCount = true;
    } else {
      currentColumnSelected = currentColumnSelected - 1;
      changeInColCount = true;
    }

    // Updating Column Object and not updating anything in employee and s/h section
    if (currentColumnSelected < this.state.maxColumnSelected) {
      toggleColumnData[idx].checked = checked;
      for (let i = 0; i < toggleColumnData.length; i++) {
        if (
          toggleColumnData[i].colDispalyName.includes("EMPLOYEE") ||
          toggleColumnData[i].colDispalyName === "S/H"
        ) {
        } else {
          toggleColumnData[i].disabled = false;
        }
      }
    }
     

    // Managing user Defined Columns for Column State Management
    for (let i = 0; i < columnData.length; i++) {
      if (
        columnData[i].headerName === "EMPLOYEE" ||
        columnData[i].headerName === "S/H"
      ) {
      } else {
        if (columnData[i].hide === false) {
          let coumnUniqueNumber = columnData[i].uniqueNumber;
          userDefinedColumnsToggle.push(coumnUniqueNumber);
        }
      }
    }

    this.setState(
      {
        columnData,
        toggleColumnData,
        currentColumnSelected,
        userDefinedColumnsToggle,
        changeInColCount,
      },
      () => {
        this.columnWidthSizeToFit("toggleColumn");
      }
    );
  };

  slideOutDataSave = () => {
    this.getPayrollData("slideOutChangesSave");
  };

  exportPayroll = () => {
    this.setState({ exportButtonSpinner: true,exportingData:true });
    this.exportDropdownToggle(false);

    let newRowData = [...this.state.rowData];
    let newContRowData = [...this.state.contRowData];
    newRowData = newRowData.map((d) => {
      if(d.isChecked){
        return {
          ...d,
          exporting:true
        }
      } else{
          return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        rowData: newRowData,
      }));

    let exportEmployeeArray: any = [];
    let exportContratorArray: any = [];
    let isExistContracorEmail = "";

    for (let i = 0; i < newRowData.length; i++) {
      if (newRowData[i].isChecked) {
        let pushObject: any = {};
        pushObject.UserUniqueno = Number(newRowData[i][employeeParameter]);
        pushObject.employeeName = newRowData[i].userName;
        pushObject.Regular = newRowData[i][employeeRegPrameter];
        pushObject.EmpStatus = newRowData[i].empStatus;
        pushObject.Checked = 1;
        exportEmployeeArray.push(pushObject);
      }
    }

    for (let i = 0; i < newContRowData.length; i++) {
      isExistContracorEmail = newContRowData[i].isExistContracorEmail;
      let pushObjectCont: any = {};
      pushObjectCont.UserUniqueno = Number(
        newContRowData[i][employeeParameter]
      );
      pushObjectCont.employeeName = newContRowData[i]["employee!!0"];
      pushObjectCont.Regular = newContRowData[i][employeeRegPrameter];
      pushObjectCont.position = newContRowData[i][positionParameter];
      pushObjectCont.payment = newContRowData[i].payment;
      pushObjectCont.agencyVendor = newContRowData[i].agencyVendor;
      pushObjectCont.ot = newContRowData[i]["oT!!105"];
      pushObjectCont.dot = "noDOT";
      if (newContRowData[i]["doT!!106"] !== undefined) {
        pushObjectCont.dot = newContRowData[i]["doT!!106"];
      }
      // pushObjectCont.Checked = 1;
      exportContratorArray.push(pushObjectCont);
    }

    userCheckArray = exportEmployeeArray;
    let requestObject = {} as IExportPayrollData;
    requestObject.date = this.state.navigationCalenderDates[0];
    requestObject.DateTo = this.state.navigationCalenderDates[1];
    requestObject.isExistContracorEmail = isExistContracorEmail;
    requestObject.Hotelid = this.state.hidValue;
    requestObject.emplyeeCheckListPayroll = exportEmployeeArray;
    requestObject.contractorPayrollExployee = exportContratorArray;

    PayrollExportService.ExportPayroll(requestObject)
      .then(async (result: any | null) => {
        this.getPayrollData("exportPayroll");
        let toastMessage: string = result.result;
        if (result != null && result.message === "Success") {
          userCheckArray = [];
          toast.success(toastMessage);

          if (isExistContracorEmail === "No" && !this.state.isPartialExport) {
            PayrollExportService.DownloadPayrolExportContractor(
              requestObject
            ).then(async (result: any | null) => {
              const fileName = `${"Contractor Exported Payroll File - "}${this.state.displayId}-${this.state.Ecode}-${this.state.hotelName}-${this.state.navigationCalenderDates[0]} to ${this.state.navigationCalenderDates[1]}`;
              let csvURL = window.URL.createObjectURL(result.data);
              let tempLink = document.createElement("a");
              tempLink.href = csvURL;
              tempLink.setAttribute("download", fileName + ".csv");
              tempLink.click();
            });
          }
        } else {
          Utils.toastError(toastMessage);
        }

        this.setState({ exportButtonSpinner: false,exportingData:false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        this.setState({ exportButtonSpinner: false,exportingData:false });
        reject();
      });
  };

  getPayRollExcelFileName = () => {
    let currentDay = new Date();
    let fileName = "ExportToPayroll_";
    if (this.state.hidTimeZone) {
      let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(
        this.state.hidTimeZone
      );
      let zoneDate: any = moment.tz(timeZoneValue).format("DD");
      let zoneMonth: any = moment.tz(timeZoneValue).format("MM");
      let zoneYear: any = moment.tz(timeZoneValue).format("YYYY");
      fileName = fileName + zoneMonth + "_" + zoneDate + "_" + zoneYear;
    } else {
      fileName =
        fileName +
        currentDay.getMonth().toString().padStart(2, "0") +
        "_" +
        currentDay.getDate().toString().padStart(2, "0") +
        "_" +
        currentDay.getFullYear();
    }

    return fileName;
  };

  flyoutMenuClick = (e: any, item: any) => {
    if (item === "Add User") {
      let rowObject: any = {};
      rowObject.employeeName = "";

      this.setState({ addUserRow: rowObject }, () => {
        this.setState({ flyOutDropdownView: "addUser" });
      });
    }
    if (item === "Download as Excel") {
      let requestObject = {} as IGetPayrollExcelExportData;
      requestObject.date = this.state.calenderSelectDateString;
      requestObject.Hotelid = this.state.hidValue;
      requestObject.IsPrevious = "false";

      let newContRowData = [...this.state.contRowData];
      let ContratorDataArray: any = [];

      for (let i = 0; i < newContRowData.length; i++) {
        let pushObjectCont: any = {};
        pushObjectCont.Contractor = newContRowData[i]["employee!!0"];
        pushObjectCont.Regular = newContRowData[i][employeeRegPrameter];
        pushObjectCont.position = newContRowData[i][positionParameter];
        pushObjectCont.Payment = newContRowData[i].payment;
        pushObjectCont.Agency = newContRowData[i].agencyVendor;
        pushObjectCont.OT = newContRowData[i]["oT!!105"];
        pushObjectCont.dot = "noDOT";
        if (newContRowData[i]["doT!!106"] !== undefined) {
          pushObjectCont.dot = newContRowData[i]["doT!!106"];
        }
        // pushObjectCont.Checked = 1;
        ContratorDataArray.push(pushObjectCont);
      }

      PayrollExportService.downloadPayrollSheet(
        requestObject,
        this.getPayRollExcelFileName()
      );
      if (newContRowData.length > 0) {
        PayrollExportService.DownloadPayrollSheetContractor(
          ContratorDataArray,
          this.state.fileName
        );
      }
    }

    if (item === "Mark as Unexported") {
      let requestObject = {} as IMarkASUnexport;
      requestObject.date = this.state.navigationCalenderDates[0];
      requestObject.Hotelid = this.state.hidValue;

      PayrollExportService.MarkAsUnexport(requestObject)
        .then(async (result: any | null) => {
          if (result != null && result.saveStatus === "Success") {
            this.getPayrollData("markAsUnexport");
            toast.success("Payroll marked as unexported");
          } else {
            Utils.toastError(result.message);
          }
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error, ${err}`);
          reject();
        });
    }
  };

  handleAddUser = (row: any, item: any) => {
    this.hideAddUser();

    let employeeList: any = [];

    employeeList.push(item.employee);

    let employeeListString = employeeList.join(",");
    let payPeriodDate = this.state.navigationCalenderDates;
    let weekStartDate = payPeriodDate[0];
    let weekEndDate = payPeriodDate[1];

    let requestItem = {} as ILaborSaveScheduleDetails;

    requestItem.weekStartDate = weekStartDate;
    requestItem.weekEndDate = weekEndDate;
    requestItem.scheduleId = 0;
    requestItem.hotelID = this.state.hidValue;
    requestItem.tenantID = localStorageUser.tenantID;
    requestItem.positionID = item.positionid;
    requestItem.date = weekStartDate;
    requestItem.inTime = "0:00";
    requestItem.outTime = "0:00";
    requestItem.notes = "";
    requestItem.entryType = "Schedule";
    requestItem.isAssignedShift = "Yes";
    requestItem.isPublishShift = "Yes";
    requestItem.employeeName = employeeListString;
    requestItem.offRepeat = "No";
    requestItem.ptoType = -1;

    let requestArray: any = [];
    requestArray.push(requestItem);

    PayrollExportService.SaveLaborScheduleFromPayroll(requestArray)
      .then(async (result: any | null) => {
        if (result != null && result.saveStatus === "Success") {
          this.getPayrollData("addUserPayroll");
          toast.success(`${item.employee} added to Payroll for Export`);
        } else {
          Utils.toastError(result.message);
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  calenderChange = (e: any) => {
    let currentdate = e.getDate();
    let currentMonth = e.getMonth() + 1;
    let currentYear = e.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

    userCheckArray = [];
    this.setState(
      {
        calenderSelectDateString: tempDate,
        calenderSelectDate: e,
        viewCalender: false,
      },
      () => {
        this.getPayrollData("calenderChange");
      }
    );
  };

  readyToExportClick = (data: any) => {
    let toPayrollArray = data.split("-");

    let toPayrollStartString = toPayrollArray[0];
    let toPayrollEndString = toPayrollArray[1];

    let day = moment(toPayrollStartString, "MMM-Do-YYYY").format("DD");
    let month = moment(toPayrollStartString, "MMM-Do-YYYY").format("MM");
    let year: any = "";
    if (toPayrollStartString.includes(",")) {
      year = moment(toPayrollStartString, "MMM-Do-YYYY").format("YYYY");
    } else {
      year = moment(toPayrollEndString, "MMM-Do-YYYY").format("YYYY");
    }

    let toPayrollDate = new Date(`${month}/${day}/${year}`);

    let currentdate = toPayrollDate.getDate();
    let currentMonth = toPayrollDate.getMonth() + 1;
    let currentYear = toPayrollDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

    this.setState(
      {
        calenderSelectDateString: tempDate,
        calenderSelectDate: toPayrollDate,
      },
      () => {
        this.getPayrollData("readyToExportClick");
      }
    );
  };

  sortOrderChange = (item: string) => {
    let copiedObject = JSON.parse(JSON.stringify(this.state.columnData));
    let copiedContObject = JSON.parse(
      JSON.stringify(this.state.contColumnData)
    );
    let columnData: any = [...copiedObject];
    let contColumnData: any = [...copiedContObject];

    let changeIndexEmployee: number = -1;
    let changeIndexPosition: number = -1;
    let changeIndexContEmp: number = -1;
    let changeIndexContPosition: number = -1;

    for (let i = 0; i < columnData.length; i++) {
      if (columnData[i].field === "employee!!0") {
        changeIndexEmployee = i;
      }
      if (columnData[i].field === "positioN!!0") {
        changeIndexPosition = i;
      }

      if (changeIndexEmployee > -1 && changeIndexPosition > -1) {
        break;
      }
    }
    for (let i = 0; i < contColumnData.length; i++) {
      if (contColumnData[i].field === "employee!!0") {
        changeIndexContEmp = i;
      }
      if (contColumnData[i].field === "positioN!!0") {
        changeIndexContPosition = i;
      }

      if (changeIndexContEmp > -1 && changeIndexContPosition > -1) {
        break;
      }
    }

    if (changeIndexEmployee > -1 && changeIndexPosition > -1) {
      // if (item === "Employee") {
      if (item === "Name") {
        columnData[changeIndexEmployee].sort = "asc";
        columnData[changeIndexPosition].sort = null;
      } else {
        columnData[changeIndexEmployee].sort = null;
        columnData[changeIndexPosition].sort = "asc";
      }
    }
    if (changeIndexContEmp > -1 && changeIndexContPosition > -1) {
      // if (item === "Employee") {
      if (item === "Name") {
        contColumnData[changeIndexContEmp].sort = "asc";
        contColumnData[changeIndexContPosition].sort = null;
      } else {
        contColumnData[changeIndexContEmp].sort = null;
        contColumnData[changeIndexContPosition].sort = "asc";
      }
    }

    this.setState(
      {
        payrollSortOrder: item,
        columnData: columnData,
        contColumnData: contColumnData,
      },
      () => {
        // this.columnWidthSizeToFit('sortOrder');
      }
    );
  };

  changeUserTableHeight =(item) => {
    const userTable = document.getElementsByClassName(
      "ag-theme-balham"
    ) as HTMLCollectionOf<HTMLElement>;
    if(userTable.length > 0){

      userTable[0].style.maxHeight = `${(densityHeight[item] * this.state.rowCount) + 50}px` 
      
      if(this.state.rowCount < 4){
        
        const userTableScroll = document.getElementsByClassName(
          "ag-body-horizontal-scroll"
          ) as HTMLCollectionOf<HTMLElement>;
          userTableScroll[0].style.top = `${(densityHeight[item] * this.state.rowCount) + 45}px` 
          userTableScroll[0].style.position = `absolute` ;
        }else{
          const userTableScroll = document.getElementsByClassName(
            "ag-body-horizontal-scroll"
            ) as HTMLCollectionOf<HTMLElement>;
          userTableScroll[0].removeAttribute('style')

        }
      }
  }

  changeDensityView = (item: string) => {
    this.gridApi.forEachNode((rowNode: any) => {
      rowNode.setRowHeight(densityHeight[item]);
    });
    this.gridApi.onRowHeightChanged();
   
     this.changeUserTableHeight(item);

    if(this.state.contRowData.length > 0){

      this.contGridApi.forEachNode((rowNode: any) => {
        rowNode.setRowHeight(densityHeight[item]);
      });
      this.contGridApi.onRowHeightChanged();
    }

    this.setState({ densityViewOrder: item }, () => {
      this.saveAsDefaultView("rowDensitySave");
    });
  };

  saveAsDefaultView = (callFrom: string) => {
    let fedilsVaieArray: Array<string> = [];

    for (let i = 0; i < this.state.userDefinedColumnsToggle.length; i++) {
      if (this.state.userDefinedColumnsToggle[i] !== "0") {
        fedilsVaieArray.push(this.state.userDefinedColumnsToggle[i]);
      }
    }

     
    if (fedilsVaieArray.length === 0) {
      Utils.toastError(
        "Minimum one column apart from Employee and S/H is required to be saved as default view"
      );
      return false;
    } else {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);

      // Sort Order Save
      let requestObjectSort = {} as ISaveAsDefautView;
      requestObjectSort.UserUniqueID = user_UniqueID;
      requestObjectSort.fieldName = "SortOredr";
      requestObjectSort.fieldValue =
        callFrom === "settingGearIcon"
          ? this.state.payrollSortOrder
          : originalPayrollSort;
      requestObjectSort.pageName = "PayrollExport";
      requestObjectSort.tenantID = tenantID;

      // Column View Save
      let requestObject = {} as ISaveAsDefautView;
      let feildValues = fedilsVaieArray.join(",");
      requestObject.UserUniqueID = user_UniqueID;
      requestObject.fieldName = "columnView";
      requestObject.fieldValue =
        callFrom === "settingGearIcon"
          ? feildValues
          : originalColumnView.join(",");
      requestObject.pageName = "PayrollExport";
      requestObject.tenantID = tenantID;

      // Row Denstiy Save
      let requestObjectDensity = {} as ISaveAsDefautView;
      requestObjectDensity.UserUniqueID = user_UniqueID;
      requestObjectDensity.fieldName = "PayrollRowDensity";
      requestObjectDensity.fieldValue = this.state.densityViewOrder;
      requestObjectDensity.pageName = "PayrollExport";
      requestObjectDensity.tenantID = tenantID;

      // Making Request Array
      let requestArray: any = [];

      requestArray.push(requestObject);
      requestArray.push(requestObjectSort);
      requestArray.push(requestObjectDensity);

      PayrollExportService.SaveAsDefaultView(requestArray)
        .then(async (result: any | null) => {
          if (result != null && result.saveStatus === "Success") {
            let toastSaveMessage: string = "Default view saved successfully";
            if (callFrom === "rowDensitySave") {
              toastSaveMessage = "Preference saved successfully";
            }
            toast.success(`${toastSaveMessage}`);
          } else {
            Utils.toastError(result.message);
          }
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error, ${err}`);
          reject();
        });
    }
  };

  tableOutsideClick = () => {
    // debugger;
    setTimeout(() => {
      this.columnWidthSizeToFit("outsideClick");
    }, 800);
  };

  hideAddUser = () => {
    this.setState({ addUserRow: {}, flyOutDropdownView: "flyOutMenu" });
  };

  checkExportToggleEligibility = () => {
    let rowData: Array<any> = [...this.state.rowData];

    let noUserCheckMessage: boolean = true;
    let badRegularHoursMessage: boolean = false;
    let isPendingPTORequest: boolean = false;
    let pendingPTOReqCount: number = 0;
    let badRedularHoursArray: Array<string> = [];
    //let isEmployeeZeroHrs : any="No";
   let isEmployeeZeroHrs: boolean = false;

   this.setState({IsEmployeeZeroHrs: isEmployeeZeroHrs})

    for (let i = 0; i < rowData.length; i++) {

     // debugger;
      if (rowData[i].showInfoBanner === "Yes") {
        badRegularHoursMessage = true;
        let employeeNameIdentifier: string = "employee!!0";
        badRedularHoursArray.push(rowData[i][employeeNameIdentifier]);
      }
      if (rowData[i].isChecked) {
      //  debugger;
        noUserCheckMessage = false;
        if (rowData[i]["isPendingPTO"] > 0) {
          isPendingPTORequest = true;
          pendingPTOReqCount = Number(
            pendingPTOReqCount + Number(rowData[i]["isPendingPTO"])
          );
        }
      }

    

if((rowData[i].isEmployeeZeroHrs ==="Yes" || rowData[i].isChecked===false ) && rowData[i]["s/H!!0"]==="S" )
            {
              isEmployeeZeroHrs=true;
              setTimeout(() => {
                this.setState({IsEmployeeZeroHrs: false,viewExportDropdown:false},()=>{
                  this.setState({IsEmployeeZeroHrs: isEmployeeZeroHrs,viewExportDropdown:true})
                });
              }, 10);
           
             
            }
           
    }
     

    if (noUserCheckMessage) {
      Utils.toastError("Please select at least one user to export payroll.");
    } else if (badRegularHoursMessage && badRedularHoursArray.length > 0) {
      const newBadRegularHoursArray: any = new Set(badRedularHoursArray);
      const uniqueBadRegularHoursArray = [...newBadRegularHoursArray];
      let userListString: string = uniqueBadRegularHoursArray.join(", ");
      Utils.toastError(
        `Users: ${userListString} do not have correct regular hours, please correct the hours, payroll can not be exported.`
      );
    } else {
      this.setState({ isPendingPTORequest, pendingPTOReqCount });
       this.exportDropdownToggle(true);
    }
  };

  exportDropdownToggle = (value: boolean) => {
    this.setState({ viewExportDropdown: value});
    if(this.state.IsEmployeeZeroHrs === true){
      this.setState({confirmation : false});
    }
    else{
      this.setState({confirmation : true});
    }
  };

  confirmation = (value: boolean) => {
   // debugger;
    if(value === true)
    {
    // this.setState({ IsEmployeeZeroHrs: null});
      this.setState({confirmation: true,IsEmployeeZeroHrs:false});
    }
    else if(value === false)
    {
      this.setState({ viewExportDropdown: false,IsEmployeeZeroHrs:false});
    }
  };



  RedirectToTimeOff = () => {
    let stateObj: any = {};
    stateObj.hid = this.state.hidValue;
    stateObj.pname = this.state.hotelFullName;
    stateObj.lettercode = this.state.hotelName;
    localStorage.setItem("mainMenu", "Labor");
    localStorage.setItem("subMenu", "Time Off Requests");

    this.setState({ isToffRRedicet: true, redirectState: stateObj });
  };

  checkPayrollExportEligibility = () => {
    let rowData: Array<any> = [...this.state.rowData];
    let alreadyExportedMember: boolean = false;

    for (let i = 0; i < rowData.length; i++) {
      if (rowData[i].isChecked && rowData[i].isPartialExport === "Yes") {
        alreadyExportedMember = true;
        break;
      }
    }

    if (this.state.isPartialExport && alreadyExportedMember) {
      this.alreadyExportedMemberAlertMessage();
    } else if (this.state.isPartialExport) {
      this.partialExportAlertMessage();
    } else {
      this.exportPayroll();
    }
  };

  partialAndAlreadyExportedAlertMessage = () => {
    confirmAlert({
      title: "Partial payroll export, with some users already exported.",
      message:
        "You are about to perform a partial payroll export. You should not expect the users in this partial export to have any additional hours entered for this pay period. This payroll will also need to be manually posted to accounting. PTO accrual for the selected users will also be calculated. Are you sure you want to perform this partial export?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.exportPayroll(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  alreadyExportedMemberAlertMessage = () => {
    confirmAlert({
      title: "Some users already exported.",
      message:
        "Users were included in a previous partial export. Including them in this export will result in paying them twice for any hours that were included in the previous partial export. PTO accrual for the selected users will also be calculated. Are you sure you want to include them in this export?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.exportPayroll(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  partialExportAlertMessage = () => {
    confirmAlert({
      title: "Partial payroll export.",
      message:
        "You are about to perform a partial payroll export. You should not expect the users in this partial export to have any additional hours entered for this pay period. This payroll will also need to be manually posted to accounting. PTO accrual for the selected users will also be calculated. Are you sure you want to perform this partial export?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.exportPayroll(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  calenderDropdownToggle = (value: boolean) => {
    this.setState({ viewCalender: value });
  };

  showSlideOut = () => {
    this.setState({ viewSlideOut: true });
  };

  hideSlideOut = () => {
    this.setState({ slidwOutRowData: {} }, () => {
      this.setState({ viewSlideOut: false }, () => {
        // this.columnWidthSizeToFit('hideSlideout');
      });
    });
  };

  toggleColumnDropdownToggle = (value: boolean) => {
    const { currentColumnSelected, changeInColCount } = this.state;
    let message = `Your column selection may cause horizontal scrolling due to the number selected. 
    To eliminate scrolling, limit the number of columns you select.`;
    const scrollBarNode = $(".ag-body-horizontal-scroll").find(
      ".ag-body-horizontal-scroll-viewport"
    );
    const hasScrollBar = scrollBarNode[0].scrollHeight !== 0;
    // debugger;
    if (!value) {
      if (hasScrollBar && changeInColCount) {
        confirmAlert({
          title: "Note:",
          // message: "Column selection has caused horizontal scrolling.",
          message: message,
          buttons: [
            {
              className: "leave-without-recalc",
              label: "Ok",
              onClick: () => {
                // this.setActiveColumn(index, true)
                // this.setState({ columnDropdownToggle: value });
              },
            },
          ],
          overlayClassName: "horizontalScrollAlert",
        });
      }
    }
    this.setState({ columnDropdownToggle: value }, () => {
      if (value === false) {
        this.setState({ changeInColCount: false });
      }
    });
  };

  tipleDotOutsideClick = () => {
    this.setState({ customToggleMaskDisplay: false });
  };

  tipleDotClick = (e: any) => {
    e.preventDefault(); 
    tripleDotSelection = true;

    if (this.state.rowCount <= 1) {
      let targetClass = e.currentTarget.className;

      if (!targetClass.includes("show")) {
        this.setState({ customToggleMaskDisplay: true });
      } else {
        this.setState({ customToggleMaskDisplay: false });
      }
    }
  };

  addPtoPayout = (e: any, rowValue: any) => {
    e.preventDefault();
    e.stopPropagation();
    tripleDotListSelection = true;

    if (rowValue.isPtoPolicy === "No") {
      Utils.toastError("No PTO policies for this employee.");
    } else {
      this.setState({ payoutModalShow: true, payoutModalRow: rowValue });
    }
  };

  showUserSlideOut = (event: any, rowValue: any) => {
    if (this.state.isNewUserAdded) {
      this.setState({ isNewUserAdded: false });
    }
    let userData = {
      status: rowValue.empStatus,
      uniqueno: Number(rowValue["empCode!!-1"]),
      tenantId: localStorageUser.tenantID,
      username: rowValue.userName,
      imagePath: "",
      user_UniqueID: rowValue.user_UniqueID,
      payrollMissing: rowValue["isPayrollSetting"] === "No",
    };
    this.setState({
      showSlideout: true,
      userRowData: userData,
    });
    // } else {
    //   Utils.toastError(
    //     "You do not have permission to view user details. Please contact your administrator.",
    //     {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     }
    //   );
    // }

    // this.setState({showSlideout:true})
  };

  hidePtoPayoutModal = () => {
    this.setState({ payoutModalShow: false, payoutModalRow: {} });
  };

  closeSlideOut = (shouldReload = false) => {
    // const {userSlideOutDataChange}  = this.state;
    this.setState({ showSlideout: false }, () => {
      if (shouldReload) {
        this.getPayrollData("calenderChange");
      }
    });
  };

  // madeChanges = (state)=>{
  //     this.setState({userSlideOutDataChange:state})
  // }

  savePtoPayoutModal = () => {
    this.hidePtoPayoutModal();
    this.getPayrollData("addPtoPayout");
  };

  threeDotRender = (props: any) => {
    let rowValue = props.data;
    const payrollMissing = rowValue["isPayrollSetting"] === "No";

    if (rowValue.defaultPopup === "1.00" && !this.state.exportBanner) {
      return (
        <OutsideClickHandler onOutsideClick={() => this.tipleDotOutsideClick()}>
          <Dropdown
            className="more-action"
            alignRight
            onClick={(e: any) => this.tipleDotClick(e)}
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(e: any) => this.addPtoPayout(e, rowValue)}
              >
                PTO Payout
              </Dropdown.Item>
              {/* user edit profile visible to all user if has proper permission */}
              {rowValue.hasEditPermission && (
                <Dropdown.Item
                  onClick={(e: any) => this.showUserSlideOut(e, rowValue)}
                >
                  View User Profile
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      );
    } else {
      return <div></div>;
    }
  };

  tickMarkRender = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
          fill="#2CAF92"
        />
      </svg>
    );
  };

  render() {
    const renderTooltip = (props) => {
      return (
        <Tooltip id="tooltip-bottom" {...props}>
          Following users are missing payroll information, payroll cannot be
          exported until these users have complete payroll information:
          {this.state.filteredUserList.length > 0 &&
            this.state.filteredUserList.map((item: any, index: any) => {
              return <li key={item.user_UniqueID}>{item.userName}</li>;
            })}
        </Tooltip>
      );
    };

    if (this.state.isToffRRedicet) {
      return (
        <Redirect
          to={{
            pathname: "/labor-management/timeoffrequest",
            state: this.state.redirectState,
          }}
        />
      );
    }

    const renderhideTooltip = (props) => {
      return <div id="tooltip-bottom" {...props}></div>;
    };
    const payrollExportTooltip = (props) => {
      return (
        <Tooltip id={"export-payroll-btn"} {...props}>
          {"You are unable to export payroll until the pay period has ended."}
        </Tooltip>
      )
    }
    return (
      <>
        {this.state.viewSlideOut && (
          <PayrollExportSlideOut
            parentState={this.state}
            hideSlideOut={this.hideSlideOut}
            slideOutDataSave={this.slideOutDataSave}
          />
        )}
        <div
          className={`payroll-export-main ${
            this.state.navMenuOpen ? "nav-opened" : "nav-closed"
          }`}
        >
          <div
            className={
              this.state.contHeaderStatic ? "headerMask2" : "headerMask2 hide"
            }
          ></div>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              {/* hide hid selector when page scrolled down */}
              {!this.state.navMenuStatic && (
                <HidSelector
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                  extraSelector={
                    !this.state.navMenuStatic && this.state.changeInNavMenu
                  }
                />
              )}
              <div className="mr-auto">Payroll</div>

              {this.state.hidValue !== "" && !this.state.loaderTrue && (
                <div className="action-group d-flex flex-row">
                  {this.state.permissionExportPayroll && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        !this.state.exportBanner && this.state.isPayrollSetting
                          ? renderTooltip
                          : renderhideTooltip
                      }
                    >
                      <span className="d-inline-block">
                        <OverlayTrigger 
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            (this.state.isPartialExport === true && this.state.IsPayrollIntegration === true) ?
                            payrollExportTooltip
                            : renderhideTooltip
                          }
                        >
                          <Button
                            className={
                              this.state.viewExportDropdown
                                ? "export-button-show"
                                : this.state.exportButtonSpinner
                                ? "export-button p-none"
                                : "export-button"
                            }
                            style={{
                              pointerEvents: this.state.isPayrollSetting
                                ? "none"
                                : "auto",
                            }}
                            disabled={
                              this.state.exportBanner ||
                              this.state.isPayrollSetting ||
                              (this.state.isPartialExport === true && this.state.IsPayrollIntegration === true)
                            }
                            onClick={() => this.checkExportToggleEligibility()}
                          >
                            {this.state.exportButtonSpinner && (
                              <Spinner
                                className="mr-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            Export Payroll
                          </Button>
                        </OverlayTrigger>
                        
                      </span>
                    </OverlayTrigger>
                  )}

                  <div className="confirm-export-payroll">
                    
                    {this.state.viewExportDropdown && this.state.confirmation === true && this.state.IsEmployeeZeroHrs === false &&
                      !this.state.isPendingPTORequest && (
                        <OutsideClickHandler
                          onOutsideClick={() =>
                            this.exportDropdownToggle(false)
                          }
                        >
                          <div className="exportDropdown">
                            <div className="export-body">
                              <div className="export-heading">
                                Are you sure you’re ready to export ?
                              </div>
                              <div className="export-txt">
                                Payroll will be exported and emailed to users
                                who are signed up to receive the export.
                                {this.state.isPartialExport ? "  Contractors will not be exported.":""}
                              </div>
                            </div>
                            <div className="export-footer action-link">
                              <button
                                className="export-cancel btn wht-bg"
                                onClick={() => this.exportDropdownToggle(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="export-schedule btn btn-primary"
                                onClick={() =>
                                  this.checkPayrollExportEligibility()
                                }
                              >
                                Export Payroll
                              </button>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                  </div>

                    {this.state.viewExportDropdown && this.state.IsEmployeeZeroHrs === true &&
                        (
                          <OutsideClickHandler
                          onOutsideClick={() =>
                            this.confirmation(false)
                          }
                        >
                          <div className="confirm-export-payroll" id="payroll-export-dropdown">
                          <div className="exportDropdown">
                            <div className="export-body">
                             
                              <div className="export-txt">
                              Unselected users or users with no hours will not be in the payroll export, possibly causing errors. Would you like to continue?
                              </div>
                            </div>
                            <div className="export-footer action-link">
                              <button
                                className="export-cancel btn wht-bg"
                                onClick={() => this.confirmation(false)}
                              >
                                No
                              </button>
                              <button
                                className="export-schedule btn btn-danger"
                                onClick={() =>
                                  this.confirmation(true)
                                }
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                          </div>
                        </OutsideClickHandler>
                      )}

                  <div className="confirm-export-payroll second-variance">
                    {this.state.viewExportDropdown &&
                      this.state.isPendingPTORequest && this.state.confirmation === true && this.state.IsEmployeeZeroHrs === false && (
                        <OutsideClickHandler
                          onOutsideClick={() =>
                            this.exportDropdownToggle(false)
                          }
                        >
                          <div className="exportDropdown">
                            <div className="export-body">
                              <div className="export-heading">
                                Pending PTO Request Alert
                              </div>
                              <div className="export-txt">
                                There{" "}
                                {this.state.pendingPTOReqCount === 1
                                  ? "is"
                                  : "are"}{" "}
                                {this.state.pendingPTOReqCount} open PTO
                                {this.state.pendingPTOReqCount === 1
                                  ? " Request"
                                  : " Requests"}{" "}
                                 in this pay period. Are you sure you
                                would like to proceed?
                              </div>
                            </div>
                            <div className="export-footer action-link d-flex align-items-center">
                              {/* <button
                              className="export-cancel btn wht-bg"
                              onClick={() => this.RedirectToTimeOff()}
                            >
                              Link To PTO Request
                            </button> */}

                              <div
                                className="link-pto-request"
                                onClick={() => this.RedirectToTimeOff()}
                              >
                                View PTO Request
                              </div>

                              <button
                                className="export-cancel btn wht-bg ml-auto"
                                onClick={() => this.exportDropdownToggle(false)}
                              >
                                Cancel
                              </button>

                              <button
                                className="export-schedule btn btn-primary"
                                onClick={() =>
                                  this.checkPayrollExportEligibility()
                                }
                              >
                                Yes, Proceed
                              </button>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                  </div>

                  {this.state.flyOutDropdownView === "flyOutMenu" && (
                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary more"
                        id="dropdown-more"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {this.state.flyoutMenuItems.map(
                          (item: any, idx: any) => {
                            if (item === "Add User") {
                              if (this.state.permissionManagePayroll) {
                                return (
                                  <Dropdown.Item
                                    key={idx}
                                    eventKey={item}
                                    disabled={this.state.exportBanner}
                                    onClick={(e: any) =>
                                      this.flyoutMenuClick(e, item)
                                    }
                                  >
                                    {item}
                                  </Dropdown.Item>
                                );
                              } else {
                                return false;
                              }
                            } else if (item === "Mark as Unexported") {
                              // condition for future and confiton for less than 10 days
                              let isFuture: boolean = false;
                              let isOutsideTenDays: boolean = false;

                              isFuture = moment().isBefore(
                                this.state.navigationCalenderDates[1]
                              );
                              if (!isFuture) {
                                let currentDate = moment();
                                let payPeriodEndDate = moment(
                                  this.state.navigationCalenderDates[1]
                                );
                                let test = currentDate.diff(
                                  payPeriodEndDate,
                                  "days"
                                );
                                if (test > 10) {
                                  isOutsideTenDays = true;
                                }
                              }
                              if (
                                this.state.permissionExportPayroll &&
                                !isFuture &&
                                !isOutsideTenDays &&
                                this.state.exportBanner
                              ) {
                                return (
                                  <Dropdown.Item
                                    key={idx}
                                    eventKey={item}
                                    onClick={(e: any) =>
                                      this.flyoutMenuClick(e, item)
                                    }
                                  >
                                    {item}
                                  </Dropdown.Item>
                                );
                              } else {
                                return false;
                              }
                            } else {
                              return (
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item}
                                  onClick={(e: any) =>
                                    this.flyoutMenuClick(e, item)
                                  }
                                >
                                  {item}
                                </Dropdown.Item>
                              );
                            }
                          }
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  {this.state.flyOutDropdownView === "addUser" && (
                    <OutsideClickHandler onOutsideClick={this.hideAddUser}>
                      <Dropdown
                        className="single-select-image more-action"
                        alignRight
                        show
                      >
                        <Dropdown.Toggle
                          className="btn-outline-primary btn btn-primary more"
                          id="dropdown-more"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="addUserList">
                            <ReassignAllModal
                              row={this.state.addUserRow}
                              parentState={this.state}
                              hidValue={this.state.hidValue}
                              requestType="ExportPayroll"
                              handleSelectedItem={this.handleAddUser.bind(
                                this,
                                this.state.addUserRow
                              )}
                            />
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </OutsideClickHandler>
                  )}
                </div>
              )}
            </div>
            {this.state.hidValue !== "" && (
              <>
                {this.state.loaderTrue ? (
                  <ReactPageLoader useas={"payrollExportLoader"} />
                ) : (
                  <>
                    <div
                      className={
                        this.state.navMenuStatic
                          ? "navigation-menus d-flex static-payroll-nav"
                          : "navigation-menus d-flex"
                      }
                    >
                      {this.state.navMenuStatic && (
                        <HidSelector
                          onClickEvent={this.selectHotelDropDown}
                          ref={this.childHID}
                          extraSelector={true}
                        />
                      )}
                      <div className="payroll-table-search">
                        <input
                          ref={this.searchInput}
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onInput={() => this.payrollExportSearch()}
                        />
                      </div>

                      <div className="calender-container">
                        <div
                          className={
                            this.state.viewCalender
                              ? "calender-display open"
                              : "calender-display"
                          }
                          onClick={() => this.calenderDropdownToggle(true)}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>

                          <span className="prev-Date">
                            {this.state.navigationCalenderDates[0]}
                          </span>
                          <span className="seperator">{" - "}</span>
                          <span className="next-Date">
                            {this.state.navigationCalenderDates[1]}
                          </span>
                          <span className="chevron-down">
                            <FiChevronDown />
                          </span>
                        </div>
                        {this.state.viewCalender && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={() =>
                                this.calenderDropdownToggle(false)
                              }
                            >
                              <Calendar
                                calendarType={"US"}
                                onChange={this.calenderChange}
                                value={this.state.calenderSelectDate}
                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>

                      <div className="navigationSettings d-flex ml-auto">
                        <div className="payroll-sort">
                          <Dropdown className="more-action bg-blue" alignRight>
                            <Dropdown.Toggle className="" id="dropdown-more">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                                </svg>

                                {this.state.payrollSortOrder}

                                <div className="drop-arrow d-flex">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Header>SORT BY</Dropdown.Header>
                              {this.state.sortOrderList.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    key={idx}
                                    className={
                                      this.state.payrollSortOrder === item
                                        ? "active"
                                        : ""
                                    }
                                    eventKey={item}
                                    onClick={() => this.sortOrderChange(item)}
                                  >
                                    <div className="d-flex">
                                      <div className="mr-auto"> {item}</div>
                                      {this.tickMarkRender()}
                                    </div>
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <Dropdown className="more-action bg-blue" alignRight>
                          <Dropdown.Toggle className="" id="dropdown-more">
                            <div className="d-flex align-items-center">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                              </svg>
                              <div className="drop-arrow ml-0 d-flex">
                                <FiChevronDown />
                              </div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="action-settings">
                              <Dropdown.Header>
                                DENSITY VIEW CONTROL
                              </Dropdown.Header>
                              {densityView.map((item, idk) => {
                                return (
                                  <Dropdown.Item
                                    key={idk}
                                    className={
                                      this.state.densityViewOrder === item
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => this.changeDensityView(item)}
                                  >
                                    <div className="d-flex">
                                      <div className="mr-auto">{item}</div>

                                      {this.state.densityViewOrder === item &&
                                        this.tickMarkRender()}
                                    </div>
                                  </Dropdown.Item>
                                );
                              })}
                            </div>
                            <div className="default-view-settings">
                              <Dropdown.Item
                                onClick={() =>
                                  this.saveAsDefaultView("settingGearIcon")
                                }
                              >
                                Save as Default View
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    {this.state.exportBanner && (
                      <div className="notification-banners green-bg">
                        <div className="mr-auto">
                          This payroll was exported on{" "}
                          <span className="bold">{this.state.exportDate}.</span>
                        </div>
                      </div>
                    )}
                    {!this.state.exportBanner && this.state.isPayrollSetting && (
                      <div className="notification-banners red-bg">
                        <div className="mr-auto">
                          <span className="bold">
                            {this.state.filteredUserList.length}{" "}
                          </span>
                          {this.state.filteredUserList.length > 1
                            ? "Users are"
                            : "User is"}{" "}
                          missing payroll information, payroll cannot be
                          exported.
                        </div>
                      </div>
                    )}

                    <div className="payroll-highlights d-flex">
                      <div className="payroll-data box flex-fill">
                        <div className="payroll-full-date">
                          {this.state.payrollHeadingDates}
                        </div>

                        <div className="payroll-details d-flex">
                          {this.state.payrollHeadingdata.map(
                            (data: any, idx: any) => (
                              <Fragment key={idx}>
                                <div
                                  className={`payroll-details-subdivison ${data.name}`}
                                >
                                  <div className="subdivison-heading">
                                    {data.name}
                                  </div>
                                  <div className="subdivison-value">
                                    {data.value}
                                  </div>
                                  <div className="subdivison-unit">
                                    {data.unit}
                                  </div>
                                </div>
                              </Fragment>
                            )
                          )}
                        </div>
                      </div>

                      <div className="ready-export-data box d-flex">
                        <div className="ready-export-title">
                          Ready for Export
                        </div>
                        <div className="ready-export-details d-flex">
                          <div className="export-icon">
                            <svg
                              width="64"
                              height="64"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="32" cy="32" r="32" fill="#DEFFF8" />
                              <path
                                d="M35.8576 37.7094C35.6146 38.3556 34.8936 38.6825 34.2476 38.4396C33.2513 38.0649 32.2006 37.875 31.125 37.875H29.25C24.3563 37.875 20.375 41.8563 20.375 46.75C20.375 47.4404 19.8154 48 19.125 48C18.4346 48 17.875 47.4404 17.875 46.75C17.875 40.4778 22.9778 35.375 29.25 35.375H31.125C32.5021 35.375 33.8487 35.6187 35.1274 36.0994C35.7736 36.3424 36.1004 37.0632 35.8576 37.7094ZM38.5 24.4375C38.5 29.0899 34.7149 32.875 30.0625 32.875C25.4101 32.875 21.625 29.0899 21.625 24.4375C21.625 19.7851 25.4101 16 30.0625 16C34.7149 16 38.5 19.7851 38.5 24.4375ZM36 24.4375C36 21.1636 33.3364 18.5 30.0625 18.5C26.7886 18.5 24.125 21.1636 24.125 24.4375C24.125 27.7114 26.7886 30.375 30.0625 30.375C33.3364 30.375 36 27.7114 36 24.4375ZM46.125 42.6458C46.125 40.6929 44.3883 39.1041 42.2535 39.1041C41.3461 39.1042 40.6875 38.6661 40.6875 38.0625C40.6875 37.4881 41.1501 37.0208 41.7188 37.0208C42.2921 37.0208 43.0388 37.0833 44.2309 37.5469C44.8743 37.7971 45.5988 37.4784 45.849 36.835C46.0993 36.1916 45.7805 35.4671 45.1371 35.2169C44.5165 34.9756 43.9754 34.8174 43.5 34.7132V33.75C43.5 33.0596 42.9404 32.5 42.25 32.5C41.5596 32.5 41 33.0596 41 33.75V34.5947C39.3964 34.9287 38.1875 36.3566 38.1875 38.0625C38.1875 40.0816 39.9355 41.6042 42.2535 41.6042C42.997 41.6042 43.625 42.0812 43.625 42.6459C43.625 43.2008 43.109 43.6876 42.5208 43.6876C42.0619 43.6876 40.9104 43.5193 39.7743 43.0676C39.1328 42.8127 38.4059 43.1259 38.1509 43.7674C37.8959 44.409 38.2092 45.1358 38.8507 45.3908C39.5746 45.6786 40.3298 45.8839 41 46.0139V46.75C41 47.4404 41.5596 48 42.25 48C42.9404 48 43.5 47.4404 43.5 46.75V46.0519C44.0716 45.8911 44.5998 45.5906 45.0399 45.1663C45.7396 44.4921 46.125 43.5969 46.125 42.6458Z"
                                fill="#0E9074"
                              />
                            </svg>
                          </div>
                          <div className="ready-export-dates d-flex">
                            {this.state.exportHeadingData.length > 0 ? (
                              this.state.exportHeadingData.map(
                                (data: any, idx: any) => (
                                  <span
                                    className="detail"
                                    key={idx}
                                    onClick={() =>
                                      this.readyToExportClick(data)
                                    }
                                  >
                                    {data}
                                  </span>
                                )
                              )
                            ) : (
                              <span className="detail noPointer">
                                All Payrolls are Exported
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="payrollExportTable">
                      <div
                        className={
                          this.state.tableHeaderStatic &&
                          !this.state.contHeaderStatic
                            ? "customToggleDropdown static-toggle"
                            : this.state.rowCount > 1
                            ? "customToggleDropdown more-than-1"
                            : "customToggleDropdown less-than-2"
                        }
                      >
                        {/* <div
                          className={
                            this.state.customToggleMaskDisplay
                              ? "mask"
                              : "mask hide"
                          }
                        ></div> */}
                        <div
                          className={
                            this.state.tableHeaderStatic
                              ? "headerMask"
                              : "headerMask hide"
                          }
                        ></div>
                        <div
                          className={
                            this.state.columnDropdownToggle
                              ? "ddnicon d-flex show"
                              : "ddnicon d-flex"
                          }
                          onClick={() => this.toggleColumnDropdownToggle(true)}
                        >
                          <span className="dropdown-icon">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                            </svg>
                          </span>
                          <span>
                            <FiChevronDown />
                          </span>
                        </div>
                        {this.state.columnDropdownToggle && (
                          <OutsideClickHandler
                            onOutsideClick={() =>
                              this.toggleColumnDropdownToggle(false)
                            }
                          >
                            <div className="toggle-drowpdown-wrapper">
                              <div className="dropdown-header d-flex">
                                <span className="header-name">Columns</span>
                                <span
                                  className="close-icon"
                                  onClick={() =>
                                    this.toggleColumnDropdownToggle(false)
                                  }
                                >
                                  &times;
                                </span>
                              </div>
                              <div className="dropdown-body">
                                {/* <div className="selected-option-info">
                                  {this.state.currentColumnSelected} out of{" "}
                                  {this.state.maxColumnSelected} Selected
                                </div> */}

                                <div className="show-hide-column">
                                  {this.state.toggleColumnData.map(
                                    (data: any, idx: any) => (
                                      <div
                                        className="dropdown-col-items d-flex form-check"
                                        key={idx}
                                      >
                                        <input
                                          className="form-check-input"
                                          key={idx}
                                          type="checkbox"
                                          checked={data.checked}
                                          disabled={data.disabled}
                                          onChange={(e: any) =>
                                            this.toggleColumnCheck(e, data, idx)
                                          }
                                        />

                                        <label
                                          className={
                                            data.disabled
                                              ? "form-check-label disabled"
                                              : data.checked
                                              ? "form-check-label active"
                                              : "form-check-label"
                                          }
                                        >
                                          {data.colDispalyName}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </OutsideClickHandler>
                        )}
                      </div>
                      <OutsideClickHandler
                        onOutsideClick={() => this.tableOutsideClick()}
                      >
                        <div
                          className={
                            this.state.tableHeaderStatic
                              ? `ag-theme-balham ${
                                  this.state.horizontalScrollFixed
                                    ? ""
                                    : "scroll-relative"
                                } static-header`
                              : this.state.rowCount > 1
                              ? `ag-theme-balham ${
                                  this.state.horizontalScrollFixed
                                    ? ""
                                    : "scroll-relative"
                                } more-than-1`
                              : `ag-theme-balham ${
                                  this.state.horizontalScrollFixed
                                    ? ""
                                    : "scroll-relative"
                                } less-than-2`
                              }
                          // style={{
                          //   height: this.state.exportBanner
                          //     ? "100vh"
                          //     : "100vh",
                          // }}
                        >
                          <AgGridReact
                            onGridReady={this.onGridReady}
                            columnDefs={this.state.columnData}
                            suppressDragLeaveHidesColumns={true}
                            suppressScrollOnNewData={true}
                            onDragStopped={this.columnDragStop}
                            rowData={this.state.rowData}
                            rowHeight={
                              densityHeight[this.state.densityViewOrder]
                            }
                            onRowClicked={this.rowClicked}
                            context={this.state.context}
                            frameworkComponents={this.state.frameworkComponents}
                            domLayout={"autoHeight"}
                          />
                          <div
                            className="bottomMask"
                            style={{
                              position: "fixed",
                              bottom: "0px",
                              background: "white",
                              height: "24px",
                              width: "100%",
                            }}
                          ></div>
                        </div>
                      </OutsideClickHandler>
                      {this.state.contRowData.length > 0 && (

                      <div
                        className={
                          this.state.contHeaderStatic
                            ? `ag-theme-balham  ${
                                this.state.horizontalScrollFixed
                                  ? ""
                                  : "scroll-relative cont"
                              }  static-header-contract`
                            : this.state.contTableRowCount > 1
                            ? `ag-theme-balham  ${
                                this.state.horizontalScrollFixed
                                  ? ""
                                  : "scroll-relative cont"
                              }  more-than-1-cont`
                            : `ag-theme-balham ${
                                this.state.horizontalScrollFixed
                                  ? ""
                                  : "scroll-relative cont"
                              }  less-than-2-cont`
                        }
                        // style={{
                        //   height: this.state.exportBanner
                        //     ? "100vh"
                        //     : "100vh",
                        // }}
                      >
                        {/* <div
                            className={
                              this.state.contHeaderStatic
                                ? "headerMask"
                                : "headerMask hide"
                            }
                          ></div> */}
    <>
                        <AgGridReact
                          onGridReady={this.onContGridReady}
                          columnDefs={this.state.contColumnData}
                          // suppressDragLeaveHidesColumns={true}
                          // suppressScrollOnNewData={true}
                          // onDragStopped={this.columnDragStop}
                          rowData={this.state.contRowData}
                          rowHeight={densityHeight[this.state.densityViewOrder]}
                          // onRowClicked={this.rowClicked}
                          // context={this.state.context}
                          frameworkComponents={
                            this.state.frameworkComponentsContractor
                          }
                          domLayout={"autoHeight"}
                        />
                        
                        <div
                          className="bottomMask"
                          style={{
                            position: "fixed",
                            bottom: "0px",
                            background: "white",
                            height: "24px",
                            width: "100%",
                          }}
                          ></div>
                          </>
                      </div>
                          )}
                    </div>
                  </>
                )}
              </>
            )}
            {this.state.payoutModalShow && (
              <PtoPayoutModal
                parentState={this.state}
                hidePtoPayoutModal={this.hidePtoPayoutModal}
                savePtoPayoutModal={this.savePtoPayoutModal}
                usedIn="payrollExport"
              />
            )}
            {this.state.showSlideout &&
              this.state.userRowData &&
              this.state.statusList?.length > 0 && (
                <UserDetailSlideOut
                  isChangeEHIDAccess={this.state.isChangeEHIDAccess}
                  isChangeUserRole={this.state.isChangeUserRole}
                  isEditUserInfo={this.state.isChangeUserRole}
                  isPageCreateNewUser={this.state.isPageCreateNewUser}
                  userData={this.state.userRowData}
                  statusList={this.state.childStatusList}
                  isNewUserAdded={this.state?.isNewUserAdded}
                  closeSlideOut={this.closeSlideOut}
                  selectedTab={
                    this.state.userRowData.payrollMissing
                      ? "payroll"
                      : "general"
                  }
                  payrollIntegrationHids={this.state.payrollIntegrationHids}
                ></UserDetailSlideOut>
              )}
          </Container>
        </div>
      </>
    );
  }
}
