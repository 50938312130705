import * as React from "react";
import { User } from "../../Common/Services/User";

export class RestoreSession extends React.Component<any, any> {


  componentDidMount() {
    debugger
    this.isTokenActive();
  }

  getParamValueByQuerySting(paramName: string): any {
    //// to get querystring parameters value by parameter Name
    let pramValue = new URLSearchParams(window.location.search).get(paramName)
    if (new URLSearchParams(window.location.search).get("Report") == "73")
      return pramValue === null ? "" : pramValue;
    else
      return window.atob(pramValue === null ? "" : pramValue);
  }

  isTokenActive = () => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      const backPage = this.getParamValueByQuerySting("backPage");
      if (backPage?.toLowerCase() === "reportdrilldown" || backPage?.toLowerCase() === "reportdrilldownmulticoa") {
        const tokenID = this.getParamValueByQuerySting("tokenID");
        const UniqueNo = this.getParamValueByQuerySting("UniqueNo");
        const SplitHID = this.getParamValueByQuerySting("SplitHID");
        const OprID = this.getParamValueByQuerySting("OprID");
        const Ctype = this.getParamValueByQuerySting("Ctype");
        const backPage = this.getParamValueByQuerySting("backPage");
        const StartDate = this.getParamValueByQuerySting("StartDate");
        const EndDate = this.getParamValueByQuerySting("EndDate");
        const ReportType = this.getParamValueByQuerySting("ReportType");
        const reportId = this.getParamValueByQuerySting("reportId");
        const SelectPL = this.getParamValueByQuerySting("SelectPL");
        const COA = this.getParamValueByQuerySting("COA");
        const Type = this.getParamValueByQuerySting("Type");
        const AccountType = this.getParamValueByQuerySting("AccountType");
        const GroupID = this.getParamValueByQuerySting("GroupID");
        const SubGroupID = this.getParamValueByQuerySting("SubGroupID");
        const SubGroupID2 = this.getParamValueByQuerySting("SubGroupID2");
        const RowType = this.getParamValueByQuerySting("RowType");
        const IsTotal = this.getParamValueByQuerySting("IsTotal");
        const IsCOS = this.getParamValueByQuerySting("IsCOS");
        const IsMOD = this.getParamValueByQuerySting("IsMOD");
        const IsFB = this.getParamValueByQuerySting("IsFB");
        const LineGroup = this.getParamValueByQuerySting("LineGroup");
        const code = this.getParamValueByQuerySting("code");

        if ((tokenID != null || Number(tokenID) > 0) && reportId != "73") {
          User.restoreTokenDetails(tokenID, code).then((response: any) => {
            this.props.history.push('/Report/' + backPage, {
              tokenID: tokenID,
              UniqueNo: UniqueNo,
              SplitHID: SplitHID,
              OprID: OprID,
              Ctype: Ctype,
              StartDate: StartDate,
              EndDate: EndDate,
              ReportType: ReportType,
              reportId: reportId,
              SelectPL: SelectPL,
              COA: COA,
              Type: Type,
              AccountType: AccountType,
              GroupID: GroupID,
              SubGroupID: SubGroupID,
              SubGroupID2: SubGroupID2,
              RowType: RowType,
              IsTotal: IsTotal,
              IsCOS: IsCOS,
              IsMOD: IsMOD,
              IsFB: IsFB,
              LineGroup: LineGroup
            })
            // if (backPage.toLowerCase() === "ReportDrilldown".toLowerCase()) {
            //   window.location.href = window.location.origin + "/Report/ReportDrilldown" + window.location.search;
            // }
            // else {
            //   window.location.href = window.location.origin + "/Report/ReportDrilldownmultiCOA" + window.location.search;
            // }
          });
        } else {
          const param = window.location.search;
          if (backPage.toLowerCase() === "ReportDrilldown".toLowerCase()) {
            this.props.history.push('/Report/ReportDrilldown', { UniqueNo: UniqueNo, SplitHID: SplitHID, OprID: OprID, StartDate: StartDate, EndDate: EndDate,
              backPage:backPage,ReportType:ReportType,reportId:reportId,SelectPL:SelectPL,COA:COA,Type:Type,AccountType:AccountType,GroupID:GroupID,SubGroupID:SubGroupID,SubGroupID2:SubGroupID2,RowType:RowType,IsTotal:IsTotal,IsCOS:IsCOS,IsMOD:IsMOD,IsFB:IsFB,LineGroup:LineGroup,code:code  });
            //window.location.href = window.location.origin + "/Report/ReportDrilldown" + window.location.search;
          }
          else {
            this.props.history.push('/Report/ReportDrilldownmultiCOA', { UniqueNo: UniqueNo, SplitHID: SplitHID, OprID: OprID, StartDate: StartDate, EndDate: EndDate,
              Ctype: Ctype, backPage:backPage,ReportType:ReportType,reportId:reportId,SelectPL:SelectPL,COA:COA,Type:Type,AccountType:AccountType,GroupID:GroupID,SubGroupID:SubGroupID,SubGroupID2:SubGroupID2,RowType:RowType,IsTotal:IsTotal,IsCOS:IsCOS,IsMOD:IsMOD,IsFB:IsFB,LineGroup:LineGroup,code:code });
            //window.location.href = window.location.origin + "/Report/ReportDrilldownmultiCOA" + window.location.search;
          }
        }
      } else {
        const uniqueNo = valuesArray ? window.atob(valuesArray[0]?.split('=')[1] as any) : "";
        const tokenID = valuesArray ? window.atob(valuesArray[1]?.split('=')[1] as any) : "";
        const etype = valuesArray ? window.atob(valuesArray[2]?.split('=')[1] as any) : "";
        const backHid = valuesArray ? window.atob(valuesArray[3]?.split('=')[1] as any) : "";
        const oprID = valuesArray ? window.atob(valuesArray[4]?.split('=')[1] as any) : "";
        const backPage = valuesArray ? window.atob(valuesArray[5]?.split('=')[1] as any) : "";
        const code = valuesArray ? valuesArray[6]?.split('=')[1] as any : "";
        if (tokenID != null || Number(tokenID) > 0) {
          User.restoreTokenDetails(tokenID, code).then((response: any) => {
            if (etype.toLowerCase() === "journal") {
              window.location.href = window.location.origin + "/Journal?Uniqueno=" + btoa(uniqueNo) + "&tokenID=" + btoa(tokenID) + "&Etype=" + btoa(etype) + "&HID=" + btoa(backHid)
                + "&OprID=" + btoa(oprID) + "&BackPage=" + btoa(backPage);
            }
            else {
              window.location.href = window.location.origin + "/Register?Uniqueno=" + btoa(uniqueNo) + "&tokenID=" + btoa(tokenID) + "&Etype=" + btoa(etype) + "&HID=" + btoa(backHid)
                + "&OprID=" + btoa(oprID) + "&BackPage=" + btoa(backPage);
            }

          });
        }
      }
    }

  }

  getParamValueByParamName = (paramName: string) => {
    let valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
    window.history.replaceState(null, '');
    return valuesArray;

  }

  render() {
    return (
      <div>

      </div>
    )
  }
}