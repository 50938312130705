import Instense from "./Axios-config";
import { IAcutalVsBugetedRequest,  IAcutalVsBugetedRespone } from "../Contracts/ILaborDashBoard";

import axios from "axios";
import { IWidgetRequest, IWidgetResponse } from "../Contracts/IWidgetRequestResponse";
import { IDashboardWidget, IDataAggrigation, IUserDashboardRequest, IUserDashboardWidgetOrderRequest } from "../Contracts/IDashboardWidget";
export class LaborDashBoard {
  private static  ajaxRequestCard: any = null; 
  private static  ajaxRequestChart: any = null; 
  private static  ajaxRequestAdrCardWidget: any = null; 
  private static  ajaxRequestAdrChartWidget: any = null; 
  private static  ajaxRequestRevPARChartWidget: any = null; 
  private static  ajaxRequestGetDashboard: any = null; 
  private static  ajaxRequestDataAggeragationAccountingDashboardSinglehoteldata:any =null;
  private static  ajaxRequestDataAggeragationLaborSinglehoteldata:any = null;
  private static  ajaxRequestGetLaborDashboardData: any = null; 
  private static  ajaxRequestGetLaborDashboardDataChart: any = null; 
  private static  ajaxRequestGetHouseKeepingDashboardUserDataChart: any = null; 
  private static  ajaxRequestGetHouseKeepingDashboardData: any = null; 
  private static  ajaxRequestGetHouseKeepingDashboardDataChart: any = null; 
  private static  ajaxRequestGetHouseKeepingUsersDashboardData: any = null; 
  private static  ajaxRequestGetHouseKeepingUsersDashboardDataChart: any = null; 
  private static  ajaxRequestOccupancyCardWidget: any = null; 
  private static  ajaxRequestOccupancyChartWidget: any = null; 
  private static  ajaxRequestDailyFlashTrends: any = null; 
  private static  ajaxRequestLaborDayApprovalDetail: any = null; 

  public static getActualVsBudgetVariance = async (
    request: IAcutalVsBugetedRequest
  ): Promise<IAcutalVsBugetedRespone[] | null> => {
    if (LaborDashBoard.ajaxRequestCard ) {
      LaborDashBoard.ajaxRequestCard.cancel("Cancel"); 
  }
  LaborDashBoard.ajaxRequestCard = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    request.userUniqueID = 0;
    const url = `/Dashboard/GetActualVsBudgetVariance`;
    const response = (await Instense.post(url,request,{
      cancelToken: LaborDashBoard.ajaxRequestCard.token
     }) as any)?.data?.result?.result as IAcutalVsBugetedRespone[] | null
    return response;
  };

  public static getActualVsBudgetVarianceChart = async (
    request: IAcutalVsBugetedRequest
  ): Promise<IAcutalVsBugetedRespone[] | null> => {
    if (LaborDashBoard.ajaxRequestChart ) {
      LaborDashBoard.ajaxRequestChart.cancel("Cancel"); 
  }
  LaborDashBoard.ajaxRequestChart = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    request.userUniqueID = 0;
    const url = `/Dashboard/GetActualVsBudgetVariance`;
    const response = (await Instense.post(url,request,{
      cancelToken: LaborDashBoard.ajaxRequestChart.token
     }) as any)?.data?.result?.result as IAcutalVsBugetedRespone[] | null
    return response;
  };

  public static GetWidgetAdrCardData = async (
    request: IWidgetRequest
  ): Promise<IWidgetResponse[] | null> => {
    if (LaborDashBoard.ajaxRequestAdrCardWidget ) {
      LaborDashBoard.ajaxRequestAdrCardWidget.cancel("Cancel"); 
   }
  LaborDashBoard.ajaxRequestAdrCardWidget = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/Dashboard/GetWidgetData`;
    const response = (await Instense.post(url,request,{
      cancelToken: LaborDashBoard.ajaxRequestAdrCardWidget.token
     }) as any)?.data?.result?.result as IWidgetResponse[] | null
    return response;
  };


  public static GetWidgetRevPARChartData = async (
    request: IWidgetRequest
  ): Promise<IWidgetResponse[] | null> => {
    if (LaborDashBoard.ajaxRequestRevPARChartWidget ) {
       LaborDashBoard.ajaxRequestRevPARChartWidget.cancel("Cancel"); 
   }
  LaborDashBoard.ajaxRequestRevPARChartWidget = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/Dashboard/GetWidgetData`;
    const response = (await Instense.post(url,request,{
      cancelToken: LaborDashBoard.ajaxRequestRevPARChartWidget.token
     }) as any)?.data?.result?.result as IWidgetResponse[] | null
    return response;
  };

  public static GetWidgetAdrChartData = async (
    request: IWidgetRequest
  ): Promise<IWidgetResponse[] | null> => {
    if (LaborDashBoard.ajaxRequestAdrChartWidget ) {
       LaborDashBoard.ajaxRequestAdrChartWidget.cancel("Cancel"); 
   }
  LaborDashBoard.ajaxRequestAdrChartWidget = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/Dashboard/GetWidgetData`;
    const response = (await Instense.post(url,request,{
      cancelToken: LaborDashBoard.ajaxRequestAdrChartWidget.token
     }) as any)?.data?.result?.result as IWidgetResponse[] | null
    return response;
  };

  public static GetWidgetOccupancyCardData = async (
    request: IWidgetRequest
  ): Promise<IWidgetResponse[] | null> => {
    if (LaborDashBoard.ajaxRequestOccupancyCardWidget ) {
       LaborDashBoard.ajaxRequestOccupancyCardWidget.cancel("Cancel"); 
   }
  LaborDashBoard.ajaxRequestOccupancyCardWidget = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/Dashboard/GetWidgetData`;
    const response = (await Instense.post(url,request,{
      cancelToken: LaborDashBoard.ajaxRequestOccupancyCardWidget.token
     }) as any)?.data?.result?.result as IWidgetResponse[] | null
    return response;
  };


  public static GetWidgetOccupancyChartData = async (
    request: IWidgetRequest
  ): Promise<IWidgetResponse[] | null> => {
    if (LaborDashBoard.ajaxRequestOccupancyChartWidget ) {
       LaborDashBoard.ajaxRequestOccupancyChartWidget.cancel("Cancel"); 
   }
  LaborDashBoard.ajaxRequestOccupancyChartWidget = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/Dashboard/GetWidgetData`;
    const response = (await Instense.post(url,request,{
      cancelToken: LaborDashBoard.ajaxRequestOccupancyChartWidget.token
     }) as any)?.data?.result?.result as IWidgetResponse[] | null
    return response;
  };

  public static GetUserDashboardWidget = async (): Promise<IDashboardWidget[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const request: any = {};
    request.tenantId = tenantID;
    request.userUniqueno = +storage?.user_UniqueID;
    const url = `/Dashboard/GetUserDashboardWidget`;
    const response = (await Instense.get(url, {params:request}) as any)?.data?.result?.result as IDashboardWidget[] | null
    return response;
  };
  public static GetDashboardWidget = async (): Promise<IDashboardWidget[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const request: any = {};
    request.tenantId = tenantID;
    request.userUniqueno = +storage?.user_UniqueID;
    const url = `/Dashboard/GetDashboardWidget`;
    const response = (await Instense.get(url, {params:request}) as any)?.data?.result?.result as IDashboardWidget[] | null
    return response;
  };

  public static SaveDashboardWidgetOrder = async (request:IUserDashboardWidgetOrderRequest[]): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = `/Dashboard/SaveDashboardWidgetOrder`;
    const data=request.map(item=>{
      item.modByUserId = +storage?.user_UniqueID;
      item.userUniqueno = +storage?.user_UniqueID;
      item.tenantId = tenantID;
      return item;
    })
    const response = (await Instense.post(url,data) as any)?.data
    return response;
  };

  public static DataAggeragationAccountingDashboardSinglehoteldata = async (request:IDataAggrigation): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/DataAggeragationAccountingDashboardSinglehoteldata"
    request.TenantID = tenantID;

    if (LaborDashBoard.ajaxRequestDataAggeragationAccountingDashboardSinglehoteldata ) {
      LaborDashBoard.ajaxRequestDataAggeragationAccountingDashboardSinglehoteldata.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestDataAggeragationAccountingDashboardSinglehoteldata = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestDataAggeragationAccountingDashboardSinglehoteldata.token
     }) as any)?.data?.result

    return response;
  };

  public static DataAggeragationLaborSinglehoteldata = async (request:IDataAggrigation): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/DataAggeragationLaborSinglehoteldata"
    request.TenantID = tenantID;

    if (LaborDashBoard.ajaxRequestDataAggeragationLaborSinglehoteldata ) {
      LaborDashBoard.ajaxRequestDataAggeragationLaborSinglehoteldata.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestDataAggeragationLaborSinglehoteldata = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestDataAggeragationLaborSinglehoteldata.token
     }) as any)?.data?.result

    return response;
  };

  public static getDashboardData = async (request:IUserDashboardRequest): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/V2_Accounting_Dashboards_TopLevel"
    request.TenantID = tenantID;

    if (LaborDashBoard.ajaxRequestGetDashboard ) {
      LaborDashBoard.ajaxRequestGetDashboard.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestGetDashboard = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestGetDashboard.token
     }) as any)?.data?.result

    return response;
  };

  public static getLaborDashboardData = async (request:IUserDashboardRequest): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/LaborDashboardsTopLevel"
    request.TenantID = tenantID;

    if (LaborDashBoard.ajaxRequestGetLaborDashboardData ) {
      LaborDashBoard.ajaxRequestGetLaborDashboardData.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestGetLaborDashboardData = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestGetLaborDashboardData.token
     }) as any)?.data?.result

    return response;
  };

  public static getLaborDashboardDataChart = async (request:IUserDashboardRequest): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/V2_Labor_Dashboards_TopLevel"
    
    request.TenantID = tenantID;
    request.ischart =1;

    if (LaborDashBoard.ajaxRequestGetLaborDashboardDataChart ) {
      LaborDashBoard.ajaxRequestGetLaborDashboardDataChart.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestGetLaborDashboardDataChart = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestGetLaborDashboardDataChart.token
     }) as any)?.data?.result

    return response;
  };

  public static getHouseKeepingDashboardData = async (request): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/V2_HouseKeeping_Dashboards_TopLevel"
    request.TenantID = tenantID;

    if (LaborDashBoard.ajaxRequestGetHouseKeepingDashboardData ) {
      LaborDashBoard.ajaxRequestGetHouseKeepingDashboardData.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestGetHouseKeepingDashboardData = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestGetHouseKeepingDashboardData.token
     }) as any)?.data?.result

    return response;
  };
  
  public static getHouseKeepingDashboardDataChart = async (request): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/V2_HouseKeeping_Dashboards_Chart"
    request.TenantID = tenantID;
  

    if (LaborDashBoard.ajaxRequestGetHouseKeepingDashboardDataChart ) {
      LaborDashBoard.ajaxRequestGetHouseKeepingDashboardDataChart.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestGetHouseKeepingDashboardDataChart = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestGetHouseKeepingDashboardDataChart.token
     }) as any)?.data?.result

    return response;
  };

  public static getHouseKeepingDashboardUserDataChart = async (request): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/V2_DashboardHouskeepingCalculation"
    request.TenantID = tenantID;
  

    if (LaborDashBoard.ajaxRequestGetHouseKeepingDashboardUserDataChart ) {
      LaborDashBoard.ajaxRequestGetHouseKeepingDashboardUserDataChart.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestGetHouseKeepingDashboardUserDataChart = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestGetHouseKeepingDashboardUserDataChart.token
     }) as any)?.data?.result

    return response;
  };

  public static getHouseKeepingUserDashboardData = async (request:IUserDashboardRequest): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    const url = "/Dashboard/V2_DashboardHouskeepingCalculation"
    request.TenantID = tenantID;

    if (LaborDashBoard.ajaxRequestGetHouseKeepingUsersDashboardData ) {
      LaborDashBoard.ajaxRequestGetHouseKeepingUsersDashboardData.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestGetHouseKeepingUsersDashboardData = axios.CancelToken.source(); 
    
    const response = (await Instense.get(url,{
      params:request,
      cancelToken: LaborDashBoard.ajaxRequestGetHouseKeepingUsersDashboardData.token
     }) as any)?.data?.result

    return response;
  };



  public static AccountingDashboardsDailyFlashTrends = async (hid:string,fromdate:string,
    todate:string,lastYearVar:string,parameterId: number,periodType: string): Promise<IDashboardWidget[] | null> => {
      if (LaborDashBoard.ajaxRequestDailyFlashTrends ) {
        LaborDashBoard.ajaxRequestDailyFlashTrends.cancel("Cancel"); 
    }
    LaborDashBoard.ajaxRequestDailyFlashTrends = axios.CancelToken.source(); 
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID   = storage === null ? 0 : storage.tenantID as any;
    const request: any = {};
    request.tenantID = tenantID;
    request.hID = hid;
    request.fromdate = fromdate;
    request.todate = todate;
    request.lastYearVar = lastYearVar;
    request.parameterId = parameterId;
    request.periodType =periodType;

    const url = `/Dashboard/V2_Accounting_Dashboards_DailyFlashTrends`;
    const response = (await Instense.get(url, {params:request, cancelToken: LaborDashBoard.ajaxRequestDailyFlashTrends.token}) as any)?.data?.result as IDashboardWidget[] | null
    return response;
  };

  public static GetDashboardParameter = async (type:string ="Daily"): Promise<any[] | null> => {

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID   = storage === null ? 0 : storage.tenantID as any;
    const request: any = {};
    request.type = type;
    const url = `/Dashboard/Get_DashboardParameter`;
    const response = (await Instense.get(url, {params:request}) as any)?.data?.result as any[] | null
    return response;
  };
  
  public static GetLaborDayApprovalDetail = async(hIDs: string, datadate:string): Promise<any[]> => {
    if (LaborDashBoard.ajaxRequestLaborDayApprovalDetail ) {
      LaborDashBoard.ajaxRequestLaborDayApprovalDetail.cancel("Cancel"); 
    }
   LaborDashBoard.ajaxRequestLaborDayApprovalDetail = axios.CancelToken.source(); 

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    const request:any = {};
    request.hID =hIDs;
    request.tenantID =tenantID;
    request.datadate =datadate;
    const url = `/Dashboard/GetLaborDayApprovalDetail`;
    return Instense.get(url ,{params: request,
      cancelToken: LaborDashBoard.ajaxRequestLaborDayApprovalDetail.token
    }).then((response) => {
        return response.data?.result as any[];
    });
  };

  public static downloadLaborDashboardData = async (
    request: any,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantId = tenantID;

    const url = `/Dashboard/DownloadV2_V2_Labor_Dashboards_TopLevel`;
    return Instense.post(url,request, {  responseType: "blob" }).then(
      (response: any) => {
        
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static downloadHousekeepingDashboardData = async (
    request: any,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantId = tenantID;

    const url = `/Dashboard/DownloadV2_V2_HouseKeepingDashboards_TopLevel`;
    return Instense.post(url,request, {  responseType: "blob" }).then(
      (response: any) => {
        
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static downloadHousekeeperLevelDashboardDataCalculation = async (
    request: any,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.TenantID = tenantID;

    const url = `/Dashboard/DownloadV2_V2_DashboardHouskeepingCalculation`;
    return Instense.post(url,request, {  responseType: "blob" }).then(
      (response: any) => {
        
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };
  public static downloadAccountingDashboardData = async (
    request: any,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantId = tenantID;

    const url = `/Dashboard/DownloadV2_Accounting_Dashboards_TopLevel`;
    return Instense.post(url,request, {  responseType: "blob" }).then(
      (response: any) => {
        
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };



}

